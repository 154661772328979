@mixin loading-spinner($width, $height, $color: $color-primary) {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: $height;

  &.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .loading-spinner {
    width: $width;
    height: $height;
    transform-origin: center;
    background-image: url('/assets/images/theme/icon_loading.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    animation: loading-spinner 1.4s linear infinite;
  }
}

.branded-loading {
  @include loading-spinner(rem(60), rem(60));

  &.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    align-items: center;
    background: radial-gradient(circle, rgba(0,0,0, .4) 0%, rgba(0,0,0,0.1) 15%, rgba(255,255,255,.4) 100%);
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
