/* ----------------------------------
    Content Grid Block
---------------------------------- */

[data-block-type='content_grid'],
[data-block-type='screen_shots_grid'] {

  &:not([data-spacing-top]) .bound {
    @include responsive-spacing(padding, spacing-l null null);
  }

  &:not([data-spacing-bottom]) .bound {
    @include responsive-spacing(padding, null null spacing-l null);
  }

  .content-grid {

    /*  Grid Layout
    ---------------------------------- */

    &[data-column-count='four'] {

      li {

        /* Desktop */
        @include media($vp-xl-up) {
          @include span-columns(3 of 12);
          @include omega(4n);

          &:nth-last-child(-n+4) {
            margin-bottom: 0;
          }
        }

        /* Tablet */
        @include media($vp-m-to-xl) {
          @include span-columns(3 of 9);
          @include omega(3n);

          &:nth-last-child(-n+3) {
            margin-bottom: 0;
          }
        }
      }
    }

    &[data-column-count='three'] {

      li {

        /* Desktop */
        @include media($vp-xl-up) {
          @include span-columns(4 of 12);
          @include omega(3n);
        }

        /* Tablet */
        @include media($vp-m-to-xl) {
          @include span-columns(3 of 9);
          @include omega(3n);
        }

        &:nth-last-child(-n+3) {

          @include media($vp-m-up) {
            margin-bottom: 0;
          }
        }
      }
    }

    &[data-column-count='two'] {

      @include media($vp-xl-up) {

        li {
          @include span-columns(6 of 12);
          @include omega(2n);
        }
      }

      @include media($vp-m-to-xl) {

        li {
          @include span-columns(4.5 of 9);
          @include omega(2n);
        }
      }

      @include media($vp-m-up) {

        li:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }
    }


    /* Mobile */
    @include media($vp-m-down) {

      li {
        margin-bottom: $spacing-l;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }



    /*  Content Styling
    ---------------------------------- */

    li {
      @include responsive-spacing(margin, null null spacing-m);

      /*  Images
      ---------------------------------- */
      img {
        display: inline-block;
        max-width: 100%;

        /*  Image Fullness (col count based on 3 column grid) ---- */

        &[data-fullness="icon"] {   width: 30.69307%; } // 1.5 cols
        &[data-fullness="small"] {  width: 44.55446%; } // 2 cols
        &[data-fullness="medium"] { width: 51.48515%; } // 2.25 cols
        &[data-fullness="large"] {  width: 72.27723%; } // 3 cols
        &[data-fullness="fullWidth"] {  width: 100%; }

        @include media($vp-m-down) {  // ... of 6 cols
          &[data-fullness="icon"] {   width: 28.66242%; } // 2 cols
          &[data-fullness="small"] {  width: 37.57962%; } // 2.5 cols
          &[data-fullness="medium"] { width: 46.49682%; } // 3 cols
          &[data-fullness="large"] {  width: 64.33121%; } // 4 cols
        }
      }

      .wysiwyg {

        > *:first-child {
          margin-top: 0;
        }

        > *:last-child {
          margin-bottom: 0;
        }

        > *:only-child {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      img + .wysiwyg,
      .wysiwyg + .cta-atom {
        @include responsive-spacing(margin, spacing-s null null);
      }


      /*  Content Justification
      ---------------------------------- */

      &[data-justification="center"] { text-align: center; }
      &[data-justification="right"] {  text-align: right; }

    }
  }
}
