@charset "UTF-8";
/* ----------------------------------
		Styles
---------------------------------- */
/*  Libraries & Resets
---------------------------------- */
/* ----------------------------------
		Definitive - Neat Grid Settings

		More info here: http://webdesign.tutsplus.com/articles/a-rundown-of-bourbon-neat-variables--cms-24964
---------------------------------- */
/* 	Visual Grid Settings
---------------------------------- */
/* 	Grid Settings
---------------------------------- */
/* 	Breakpoints
---------------------------------- */
/* 	Media Queries ---- */
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/*! Flickity v2.0.3
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover {
  background: white;
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

/* flickity-fade */
.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

/*! angularjs-slider - v5.5.0 - 
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervieu.me>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> - 
 https://github.com/angular-slider/angularjs-slider - 
 2016-09-06 */
.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rzslider.with-legend {
  margin-bottom: 40px;
}

.rzslider[disabled] {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}

.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}

.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box;
}

.rzslider .rz-bar-wrapper.rz-draggable {
  cursor: move;
}

.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background: #d8e0f3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.rzslider .rz-bar.rz-selection {
  z-index: 2;
  background: #0db9f0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.rzslider .rz-pointer {
  top: -14px;
  z-index: 3;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #0db9f0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.rzslider .rz-pointer:after {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  content: "";
}

.rzslider .rz-pointer:hover:after {
  background-color: #ffffff;
}

.rzslider .rz-pointer.rz-active {
  z-index: 4;
}

.rzslider .rz-pointer.rz-active:after {
  background-color: #451aff;
}

.rzslider .rz-bubble {
  bottom: 16px;
  padding: 1px 3px;
  color: #55637d;
  cursor: default;
}

.rzslider .rz-bubble.rz-selection {
  top: 16px;
}

.rzslider .rz-bubble.rz-limit {
  color: #55637d;
}

.rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0;
  padding: 0 11px;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rzslider .rz-ticks .rz-tick {
  width: 10px;
  height: 10px;
  text-align: center;
  cursor: pointer;
  background: #d8e0f3;
  border-radius: 50%;
}

.rzslider .rz-ticks .rz-tick.rz-selected {
  background: #0db9f0;
}

.rzslider .rz-ticks .rz-tick .rz-tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0);
}

.rzslider .rz-ticks .rz-tick .rz-tick-legend {
  position: absolute;
  top: 24px;
  max-width: 50px;
  white-space: normal;
  transform: translate(-50%, 0);
}

.rzslider .rz-ticks.rz-ticks-values-under .rz-tick-value {
  top: initial;
  bottom: -40px;
}

.rzslider.rz-vertical {
  position: relative;
  width: 4px;
  height: 100%;
  padding: 0;
  margin: 0 20px;
  vertical-align: baseline;
}

.rzslider.rz-vertical .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rzslider.rz-vertical .rz-bar-wrapper {
  top: auto;
  left: 0;
  width: 32px;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0 0 0 -16px;
}

.rzslider.rz-vertical .rz-bar {
  bottom: 0;
  left: auto;
  width: 4px;
  height: 100%;
}

.rzslider.rz-vertical .rz-pointer {
  top: auto;
  bottom: 0;
  left: -14px !important;
}

.rzslider.rz-vertical .rz-bubble {
  bottom: 0;
  left: 16px !important;
  margin-left: 3px;
}

.rzslider.rz-vertical .rz-bubble.rz-selection {
  top: auto;
  left: 16px !important;
}

.rzslider.rz-vertical .rz-ticks {
  top: 0;
  left: -3px;
  z-index: 1;
  width: 0;
  height: 100%;
  padding: 11px 0;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.rzslider.rz-vertical .rz-ticks .rz-tick {
  vertical-align: middle;
}

.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-value {
  top: initial;
  left: 22px;
  transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-legend {
  top: initial;
  right: 24px;
  max-width: none;
  white-space: nowrap;
  transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-ticks.rz-ticks-values-under .rz-tick-value {
  right: 12px;
  bottom: initial;
  left: initial;
}

/* 	Theme Settings
---------------------------------- */
/* ----------------------------------
    Animations
---------------------------------- */
/* ----------------------------------
    Shadows
---------------------------------- */
/* ----------------------------------
    Theme Colors
---------------------------------- */
/*  Brand Specific Colors
---------------------------------- */
/*  Generic UI Colors
---------------------------------- */
/* 	Primary Colors ---- */
/* 	Base Colors ---- */
/* 	Backgrounds ---- */
/*  Borders ---- */
/* 	Activity Colors ---- */
/* ----------------------------------
		Global Reset
		Based upon 'reset.css' in the Yahoo! User Interface Library: http://developer.yahoo.com/yui
---------------------------------- */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline-color: rgba(255, 87, 158, 0.58);
}

*, html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, label, fieldset, input, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, b, i, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul, li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: inherit;
}

q:before, q:after {
  content: "";
}

header,
aside,
nav,
footer,
section,
article,
hgroup,
address,
figure,
figcaption,
video,
details,
small,
summary,
img,
main,
time,
dl, dt, dd,
li {
  display: block;
}

mark {
  background: none;
}

mark,
a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  min-height: 100%;
}

/* ----------------------------------
		Blow away Chrome's autofill input background-color
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Credit for this ingenius workaround: stackoverflow.com/a/37432260
---------------------------------- */
@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

/* 	Eliminate stock browser error states (esp. Firefox)
---------------------------------- */
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

/* ----------------------------------
    Theme Typography
---------------------------------- */
/* 	Font Basics
---------------------------------- */
/* 	Font Families ---- */
/*  Font Sizes ---- */
/*  Headline Sizes
---------------------------------- */
/* 	Body Sizes
---------------------------------- */
/* 	Basic "Normal" Typogaphy
---------------------------------- */
/*

These are scoped just to the .wysiwyg element. Take a look in that block styles file to see all the normal H1-6, ul, ol, etc styling.

That's also a good place to reference spacing considerations between the special headlines; Very Tiny Headline + Huge Headline, Huge + Normal, etc

*/
/* ----------------------------------
    Theme Buttons
---------------------------------- */
/* 	Text Links w/ Icons
---------------------------------- */
/* 	Button-y Buttons
---------------------------------- */
/* 	Split Buttons [ Load More|3 ]
---------------------------------- */
/* ----------------------------------
    Theme Layout
---------------------------------- */
/*  Spacing Base
---------------------------------- */
/*  Base as EMs instead ---- */
/*  Responsive Spacing
---------------------------------- */
/* ----------------------------------
    Theme Forms
---------------------------------- */
/*  Input Email & Password
---------------------------------- */
/*  Input Email (Currently used in Newsletter Signup)
---------------------------------- */
/*  Success Bars
---------------------------------- */
/*  Radio & Checkbox Buttons
---------------------------------- */
/*  Dropdowns
---------------------------------- */
@keyframes dropdown-flip {
  from {
    transform: translateY(-50%) rotateZ(0);
  }
  75% {
    transform: translateY(-50%) rotateZ(-180deg);
  }
  to {
    transform: translateY(-50%) rotateZ(-180deg);
  }
}
@keyframes dropdown-flip-reverse {
  from {
    transform: translateY(-50%) rotateZ(-180deg);
  }
  75% {
    transform: translateY(-50%) rotateZ(-360deg);
  }
  to {
    transform: translateY(-50%) rotateZ(-360deg);
  }
}
/*  Placeholder Text
---------------------------------- */
textarea::placeholder,
input::placeholder {
  color: #333333;
}

/*  IE: Placeholder Text
---------------------------------- */
input:-ms-input-placeholder {
  color: #333333 !important;
}

input::-ms-input-placeholder {
  color: #333333 !important;
}

/* 	Basics
---------------------------------- */
/* ----------------------------------
		Scaling for Responsive Elements
---------------------------------- */
/*
  This function returns a value at some stop
  between the min & max. This was conceived
  to help calculate responsive fonts since
  we have 2 themes with different font-scales
  e.g.:
  font-size: scale-stops(rem(55), rem(20), 4, 3);

  However, it could be used to figure out margins
  & spacing since those have similar issues. e.g.:
  margin-top: scale-stops($spacing-xl, $spacing-m, 4, 2);

  Q:  OK, but why "stops"?
  A:  Because when dealing with a design system that
      only defines desktop and mobile sizes it's nice
      to not have to do math by hand, and when themes
      are involved there's pretty much no way you could
      in a shared (base) stylesheet.
*/
/* ----------------------------------
    Spacing Helper Functions
---------------------------------- */
/*  Sizes as Maps
---------------------------------- */
/*  Clean up the Spacing Vars...
---------------------------------- */
/* ----------------------------------
		Maintain Aspect Ratio
---------------------------------- *

  from: https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

  The mixin assumes you'll be nesting an element
  with the class of content inside your initial
  block. Like this:

    <div class="sixteen-nine">
      <div class="content">
        insert content here
      </div>
    </div>

/* Use --------------------------- *

  .sixteen-nine {
    @include aspect-ratio(16, 9);
  }

---------------------------------- */
/*    For Flickity Aspect Raitio
---------------------------------- */
/* ----------------------------------
    Moby Circle Background
---------------------------------- */
/* ----------------------------------
    Numbers within Circles
---------------------------------- */
/* ----------------------------------
    Constrain Image by Aspect Ratio
---------------------------------- *

The mixin assumes you'll be nesting an element
with the class of content inside your initial
block. Like this:

  <div class="foo-img">
    <div class="content">
      insert content here
    </div>
  </div>
*/
/* ----------------------------------
    Hover & Focus

    We don't want to declare hover states
    because they can make touch devices
    freak out. So we'll use a media query
    to keep those to devices that have
    pointers. But this leads to annoyingly
    writting out duplicate styles for focus.

    Thus this mixin.
---------------------------------- */
/* ----------------------------------
		Items as a Comfy List

		Used primarily in ____, ___,
		___, & _____ cases.
---------------------------------- */
/* ----------------------------------
		Items as a Quadtych

		Used primarily in ____, ___,
		___, & _____ cases.
---------------------------------- */
.branded-loading {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 3.75rem;
}
.branded-loading.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.branded-loading .loading-spinner {
  width: 3.75rem;
  height: 3.75rem;
  transform-origin: center;
  background-image: url("/assets/images/theme/icon_loading.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: loading-spinner 1.4s linear infinite;
}
.branded-loading.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  align-items: center;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 15%, rgba(255, 255, 255, 0.4) 100%);
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------------------
		Mixins for controlling spacing
		of elements based on the theme's
		variables.

    Blatently taken mostly from Bourbon's mixin: https://github.com/thoughtbot/bourbon/blob/v4-stable/app/assets/stylesheets/helpers/_directional-values.scss
---------------------------------- */
/* ----------------------------------
    Underlines for Links
---------------------------------- */
/* ----------------------------------
    Base Typography

    These can mostly be ignored
    in practice.
---------------------------------- */
/*  Font Weights
---------------------------------- */
/*  Letter Spacing
---------------------------------- */
/*  Base Font Mixin
---------------------------------- */
/*  Links
---------------------------------- */
/*  Is itself a link ---- */
/*  Has a link inside it (headlines, etc) ---- */
/* 	HRs
---------------------------------- */
/* 	Lists
---------------------------------- */
/* ----------------------------------
    Basic Layout Items
---------------------------------- */
/*  Body Resets ---- */
html {
  background-color: #001623;
}

body {
  line-height: 1.75em;
  font-style: normal;
  color: #001623;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  min-width: 20rem;
  text-rendering: optimizeLegibility;
  background-color: #001623;
  -webkit-text-size-adjust: 100%;
  /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-print-color-adjust: exact !important;
}
body.menu_open {
  overflow: hidden;
}

#app {
  display: block;
  overflow-x: hidden;
  background: white;
}

#main {
  position: relative;
  z-index: 0;
  min-height: 31.25rem;
}

.bound {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
  width: 85%;
}
.bound::after {
  clear: both;
  content: "";
  display: table;
}

/*  Print
---------------------------------- */
@page {
  size: 8.5in 11in;
  margin: 20pt;
}
/* ----------------------------------
		Shared Buttons
---------------------------------- */
/* 	Text Links w/ Icons
---------------------------------- */
/* 	Base Button Mixin
---------------------------------- */
/* 	Disabled submit buttons
    (angular kicks this in if only 1 character is inputted into the adjoinging search field)
---------------------------------- */
a.submit[disabled=disabled] {
  cursor: default;
  opacity: 0.4;
  /*  Hover & Active States ---- */
}
a.submit[disabled=disabled]:focus {
  color: inherit !important;
}
@media (hover: hover) {
  a.submit[disabled=disabled]:hover {
    color: inherit !important;
  }
}

/* ----------------------------------
    Close Button
---------------------------------- */
/* ----------------------------------
    Buttons with Icons
---------------------------------- */
/*  Icon Button
---------------------------------- */
/* ----------------------------------
    Modal Base

    These are styles that are shared
    across all modals.
---------------------------------- */
body.modal-open {
  overflow: hidden;
}

/* ----------------------------------
    Wrapper
---------------------------------- */
.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: safe center;
  overflow: auto;
  padding: 5.25rem;
}
@media screen and (max-width: 61.9375rem) {
  .modal-wrapper {
    padding: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .modal-wrapper {
    padding: 0.625rem;
  }
}
.modal-wrapper .modal-container {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
.modal-wrapper.active .modal-container {
  opacity: 1;
}

/* ----------------------------------
    Modal itself
---------------------------------- */
.modal-container {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin: auto;
  display: flex;
  flex-shrink: 0;
  min-width: 18.75rem;
  max-width: 100%;
  padding: 0.625rem;
  background: white;
  /* ----------------------------------
      Close Button
  ---------------------------------- */
}
.modal-container::after {
  clear: both;
  content: "";
  display: table;
}
.modal-container .modal-content {
  position: relative;
  z-index: 0;
  flex-grow: 1;
}
.modal-container .btn-close {
  cursor: pointer;
  /*  Focus & Hover State
  ---------------------------------- */
  /*  Hover & Active States ---- */
  position: absolute;
  z-index: 1;
  top: 0.625rem;
  right: 0.625rem;
  margin-bottom: 0.625rem;
}
.modal-container .btn-close i {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.modal-container .btn-close .icon {
  position: relative;
  display: inline-block;
  width: 3.125em;
  height: 3.125em;
}
@media screen and (max-width: 61.9375rem) {
  .modal-container .btn-close .icon {
    width: 2.5em;
    height: 2.5em;
  }
}
@media screen and (max-width: 46.875rem) {
  .modal-container .btn-close .icon {
    width: 1.875em;
    height: 1.875em;
  }
}
.modal-container .btn-close .icon:before, .modal-container .btn-close .icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 0.125em;
  width: 100%;
  background-color: #2e408a;
  transition: background-color 200ms ease-in-out;
}
.modal-container .btn-close .icon:before {
  transform: rotate(-225deg);
}
.modal-container .btn-close .icon:after {
  transform: rotate(225deg);
}
.modal-container .btn-close:focus .icon:after,
.modal-container .btn-close:focus .icon:before {
  background-color: #1b2550;
}
@media (hover: hover) {
  .modal-container .btn-close:hover .icon:after,
.modal-container .btn-close:hover .icon:before {
    background-color: #1b2550;
  }
}

/* ----------------------------------
    Flickity Overrides
---------------------------------- */
/*  Prev/next buttons
---------------------------------- */
.flickity-prev-next-button {
  width: 2.625rem;
  height: 2.625rem;
  border: 0.125rem solid #2e408a;
}
.flickity-prev-next-button .arrow {
  fill: #2e408a;
}

/*  Button row
---------------------------------- */
.button-row {
  position: relative;
  display: block;
  text-align: center;
  /*  Move Prev/Next into bottom if
      they're used with the pg dots ---- */
  /*  Page Dots ---- */
}
@media screen and (min-width: 68.8125rem) {
  .button-row {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .button-row {
    margin-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .button-row {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .button-row {
    margin-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .button-row {
    margin-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .button-row {
    margin-bottom: 0.875rem;
  }
}
.button-row .flickity-prev-next-button,
.button-row .flickity-page-dots {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.button-row .flickity-prev-next-button {
  transform: translateY(0);
  left: auto;
  right: auto;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: 0.125rem solid #2e408a;
}
.button-row .flickity-prev-next-button .arrow {
  fill: #2e408a;
}
.button-row .flickity-page-dots {
  bottom: auto;
  width: auto;
  margin: 0 0.625rem;
}
.button-row .flickity-page-dots .dot {
  opacity: 1;
  background: transparent;
  border: 0.0625rem solid #2e408a;
}
.button-row .flickity-page-dots .dot.is-selected {
  box-shadow: inset 0 0 0 0.0625rem white;
  background: #2e408a;
}

/*  Content Styling
---------------------------------- */
/* ----------------------------------
    Manipulating the Layout of
    WYSIWYG, HTML, and CTA Blocks
---------------------------------- */
.content-block[data-block-type=wysiwyg],
.content-block[data-block-type=general-form],
.content-block[data-block-type=image],
.content-block[data-block-type=video],
.content-block[data-block-type=content-embed],
.content-block[data-block-type=ctas],
.content-block[data-block-type=hero],
.content-block[data-block-type=form] {
  /* 	Left Alignment
  ---------------------------------- */
  /* 	Right Alignment
      (neat can be slightly annoying sometimes...)
  ---------------------------------- */
  /* 	Center Alignment
  ---------------------------------- */
}
.content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container {
  margin-right: 0;
  /* 	Tablet & Desktop ---- */
  /* 	Mobile ---- */
}
@media screen and (min-width: 68.8125rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 9.5384615385%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 13.8461538462%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 31.0769230769%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 39.6923076923%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 56.9230769231%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 65.5384615385%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 74.1538461538%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 82.7692307692%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 91.3846153846%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 12.8630705394%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="1.5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 18.6721991701%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="2"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 41.9087136929%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="4"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 41.9087136929%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="6"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 53.5269709544%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="7"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 65.1452282158%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="8"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 76.7634854772%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="9"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 88.3817427386%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="10"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="11"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-desktop-width="12"]:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="2"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 28.6624203822%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="2"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 55.4140127389%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="3.5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="4"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 64.3312101911%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="4"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="6"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=left] .foundation-container[data-mobile-width="6"]:last-child {
    margin-right: 0;
  }
}
.content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container {
  margin-left: 0;
  /* 	Tablet & Desktop ---- */
  /* 	Mobile ---- */
}
@media screen and (min-width: 68.8125rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 9.5384615385%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 13.8461538462%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 31.0769230769%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 39.6923076923%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 48.3076923077%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 56.9230769231%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 65.5384615385%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 74.1538461538%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 82.7692307692%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 91.3846153846%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"] {
    float: right;
    display: block;
    margin-left: 3.3846153846%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child {
    margin-left: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 12.8630705394%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="1.5"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 18.6721991701%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="2"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 41.9087136929%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="4"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 41.9087136929%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="5"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 47.7178423237%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="6"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 53.5269709544%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="7"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 65.1452282158%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="8"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 76.7634854772%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="9"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 88.3817427386%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="10"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="11"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"] {
    float: right;
    display: block;
    margin-left: 4.5643153527%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-desktop-width="12"]:last-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="2"] {
    float: right;
    display: block;
    margin-left: 7.0063694268%;
    width: 28.6624203822%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="2"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"] {
    float: right;
    display: block;
    margin-left: 7.0063694268%;
    width: 55.4140127389%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="3.5"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="4"] {
    float: right;
    display: block;
    margin-left: 7.0063694268%;
    width: 64.3312101911%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="4"]:last-child {
    margin-left: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="6"] {
    float: right;
    display: block;
    margin-left: 7.0063694268%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=right] .foundation-container[data-mobile-width="6"]:last-child {
    margin-left: 0;
  }
}
.content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container {
  margin-right: 0;
  /* 	Tablet & Desktop ---- */
  /* 	Mobile ---- */
}
@media screen and (min-width: 68.8125rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 9.5384615385%;
    margin-left: 45.2307692308%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 13.8461538462%;
    margin-left: 43.0769230769%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 31.0769230769%;
    margin-left: 34.4615384615%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 39.6923076923%;
    margin-left: 30.1538461538%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
    margin-left: 25.8461538462%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 56.9230769231%;
    margin-left: 21.5384615385%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 65.5384615385%;
    margin-left: 17.2307692308%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 74.1538461538%;
    margin-left: 12.9230769231%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 82.7692307692%;
    margin-left: 8.6153846154%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 91.3846153846%;
    margin-left: 4.3076923077%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"] {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 12.8630705394%;
    margin-left: 43.5684647303%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="1.5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="2"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 18.6721991701%;
    margin-left: 40.6639004149%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="2"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="4"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 41.9087136929%;
    margin-left: 29.0456431535%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="4"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="5"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 41.9087136929%;
    margin-left: 29.0456431535%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="6"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
    margin-left: 26.1410788382%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="6"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="7"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 53.5269709544%;
    margin-left: 23.2365145228%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="7"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="8"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 65.1452282158%;
    margin-left: 17.4273858921%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="8"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="9"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 76.7634854772%;
    margin-left: 11.6182572614%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="9"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="10"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 88.3817427386%;
    margin-left: 5.8091286307%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="10"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="11"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="11"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="12"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"] {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-desktop-width="12"]:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="2"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="2"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 28.6624203822%;
    margin-left: 35.6687898089%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="2"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 55.4140127389%;
    margin-left: 22.2929936306%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="3.5"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="4"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="4"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 64.3312101911%;
    margin-left: 17.8343949045%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="4"]:last-child {
    margin-right: 0;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="6"],
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="6"] {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 100%;
  }
  .content-block[data-block-type=wysiwyg] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=general-form] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=image] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=video] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=content-embed] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=ctas] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=hero] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child,
.content-block[data-block-type=form] .bound[data-justification=center] .foundation-container[data-mobile-width="6"]:last-child {
    margin-right: 0;
  }
}

/* ----------------------------------
    Content Block Spacing
---------------------------------- */
@media screen and (min-width: 68.8125rem) {
  [data-spacing-top=spacing-xxs] .bound {
    padding-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-top=spacing-xxs] .bound {
    padding-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-top=spacing-xxs] .bound {
    padding-top: 0.625rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-bottom=spacing-xxs] .bound {
    padding-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-bottom=spacing-xxs] .bound {
    padding-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-bottom=spacing-xxs] .bound {
    padding-bottom: 0.625rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-top=spacing-xs] .bound {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-top=spacing-xs] .bound {
    padding-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-top=spacing-xs] .bound {
    padding-top: 0.875rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-bottom=spacing-xs] .bound {
    padding-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-bottom=spacing-xs] .bound {
    padding-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-bottom=spacing-xs] .bound {
    padding-bottom: 0.875rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-top=spacing-s] .bound {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-top=spacing-s] .bound {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-top=spacing-s] .bound {
    padding-top: 1.25rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-bottom=spacing-s] .bound {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-bottom=spacing-s] .bound {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-bottom=spacing-s] .bound {
    padding-bottom: 1.25rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-top=spacing-m] .bound {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-top=spacing-m] .bound {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-top=spacing-m] .bound {
    padding-top: 2.375rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-bottom=spacing-m] .bound {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-bottom=spacing-m] .bound {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-bottom=spacing-m] .bound {
    padding-bottom: 2.375rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-top=spacing-l] .bound {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-top=spacing-l] .bound {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-top=spacing-l] .bound {
    padding-top: 4.6875rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-bottom=spacing-l] .bound {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-bottom=spacing-l] .bound {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-bottom=spacing-l] .bound {
    padding-bottom: 4.6875rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-top=spacing-xl] .bound {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-top=spacing-xl] .bound {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-top=spacing-xl] .bound {
    padding-top: 5.25rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-bottom=spacing-xl] .bound {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-bottom=spacing-xl] .bound {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-bottom=spacing-xl] .bound {
    padding-bottom: 5.25rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-top=spacing-xxl] .bound {
    padding-top: 12.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-top=spacing-xxl] .bound {
    padding-top: 9.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-top=spacing-xxl] .bound {
    padding-top: 6.25rem;
  }
}

@media screen and (min-width: 68.8125rem) {
  [data-spacing-bottom=spacing-xxl] .bound {
    padding-bottom: 12.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-spacing-bottom=spacing-xxl] .bound {
    padding-bottom: 9.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-spacing-bottom=spacing-xxl] .bound {
    padding-bottom: 6.25rem;
  }
}

.content-block {
  position: relative;
  z-index: 5;
}
.content-block > * {
  position: relative;
  z-index: 5;
}
.content-block[data-zindex=minus-4] {
  z-index: 1;
}
.content-block[data-zindex=minus-3] {
  z-index: 2;
}
.content-block[data-zindex=minus-2] {
  z-index: 3;
}
.content-block[data-zindex=minus-1] {
  z-index: 4;
}
.content-block[data-zindex=plus-1] {
  z-index: 6;
}
.content-block[data-zindex=plus-2] {
  z-index: 7;
}
.content-block[data-zindex=plus-3] {
  z-index: 8;
}
.content-block[data-zindex=plus-4] {
  z-index: 9;
}

/* 	Scenery
---------------------------------- */
.scenery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*  Color  ---- */
  /*  Overlay  ---- */
  /*  Background Image & Video ---- */
}
.scenery.color {
  z-index: 1;
}
.scenery.screen {
  z-index: 4;
  opacity: 0;
  background: white;
}
.scenery.imagery, .scenery.video {
  z-index: 2;
  overflow: hidden;
}
.scenery.imagery img,
.scenery.imagery video, .scenery.video img,
.scenery.video video {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.scenery.video {
  z-index: 3;
}

/* 	Night Mode coloring ---- */
[data-night-mode=true] .scenery.screen {
  background: black;
  mix-blend-mode: multiply;
}

[data-top-rounded-edge=edge1]::before,
[data-bottom-rounded-edge=edge1]::after {
  background-image: url("images/theme/bkgrd_edge1.png");
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.0625rem;
  left: 0;
  z-index: 50;
  height: 2.5rem;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

[data-top-rounded-edge=edge1]::before {
  transform: rotate(180deg);
  bottom: auto;
  top: -0.0625rem;
}

[data-top-rounded-edge=edge2]::before,
[data-bottom-rounded-edge=edge2]::after {
  background-image: url("images/theme/bkgrd_edge2.png");
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.0625rem;
  left: 0;
  z-index: 50;
  height: 2.5rem;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

[data-top-rounded-edge=edge2]::before {
  transform: rotate(180deg);
  bottom: auto;
  top: -0.0625rem;
}

[data-top-rounded-edge=edge3]::before,
[data-bottom-rounded-edge=edge3]::after {
  background-image: url("images/theme/bkgrd_edge3.png");
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.0625rem;
  left: 0;
  z-index: 50;
  height: 2.5rem;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

[data-top-rounded-edge=edge3]::before {
  transform: rotate(180deg);
  bottom: auto;
  top: -0.0625rem;
}

/* ----------------------------------
    CTA - Arrow and text
---------------------------------- */
.btn-cta-txt {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  /*  Hover & Active States ---- */
}
.btn-cta-txt svg {
  width: 1.84em;
  margin-left: 0.84em;
}
.btn-cta-txt svg .arrow {
  transform-origin: center;
  transition: all 200ms ease-in-out;
}
.btn-cta-txt svg .circle {
  transition: all 200ms ease-in-out;
}
.btn-cta-txt:focus svg .arrow {
  transform: translateX(-20%) scale(1.24);
}
.btn-cta-txt:focus svg .circle {
  opacity: 0.1;
}
@media (hover: hover) {
  .btn-cta-txt:hover svg .arrow {
    transform: translateX(-20%) scale(1.24);
  }
  .btn-cta-txt:hover svg .circle {
    opacity: 0.1;
  }
}

/* ----------------------------------
    App Download Buttons

    Note that these are classed
    because they're in an atom html
---------------------------------- */
.btn-app_store,
.btn-play_store {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #333;
  font-size: 1em;
  border-radius: 0.5rem;
  text-decoration: none;
  transition: background-color 200ms ease-in-out;
  /*  Hover & Active States ---- */
}
.btn-app_store .logo,
.btn-play_store .logo {
  display: flex;
  align-items: center;
}
.btn-app_store .logo svg,
.btn-play_store .logo svg {
  height: 1.625em;
  margin: 0.3125em 0 0.3125em 0.5em;
}
.btn-app_store .txt,
.btn-play_store .txt {
  margin: 0.3125em 0.5em 0.3125em 0.25em;
}
.btn-app_store i,
.btn-play_store i {
  display: block;
  padding: 0 0.3125em;
  line-height: 1.1;
  font-size: 0.625em;
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
}
.btn-app_store b,
.btn-play_store b {
  display: block;
  padding: 0 0.3125em;
  grid-area: storename;
  line-height: 1.1;
  font-size: 0.9375em;
  font-weight: bold;
  color: white;
  white-space: nowrap;
}
.btn-app_store:focus,
.btn-play_store:focus {
  background-color: #000;
}
@media (hover: hover) {
  .btn-app_store:hover,
.btn-play_store:hover {
    background-color: #000;
  }
}

/* ----------------------------------
    CTA Atom
---------------------------------- */
.cta-atom {
  /*  Justification
  ---------------------------------- */
}
.cta-atom [data-btn-type=primary] {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: white;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.1875rem;
  background-color: #2e408a;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
}
.cta-atom [data-btn-type=primary] i,
.cta-atom [data-btn-type=primary] em {
  font-style: italic;
}
.cta-atom [data-btn-type=primary] i b,
.cta-atom [data-btn-type=primary] i strong,
.cta-atom [data-btn-type=primary] em b,
.cta-atom [data-btn-type=primary] em strong {
  font-style: italic;
}
.cta-atom [data-btn-type=primary] b,
.cta-atom [data-btn-type=primary] strong {
  font-weight: 600;
}
.cta-atom [data-btn-type=primary] b i,
.cta-atom [data-btn-type=primary] b em,
.cta-atom [data-btn-type=primary] strong i,
.cta-atom [data-btn-type=primary] strong em {
  font-weight: 600;
}
.cta-atom [data-btn-type=primary] a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.cta-atom [data-btn-type=primary] a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=primary] a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.cta-atom [data-btn-type=primary]:focus {
  color: white;
  background-image: none;
  text-decoration: none;
  background-color: #ff579e;
  border-color: transparent;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=primary]:hover {
    color: white;
    background-image: none;
    text-decoration: none;
    background-color: #ff579e;
    border-color: transparent;
  }
}
.cta-atom [data-btn-type=primary].inactive, .cta-atom [data-btn-type=primary]:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.cta-atom [data-btn-type=primary].inactive:focus, .cta-atom [data-btn-type=primary]:disabled:focus {
  color: white;
  border-color: transparent;
  background-color: #2e408a;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=primary].inactive:hover, .cta-atom [data-btn-type=primary]:disabled:hover {
    color: white;
    border-color: transparent;
    background-color: #2e408a;
  }
}
.cta-atom [data-btn-type=secondary] {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #2e408a;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #2e408a;
  border-radius: 0.1875rem;
  background-color: transparent;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
}
.cta-atom [data-btn-type=secondary] i,
.cta-atom [data-btn-type=secondary] em {
  font-style: italic;
}
.cta-atom [data-btn-type=secondary] i b,
.cta-atom [data-btn-type=secondary] i strong,
.cta-atom [data-btn-type=secondary] em b,
.cta-atom [data-btn-type=secondary] em strong {
  font-style: italic;
}
.cta-atom [data-btn-type=secondary] b,
.cta-atom [data-btn-type=secondary] strong {
  font-weight: 600;
}
.cta-atom [data-btn-type=secondary] b i,
.cta-atom [data-btn-type=secondary] b em,
.cta-atom [data-btn-type=secondary] strong i,
.cta-atom [data-btn-type=secondary] strong em {
  font-weight: 600;
}
.cta-atom [data-btn-type=secondary] a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.cta-atom [data-btn-type=secondary] a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=secondary] a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.cta-atom [data-btn-type=secondary]:focus {
  color: #fff;
  background-image: none;
  text-decoration: none;
  background-color: #2e408a;
  border-color: #2e408a;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=secondary]:hover {
    color: #fff;
    background-image: none;
    text-decoration: none;
    background-color: #2e408a;
    border-color: #2e408a;
  }
}
.cta-atom [data-btn-type=secondary].inactive, .cta-atom [data-btn-type=secondary]:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.cta-atom [data-btn-type=secondary].inactive:focus, .cta-atom [data-btn-type=secondary]:disabled:focus {
  color: #2e408a;
  border-color: #2e408a;
  background-color: transparent;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=secondary].inactive:hover, .cta-atom [data-btn-type=secondary]:disabled:hover {
    color: #2e408a;
    border-color: #2e408a;
    background-color: transparent;
  }
}
.cta-atom [data-btn-type=linkText] {
  font-family: "Poppins", sans-serif;
  font-size: 1.25em;
  font-weight: 500;
  color: #2e408a;
  line-height: 1.1em;
  display: inline-block;
  transition: color 200ms, background-color 200ms;
  padding-right: 1.25em;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  padding-right: 1em;
  font-weight: 600;
}
.cta-atom [data-btn-type=linkText] i,
.cta-atom [data-btn-type=linkText] em {
  font-style: italic;
}
.cta-atom [data-btn-type=linkText] i b,
.cta-atom [data-btn-type=linkText] i strong,
.cta-atom [data-btn-type=linkText] em b,
.cta-atom [data-btn-type=linkText] em strong {
  font-style: italic;
}
.cta-atom [data-btn-type=linkText] b,
.cta-atom [data-btn-type=linkText] strong {
  font-weight: 600;
}
.cta-atom [data-btn-type=linkText] b i,
.cta-atom [data-btn-type=linkText] b em,
.cta-atom [data-btn-type=linkText] strong i,
.cta-atom [data-btn-type=linkText] strong em {
  font-weight: 600;
}
.cta-atom [data-btn-type=linkText] a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.cta-atom [data-btn-type=linkText] a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=linkText] a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.cta-atom [data-btn-type=linkText]:after {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  margin: -0.2em -1.25em 0 0.25em;
  background-image: url("/assets/images/theme/link-arrow-left.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: opacity 200ms;
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .cta-atom [data-btn-type=linkText] {
    font-size: 1.1875em;
  }
}
@media screen and (max-width: 34rem) {
  .cta-atom [data-btn-type=linkText] {
    font-size: 1.125em;
  }
}
.cta-atom [data-btn-type=linkText]:focus {
  color: #ff579e;
}
.cta-atom [data-btn-type=linkText]:focus:after {
  opacity: 0.6;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=linkText]:hover {
    color: #ff579e;
  }
  .cta-atom [data-btn-type=linkText]:hover:after {
    opacity: 0.6;
  }
}
.cta-atom [data-btn-type=linkText]:after {
  height: 0.8em;
  width: 1em;
  background-size: 0.8em 0.8em;
  background-position: right center;
  margin-right: -1em;
}
.cta-atom [data-btn-type=circleTextLink] {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  /*  Hover & Active States ---- */
  font-size: 1.25em;
  color: #2e408a;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
}
.cta-atom [data-btn-type=circleTextLink] svg {
  width: 1.84em;
  margin-left: 0.84em;
}
.cta-atom [data-btn-type=circleTextLink] svg .arrow {
  transform-origin: center;
  transition: all 200ms ease-in-out;
}
.cta-atom [data-btn-type=circleTextLink] svg .circle {
  transition: all 200ms ease-in-out;
}
.cta-atom [data-btn-type=circleTextLink]:focus svg .arrow {
  transform: translateX(-20%) scale(1.24);
}
.cta-atom [data-btn-type=circleTextLink]:focus svg .circle {
  opacity: 0.1;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=circleTextLink]:hover svg .arrow {
    transform: translateX(-20%) scale(1.24);
  }
  .cta-atom [data-btn-type=circleTextLink]:hover svg .circle {
    opacity: 0.1;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .cta-atom [data-btn-type=circleTextLink] {
    font-size: 1.1875em;
  }
}
@media screen and (max-width: 34rem) {
  .cta-atom [data-btn-type=circleTextLink] {
    font-size: 1.125em;
  }
}
.cta-atom [data-btn-type=circleTextLink]:focus {
  color: #ff579e;
}
@media (hover: hover) {
  .cta-atom [data-btn-type=circleTextLink]:hover {
    color: #ff579e;
  }
}
.cta-atom .cta-atom-interior {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.cta-atom .cta-atom-primary,
.cta-atom .cta-atom-secondary {
  margin: 0.625em 0;
}
.cta-atom .with-secondary {
  margin-right: 1.125em;
}
.cta-atom .delimiter {
  display: inline-block;
  margin: 0.625em 1.125em 0.625em 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  font-style: italic;
  color: #828e90;
}
.cta-atom .delimiter i,
.cta-atom .delimiter em {
  font-style: italic;
}
.cta-atom .delimiter i b,
.cta-atom .delimiter i strong,
.cta-atom .delimiter em b,
.cta-atom .delimiter em strong {
  font-style: italic;
}
.cta-atom .delimiter b,
.cta-atom .delimiter strong {
  font-weight: 500;
}
.cta-atom .delimiter b i,
.cta-atom .delimiter b em,
.cta-atom .delimiter strong i,
.cta-atom .delimiter strong em {
  font-weight: 500;
}
.cta-atom .delimiter a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.cta-atom .delimiter a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .cta-atom .delimiter a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .cta-atom .delimiter {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .cta-atom .delimiter {
    font-size: 0.875rem;
  }
}
.cta-atom [data-btn-type=linkText] + .delimiter {
  margin-left: 0.25em;
}
.cta-atom [data-btn-type=linkText] + .delimiter + [data-btn-type=linkText] {
  margin-left: 0.25em;
}
.cta-atom[data-ctaJustification=center] .cta-atom-interior {
  justify-content: center;
}
.cta-atom[data-ctaJustification=right] .cta-atom-interior {
  justify-content: flex-end;
}

/*  Night Mode
---------------------------------- */
[data-night-mode=true] .cta-atom [data-btn-type=secondary] {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.42);
  /*  Hover & Active States ---- */
}
[data-night-mode=true] .cta-atom [data-btn-type=secondary]:focus {
  background: transparent;
  border-color: #fff;
}
@media (hover: hover) {
  [data-night-mode=true] .cta-atom [data-btn-type=secondary]:hover {
    background: transparent;
    border-color: #fff;
  }
}
[data-night-mode=true] .cta-atom [data-btn-type=linkText] {
  color: #fff;
  /*  Hover & Active States ---- */
}
[data-night-mode=true] .cta-atom [data-btn-type=linkText]:after {
  transition: all 200ms ease-in-out;
}
[data-night-mode=true] .cta-atom [data-btn-type=linkText]:focus {
  color: #fff;
  background-color: none;
  box-shadow: none;
}
[data-night-mode=true] .cta-atom [data-btn-type=linkText]:focus:after {
  -webkit-filter: saturate(0) brightness(500%);
  filter: saturate(0) brightness(500%);
  opacity: 1;
}
@media (hover: hover) {
  [data-night-mode=true] .cta-atom [data-btn-type=linkText]:hover {
    color: #fff;
    background-color: none;
    box-shadow: none;
  }
  [data-night-mode=true] .cta-atom [data-btn-type=linkText]:hover:after {
    -webkit-filter: saturate(0) brightness(500%);
    filter: saturate(0) brightness(500%);
    opacity: 1;
  }
}
[data-night-mode=true] .cta-atom [data-btn-type=circleTextLink] {
  color: #fff;
  /*  Hover & Active States ---- */
}
[data-night-mode=true] .cta-atom [data-btn-type=circleTextLink]:focus .arrow,
[data-night-mode=true] .cta-atom [data-btn-type=circleTextLink]:focus .circle {
  fill: #fff;
}
@media (hover: hover) {
  [data-night-mode=true] .cta-atom [data-btn-type=circleTextLink]:hover .arrow,
[data-night-mode=true] .cta-atom [data-btn-type=circleTextLink]:hover .circle {
    fill: #fff;
  }
}
[data-night-mode=true] .cta-atom .delimiter {
  color: rgba(255, 255, 255, 0.6);
}

/* ----------------------------------
    Device Frames

    This adds a frame around an image or Video
    to match the chosen device.

    Note you can use this mixin as a
    single frame or just add it to a
    div to allow all frames to be chosen
    in the CMS.

    Note that the source needs to have
    the image or video within a .screen
    child element.
---------------------------------- */
/*  Shared styling
---------------------------------- */
/*  Individual Devices
---------------------------------- */
/*  All Devices at once that's hooked up to pull `deviceFrame` from the CMS.
---------------------------------- */
/* ----------------------------------
    Fancy Select element
---------------------------------- */
.fancy_select {
  position: relative;
  display: block;
  width: 100%;
}
.fancy_select select {
  display: block;
  width: 100%;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  appearance: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.75em;
  border-radius: 0;
  border: 0.0625rem solid #001623;
  padding: 0.3em 1.9444444444em 0.3em 0.8em;
}
.fancy_select select i,
.fancy_select select em {
  font-style: italic;
}
.fancy_select select i b,
.fancy_select select i strong,
.fancy_select select em b,
.fancy_select select em strong {
  font-style: italic;
}
.fancy_select select b,
.fancy_select select strong {
  font-weight: 600;
}
.fancy_select select b i,
.fancy_select select b em,
.fancy_select select strong i,
.fancy_select select strong em {
  font-weight: 600;
}
.fancy_select select a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.fancy_select select a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .fancy_select select a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.fancy_select:before {
  content: "";
  position: absolute;
  width: 1.5625rem;
  height: 1.5625rem;
  top: 50%;
  right: 0.4444444444em;
  transform: translateY(-49%) rotate(180deg);
  background-image: url("/assets/images/theme/link-carrot-up.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

/* ----------------------------------
    Overlap Spacing
---------------------------------- */
[data-overlap-spacing=overlap-m] {
  margin-top: -2.375rem;
}
@media screen and (min-width: 46.9375rem) {
  [data-overlap-spacing=overlap-m] {
    margin-top: -2.5rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-overlap-spacing=overlap-m] {
    margin-top: -2.625rem;
  }
}

[data-overlap-spacing=overlap-l] {
  margin-top: -4.6875rem;
}
@media screen and (min-width: 46.9375rem) {
  [data-overlap-spacing=overlap-l] {
    margin-top: -5rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-overlap-spacing=overlap-l] {
    margin-top: -5.25rem;
  }
}

[data-overlap-spacing=overlap-xl] {
  margin-top: -5.25rem;
}
@media screen and (min-width: 46.9375rem) {
  [data-overlap-spacing=overlap-xl] {
    margin-top: -5.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-overlap-spacing=overlap-xl] {
    margin-top: -6.25rem;
  }
}

/* ----------------------------------
    Responsive Video
---------------------------------- */
.responsive_video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
}
.responsive_video iframe,
.responsive_video object,
.responsive_video embed,
.responsive_video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.result-template-thumbnail {
  display: flex;
  flex-direction: column;
  /*  Hover States ---- */
  /*  Hover & Active States ---- */
}
.result-template-thumbnail h5 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 200ms;
}
.result-template-thumbnail figure,
.result-template-thumbnail .image-not-available {
  background-color: #c8c8c8;
  transition: box-shadow 200ms;
}
@media screen and (min-width: 68.8125rem) {
  .result-template-thumbnail figure,
.result-template-thumbnail .image-not-available {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .result-template-thumbnail figure,
.result-template-thumbnail .image-not-available {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .result-template-thumbnail figure,
.result-template-thumbnail .image-not-available {
    margin-bottom: 0.625rem;
  }
}
.result-template-thumbnail figure span,
.result-template-thumbnail .image-not-available span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.75rem;
  color: white;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
}
.result-template-thumbnail figure {
  position: relative;
  display: block;
}
.result-template-thumbnail figure:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.result-template-thumbnail figure > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.result-template-thumbnail figure img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.result-template-thumbnail .image-not-available {
  position: relative;
}
.result-template-thumbnail .image-not-available:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.result-template-thumbnail .image-not-available > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.result-template-thumbnail:focus figure,
.result-template-thumbnail:focus .image-not-available {
  box-shadow: 0.25rem 0.25rem 0 #2e408a;
}
.result-template-thumbnail:focus h5 {
  color: #2e408a;
}
@media (hover: hover) {
  .result-template-thumbnail:hover figure,
.result-template-thumbnail:hover .image-not-available {
    box-shadow: 0.25rem 0.25rem 0 #2e408a;
  }
  .result-template-thumbnail:hover h5 {
    color: #2e408a;
  }
}

/* ----------------------------------
    Share Buttons
---------------------------------- */
.share_buttons {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0;
  line-height: 1;
}
.share_buttons a {
  display: inline-block;
  padding: 0 0.3125rem;
  /*  Hover & Active States ---- */
}
.share_buttons a i {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  width: 1px;
  overflow: hidden;
  font-size: 1rem;
}
.share_buttons a svg {
  display: inline-block;
  width: 1.5625rem;
  fill: #001623;
  transition: all ease-in-out 200ms;
}
.share_buttons a:focus svg {
  fill: #2e408a;
}
@media (hover: hover) {
  .share_buttons a:hover svg {
    fill: #2e408a;
  }
}

/* ----------------------------------
    AB Image Drag Block
---------------------------------- */
.ab_image_drag {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.ab_image_drag .image-a {
  max-width: 100%;
  user-select: none;
}
.ab_image_drag .image-b {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
  overflow: hidden;
  user-select: none;
}
.ab_image_drag .image-b img {
  height: 100%;
  user-select: none;
}
.ab_image_drag .is-visible .image-b {
  width: 50%;
}
.ab_image_drag .dragger {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: 0;
  width: 2px;
  height: 100%;
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.42);
  user-select: none;
}
.ab_image_drag .dragger .dragger-icon {
  position: relative;
  top: 50%;
  left: -14px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  transform: translateY(-50%) rotate(45deg);
  z-index: 2;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.42);
  user-select: none;
}
.ab_image_drag .dragger .dragger-icon:before {
  content: "";
  position: absolute;
  left: -0.375rem;
  top: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  background-image: url("/assets/images/theme/link-carrot-left-active.svg");
  background-size: 100% auto;
  transform: rotate(-45deg);
}
.ab_image_drag .dragger .dragger-icon:after {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: -0.375rem;
  width: 1.75rem;
  height: 1.75rem;
  background-image: url("/assets/images/theme/link-carrot-left-active.svg");
  background-size: 100% auto;
  transform: rotate(135deg);
}

@keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
/* ----------------------------------
    Background Media Block
---------------------------------- */
[data-block-type=background_media] .foundation-container {
  /*  Maintaining the aspect ratio for backgrounds ---- */
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=background_media] .foundation-container {
    background: none !important;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=small] {
    position: relative;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=small]:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 25%;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=small] > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=medium] {
    position: relative;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=medium]:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=medium] > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=large] {
    position: relative;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=large]:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
  [data-block-type=background_media] .foundation-container[data-desktop-height=large] > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=background_media] .foundation-container {
    background-size: cover;
  }
  [data-block-type=background_media] .foundation-container img {
    display: none;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=small] {
    position: relative;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=small]:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=small] > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=medium] {
    position: relative;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=medium]:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=medium] > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=large] {
    position: relative;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=large]:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 133.3333333333%;
  }
  [data-block-type=background_media] .foundation-container[data-mobile-height=large] > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* ----------------------------------
		Billboard Base
---------------------------------- */
/* 	Shared Layout & Basics
---------------------------------- */
[data-block-type=billboard] {
  position: relative;
  background-color: white;
  color: white;
  /* 	Content Bucket Layout ---- */
  /*  Logo Icon Sizing (same as "Small" image) ---- */
  /* 	Foreground Icon Sizing ---- */
  /* Foreground Image Justification ---- */
}
[data-block-type=billboard] .content-buckets {
  position: relative;
  z-index: 4;
}
[data-block-type=billboard] .logo-icon {
  width: 80%;
  max-width: 20.3125rem;
}
[data-block-type=billboard] .logo-icon img {
  width: 100%;
  height: auto;
}
[data-block-type=billboard] .foreground-icon {
  width: 5rem;
  margin-bottom: 1rem;
}
[data-block-type=billboard] .foreground-icon img {
  width: 100%;
  height: auto;
}
[data-block-type=billboard] .essay[data-justification=center] .foreground-icon,
[data-block-type=billboard] .essay[data-justification=center] .logo-icon {
  margin-right: auto;
  margin-left: auto;
}
[data-block-type=billboard] .essay[data-justification=right] .foreground-icon, [data-block-type=billboard] .essay[data-justification=right] .logo-icon {
  float: right;
}
[data-block-type=billboard] .essay[data-justification=right] .foreground-icon + *, [data-block-type=billboard] .essay[data-justification=right] .logo-icon + * {
  clear: both;
}

/* ----------------------------------
		Content Buckets
---------------------------------- */
.content-buckets {
  /* 	Basics ---- */
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  /*  Diptych Content Layout
  ---------------------------------- */
  /*  Image & Essay Sizing
  ---------------------------------- */
  /*  Vertically Center Essay Content on Desktop
  ---------------------------------- */
  /* 	Image & Essay Layout (Tablet Plus)
  ---------------------------------- */
  /*  Video & Essay Sizing & Layout
  ---------------------------------- */
  /* 	CTAs
  ---------------------------------- */
  /* 	Essay Max Widths
  ---------------------------------- */
  /* 	Prosaic Billboards
  ---------------------------------- */
  /* 	Essay Justification
  ---------------------------------- */
  /*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  TEXT JUSTIFICATION:

  Gets assigned on a per-element basis, from craft, ASSIGNED TO
  the actual prosaic element.

  Which means that it overrides both .essay and .wysiwyg
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  /* 	CTA Justification Overrides
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      (NOTE: This is a temporary patch
      that will be moot after CTAs
      are refactored to exhibit their
      own atomic L/C/R justification)
  ---------------------------------- */
  /* 	Image Vertical Behavior
  ---------------------------------- */
  /*  Tablets & Desktop Only ---- */
  /*  All Sizes ---- */
  /* 	Image Widths w/in Parent ("Fullness")
  ---------------------------------- */
  /* 	Image Gravity w/in Parent
  ---------------------------------- */
  /*  Tablets & Desktop Only ---- */
  /*  Mobile Only
  ---------------------------------- */
  /* 	Mobile (Lone, Block, Single,
      Vertical Column) Behavior
  ---------------------------------- */
  /*  Mobile Only ---- */
}
.content-buckets::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets .essay .essay-content {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets .essay .essay-content {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets .essay .essay-content {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets .essay .essay-content {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets .essay .essay-content {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets .essay .essay-content {
    padding-bottom: 4.6875rem;
  }
}
.content-buckets[data-horiz-split=none] .essay {
  width: 100%;
}
.content-buckets[data-horiz-split=none] .art {
  width: 100%;
}
.content-buckets[data-horiz-split=none] .foreground-video {
  width: 100%;
}
.content-buckets[data-horiz-split=halves][data-presumption=image] .essay {
  align-self: center;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-horiz-split=halves][data-presumption=image] .essay {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  .content-buckets[data-horiz-split=halves][data-presumption=image] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-horiz-split=halves][data-presumption=image] .essay {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
  }
  .content-buckets[data-horiz-split=halves][data-presumption=image] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-horiz-split=halves][data-presumption=image] .art {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  .content-buckets[data-horiz-split=halves][data-presumption=image] .art:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-horiz-split=halves][data-presumption=image] .art {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
  }
  .content-buckets[data-horiz-split=halves][data-presumption=image] .art:last-child {
    margin-right: 0;
  }
}
.content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .essay {
  align-self: center;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .essay {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 65.5384615385%;
  }
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .essay {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 65.1452282158%;
  }
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .art {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 31.0769230769%;
  }
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .art:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .art {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
  }
  .content-buckets[data-horiz-split=essay-heavy][data-presumption=image] .art:last-child {
    margin-right: 0;
  }
}
.content-buckets[data-horiz-split=essay-light][data-presumption=image] .essay {
  align-self: center;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .essay {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 31.0769230769%;
  }
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .essay {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
  }
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .art {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 65.5384615385%;
  }
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .art:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .art {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 65.1452282158%;
  }
  .content-buckets[data-horiz-split=essay-light][data-presumption=image] .art:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) {
  .content-buckets[data-axis=horizontal-reverse] .essay, .content-buckets[data-axis=horizontal-forward] .essay {
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
  }
  .content-buckets[data-axis=horizontal-reverse] .essay .essay-wrap, .content-buckets[data-axis=horizontal-forward] .essay .essay-wrap {
    width: 100%;
    height: auto;
  }
}
@media screen and (min-width: 46.9375rem) {
  .content-buckets[data-presumption=image] {
    /* Foreground Media Behaviors ---- */
  }
  .content-buckets[data-presumption=image][data-axis=horizontal-reverse] {
    justify-content: space-between;
  }
  .content-buckets[data-presumption=image][data-axis=horizontal-reverse] .essay {
    order: 2;
    -ms-flex-order: 1;
    margin-left: 3.4854771784%;
    margin-right: 0;
  }
  .content-buckets[data-presumption=image][data-axis=horizontal-reverse] .art {
    order: 1;
    -ms-flex-order: 0;
  }
  .content-buckets[data-presumption=image][data-axis=horizontal-reverse] .art + .essay {
    margin-left: 0;
  }
  .content-buckets[data-presumption=image][data-axis=horizontal-forward], .content-buckets[data-presumption=image][data-axis=horizontal-reverse] {
    min-height: 100%;
    display: flex;
    -webkit-box-align: stretch;
    -moz-box-align: stretch;
    box-align: stretch;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    -o-align-items: stretch;
    align-items: stretch;
    -ms-flex-align: stretch;
  }
  .content-buckets[data-presumption=image][data-axis=horizontal-forward] .art-wrap, .content-buckets[data-presumption=image][data-axis=horizontal-reverse] .art-wrap {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  .content-buckets[data-presumption=image][data-axis=horizontal-forward] img, .content-buckets[data-presumption=image][data-axis=horizontal-reverse] img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}
@media screen and (min-width: 46.9375rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves] {
    min-height: 100%;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video-wrap, .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video-wrap {
    width: 100%;
    height: auto;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video-wrap, .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video-wrap {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video-wrap, .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video-wrap {
    padding-top: 5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 46.875rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video-wrap, .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video-wrap {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video-wrap, .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video-wrap {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video-wrap, .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video-wrap {
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 46.875rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video-wrap, .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video-wrap {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves] .foreground-video {
    padding-bottom: 5.25rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves] .foreground-video {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves] .foreground-video {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .essay {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .essay {
    width: 100%;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
    float: right;
    margin-right: 0;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-forward] .foreground-video {
    width: 100%;
  }
}
@media screen and (min-width: 46.9375rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] {
    justify-content: space-between;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .essay {
    order: 2;
    margin-left: 3.4461538462%;
    margin-right: 0;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foregraound-video {
    order: 1;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video + .essay {
    margin-left: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .essay {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
    margin-right: 0;
    right: 0;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .essay:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .essay {
    width: 100%;
  }
}
.content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video {
  position: relative;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=video][data-horiz-split=halves][data-axis=horizontal-reverse] .foreground-video {
    width: 100%;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets .essay-content .cta-atom {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets .essay-content .cta-atom {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets .essay-content .cta-atom {
    margin-top: 1.25rem;
  }
}
.content-buckets .essay-content {
  margin: 0 auto;
}
.content-buckets .essay-content[data-essay-max-width=small] {
  max-width: 27.625rem;
}
.content-buckets .essay-content[data-essay-max-width=medium] {
  max-width: 39rem;
}
.content-buckets .essay-content[data-essay-max-width=large] {
  max-width: 53.25rem;
}
.content-buckets .essay-content[data-essay-max-width=full] {
  max-width: 100%;
}
.content-buckets[data-presumption=prosaic] .essay[data-justification=center][data-plate=off] .essay-content {
  margin: 0 auto;
}
.content-buckets[data-presumption=prosaic] .essay[data-justification=right][data-plate=off] .essay-content {
  float: right;
}
.content-buckets .essay[data-justification=left] {
  text-align: left;
}
.content-buckets .essay[data-justification=center] {
  text-align: center;
}
.content-buckets .essay[data-justification=right] {
  text-align: right;
}
.content-buckets .essay[data-justification=left]:not([data-plate=on]) .wysiwyg,
.content-buckets .essay[data-justification=center]:not([data-plate=on]) .wysiwyg,
.content-buckets .essay[data-justification=right]:not([data-plate=on]) .wysiwyg {
  text-align: left;
}
.content-buckets[data-presumption=prosaic] .essay[data-justification=right] {
  text-align: left;
}
@media screen and (min-width: 46.9375rem) {
  .content-buckets[data-axis=vertical-ceiling] .essay, .content-buckets[data-axis=vertical-superior] .essay, .content-buckets[data-axis=vertical-inferior] .essay, .content-buckets[data-axis=vertical-ground] .essay {
    margin: 0 auto;
  }
  .content-buckets[data-axis=vertical-ceiling] .art-wrap, .content-buckets[data-axis=vertical-superior] .art-wrap, .content-buckets[data-axis=vertical-inferior] .art-wrap, .content-buckets[data-axis=vertical-ground] .art-wrap {
    margin: 0 auto;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-ceiling] .essay-content {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-ceiling] .essay-content {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-ceiling] .essay-content {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-ceiling] .essay-content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-ceiling] .essay-content {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-ceiling] .essay-content {
    padding-bottom: 5.25rem;
  }
}
.content-buckets[data-axis=vertical-ceiling] .art,
.content-buckets[data-axis=vertical-ceiling] .foreground-video {
  padding: 0;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-superior] .essay-content {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-superior] .essay-content {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-superior] .essay-content {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-superior] .essay-content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-superior] .essay-content {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-superior] .essay-content {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-superior] .art,
.content-buckets[data-axis=vertical-superior] .foreground-video {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-superior] .art,
.content-buckets[data-axis=vertical-superior] .foreground-video {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-superior] .art,
.content-buckets[data-axis=vertical-superior] .foreground-video {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-inferior] .essay-content {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-inferior] .essay-content {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-inferior] .essay-content {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-inferior] .essay-content {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-inferior] .essay-content {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-inferior] .essay-content {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-inferior] .art,
.content-buckets[data-axis=vertical-inferior] .foreground-video {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-inferior] .art,
.content-buckets[data-axis=vertical-inferior] .foreground-video {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-inferior] .art,
.content-buckets[data-axis=vertical-inferior] .foreground-video {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-ground] .essay-content {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-ground] .essay-content {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-ground] .essay-content {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-axis=vertical-ground] .essay-content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=vertical-ground] .essay-content {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-axis=vertical-ground] .essay-content {
    padding-bottom: 5.25rem;
  }
}
.content-buckets[data-axis=vertical-ground] .art,
.content-buckets[data-axis=vertical-ground] .foreground-video {
  padding: 0;
}
.content-buckets[data-presumption=image] {
  /* 	Mobile ---- */
}
.content-buckets[data-presumption=image] .art img {
  width: 100%;
  height: auto;
}
.content-buckets[data-presumption=image] .art-wrap[data-fullness=full] {
  width: 100%;
}
.content-buckets[data-presumption=image] .art-wrap[data-fullness=large] {
  width: 75%;
}
.content-buckets[data-presumption=image] .art-wrap[data-fullness=medium] {
  width: 50%;
}
.content-buckets[data-presumption=image] .art-wrap[data-fullness=small] {
  width: 25%;
}
.content-buckets[data-presumption=image] .art-wrap[data-fullness=icon] {
  width: 48px;
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=image] .art img {
    margin: 0 auto;
  }
  .content-buckets[data-presumption=image] .art-wrap[data-fullness=large] {
    width: 100%;
  }
  .content-buckets[data-presumption=image] .art-wrap[data-fullness=medium] {
    width: 75%;
  }
  .content-buckets[data-presumption=image] .art-wrap[data-fullness=small] {
    width: 50%;
  }
  .content-buckets[data-presumption=image] .art-wrap[data-fullness=icon] {
    width: 48px;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 68.8125rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=ceiling] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=ceiling] img {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=ceiling] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=ceiling] img {
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 46.875rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=ceiling] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=ceiling] img {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (min-width: 46.9375rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=ground], .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=ground] {
    display: flex;
    -webkit-box-align: end;
    -moz-box-align: end;
    box-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    -o-align-items: flex-end;
    align-items: flex-end;
    -ms-flex-align: end;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 68.8125rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=ground] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=ground] img {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=ground] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=ground] img {
    padding-top: 5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 46.875rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=ground] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=ground] img {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 46.9375rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=floaty], .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=floaty] {
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 68.8125rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=floaty] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=floaty] img {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=floaty] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=floaty] img {
    padding-top: 5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 46.875rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=floaty] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=floaty] img {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 68.8125rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=floaty] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=floaty] img {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=floaty] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=floaty] img {
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 46.875rem) {
  .content-buckets[data-axis=horizontal-forward] .art[data-gravity=floaty] img, .content-buckets[data-axis=horizontal-reverse] .art[data-gravity=floaty] img {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  .content-buckets .art[data-gravity=floaty] img {
    padding-bottom: 5.25rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets .art[data-gravity=floaty] img {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  .content-buckets .art[data-gravity=floaty] img {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  .content-buckets[data-axis=horizontal-forward] .essay-content, .content-buckets[data-axis=horizontal-reverse] .essay-content {
    padding-top: 6.25rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=horizontal-forward] .essay-content, .content-buckets[data-axis=horizontal-reverse] .essay-content {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  .content-buckets[data-axis=horizontal-forward] .essay-content, .content-buckets[data-axis=horizontal-reverse] .essay-content {
    padding-top: 5.25rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  .content-buckets[data-axis=horizontal-forward] .essay-content, .content-buckets[data-axis=horizontal-reverse] .essay-content {
    padding-bottom: 5.25rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-axis=horizontal-forward] .essay-content, .content-buckets[data-axis=horizontal-reverse] .essay-content {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  .content-buckets[data-axis=horizontal-forward] .essay-content, .content-buckets[data-axis=horizontal-reverse] .essay-content {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets .essay + .foreground-video {
    padding-top: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets .art-wrap {
    margin: 0 auto;
  }
}

/* ----------------------------------
    Content Frame & Plate
---------------------------------- */
[data-block-type=billboard] {
  /* 	Frame Around Content
  ---------------------------------- */
  /* 	Plate Under Content
  ---------------------------------- */
}
[data-block-type=billboard] .essay[data-frame=on] {
  padding: 6.25rem;
}
@media screen and (max-width: 68.75rem) {
  [data-block-type=billboard] .essay[data-frame=on] {
    padding: 5.25rem 0;
  }
}
@media screen and (max-width: 61.9375rem) {
  [data-block-type=billboard] .essay[data-frame=on] {
    padding: 5rem 0;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-frame=on] {
    padding: 1.25rem 0;
  }
}
[data-block-type=billboard] .essay[data-frame=on] .essay-content {
  display: table;
  min-height: 25rem;
  box-sizing: border-box;
  margin: 0 auto;
  border: solid rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 62rem) {
  [data-block-type=billboard] .essay[data-frame=on] .essay-content {
    min-width: 25rem;
    border-width: 0.875rem;
    padding: 2.625rem;
  }
}
@media screen and (max-width: 61.9375rem) {
  [data-block-type=billboard] .essay[data-frame=on] .essay-content {
    min-width: auto;
    width: 80%;
    border-width: 0.75rem;
    padding: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-frame=on] .essay-content {
    min-width: auto;
    width: 100%;
    border-width: 0.5rem;
    padding: 1.25rem;
  }
}
[data-block-type=billboard] .essay[data-frame=on] .essay-content .essay-content-wrapper {
  display: table-cell;
  vertical-align: middle;
}
[data-block-type=billboard] .essay[data-frame=on] .essay-content > .wysiwyg,
[data-block-type=billboard] .essay[data-frame=on] .essay-content > .cta-atom {
  width: 100%;
}
[data-block-type=billboard] .essay[data-frame=on] .essay-content[data-essay-max-width=small] {
  max-width: 25rem;
}
[data-block-type=billboard][data-night-mode=true] .essay[data-frame=on] .essay-content {
  border-color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard] .essay[data-plate=on] {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard] .essay[data-plate=on] {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-plate=on] {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard] .essay[data-plate=on] {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard] .essay[data-plate=on] {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-plate=on] {
    padding-bottom: 2.375rem;
  }
}
[data-block-type=billboard] .essay[data-plate=on] .essay-content {
  box-sizing: border-box;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-right: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-right: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-right: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-bottom: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-left: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-left: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard] .essay[data-plate=on] .essay-content {
    padding-left: 2.375rem;
  }
}
[data-block-type=billboard][data-night-mode=true] .essay[data-plate=on] .essay-content {
  background-color: rgba(0, 0, 0, 0.6);
}

/* ----------------------------------
    Billboard with Snug Header
---------------------------------- */
@media screen and (min-width: 62rem) {
  .snug + .main [data-block-type=billboard] .bound {
    padding-top: 2.625rem;
  }
}
/* ----------------------------------
		Billboard - Showcase
---------------------------------- */
.content-buckets[data-presumption=showcase] {
  display: table;
  /* 	Brochure & Portrait Defaults ---- */
  /*
  ---------------------------------- */
  /*
  ---------------------------------- */
  /* 	Layout - S down
  ---------------------------------- */
}
.content-buckets[data-presumption=showcase][data-linearity=true] {
  direction: rtl;
}
.content-buckets[data-presumption=showcase] > * {
  direction: ltr;
}
.content-buckets[data-presumption=showcase] .brochure,
.content-buckets[data-presumption=showcase] .portrait {
  box-sizing: border-box;
  display: table-cell;
  vertical-align: middle;
}
.content-buckets[data-presumption=showcase] .brochure {
  width: 60.3076923077%;
  padding: 6.25rem 0;
}
.content-buckets[data-presumption=showcase] .portrait {
  width: 39.6923076923%;
}
.content-buckets[data-presumption=showcase] .portrait img {
  width: 100%;
  height: auto;
}
.content-buckets[data-presumption=showcase][data-linearity=true] .brochure-inner {
  margin-right: 19.8979591837%;
}
.content-buckets[data-presumption=showcase][data-linearity=false] .brochure-inner {
  margin-left: 19.8979591837%;
}
.content-buckets[data-presumption=showcase] .flagship {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}
.content-buckets[data-presumption=showcase] .flagship.simple-feature {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.content-buckets[data-presumption=showcase] .appendices p {
  margin-bottom: 0 !important;
}
.content-buckets[data-presumption=showcase] .appendices .feature {
  width: 46.4968152866%;
  float: left;
  margin-right: 3.5031847134%;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices .feature {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices .feature {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices .feature {
    margin-bottom: 1.25rem;
  }
}
.content-buckets[data-presumption=showcase] .appendices .feature:nth-of-type(2n) {
  margin-right: 0;
}
.content-buckets[data-presumption=showcase] .appendices .feature:nth-of-type(2n+1) {
  clear: both;
}
.content-buckets[data-presumption=showcase] .appendices .foreground-icon {
  width: 23.2876712329%;
  max-width: 68px;
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices .foreground-icon {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices .foreground-icon {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices .foreground-icon {
    margin-bottom: 1.25rem;
  }
}
.content-buckets[data-presumption=showcase] .appendices h4 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
  text-transform: none;
}
.content-buckets[data-presumption=showcase] .appendices h4 i,
.content-buckets[data-presumption=showcase] .appendices h4 em {
  font-style: italic;
}
.content-buckets[data-presumption=showcase] .appendices h4 i b,
.content-buckets[data-presumption=showcase] .appendices h4 i strong,
.content-buckets[data-presumption=showcase] .appendices h4 em b,
.content-buckets[data-presumption=showcase] .appendices h4 em strong {
  font-style: italic;
}
.content-buckets[data-presumption=showcase] .appendices h4 b,
.content-buckets[data-presumption=showcase] .appendices h4 strong {
  font-weight: 400;
}
.content-buckets[data-presumption=showcase] .appendices h4 b i,
.content-buckets[data-presumption=showcase] .appendices h4 b em,
.content-buckets[data-presumption=showcase] .appendices h4 strong i,
.content-buckets[data-presumption=showcase] .appendices h4 strong em {
  font-weight: 400;
}
.content-buckets[data-presumption=showcase] .appendices h4 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.content-buckets[data-presumption=showcase] .appendices h4 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .content-buckets[data-presumption=showcase] .appendices h4 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices h4 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices h4 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices h4 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  .content-buckets[data-presumption=showcase] .appendices h4 {
    font-size: 0.9375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices h4 {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices h4 {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices h4 {
    margin-bottom: 0.625rem;
  }
}
.content-buckets[data-presumption=showcase] .appendices p {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
}
.content-buckets[data-presumption=showcase] .appendices p i,
.content-buckets[data-presumption=showcase] .appendices p em {
  font-style: italic;
}
.content-buckets[data-presumption=showcase] .appendices p i b,
.content-buckets[data-presumption=showcase] .appendices p i strong,
.content-buckets[data-presumption=showcase] .appendices p em b,
.content-buckets[data-presumption=showcase] .appendices p em strong {
  font-style: italic;
}
.content-buckets[data-presumption=showcase] .appendices p b,
.content-buckets[data-presumption=showcase] .appendices p strong {
  font-weight: 500;
}
.content-buckets[data-presumption=showcase] .appendices p b i,
.content-buckets[data-presumption=showcase] .appendices p b em,
.content-buckets[data-presumption=showcase] .appendices p strong i,
.content-buckets[data-presumption=showcase] .appendices p strong em {
  font-weight: 500;
}
.content-buckets[data-presumption=showcase] .appendices p a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.content-buckets[data-presumption=showcase] .appendices p a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .content-buckets[data-presumption=showcase] .appendices p a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 34rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-top: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-top: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-top: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] .appendices p {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .content-buckets[data-presumption=showcase] .appendices .feature {
    float: none !important;
    width: 100%;
  }
}
@media screen and (max-width: 46.875rem) {
  .content-buckets[data-presumption=showcase] {
    display: block;
  }
  .content-buckets[data-presumption=showcase] .brochure,
.content-buckets[data-presumption=showcase] .portrait {
    display: block;
    width: 100%;
  }
  .content-buckets[data-presumption=showcase] .portrait {
    padding-top: 6.25rem;
  }
  .content-buckets[data-presumption=showcase] .portrait img {
    max-width: 400px;
    margin: 0 auto;
  }
  .content-buckets[data-presumption=showcase] .brochure-inner {
    max-width: 600px;
    margin: 0 auto !important;
  }
}
@media screen and (max-width: 34rem) {
  .content-buckets[data-presumption=showcase] .appendices .feature {
    float: none !important;
    width: 100%;
  }
}

/* ----------------------------------
		Night Mode
---------------------------------- */
.content-block[data-night-mode=true] .content-buckets[data-presumption=showcase] .appendices h4,
.content-block[data-night-mode=true] .content-buckets[data-presumption=showcase] .appendices p {
  color: white;
}
.content-block[data-night-mode=true] .content-buckets[data-presumption=showcase] .flagship {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* ----------------------------------
    Billboard Slider Block
---------------------------------- */
/*  General Setup
---------------------------------- */
/*  Animate each slide when height changes ---- */
.flickity-viewport {
  transition: height 0.2s;
}

/*  Gallery layout & lazyload animation defaults ---- */
.gallery .gallery-cell {
  width: 100%;
  /* fade in background image when loaded */
  /* fade in image when loaded */
}
.gallery .gallery-cell .content-block {
  width: 100%;
}
.gallery .gallery-cell .scenery.imagery {
  transition: opacity 0.4s ease-in-out;
}
.gallery .gallery-cell .scenery.imagery.flickity-bg-lazyloaded, .gallery .gallery-cell .scenery.imagery.flickity-bg-lazyerror {
  opacity: 1;
}
.gallery .gallery-cell img {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
.gallery .gallery-cell img.flickity-lazyloaded, .gallery .gallery-cell img.flickity-lazyerror {
  opacity: 1;
}

/*  Conistant Height Additions
---------------------------------- */
[data-height-option="1"] .gallery-cell {
  min-height: 100%;
  display: flex;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  box-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
  -ms-flex-align: stretch;
}
[data-height-option="1"] .bound {
  height: 100%;
}

/*  Page Dot Nav
---------------------------------- */
/*  Prev/Next button placement ---- *

.media-carousel,
.gallery {

  .flickity-prev-next-button {
    top: auto;
    bottom: auto;
  }
}

/* Page Dot Buttons ---- *

.button-row {
  display: block;
  position: absolute;
  width: 100%;
  bottom: rem(20);
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-page-dots {
  position: static;
  display: inline-block;
  bottom: auto;
  width: auto;
  padding-bottom: rem(5);

  .dot {
    opacity: 1;
  }
}

.flickity-prev-next-button {
  display: inline-block;
  position: static;
  height: rem(30);
  width: rem(30);
  margin: 0;
  background: transparent;
  vertical-align: bottom;
  transform: translateY(0);

  &.previous {
    left: auto;
  }

  &.next {
    right: auto;
  }

  &:hover {
    background: transparent;
  }

  &:disabled {
    opacity: 0.5;
  }

  .arrow {
    fill: $color-primary;
  }
}
--- */
/* ----------------------------------
    Billboard Tabs Block
---------------------------------- */
[data-block-type=billboard-tabs] {
  z-index: 0;
  /*  Cell Content
  ---------------------------------- */
  /*  Tabs Naviation
  ---------------------------------- */
  /*  Nav Buttons ---- */
  /*  Dark Background Nav Colors ---- */
  /*  Flickity Buttons/Dots Nav
  ---------------------------------- */
}
[data-block-type=billboard-tabs][data-visible=hidden] {
  opacity: 0;
}
[data-block-type=billboard-tabs][data-visible=visible] {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
[data-block-type=billboard-tabs] .bound {
  max-width: none;
  width: 100%;
}
[data-block-type=billboard-tabs] .billboard-tabs {
  /*  Billboard/Cell Layout &
  /*  Flickity Behavior
  ---------------------------------- */
  z-index: 2;
  height: 100%;
}
[data-block-type=billboard-tabs] .billboard-tabs .flickity-viewport {
  transition: none;
}
[data-block-type=billboard-tabs] .billboard-tabs .flickity-slider {
  position: relative;
  transform: none !important;
}
[data-block-type=billboard-tabs] .billboard-tabs .cell-wrapper {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
  width: 85%;
}
[data-block-type=billboard-tabs] .billboard-tabs .cell-wrapper::after {
  clear: both;
  content: "";
  display: table;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell {
  left: 0 !important;
  bottom: 0;
  width: 100%;
  z-index: 0;
  /*  Content ---- */
  /*  Cell Background ---- */
  /*  Re-order content on mobile
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell {
    padding-bottom: 4.6875rem;
  }
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell.is-selected {
  opacity: 1;
  z-index: 1;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell.is-selected .wysiwyg,
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell.is-selected .cell-bkgrd {
  opacity: 1;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell.is-selected .tab-icon .img-wrapper {
  border-color: #2e408a;
  transition: border 0.4s ease-in-out;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell:not(.is-selected) .tab-row,
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell:not(.is-selected) .tab-icon {
  opacity: 0;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg {
  position: relative;
  opacity: 0;
  z-index: 3;
  max-width: 65%;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xxs] {
    padding-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xxs] {
    padding-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xxs] {
    padding-top: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xxs] {
    padding-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xxs] {
    padding-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xxs] {
    padding-bottom: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xs] {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xs] {
    padding-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xs] {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xs] {
    padding-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xs] {
    padding-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xs] {
    padding-bottom: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=s] {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=s] {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=s] {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=s] {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=s] {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=s] {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=m] {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=m] {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=m] {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=m] {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=m] {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=m] {
    padding-bottom: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=l] {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=l] {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=l] {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=l] {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=l] {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=l] {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xl] {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xl] {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xl] {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xl] {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xl] {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xl] {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xxl] {
    padding-top: 12.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xxl] {
    padding-top: 9.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-top=xxl] {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xxl] {
    padding-bottom: 12.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xxl] {
    padding-bottom: 9.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg[data-spacer-bottom=xxl] {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 61.9375rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg {
    max-width: 75%;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .wysiwyg {
    max-width: 100%;
  }
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .cell-bkgrd {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .cell-bkgrd .scenery.imagery {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .cell-bkgrd .scenery.imagery.flickity-bg-lazyloaded, [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .cell-bkgrd .scenery.imagery.flickity-bg-lazyerror {
  opacity: 1;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell.is-selected .cell-bkgrd {
  opacity: 1;
}
[data-block-type=billboard-tabs] .billboard-tabs .gallery-cell.is-selected .cell-bkgrd .scenery.imagery {
  opacity: 1;
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .cell-wrapper {
    display: flex;
    flex-direction: column;
  }
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .cell-wrapper .wysiwyg {
    order: 2;
  }
  [data-block-type=billboard-tabs] .billboard-tabs .gallery-cell .cell-wrapper .tab-icon {
    order: 1;
    margin-top: 0.875rem;
    margin-bottom: -2.625rem;
  }
}
[data-block-type=billboard-tabs] .tab-row {
  display: block;
  position: relative;
  opacity: 1;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .tab-row {
    display: none;
  }
}
[data-block-type=billboard-tabs] .tab-icon {
  display: none;
  position: relative;
  opacity: 1;
  z-index: 3;
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .tab-icon {
    display: block;
  }
}
[data-block-type=billboard-tabs] .tab-icon,
[data-block-type=billboard-tabs] .tab-thumbnail {
  margin: 0 2%;
  background: transparent;
  border: 0;
  outline: none;
}
[data-block-type=billboard-tabs] .tab-icon .img-wrapper,
[data-block-type=billboard-tabs] .tab-thumbnail .img-wrapper {
  position: relative;
  width: 4.375rem;
  border-width: 0.1875rem;
  border-color: #c3c9ca;
  border-style: solid;
  transition: border 0.4s ease-in-out;
}
[data-block-type=billboard-tabs] .tab-icon .img-wrapper img,
[data-block-type=billboard-tabs] .tab-thumbnail .img-wrapper img {
  width: 100%;
}
[data-block-type=billboard-tabs] .tab-icon figcaption,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  color: #001623;
  line-height: 1.75em;
  letter-spacing: 0.01em;
  margin: 0.625rem 0 0;
}
[data-block-type=billboard-tabs] .tab-icon figcaption i,
[data-block-type=billboard-tabs] .tab-icon figcaption em,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption i,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption em {
  font-style: italic;
}
[data-block-type=billboard-tabs] .tab-icon figcaption i b,
[data-block-type=billboard-tabs] .tab-icon figcaption i strong,
[data-block-type=billboard-tabs] .tab-icon figcaption em b,
[data-block-type=billboard-tabs] .tab-icon figcaption em strong,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption i b,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption i strong,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption em b,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption em strong {
  font-style: italic;
}
[data-block-type=billboard-tabs] .tab-icon figcaption b,
[data-block-type=billboard-tabs] .tab-icon figcaption strong,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption b,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption strong {
  font-weight: 600;
}
[data-block-type=billboard-tabs] .tab-icon figcaption b i,
[data-block-type=billboard-tabs] .tab-icon figcaption b em,
[data-block-type=billboard-tabs] .tab-icon figcaption strong i,
[data-block-type=billboard-tabs] .tab-icon figcaption strong em,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption b i,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption b em,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption strong i,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption strong em {
  font-weight: 600;
}
[data-block-type=billboard-tabs] .tab-icon figcaption a,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=billboard-tabs] .tab-icon figcaption a:focus,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=billboard-tabs] .tab-icon figcaption a:hover,
[data-block-type=billboard-tabs] .tab-thumbnail figcaption a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
[data-block-type=billboard-tabs] .tab-thumbnail {
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
[data-block-type=billboard-tabs] .tab-thumbnail.is-selected {
  opacity: 1;
}
[data-block-type=billboard-tabs] .tab-thumbnail.is-selected figcaption {
  font-weight: 500;
}
[data-block-type=billboard-tabs] .tab-thumbnail.is-selected .img-wrapper {
  border-color: #2e408a;
  transition: border 0.4s ease-in-out;
}
[data-block-type=billboard-tabs] .tab-thumbnail:first-child {
  margin-left: 0;
}
[data-block-type=billboard-tabs] .tab-thumbnail:last-child {
  margin-right: 0;
}
[data-block-type=billboard-tabs] [data-night-mode=true] .tab-icon figcaption,
[data-block-type=billboard-tabs] [data-night-mode=true] .tab-thumbnail figcaption {
  color: white;
}
[data-block-type=billboard-tabs] .button-row {
  display: none;
  position: absolute;
  bottom: 0.875rem;
  z-index: 1;
  left: 5%;
  width: 90%;
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=billboard-tabs] .button-row {
    display: block;
  }
}

/* ----------------------------------
    Foundational Video - Content Block Styles
---------------------------------- */
[data-block-type=content-embed] .foundation-container * {
  max-width: 100%;
}

/* ----------------------------------
    Content Grid Block
---------------------------------- */
@media screen and (min-width: 68.8125rem) {
  [data-block-type=content_grid]:not([data-spacing-top]) .bound,
[data-block-type=screen_shots_grid]:not([data-spacing-top]) .bound {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=content_grid]:not([data-spacing-top]) .bound,
[data-block-type=screen_shots_grid]:not([data-spacing-top]) .bound {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=content_grid]:not([data-spacing-top]) .bound,
[data-block-type=screen_shots_grid]:not([data-spacing-top]) .bound {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=content_grid]:not([data-spacing-bottom]) .bound,
[data-block-type=screen_shots_grid]:not([data-spacing-bottom]) .bound {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=content_grid]:not([data-spacing-bottom]) .bound,
[data-block-type=screen_shots_grid]:not([data-spacing-bottom]) .bound {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=content_grid]:not([data-spacing-bottom]) .bound,
[data-block-type=screen_shots_grid]:not([data-spacing-bottom]) .bound {
    padding-bottom: 4.6875rem;
  }
}
[data-block-type=content_grid] .content-grid,
[data-block-type=screen_shots_grid] .content-grid {
  /*  Grid Layout
  ---------------------------------- */
  /* Mobile */
  /*  Content Styling
  ---------------------------------- */
}
[data-block-type=content_grid] .content-grid[data-column-count=four] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li {
  /* Desktop */
  /* Tablet */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=four] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 22.4615384615%;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:last-child,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:last-child {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:nth-child(4n),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:nth-child(4n) {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:nth-child(4n+1),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:nth-child(4n+1) {
    clear: left;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:nth-last-child(-n+4),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:nth-last-child(-n+4) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=four] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:last-child,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:last-child {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:nth-child(3n),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:nth-child(3n) {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:nth-child(3n+1),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:nth-child(3n+1) {
    clear: left;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=four] li:nth-last-child(-n+3),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=four] li:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
[data-block-type=content_grid] .content-grid[data-column-count=three] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li {
  /* Desktop */
  /* Tablet */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=three] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 31.0769230769%;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=three] li:last-child,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li:last-child {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=three] li:nth-child(3n),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li:nth-child(3n) {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=three] li:nth-child(3n+1),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=three] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=three] li:last-child,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li:last-child {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=three] li:nth-child(3n),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li:nth-child(3n) {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=three] li:nth-child(3n+1),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=three] li:nth-last-child(-n+3),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=three] li:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=two] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=two] li:last-child,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li:last-child {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=two] li:nth-child(2n),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=two] li:nth-child(2n+1),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=two] li,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=two] li:last-child,
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li:last-child {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=two] li:nth-child(2n),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=content_grid] .content-grid[data-column-count=two] li:nth-child(2n+1),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=content_grid] .content-grid[data-column-count=two] li:nth-last-child(-n+2),
[data-block-type=screen_shots_grid] .content-grid[data-column-count=two] li:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=content_grid] .content-grid li,
[data-block-type=screen_shots_grid] .content-grid li {
    margin-bottom: 5.25rem;
  }
  [data-block-type=content_grid] .content-grid li:last-child,
[data-block-type=screen_shots_grid] .content-grid li:last-child {
    margin-bottom: 0;
  }
}
[data-block-type=content_grid] .content-grid li,
[data-block-type=screen_shots_grid] .content-grid li {
  /*  Images
  ---------------------------------- */
  /*  Content Justification
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=content_grid] .content-grid li,
[data-block-type=screen_shots_grid] .content-grid li {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=content_grid] .content-grid li,
[data-block-type=screen_shots_grid] .content-grid li {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=content_grid] .content-grid li,
[data-block-type=screen_shots_grid] .content-grid li {
    margin-bottom: 2.375rem;
  }
}
[data-block-type=content_grid] .content-grid li img,
[data-block-type=screen_shots_grid] .content-grid li img {
  display: inline-block;
  max-width: 100%;
  /*  Image Fullness (col count based on 3 column grid) ---- */
}
[data-block-type=content_grid] .content-grid li img[data-fullness=icon],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=icon] {
  width: 30.69307%;
}
[data-block-type=content_grid] .content-grid li img[data-fullness=small],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=small] {
  width: 44.55446%;
}
[data-block-type=content_grid] .content-grid li img[data-fullness=medium],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=medium] {
  width: 51.48515%;
}
[data-block-type=content_grid] .content-grid li img[data-fullness=large],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=large] {
  width: 72.27723%;
}
[data-block-type=content_grid] .content-grid li img[data-fullness=fullWidth],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=fullWidth] {
  width: 100%;
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=content_grid] .content-grid li img[data-fullness=icon],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=icon] {
    width: 28.66242%;
  }
  [data-block-type=content_grid] .content-grid li img[data-fullness=small],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=small] {
    width: 37.57962%;
  }
  [data-block-type=content_grid] .content-grid li img[data-fullness=medium],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=medium] {
    width: 46.49682%;
  }
  [data-block-type=content_grid] .content-grid li img[data-fullness=large],
[data-block-type=screen_shots_grid] .content-grid li img[data-fullness=large] {
    width: 64.33121%;
  }
}
[data-block-type=content_grid] .content-grid li .wysiwyg > *:first-child,
[data-block-type=screen_shots_grid] .content-grid li .wysiwyg > *:first-child {
  margin-top: 0;
}
[data-block-type=content_grid] .content-grid li .wysiwyg > *:last-child,
[data-block-type=screen_shots_grid] .content-grid li .wysiwyg > *:last-child {
  margin-bottom: 0;
}
[data-block-type=content_grid] .content-grid li .wysiwyg > *:only-child,
[data-block-type=screen_shots_grid] .content-grid li .wysiwyg > *:only-child {
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=content_grid] .content-grid li img + .wysiwyg,
[data-block-type=content_grid] .content-grid li .wysiwyg + .cta-atom,
[data-block-type=screen_shots_grid] .content-grid li img + .wysiwyg,
[data-block-type=screen_shots_grid] .content-grid li .wysiwyg + .cta-atom {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=content_grid] .content-grid li img + .wysiwyg,
[data-block-type=content_grid] .content-grid li .wysiwyg + .cta-atom,
[data-block-type=screen_shots_grid] .content-grid li img + .wysiwyg,
[data-block-type=screen_shots_grid] .content-grid li .wysiwyg + .cta-atom {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=content_grid] .content-grid li img + .wysiwyg,
[data-block-type=content_grid] .content-grid li .wysiwyg + .cta-atom,
[data-block-type=screen_shots_grid] .content-grid li img + .wysiwyg,
[data-block-type=screen_shots_grid] .content-grid li .wysiwyg + .cta-atom {
    margin-top: 1.25rem;
  }
}
[data-block-type=content_grid] .content-grid li[data-justification=center],
[data-block-type=screen_shots_grid] .content-grid li[data-justification=center] {
  text-align: center;
}
[data-block-type=content_grid] .content-grid li[data-justification=right],
[data-block-type=screen_shots_grid] .content-grid li[data-justification=right] {
  text-align: right;
}

/* ----------------------------------
    Featured Products - Large
---------------------------------- */
[data-block-type=featured_case_studies] {
  /*  Layout
  ---------------------------------- */
  /*  Case Study
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=featured_case_studies] {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=featured_case_studies] {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=featured_case_studies] {
    padding-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=featured_case_studies] {
    padding-right: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] {
    padding-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=featured_case_studies] {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=featured_case_studies] {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=featured_case_studies] {
    padding-left: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=featured_case_studies] {
    padding-left: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] {
    padding-left: 0;
  }
}
[data-block-type=featured_case_studies] section {
  display: block;
}
[data-block-type=featured_case_studies] section::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=featured_case_studies] section:not(.gallery) {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=featured_case_studies] section:not(.gallery) {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] section:not(.gallery) {
    margin-bottom: 2.375rem;
  }
}
@media screen and (min-width: 62rem) {
  [data-block-type=featured_case_studies] section:not(.case_study_gallery) .details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  [data-block-type=featured_case_studies] section:not(.case_study_gallery) .details .cta {
    margin-left: 1.25rem;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=featured_case_studies] .featured .case-study {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  [data-block-type=featured_case_studies] .featured .case-study:last-child {
    margin-right: 0;
  }
  [data-block-type=featured_case_studies] .featured .case-study:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=featured_case_studies] .featured .case-study:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] .featured .case-study:first-child {
    padding-bottom: 2.625rem;
  }
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study {
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=featured_case_studies] .case_study_gallery .case-study {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 22.4615384615%;
  }
  [data-block-type=featured_case_studies] .case_study_gallery .case-study:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=featured_case_studies] .case_study_gallery .case-study {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
  }
  [data-block-type=featured_case_studies] .case_study_gallery .case-study:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] .case_study_gallery .case-study {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 46.4968152866%;
  }
  [data-block-type=featured_case_studies] .case_study_gallery .case-study:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=featured_case_studies] .case_study_gallery .case-study {
    width: 100%;
  }
}
[data-block-type=featured_case_studies] .case-study {
  /*  Photo
  ---------------------------------- */
  /*  Content
  ---------------------------------- */
}
[data-block-type=featured_case_studies] .case-study .photo {
  display: block;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=featured_case_studies] .case-study .photo {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=featured_case_studies] .case-study .photo {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] .case-study .photo {
    margin-bottom: 1.25rem;
  }
}
[data-block-type=featured_case_studies] .case-study .photo img {
  width: 100%;
}
[data-block-type=featured_case_studies] .case-study h1 {
  text-transform: uppercase;
  margin-bottom: 0.625rem;
}
[data-block-type=featured_case_studies] .case-study p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  margin-bottom: 0.875rem;
  font-size: 1.25rem;
  font-style: italic;
  line-height: 1.2;
}
[data-block-type=featured_case_studies] .case-study p i,
[data-block-type=featured_case_studies] .case-study p em {
  font-style: italic;
}
[data-block-type=featured_case_studies] .case-study p i b,
[data-block-type=featured_case_studies] .case-study p i strong,
[data-block-type=featured_case_studies] .case-study p em b,
[data-block-type=featured_case_studies] .case-study p em strong {
  font-style: italic;
}
[data-block-type=featured_case_studies] .case-study p b,
[data-block-type=featured_case_studies] .case-study p strong {
  font-weight: 500;
}
[data-block-type=featured_case_studies] .case-study p b i,
[data-block-type=featured_case_studies] .case-study p b em,
[data-block-type=featured_case_studies] .case-study p strong i,
[data-block-type=featured_case_studies] .case-study p strong em {
  font-weight: 500;
}
[data-block-type=featured_case_studies] .case-study p a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=featured_case_studies] .case-study p a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=featured_case_studies] .case-study p a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=featured_case_studies] .case-study p {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=featured_case_studies] .case-study p {
    font-size: 0.875rem;
  }
}
[data-block-type=featured_case_studies] .case-study .cta {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: black;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: black;
  border-radius: 0.1875rem;
  background-color: transparent;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
  padding: 0.8em;
  font-size: 1em;
}
[data-block-type=featured_case_studies] .case-study .cta i,
[data-block-type=featured_case_studies] .case-study .cta em {
  font-style: italic;
}
[data-block-type=featured_case_studies] .case-study .cta i b,
[data-block-type=featured_case_studies] .case-study .cta i strong,
[data-block-type=featured_case_studies] .case-study .cta em b,
[data-block-type=featured_case_studies] .case-study .cta em strong {
  font-style: italic;
}
[data-block-type=featured_case_studies] .case-study .cta b,
[data-block-type=featured_case_studies] .case-study .cta strong {
  font-weight: 600;
}
[data-block-type=featured_case_studies] .case-study .cta b i,
[data-block-type=featured_case_studies] .case-study .cta b em,
[data-block-type=featured_case_studies] .case-study .cta strong i,
[data-block-type=featured_case_studies] .case-study .cta strong em {
  font-weight: 600;
}
[data-block-type=featured_case_studies] .case-study .cta a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=featured_case_studies] .case-study .cta a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=featured_case_studies] .case-study .cta a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
[data-block-type=featured_case_studies] .case-study .cta:focus {
  color: #fff;
  background-image: none;
  text-decoration: none;
  background-color: #2e408a;
  border-color: #2e408a;
}
@media (hover: hover) {
  [data-block-type=featured_case_studies] .case-study .cta:hover {
    color: #fff;
    background-image: none;
    text-decoration: none;
    background-color: #2e408a;
    border-color: #2e408a;
  }
}
[data-block-type=featured_case_studies] .case-study .cta.inactive, [data-block-type=featured_case_studies] .case-study .cta:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
[data-block-type=featured_case_studies] .case-study .cta.inactive:focus, [data-block-type=featured_case_studies] .case-study .cta:disabled:focus {
  color: black;
  border-color: black;
  background-color: transparent;
}
@media (hover: hover) {
  [data-block-type=featured_case_studies] .case-study .cta.inactive:hover, [data-block-type=featured_case_studies] .case-study .cta:disabled:hover {
    color: black;
    border-color: black;
    background-color: transparent;
  }
}
[data-block-type=featured_case_studies] .primary .case-study h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  font-weight: 500;
}
[data-block-type=featured_case_studies] .primary .case-study h1 i,
[data-block-type=featured_case_studies] .primary .case-study h1 em {
  font-style: italic;
}
[data-block-type=featured_case_studies] .primary .case-study h1 i b,
[data-block-type=featured_case_studies] .primary .case-study h1 i strong,
[data-block-type=featured_case_studies] .primary .case-study h1 em b,
[data-block-type=featured_case_studies] .primary .case-study h1 em strong {
  font-style: italic;
}
[data-block-type=featured_case_studies] .primary .case-study h1 b,
[data-block-type=featured_case_studies] .primary .case-study h1 strong {
  font-weight: 500;
}
[data-block-type=featured_case_studies] .primary .case-study h1 b i,
[data-block-type=featured_case_studies] .primary .case-study h1 b em,
[data-block-type=featured_case_studies] .primary .case-study h1 strong i,
[data-block-type=featured_case_studies] .primary .case-study h1 strong em {
  font-weight: 500;
}
[data-block-type=featured_case_studies] .primary .case-study h1 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  /*  Hover & Active States ---- */
}
[data-block-type=featured_case_studies] .primary .case-study h1 a:focus {
  color: #2e408a;
}
@media (hover: hover) {
  [data-block-type=featured_case_studies] .primary .case-study h1 a:hover {
    color: #2e408a;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=featured_case_studies] .primary .case-study h1 {
    font-size: 1.5rem;
  }
}
[data-block-type=featured_case_studies] .primary .case-study h1 b, [data-block-type=featured_case_studies] .primary .case-study h1 strong {
  color: #ff579e;
}
[data-block-type=featured_case_studies] .featured .case-study h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  font-weight: 500;
}
[data-block-type=featured_case_studies] .featured .case-study h1 i,
[data-block-type=featured_case_studies] .featured .case-study h1 em {
  font-style: italic;
}
[data-block-type=featured_case_studies] .featured .case-study h1 i b,
[data-block-type=featured_case_studies] .featured .case-study h1 i strong,
[data-block-type=featured_case_studies] .featured .case-study h1 em b,
[data-block-type=featured_case_studies] .featured .case-study h1 em strong {
  font-style: italic;
}
[data-block-type=featured_case_studies] .featured .case-study h1 b,
[data-block-type=featured_case_studies] .featured .case-study h1 strong {
  font-weight: 600;
}
[data-block-type=featured_case_studies] .featured .case-study h1 b i,
[data-block-type=featured_case_studies] .featured .case-study h1 b em,
[data-block-type=featured_case_studies] .featured .case-study h1 strong i,
[data-block-type=featured_case_studies] .featured .case-study h1 strong em {
  font-weight: 600;
}
[data-block-type=featured_case_studies] .featured .case-study h1 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  /*  Hover & Active States ---- */
}
[data-block-type=featured_case_studies] .featured .case-study h1 a:focus {
  color: #2e408a;
}
@media (hover: hover) {
  [data-block-type=featured_case_studies] .featured .case-study h1 a:hover {
    color: #2e408a;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=featured_case_studies] .featured .case-study h1 {
    font-size: 1.125rem;
  }
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study .details {
  text-align: center;
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  font-weight: 500;
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 i,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 em {
  font-style: italic;
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 i b,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 i strong,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 em b,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 em strong {
  font-style: italic;
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 b,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 strong {
  font-weight: 600;
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 b i,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 b em,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 strong i,
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 strong em {
  font-weight: 600;
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  /*  Hover & Active States ---- */
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study h1 a:focus {
  color: #2e408a;
}
@media (hover: hover) {
  [data-block-type=featured_case_studies] .case_study_gallery .case-study h1 a:hover {
    color: #2e408a;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=featured_case_studies] .case_study_gallery .case-study h1 {
    font-size: 1.125rem;
  }
}
[data-block-type=featured_case_studies] .case_study_gallery .case-study p {
  font-size: 0.9375rem;
}

[data-block-type=features-grid] {
  /*  Basics
  ---------------------------------- */
  /*  Standard Feature Splits
  ---------------------------------- */
  /*  Triptych vs Diptych
  ---------------------------------- */
  /*  Content Styles
  /*  Different font-sizing based on available space...
  ---------------------------------- */
  /*  Slimline
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] {
    padding-bottom: 0;
  }
}
[data-block-type=features-grid] img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] .feature {
    margin-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] .feature {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] .feature {
    margin-bottom: 4.6875rem;
  }
}
[data-block-type=features-grid] .feature[data-axis=horiz] .inner {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
[data-block-type=features-grid] .feature[data-axis=horiz] .inner::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 34.0625rem) and (max-width: 61.9375rem) {
  [data-block-type=features-grid] .feature {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
  }
  [data-block-type=features-grid] .feature:last-child {
    margin-right: 0;
  }
  [data-block-type=features-grid] .feature:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=features-grid] .feature:nth-child(2n+1) {
    clear: left;
  }
  [data-block-type=features-grid] .feature .icon {
    float: left;
    display: block;
    margin-right: 9.5652173913%;
    width: 20.8695652174%;
  }
  [data-block-type=features-grid] .feature .icon:last-child {
    margin-right: 0;
  }
  [data-block-type=features-grid] .feature[data-axis=horiz] .prose {
    float: left;
    display: block;
    margin-right: 9.5652173913%;
    width: 69.5652173913%;
  }
  [data-block-type=features-grid] .feature[data-axis=horiz] .prose:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=features-grid] .feature .icon {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 28.6624203822%;
  }
  [data-block-type=features-grid] .feature .icon:last-child {
    margin-right: 0;
  }
  [data-block-type=features-grid] .feature[data-axis=vert] .prose {
    clear: both;
  }
  [data-block-type=features-grid] .feature[data-axis=horiz] .prose {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 64.3312101911%;
  }
  [data-block-type=features-grid] .feature[data-axis=horiz] .prose:last-child {
    margin-right: 0;
  }
}
[data-block-type=features-grid] .anthology-of-features {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
[data-block-type=features-grid] .anthology-of-features::after {
  clear: both;
  content: "";
  display: table;
}
[data-block-type=features-grid] .anthology-of-features [data-axis=vert] .prose {
  clear: both;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] .anthology-of-features [data-axis=vert] .prose {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] .anthology-of-features [data-axis=vert] .prose {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] .anthology-of-features [data-axis=vert] .prose {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 62rem) {
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] .feature {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 31.0769230769%;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] .feature:last-child {
    margin-right: 0;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] .feature:nth-child(3n) {
    margin-right: 0;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] .feature:nth-child(3n+1) {
    clear: left;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] .icon {
    float: left;
    display: block;
    margin-right: 10.8910891089%;
    width: 16.8316831683%;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] .icon:last-child {
    margin-right: 0;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] [data-axis=horiz] .prose {
    float: left;
    display: block;
    margin-right: 10.8910891089%;
    width: 72.2772277228%;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=triptych] [data-axis=horiz] .prose:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 62rem) {
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] .feature {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] .feature:last-child {
    margin-right: 0;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] .feature:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] .feature:nth-child(2n+1) {
    clear: left;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] .icon {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 10.8280254777%;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] .icon:last-child {
    margin-right: 0;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] [data-axis=horiz] .prose {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 73.2484076433%;
  }
  [data-block-type=features-grid] .anthology-of-features[data-desktop-split=diptych] [data-axis=horiz] .prose:last-child {
    margin-right: 0;
  }
}
[data-block-type=features-grid] [data-desktop-split=triptych] h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
}
[data-block-type=features-grid] [data-desktop-split=triptych] h1 i,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 em {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=triptych] h1 i b,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 i strong,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 em b,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 em strong {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=triptych] h1 b,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 strong {
  font-weight: 600;
}
[data-block-type=features-grid] [data-desktop-split=triptych] h1 b i,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 b em,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 strong i,
[data-block-type=features-grid] [data-desktop-split=triptych] h1 strong em {
  font-weight: 600;
}
[data-block-type=features-grid] [data-desktop-split=triptych] h1 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=features-grid] [data-desktop-split=triptych] h1 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=features-grid] [data-desktop-split=triptych] h1 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] h1 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] h1 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] h1 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] h1 {
    font-size: 1.125rem;
  }
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p i,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p em {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p i b,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p i strong,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p em b,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p em strong {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p b,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p strong {
  font-weight: 500;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p b i,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p b em,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p strong i,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p strong em {
  font-weight: 500;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg p {
    font-size: 0.75rem;
  }
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li i,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li em {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li i b,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li i strong,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li em b,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li em strong {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li b,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li strong {
  font-weight: 500;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li b i,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li b em,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li strong i,
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li strong em {
  font-weight: 500;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg li {
    font-size: 0.75rem;
  }
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg > *:first-child {
  margin-top: 0;
}
[data-block-type=features-grid] [data-desktop-split=triptych] .wysiwyg > *:last-child {
  margin-bottom: 0;
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 i,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 em {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 i b,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 i strong,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 em b,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 em strong {
  font-style: italic;
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 b,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 strong {
  font-weight: 500;
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 b i,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 b em,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 strong i,
[data-block-type=features-grid] [data-desktop-split=diptych] h1 strong em {
  font-weight: 500;
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=features-grid] [data-desktop-split=diptych] h1 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] [data-desktop-split=diptych] h1 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] [data-desktop-split=diptych] h1 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] [data-desktop-split=diptych] h1 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=features-grid] [data-desktop-split=diptych] h1 {
    font-size: 1.5rem;
  }
}
[data-block-type=features-grid] [data-desktop-split=diptych] h1 b, [data-block-type=features-grid] [data-desktop-split=diptych] h1 strong {
  color: #ff579e;
}
[data-block-type=features-grid] mark {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
}
[data-block-type=features-grid] mark i,
[data-block-type=features-grid] mark em {
  font-style: italic;
}
[data-block-type=features-grid] mark i b,
[data-block-type=features-grid] mark i strong,
[data-block-type=features-grid] mark em b,
[data-block-type=features-grid] mark em strong {
  font-style: italic;
}
[data-block-type=features-grid] mark b,
[data-block-type=features-grid] mark strong {
  font-weight: 400;
}
[data-block-type=features-grid] mark b i,
[data-block-type=features-grid] mark b em,
[data-block-type=features-grid] mark strong i,
[data-block-type=features-grid] mark strong em {
  font-weight: 400;
}
[data-block-type=features-grid] mark a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=features-grid] mark a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=features-grid] mark a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] mark {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] mark {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] mark {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=features-grid] mark {
    font-size: 0.9375rem;
  }
}
[data-block-type=features-grid] .wysiwyg > *:first-child {
  margin-top: 0;
}
[data-block-type=features-grid] .wysiwyg > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=features-grid] .wysiwyg + .cta-atom {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] .wysiwyg + .cta-atom {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] .wysiwyg + .cta-atom {
    margin-top: 1.25rem;
  }
}
[data-block-type=features-grid][data-night-mode=true] h1,
[data-block-type=features-grid][data-night-mode=true] mark,
[data-block-type=features-grid][data-night-mode=true] .wysiwyg p,
[data-block-type=features-grid][data-night-mode=true] .wysiwyg li,
[data-block-type=features-grid][data-night-mode=true] a,
[data-block-type=features-grid][data-night-mode=true] h1 a {
  color: white;
}
[data-block-type=features-grid][data-night-mode=true] h1 a:hover,
[data-block-type=features-grid][data-night-mode=true] a:hover {
  color: rgba(255, 255, 255, 0.6);
}
[data-block-type=features-grid] .feature.slimline h1 {
  margin: 0;
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=features-grid] .feature.slimline h1 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=features-grid] .feature.slimline {
    clear: both;
    float: none;
    width: 100%;
  }
}
[data-block-type=features-grid] .slimline-wrap {
  width: 100%;
}
[data-block-type=features-grid] .slimline-icon {
  display: table-cell;
  width: 16.8316831683%;
  vertical-align: middle;
}
[data-block-type=features-grid] .slimline-prose {
  display: table-cell;
  width: 83.1683168317%;
  padding-left: 13.0952380952%;
  vertical-align: middle;
}
[data-block-type=features-grid][data-desktop-split=diptych] .feature.slimline .slimline-icon {
  width: 10.8280254777%;
}
[data-block-type=features-grid][data-desktop-split=diptych] .feature.slimline .slimline-prose {
  width: 89.1719745223%;
  padding-left: 7.8571428571%;
}

/* ----------------------------------
    Sprout Forms Shared Styles
---------------------------------- */
.sprout-form,
.quote-form {
  /*  Messaging ---- */
  /*  Tab Structure ---- */
  /*  Field Structure ---- */
  /*  Text Inputs ---- */
  /*  TextArea ---- */
  /*  Select ---- */
  /*  Multi Select ---- */
  /*  Radio & Checkboxes ---- */
  /*  Submit Button ---- */
  /*  Phone Input ---- */
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form,
.quote-form {
    margin-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form,
.quote-form {
    margin-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form,
.quote-form {
    margin-bottom: 5.25rem;
  }
}
.sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: 0;
  line-height: 1.75em;
  position: relative;
  background-color: #ffeee2;
  border: 1px solid #c1c7c8;
}
.sprout-form .sprout-form-error i,
.sprout-form .sprout-form-error em,
.quote-form .sprout-form-error i,
.quote-form .sprout-form-error em {
  font-style: italic;
}
.sprout-form .sprout-form-error i b,
.sprout-form .sprout-form-error i strong,
.sprout-form .sprout-form-error em b,
.sprout-form .sprout-form-error em strong,
.quote-form .sprout-form-error i b,
.quote-form .sprout-form-error i strong,
.quote-form .sprout-form-error em b,
.quote-form .sprout-form-error em strong {
  font-style: italic;
}
.sprout-form .sprout-form-error b,
.sprout-form .sprout-form-error strong,
.quote-form .sprout-form-error b,
.quote-form .sprout-form-error strong {
  font-weight: 500;
}
.sprout-form .sprout-form-error b i,
.sprout-form .sprout-form-error b em,
.sprout-form .sprout-form-error strong i,
.sprout-form .sprout-form-error strong em,
.quote-form .sprout-form-error b i,
.quote-form .sprout-form-error b em,
.quote-form .sprout-form-error strong i,
.quote-form .sprout-form-error strong em {
  font-weight: 500;
}
.sprout-form .sprout-form-error a,
.quote-form .sprout-form-error a {
  transition: color 200ms;
  color: 0;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .sprout-form-error a:focus,
.quote-form .sprout-form-error a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .sprout-form-error a:hover,
.quote-form .sprout-form-error a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-right: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-right: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-right: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-left: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    padding-left: 4.6875rem;
  }
}
.sprout-form .sprout-form-error:before,
.quote-form .sprout-form-error:before {
  content: "";
  display: block;
  position: absolute;
  left: 1.25rem;
  height: 1.5625rem;
  width: 1.875rem;
  margin-right: 1.5625rem;
  background-image: url("/images/theme-definitive/dt_exclamation.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-error,
.quote-form .sprout-form-error {
    margin-bottom: 2.375rem;
  }
}
.sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: 0;
  line-height: 1.75em;
  position: relative;
  background-color: #2e408a;
  border: 1px solid #667acc;
}
.sprout-form .sprout-form-success i,
.sprout-form .sprout-form-success em,
.quote-form .sprout-form-success i,
.quote-form .sprout-form-success em {
  font-style: italic;
}
.sprout-form .sprout-form-success i b,
.sprout-form .sprout-form-success i strong,
.sprout-form .sprout-form-success em b,
.sprout-form .sprout-form-success em strong,
.quote-form .sprout-form-success i b,
.quote-form .sprout-form-success i strong,
.quote-form .sprout-form-success em b,
.quote-form .sprout-form-success em strong {
  font-style: italic;
}
.sprout-form .sprout-form-success b,
.sprout-form .sprout-form-success strong,
.quote-form .sprout-form-success b,
.quote-form .sprout-form-success strong {
  font-weight: 500;
}
.sprout-form .sprout-form-success b i,
.sprout-form .sprout-form-success b em,
.sprout-form .sprout-form-success strong i,
.sprout-form .sprout-form-success strong em,
.quote-form .sprout-form-success b i,
.quote-form .sprout-form-success b em,
.quote-form .sprout-form-success strong i,
.quote-form .sprout-form-success strong em {
  font-weight: 500;
}
.sprout-form .sprout-form-success a,
.quote-form .sprout-form-success a {
  transition: color 200ms;
  color: 0;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .sprout-form-success a:focus,
.quote-form .sprout-form-success a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .sprout-form-success a:hover,
.quote-form .sprout-form-success a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-right: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-right: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-right: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-left: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    padding-left: 4.6875rem;
  }
}
.sprout-form .sprout-form-success:before,
.quote-form .sprout-form-success:before {
  content: "";
  display: block;
  position: absolute;
  left: 1.25rem;
  height: 1.5625rem;
  width: 1.875rem;
  margin-right: 1.5625rem;
  background-image: url("/images/theme-definitive/dt_checkmark.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .sprout-form-success,
.quote-form .sprout-form-success {
    margin-bottom: 2.375rem;
  }
}
.sprout-form .tab,
.quote-form .tab {
  border-bottom: 1px solid #828e90;
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .tab,
.quote-form .tab {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .tab,
.quote-form .tab {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .tab,
.quote-form .tab {
    margin-bottom: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .tab,
.quote-form .tab {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .tab,
.quote-form .tab {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .tab,
.quote-form .tab {
    padding-bottom: 2.375rem;
  }
}
.sprout-form .tab:last-child,
.quote-form .tab:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.sprout-form .tab h3,
.quote-form .tab h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.sprout-form .tab h3 i,
.sprout-form .tab h3 em,
.quote-form .tab h3 i,
.quote-form .tab h3 em {
  font-style: italic;
}
.sprout-form .tab h3 i b,
.sprout-form .tab h3 i strong,
.sprout-form .tab h3 em b,
.sprout-form .tab h3 em strong,
.quote-form .tab h3 i b,
.quote-form .tab h3 i strong,
.quote-form .tab h3 em b,
.quote-form .tab h3 em strong {
  font-style: italic;
}
.sprout-form .tab h3 b,
.sprout-form .tab h3 strong,
.quote-form .tab h3 b,
.quote-form .tab h3 strong {
  font-weight: 600;
}
.sprout-form .tab h3 b i,
.sprout-form .tab h3 b em,
.sprout-form .tab h3 strong i,
.sprout-form .tab h3 strong em,
.quote-form .tab h3 b i,
.quote-form .tab h3 b em,
.quote-form .tab h3 strong i,
.quote-form .tab h3 strong em {
  font-weight: 600;
}
.sprout-form .tab h3 a,
.quote-form .tab h3 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .tab h3 a:focus,
.quote-form .tab h3 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .tab h3 a:hover,
.quote-form .tab h3 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .tab h3,
.quote-form .tab h3 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .tab h3,
.quote-form .tab h3 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .tab h3,
.quote-form .tab h3 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form .tab h3,
.quote-form .tab h3 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .tab h3,
.quote-form .tab h3 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .tab h3,
.quote-form .tab h3 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .tab h3,
.quote-form .tab h3 {
    margin-bottom: 1.25rem;
  }
}
.sprout-form .field,
.quote-form .field {
  position: relative;
  /*  Sprout Notes Field ---- */
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .field,
.quote-form .field {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .field,
.quote-form .field {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .field,
.quote-form .field {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .field,
.quote-form .field {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .field,
.quote-form .field {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .field,
.quote-form .field {
    margin-bottom: 1.25rem;
  }
}
.sprout-form .field:last-child,
.quote-form .field:last-child {
  margin-bottom: 0;
}
.sprout-form .field .heading label,
.sprout-form .field .heading legend,
.quote-form .field .heading label,
.quote-form .field .heading legend {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: none;
}
.sprout-form .field .heading label i,
.sprout-form .field .heading label em,
.sprout-form .field .heading legend i,
.sprout-form .field .heading legend em,
.quote-form .field .heading label i,
.quote-form .field .heading label em,
.quote-form .field .heading legend i,
.quote-form .field .heading legend em {
  font-style: italic;
}
.sprout-form .field .heading label i b,
.sprout-form .field .heading label i strong,
.sprout-form .field .heading label em b,
.sprout-form .field .heading label em strong,
.sprout-form .field .heading legend i b,
.sprout-form .field .heading legend i strong,
.sprout-form .field .heading legend em b,
.sprout-form .field .heading legend em strong,
.quote-form .field .heading label i b,
.quote-form .field .heading label i strong,
.quote-form .field .heading label em b,
.quote-form .field .heading label em strong,
.quote-form .field .heading legend i b,
.quote-form .field .heading legend i strong,
.quote-form .field .heading legend em b,
.quote-form .field .heading legend em strong {
  font-style: italic;
}
.sprout-form .field .heading label b,
.sprout-form .field .heading label strong,
.sprout-form .field .heading legend b,
.sprout-form .field .heading legend strong,
.quote-form .field .heading label b,
.quote-form .field .heading label strong,
.quote-form .field .heading legend b,
.quote-form .field .heading legend strong {
  font-weight: 400;
}
.sprout-form .field .heading label b i,
.sprout-form .field .heading label b em,
.sprout-form .field .heading label strong i,
.sprout-form .field .heading label strong em,
.sprout-form .field .heading legend b i,
.sprout-form .field .heading legend b em,
.sprout-form .field .heading legend strong i,
.sprout-form .field .heading legend strong em,
.quote-form .field .heading label b i,
.quote-form .field .heading label b em,
.quote-form .field .heading label strong i,
.quote-form .field .heading label strong em,
.quote-form .field .heading legend b i,
.quote-form .field .heading legend b em,
.quote-form .field .heading legend strong i,
.quote-form .field .heading legend strong em {
  font-weight: 400;
}
.sprout-form .field .heading label a,
.sprout-form .field .heading legend a,
.quote-form .field .heading label a,
.quote-form .field .heading legend a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .field .heading label a:focus,
.sprout-form .field .heading legend a:focus,
.quote-form .field .heading label a:focus,
.quote-form .field .heading legend a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .field .heading label a:hover,
.sprout-form .field .heading legend a:hover,
.quote-form .field .heading label a:hover,
.quote-form .field .heading legend a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form .field .heading label,
.sprout-form .field .heading legend,
.quote-form .field .heading label,
.quote-form .field .heading legend {
    font-size: 0.9375rem;
  }
}
.sprout-form .field .heading p,
.quote-form .field .heading p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.75em;
  color: #ff579e;
  font-size: 0.875rem;
}
.sprout-form .field .heading p i,
.sprout-form .field .heading p em,
.quote-form .field .heading p i,
.quote-form .field .heading p em {
  font-style: italic;
}
.sprout-form .field .heading p i b,
.sprout-form .field .heading p i strong,
.sprout-form .field .heading p em b,
.sprout-form .field .heading p em strong,
.quote-form .field .heading p i b,
.quote-form .field .heading p i strong,
.quote-form .field .heading p em b,
.quote-form .field .heading p em strong {
  font-style: italic;
}
.sprout-form .field .heading p b,
.sprout-form .field .heading p strong,
.quote-form .field .heading p b,
.quote-form .field .heading p strong {
  font-weight: 600;
}
.sprout-form .field .heading p b i,
.sprout-form .field .heading p b em,
.sprout-form .field .heading p strong i,
.sprout-form .field .heading p strong em,
.quote-form .field .heading p b i,
.quote-form .field .heading p b em,
.quote-form .field .heading p strong i,
.quote-form .field .heading p strong em {
  font-weight: 600;
}
.sprout-form .field .heading p a,
.quote-form .field .heading p a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .field .heading p a:focus,
.quote-form .field .heading p a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .field .heading p a:hover,
.quote-form .field .heading p a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.sprout-form .field .errors,
.quote-form .field .errors {
  position: relative;
  padding-left: 1.25em;
  font-size: 0.875em;
  color: #828e90;
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .field .errors,
.quote-form .field .errors {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .field .errors,
.quote-form .field .errors {
    margin-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .field .errors,
.quote-form .field .errors {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .field .errors,
.quote-form .field .errors {
    margin-left: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .field .errors,
.quote-form .field .errors {
    margin-left: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .field .errors,
.quote-form .field .errors {
    margin-left: 0.875rem;
  }
}
.sprout-form .field .errors:before,
.quote-form .field .errors:before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  bottom: 0.0625rem;
  display: block;
  height: 0.9375rem;
  width: 0.9375rem;
  background-image: url("/images/theme-definitive/dt_mini-checkmark.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.sprout-form .field.required .heading label:after,
.quote-form .field.required .heading label:after {
  content: " *";
  color: #2e408a;
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .field.sproutfields_notes,
.quote-form .field.sproutfields_notes {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .field.sproutfields_notes,
.quote-form .field.sproutfields_notes {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .field.sproutfields_notes,
.quote-form .field.sproutfields_notes {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .field.sproutfields_notes,
.quote-form .field.sproutfields_notes {
    margin-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .field.sproutfields_notes,
.quote-form .field.sproutfields_notes {
    margin-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .field.sproutfields_notes,
.quote-form .field.sproutfields_notes {
    margin-bottom: 0.875rem;
  }
}
.sprout-form .field.sproutfields_notes .heading,
.quote-form .field.sproutfields_notes .heading {
  padding-left: 0;
}
.sprout-form input[type=text],
.sprout-form input[type=url],
.sprout-form input[type=tel],
.sprout-form input[type=number],
.sprout-form input[type=password],
.sprout-form input[type=email],
.quote-form input[type=text],
.quote-form input[type=url],
.quote-form input[type=tel],
.quote-form input[type=number],
.quote-form input[type=password],
.quote-form input[type=email] {
  display: inline-block;
  line-height: 1;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0.3125rem 0.875rem 0.3125rem 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #ff579e;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333333;
  line-height: 1.75em;
  width: 100%;
}
.sprout-form input[type=text]::placeholder,
.sprout-form input[type=url]::placeholder,
.sprout-form input[type=tel]::placeholder,
.sprout-form input[type=number]::placeholder,
.sprout-form input[type=password]::placeholder,
.sprout-form input[type=email]::placeholder,
.quote-form input[type=text]::placeholder,
.quote-form input[type=url]::placeholder,
.quote-form input[type=tel]::placeholder,
.quote-form input[type=number]::placeholder,
.quote-form input[type=password]::placeholder,
.quote-form input[type=email]::placeholder {
  color: #ccc;
}
.sprout-form input[type=text] i,
.sprout-form input[type=text] em,
.sprout-form input[type=url] i,
.sprout-form input[type=url] em,
.sprout-form input[type=tel] i,
.sprout-form input[type=tel] em,
.sprout-form input[type=number] i,
.sprout-form input[type=number] em,
.sprout-form input[type=password] i,
.sprout-form input[type=password] em,
.sprout-form input[type=email] i,
.sprout-form input[type=email] em,
.quote-form input[type=text] i,
.quote-form input[type=text] em,
.quote-form input[type=url] i,
.quote-form input[type=url] em,
.quote-form input[type=tel] i,
.quote-form input[type=tel] em,
.quote-form input[type=number] i,
.quote-form input[type=number] em,
.quote-form input[type=password] i,
.quote-form input[type=password] em,
.quote-form input[type=email] i,
.quote-form input[type=email] em {
  font-style: italic;
}
.sprout-form input[type=text] i b,
.sprout-form input[type=text] i strong,
.sprout-form input[type=text] em b,
.sprout-form input[type=text] em strong,
.sprout-form input[type=url] i b,
.sprout-form input[type=url] i strong,
.sprout-form input[type=url] em b,
.sprout-form input[type=url] em strong,
.sprout-form input[type=tel] i b,
.sprout-form input[type=tel] i strong,
.sprout-form input[type=tel] em b,
.sprout-form input[type=tel] em strong,
.sprout-form input[type=number] i b,
.sprout-form input[type=number] i strong,
.sprout-form input[type=number] em b,
.sprout-form input[type=number] em strong,
.sprout-form input[type=password] i b,
.sprout-form input[type=password] i strong,
.sprout-form input[type=password] em b,
.sprout-form input[type=password] em strong,
.sprout-form input[type=email] i b,
.sprout-form input[type=email] i strong,
.sprout-form input[type=email] em b,
.sprout-form input[type=email] em strong,
.quote-form input[type=text] i b,
.quote-form input[type=text] i strong,
.quote-form input[type=text] em b,
.quote-form input[type=text] em strong,
.quote-form input[type=url] i b,
.quote-form input[type=url] i strong,
.quote-form input[type=url] em b,
.quote-form input[type=url] em strong,
.quote-form input[type=tel] i b,
.quote-form input[type=tel] i strong,
.quote-form input[type=tel] em b,
.quote-form input[type=tel] em strong,
.quote-form input[type=number] i b,
.quote-form input[type=number] i strong,
.quote-form input[type=number] em b,
.quote-form input[type=number] em strong,
.quote-form input[type=password] i b,
.quote-form input[type=password] i strong,
.quote-form input[type=password] em b,
.quote-form input[type=password] em strong,
.quote-form input[type=email] i b,
.quote-form input[type=email] i strong,
.quote-form input[type=email] em b,
.quote-form input[type=email] em strong {
  font-style: italic;
}
.sprout-form input[type=text] b,
.sprout-form input[type=text] strong,
.sprout-form input[type=url] b,
.sprout-form input[type=url] strong,
.sprout-form input[type=tel] b,
.sprout-form input[type=tel] strong,
.sprout-form input[type=number] b,
.sprout-form input[type=number] strong,
.sprout-form input[type=password] b,
.sprout-form input[type=password] strong,
.sprout-form input[type=email] b,
.sprout-form input[type=email] strong,
.quote-form input[type=text] b,
.quote-form input[type=text] strong,
.quote-form input[type=url] b,
.quote-form input[type=url] strong,
.quote-form input[type=tel] b,
.quote-form input[type=tel] strong,
.quote-form input[type=number] b,
.quote-form input[type=number] strong,
.quote-form input[type=password] b,
.quote-form input[type=password] strong,
.quote-form input[type=email] b,
.quote-form input[type=email] strong {
  font-weight: 600;
}
.sprout-form input[type=text] b i,
.sprout-form input[type=text] b em,
.sprout-form input[type=text] strong i,
.sprout-form input[type=text] strong em,
.sprout-form input[type=url] b i,
.sprout-form input[type=url] b em,
.sprout-form input[type=url] strong i,
.sprout-form input[type=url] strong em,
.sprout-form input[type=tel] b i,
.sprout-form input[type=tel] b em,
.sprout-form input[type=tel] strong i,
.sprout-form input[type=tel] strong em,
.sprout-form input[type=number] b i,
.sprout-form input[type=number] b em,
.sprout-form input[type=number] strong i,
.sprout-form input[type=number] strong em,
.sprout-form input[type=password] b i,
.sprout-form input[type=password] b em,
.sprout-form input[type=password] strong i,
.sprout-form input[type=password] strong em,
.sprout-form input[type=email] b i,
.sprout-form input[type=email] b em,
.sprout-form input[type=email] strong i,
.sprout-form input[type=email] strong em,
.quote-form input[type=text] b i,
.quote-form input[type=text] b em,
.quote-form input[type=text] strong i,
.quote-form input[type=text] strong em,
.quote-form input[type=url] b i,
.quote-form input[type=url] b em,
.quote-form input[type=url] strong i,
.quote-form input[type=url] strong em,
.quote-form input[type=tel] b i,
.quote-form input[type=tel] b em,
.quote-form input[type=tel] strong i,
.quote-form input[type=tel] strong em,
.quote-form input[type=number] b i,
.quote-form input[type=number] b em,
.quote-form input[type=number] strong i,
.quote-form input[type=number] strong em,
.quote-form input[type=password] b i,
.quote-form input[type=password] b em,
.quote-form input[type=password] strong i,
.quote-form input[type=password] strong em,
.quote-form input[type=email] b i,
.quote-form input[type=email] b em,
.quote-form input[type=email] strong i,
.quote-form input[type=email] strong em {
  font-weight: 600;
}
.sprout-form input[type=text] a,
.sprout-form input[type=url] a,
.sprout-form input[type=tel] a,
.sprout-form input[type=number] a,
.sprout-form input[type=password] a,
.sprout-form input[type=email] a,
.quote-form input[type=text] a,
.quote-form input[type=url] a,
.quote-form input[type=tel] a,
.quote-form input[type=number] a,
.quote-form input[type=password] a,
.quote-form input[type=email] a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form input[type=text] a:focus,
.sprout-form input[type=url] a:focus,
.sprout-form input[type=tel] a:focus,
.sprout-form input[type=number] a:focus,
.sprout-form input[type=password] a:focus,
.sprout-form input[type=email] a:focus,
.quote-form input[type=text] a:focus,
.quote-form input[type=url] a:focus,
.quote-form input[type=tel] a:focus,
.quote-form input[type=number] a:focus,
.quote-form input[type=password] a:focus,
.quote-form input[type=email] a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form input[type=text] a:hover,
.sprout-form input[type=url] a:hover,
.sprout-form input[type=tel] a:hover,
.sprout-form input[type=number] a:hover,
.sprout-form input[type=password] a:hover,
.sprout-form input[type=email] a:hover,
.quote-form input[type=text] a:hover,
.quote-form input[type=url] a:hover,
.quote-form input[type=tel] a:hover,
.quote-form input[type=number] a:hover,
.quote-form input[type=password] a:hover,
.quote-form input[type=email] a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .sprout-form input[type=text],
.sprout-form input[type=url],
.sprout-form input[type=tel],
.sprout-form input[type=number],
.sprout-form input[type=password],
.sprout-form input[type=email],
.quote-form input[type=text],
.quote-form input[type=url],
.quote-form input[type=tel],
.quote-form input[type=number],
.quote-form input[type=password],
.quote-form input[type=email] {
    font-size: 1.0625rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form input[type=text],
.sprout-form input[type=url],
.sprout-form input[type=tel],
.sprout-form input[type=number],
.sprout-form input[type=password],
.sprout-form input[type=email],
.quote-form input[type=text],
.quote-form input[type=url],
.quote-form input[type=tel],
.quote-form input[type=number],
.quote-form input[type=password],
.quote-form input[type=email] {
    font-size: 1rem;
  }
}
.sprout-form input[type=text]:focus,
.sprout-form input[type=url]:focus,
.sprout-form input[type=tel]:focus,
.sprout-form input[type=number]:focus,
.sprout-form input[type=password]:focus,
.sprout-form input[type=email]:focus,
.quote-form input[type=text]:focus,
.quote-form input[type=url]:focus,
.quote-form input[type=tel]:focus,
.quote-form input[type=number]:focus,
.quote-form input[type=password]:focus,
.quote-form input[type=email]:focus {
  padding: 0.3125rem 0.875rem 0.3125rem 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #2e408a;
}
.sprout-form input[type=number],
.quote-form input[type=number] {
  width: auto;
  min-width: 25%;
}
.sprout-form .field.errors input[type=text],
.sprout-form .field.errors input[type=url],
.sprout-form .field.errors input[type=tel],
.sprout-form .field.errors input[type=number],
.sprout-form .field.errors input[type=password],
.sprout-form .field.errors input[type=email],
.quote-form .field.errors input[type=text],
.quote-form .field.errors input[type=url],
.quote-form .field.errors input[type=tel],
.quote-form .field.errors input[type=number],
.quote-form .field.errors input[type=password],
.quote-form .field.errors input[type=email] {
  padding: 0.3125rem 0.875rem 0.3125rem 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #d10014;
}
.sprout-form textarea,
.quote-form textarea {
  line-height: 1;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0.375rem 0.875rem;
  border: 1px solid #ff579e;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333333;
  line-height: 1.75em;
  width: 100%;
}
.sprout-form textarea::placeholder,
.quote-form textarea::placeholder {
  color: #ccc;
}
.sprout-form textarea i,
.sprout-form textarea em,
.quote-form textarea i,
.quote-form textarea em {
  font-style: italic;
}
.sprout-form textarea i b,
.sprout-form textarea i strong,
.sprout-form textarea em b,
.sprout-form textarea em strong,
.quote-form textarea i b,
.quote-form textarea i strong,
.quote-form textarea em b,
.quote-form textarea em strong {
  font-style: italic;
}
.sprout-form textarea b,
.sprout-form textarea strong,
.quote-form textarea b,
.quote-form textarea strong {
  font-weight: 600;
}
.sprout-form textarea b i,
.sprout-form textarea b em,
.sprout-form textarea strong i,
.sprout-form textarea strong em,
.quote-form textarea b i,
.quote-form textarea b em,
.quote-form textarea strong i,
.quote-form textarea strong em {
  font-weight: 600;
}
.sprout-form textarea a,
.quote-form textarea a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form textarea a:focus,
.quote-form textarea a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form textarea a:hover,
.quote-form textarea a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .sprout-form textarea,
.quote-form textarea {
    font-size: 1.0625rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form textarea,
.quote-form textarea {
    font-size: 1rem;
  }
}
.sprout-form textarea:focus,
.quote-form textarea:focus {
  padding: 0.375rem 0.875rem;
  border: 1px solid #2e408a;
}
.sprout-form .field.errors textarea,
.quote-form .field.errors textarea {
  padding: 0.375rem 0.875rem;
  border: 1px solid #d10014;
}
.sprout-form .dropdown .input,
.sprout-form .sproutfields_emailselect .input,
.quote-form .dropdown .input,
.quote-form .sproutfields_emailselect .input {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 0.3125rem 0.875rem 0.3125rem 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #ff579e;
}
.sprout-form .dropdown .input select,
.sprout-form .sproutfields_emailselect .input select,
.quote-form .dropdown .input select,
.quote-form .sproutfields_emailselect .input select {
  position: relative;
  display: inline-block;
  z-index: 3;
  width: 100%;
  line-height: 1;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 0;
  background: none;
  cursor: pointer;
  transition: all 150ms;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333333;
  line-height: 1.75em;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}
.sprout-form .dropdown .input select::placeholder,
.sprout-form .sproutfields_emailselect .input select::placeholder,
.quote-form .dropdown .input select::placeholder,
.quote-form .sproutfields_emailselect .input select::placeholder {
  color: #ccc;
}
.sprout-form .dropdown .input select i,
.sprout-form .dropdown .input select em,
.sprout-form .sproutfields_emailselect .input select i,
.sprout-form .sproutfields_emailselect .input select em,
.quote-form .dropdown .input select i,
.quote-form .dropdown .input select em,
.quote-form .sproutfields_emailselect .input select i,
.quote-form .sproutfields_emailselect .input select em {
  font-style: italic;
}
.sprout-form .dropdown .input select i b,
.sprout-form .dropdown .input select i strong,
.sprout-form .dropdown .input select em b,
.sprout-form .dropdown .input select em strong,
.sprout-form .sproutfields_emailselect .input select i b,
.sprout-form .sproutfields_emailselect .input select i strong,
.sprout-form .sproutfields_emailselect .input select em b,
.sprout-form .sproutfields_emailselect .input select em strong,
.quote-form .dropdown .input select i b,
.quote-form .dropdown .input select i strong,
.quote-form .dropdown .input select em b,
.quote-form .dropdown .input select em strong,
.quote-form .sproutfields_emailselect .input select i b,
.quote-form .sproutfields_emailselect .input select i strong,
.quote-form .sproutfields_emailselect .input select em b,
.quote-form .sproutfields_emailselect .input select em strong {
  font-style: italic;
}
.sprout-form .dropdown .input select b,
.sprout-form .dropdown .input select strong,
.sprout-form .sproutfields_emailselect .input select b,
.sprout-form .sproutfields_emailselect .input select strong,
.quote-form .dropdown .input select b,
.quote-form .dropdown .input select strong,
.quote-form .sproutfields_emailselect .input select b,
.quote-form .sproutfields_emailselect .input select strong {
  font-weight: 600;
}
.sprout-form .dropdown .input select b i,
.sprout-form .dropdown .input select b em,
.sprout-form .dropdown .input select strong i,
.sprout-form .dropdown .input select strong em,
.sprout-form .sproutfields_emailselect .input select b i,
.sprout-form .sproutfields_emailselect .input select b em,
.sprout-form .sproutfields_emailselect .input select strong i,
.sprout-form .sproutfields_emailselect .input select strong em,
.quote-form .dropdown .input select b i,
.quote-form .dropdown .input select b em,
.quote-form .dropdown .input select strong i,
.quote-form .dropdown .input select strong em,
.quote-form .sproutfields_emailselect .input select b i,
.quote-form .sproutfields_emailselect .input select b em,
.quote-form .sproutfields_emailselect .input select strong i,
.quote-form .sproutfields_emailselect .input select strong em {
  font-weight: 600;
}
.sprout-form .dropdown .input select a,
.sprout-form .sproutfields_emailselect .input select a,
.quote-form .dropdown .input select a,
.quote-form .sproutfields_emailselect .input select a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .dropdown .input select a:focus,
.sprout-form .sproutfields_emailselect .input select a:focus,
.quote-form .dropdown .input select a:focus,
.quote-form .sproutfields_emailselect .input select a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .dropdown .input select a:hover,
.sprout-form .sproutfields_emailselect .input select a:hover,
.quote-form .dropdown .input select a:hover,
.quote-form .sproutfields_emailselect .input select a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .sprout-form .dropdown .input select,
.sprout-form .sproutfields_emailselect .input select,
.quote-form .dropdown .input select,
.quote-form .sproutfields_emailselect .input select {
    font-size: 1.0625rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form .dropdown .input select,
.sprout-form .sproutfields_emailselect .input select,
.quote-form .dropdown .input select,
.quote-form .sproutfields_emailselect .input select {
    font-size: 1rem;
  }
}
.sprout-form .dropdown .input:after,
.sprout-form .sproutfields_emailselect .input:after,
.quote-form .dropdown .input:after,
.quote-form .sproutfields_emailselect .input:after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%) rotateZ(180deg);
  width: 24px;
  height: 24px;
  background: url("/assets/images/theme/link-carrot-up.svg") no-repeat center;
  background-size: 24px 24px;
  transition: all 150ms;
}
.sprout-form .dropdown .input:hover:after,
.sprout-form .sproutfields_emailselect .input:hover:after,
.quote-form .dropdown .input:hover:after,
.quote-form .sproutfields_emailselect .input:hover:after {
  opacity: 1;
}
.sprout-form .dropdown .input:hover select,
.sprout-form .sproutfields_emailselect .input:hover select,
.quote-form .dropdown .input:hover select,
.quote-form .sproutfields_emailselect .input:hover select {
  color: black;
}
.sprout-form .field.errors.dropdown .input, .sprout-form .field.errors.sproutfields_emailselect .input,
.quote-form .field.errors.dropdown .input,
.quote-form .field.errors.sproutfields_emailselect .input {
  padding: 0.3125rem 0.875rem 0.3125rem 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #d10014;
}
.sprout-form .multiselect .input select,
.quote-form .multiselect .input select {
  line-height: 1;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0.375rem 0.875rem;
  border: 1px solid #2e408a;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.75em;
  width: 100%;
  padding: 0;
}
.sprout-form .multiselect .input select::placeholder,
.quote-form .multiselect .input select::placeholder {
  color: #ccc;
}
.sprout-form .multiselect .input select i,
.sprout-form .multiselect .input select em,
.quote-form .multiselect .input select i,
.quote-form .multiselect .input select em {
  font-style: italic;
}
.sprout-form .multiselect .input select i b,
.sprout-form .multiselect .input select i strong,
.sprout-form .multiselect .input select em b,
.sprout-form .multiselect .input select em strong,
.quote-form .multiselect .input select i b,
.quote-form .multiselect .input select i strong,
.quote-form .multiselect .input select em b,
.quote-form .multiselect .input select em strong {
  font-style: italic;
}
.sprout-form .multiselect .input select b,
.sprout-form .multiselect .input select strong,
.quote-form .multiselect .input select b,
.quote-form .multiselect .input select strong {
  font-weight: 600;
}
.sprout-form .multiselect .input select b i,
.sprout-form .multiselect .input select b em,
.sprout-form .multiselect .input select strong i,
.sprout-form .multiselect .input select strong em,
.quote-form .multiselect .input select b i,
.quote-form .multiselect .input select b em,
.quote-form .multiselect .input select strong i,
.quote-form .multiselect .input select strong em {
  font-weight: 600;
}
.sprout-form .multiselect .input select a,
.quote-form .multiselect .input select a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .multiselect .input select a:focus,
.quote-form .multiselect .input select a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .multiselect .input select a:hover,
.quote-form .multiselect .input select a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .multiselect .input select,
.quote-form .multiselect .input select {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .multiselect .input select,
.quote-form .multiselect .input select {
    margin-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .multiselect .input select,
.quote-form .multiselect .input select {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .sprout-form .multiselect .input select,
.quote-form .multiselect .input select {
    font-size: 1.0625rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form .multiselect .input select,
.quote-form .multiselect .input select {
    font-size: 1rem;
  }
}
.sprout-form .multiselect .input select option,
.quote-form .multiselect .input select option {
  margin: 0.0625rem 0;
  padding: 0.375rem 1rem;
}
.sprout-form .field.errors.multiselect .input select,
.quote-form .field.errors.multiselect .input select {
  padding: 0.375rem 0.875rem;
  border: 1px solid #d10014;
}
.sprout-form .multiplechoice .input,
.sprout-form .radiobuttons .input,
.sprout-form .checkboxes .input,
.quote-form .multiplechoice .input,
.quote-form .radiobuttons .input,
.quote-form .checkboxes .input {
  padding: 0.375rem 0.625rem;
}
@media screen and (min-width: 68.8125rem) {
  .sprout-form .multiplechoice .input,
.sprout-form .radiobuttons .input,
.sprout-form .checkboxes .input,
.quote-form .multiplechoice .input,
.quote-form .radiobuttons .input,
.quote-form .checkboxes .input {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sprout-form .multiplechoice .input,
.sprout-form .radiobuttons .input,
.sprout-form .checkboxes .input,
.quote-form .multiplechoice .input,
.quote-form .radiobuttons .input,
.quote-form .checkboxes .input {
    margin-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sprout-form .multiplechoice .input,
.sprout-form .radiobuttons .input,
.sprout-form .checkboxes .input,
.quote-form .multiplechoice .input,
.quote-form .radiobuttons .input,
.quote-form .checkboxes .input {
    margin-top: 0.625rem;
  }
}
.sprout-form .multiplechoice .input label,
.sprout-form .radiobuttons .input label,
.sprout-form .checkboxes .input label,
.quote-form .multiplechoice .input label,
.quote-form .radiobuttons .input label,
.quote-form .checkboxes .input label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333333;
  line-height: 1.75em;
}
.sprout-form .multiplechoice .input label::placeholder,
.sprout-form .radiobuttons .input label::placeholder,
.sprout-form .checkboxes .input label::placeholder,
.quote-form .multiplechoice .input label::placeholder,
.quote-form .radiobuttons .input label::placeholder,
.quote-form .checkboxes .input label::placeholder {
  color: #ccc;
}
.sprout-form .multiplechoice .input label i,
.sprout-form .multiplechoice .input label em,
.sprout-form .radiobuttons .input label i,
.sprout-form .radiobuttons .input label em,
.sprout-form .checkboxes .input label i,
.sprout-form .checkboxes .input label em,
.quote-form .multiplechoice .input label i,
.quote-form .multiplechoice .input label em,
.quote-form .radiobuttons .input label i,
.quote-form .radiobuttons .input label em,
.quote-form .checkboxes .input label i,
.quote-form .checkboxes .input label em {
  font-style: italic;
}
.sprout-form .multiplechoice .input label i b,
.sprout-form .multiplechoice .input label i strong,
.sprout-form .multiplechoice .input label em b,
.sprout-form .multiplechoice .input label em strong,
.sprout-form .radiobuttons .input label i b,
.sprout-form .radiobuttons .input label i strong,
.sprout-form .radiobuttons .input label em b,
.sprout-form .radiobuttons .input label em strong,
.sprout-form .checkboxes .input label i b,
.sprout-form .checkboxes .input label i strong,
.sprout-form .checkboxes .input label em b,
.sprout-form .checkboxes .input label em strong,
.quote-form .multiplechoice .input label i b,
.quote-form .multiplechoice .input label i strong,
.quote-form .multiplechoice .input label em b,
.quote-form .multiplechoice .input label em strong,
.quote-form .radiobuttons .input label i b,
.quote-form .radiobuttons .input label i strong,
.quote-form .radiobuttons .input label em b,
.quote-form .radiobuttons .input label em strong,
.quote-form .checkboxes .input label i b,
.quote-form .checkboxes .input label i strong,
.quote-form .checkboxes .input label em b,
.quote-form .checkboxes .input label em strong {
  font-style: italic;
}
.sprout-form .multiplechoice .input label b,
.sprout-form .multiplechoice .input label strong,
.sprout-form .radiobuttons .input label b,
.sprout-form .radiobuttons .input label strong,
.sprout-form .checkboxes .input label b,
.sprout-form .checkboxes .input label strong,
.quote-form .multiplechoice .input label b,
.quote-form .multiplechoice .input label strong,
.quote-form .radiobuttons .input label b,
.quote-form .radiobuttons .input label strong,
.quote-form .checkboxes .input label b,
.quote-form .checkboxes .input label strong {
  font-weight: 600;
}
.sprout-form .multiplechoice .input label b i,
.sprout-form .multiplechoice .input label b em,
.sprout-form .multiplechoice .input label strong i,
.sprout-form .multiplechoice .input label strong em,
.sprout-form .radiobuttons .input label b i,
.sprout-form .radiobuttons .input label b em,
.sprout-form .radiobuttons .input label strong i,
.sprout-form .radiobuttons .input label strong em,
.sprout-form .checkboxes .input label b i,
.sprout-form .checkboxes .input label b em,
.sprout-form .checkboxes .input label strong i,
.sprout-form .checkboxes .input label strong em,
.quote-form .multiplechoice .input label b i,
.quote-form .multiplechoice .input label b em,
.quote-form .multiplechoice .input label strong i,
.quote-form .multiplechoice .input label strong em,
.quote-form .radiobuttons .input label b i,
.quote-form .radiobuttons .input label b em,
.quote-form .radiobuttons .input label strong i,
.quote-form .radiobuttons .input label strong em,
.quote-form .checkboxes .input label b i,
.quote-form .checkboxes .input label b em,
.quote-form .checkboxes .input label strong i,
.quote-form .checkboxes .input label strong em {
  font-weight: 600;
}
.sprout-form .multiplechoice .input label a,
.sprout-form .radiobuttons .input label a,
.sprout-form .checkboxes .input label a,
.quote-form .multiplechoice .input label a,
.quote-form .radiobuttons .input label a,
.quote-form .checkboxes .input label a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .multiplechoice .input label a:focus,
.sprout-form .radiobuttons .input label a:focus,
.sprout-form .checkboxes .input label a:focus,
.quote-form .multiplechoice .input label a:focus,
.quote-form .radiobuttons .input label a:focus,
.quote-form .checkboxes .input label a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .multiplechoice .input label a:hover,
.sprout-form .radiobuttons .input label a:hover,
.sprout-form .checkboxes .input label a:hover,
.quote-form .multiplechoice .input label a:hover,
.quote-form .radiobuttons .input label a:hover,
.quote-form .checkboxes .input label a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.sprout-form .multiplechoice .input label:before, .sprout-form .multiplechoice .input label:after,
.sprout-form .radiobuttons .input label:before,
.sprout-form .radiobuttons .input label:after,
.sprout-form .checkboxes .input label:before,
.sprout-form .checkboxes .input label:after,
.quote-form .multiplechoice .input label:before,
.quote-form .multiplechoice .input label:after,
.quote-form .radiobuttons .input label:before,
.quote-form .radiobuttons .input label:after,
.quote-form .checkboxes .input label:before,
.quote-form .checkboxes .input label:after {
  border-radius: 50%;
}
.sprout-form .multiplechoice .input label:after,
.sprout-form .radiobuttons .input label:after,
.sprout-form .checkboxes .input label:after,
.quote-form .multiplechoice .input label:after,
.quote-form .radiobuttons .input label:after,
.quote-form .checkboxes .input label:after {
  border: 0.0625rem solid #ff579e;
}
.sprout-form .multiplechoice .input input,
.sprout-form .radiobuttons .input input,
.sprout-form .checkboxes .input input,
.quote-form .multiplechoice .input input,
.quote-form .radiobuttons .input input,
.quote-form .checkboxes .input input {
  margin-right: 1em;
  margin-top: 4px;
}
.sprout-form .submit button,
.quote-form .submit button {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: white;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.1875rem;
  background-color: #2e408a;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
  text-transform: uppercase;
}
.sprout-form .submit button i,
.sprout-form .submit button em,
.quote-form .submit button i,
.quote-form .submit button em {
  font-style: italic;
}
.sprout-form .submit button i b,
.sprout-form .submit button i strong,
.sprout-form .submit button em b,
.sprout-form .submit button em strong,
.quote-form .submit button i b,
.quote-form .submit button i strong,
.quote-form .submit button em b,
.quote-form .submit button em strong {
  font-style: italic;
}
.sprout-form .submit button b,
.sprout-form .submit button strong,
.quote-form .submit button b,
.quote-form .submit button strong {
  font-weight: 600;
}
.sprout-form .submit button b i,
.sprout-form .submit button b em,
.sprout-form .submit button strong i,
.sprout-form .submit button strong em,
.quote-form .submit button b i,
.quote-form .submit button b em,
.quote-form .submit button strong i,
.quote-form .submit button strong em {
  font-weight: 600;
}
.sprout-form .submit button a,
.quote-form .submit button a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .submit button a:focus,
.quote-form .submit button a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .submit button a:hover,
.quote-form .submit button a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.sprout-form .submit button:focus,
.quote-form .submit button:focus {
  color: white;
  background-image: none;
  text-decoration: none;
  background-color: #ff579e;
  border-color: transparent;
}
@media (hover: hover) {
  .sprout-form .submit button:hover,
.quote-form .submit button:hover {
    color: white;
    background-image: none;
    text-decoration: none;
    background-color: #ff579e;
    border-color: transparent;
  }
}
.sprout-form .submit button.inactive, .sprout-form .submit button:disabled,
.quote-form .submit button.inactive,
.quote-form .submit button:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.sprout-form .submit button.inactive:focus, .sprout-form .submit button:disabled:focus,
.quote-form .submit button.inactive:focus,
.quote-form .submit button:disabled:focus {
  color: white;
  border-color: transparent;
  background-color: #2e408a;
}
@media (hover: hover) {
  .sprout-form .submit button.inactive:hover, .sprout-form .submit button:disabled:hover,
.quote-form .submit button.inactive:hover,
.quote-form .submit button:disabled:hover {
    color: white;
    border-color: transparent;
    background-color: #2e408a;
  }
}
.sprout-form .field.phone .input,
.quote-form .field.phone .input {
  border-bottom: 0;
  padding: 0;
}
@media screen and (min-width: 46.9375rem) {
  .sprout-form .field.phone .input > *,
.quote-form .field.phone .input > * {
    display: flex;
  }
}
.sprout-form .field.phone .input input,
.quote-form .field.phone .input input {
  flex-grow: 1;
  padding-left: 1em;
}
.sprout-form .field.phone .input select,
.quote-form .field.phone .input select {
  position: relative;
  display: inline-block;
  z-index: 3;
  flex-grow: 0;
  line-height: 1;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 0;
  background: none;
  padding: 0.3125rem 0.875rem 0.3125rem 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #ff579e;
  cursor: pointer;
  transition: all 150ms;
  background: url("/assets/images/theme/link-carrot-down.svg") no-repeat center right;
  background-size: 24px 24px;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333333;
  line-height: 1.75em;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}
.sprout-form .field.phone .input select::placeholder,
.quote-form .field.phone .input select::placeholder {
  color: #ccc;
}
.sprout-form .field.phone .input select i,
.sprout-form .field.phone .input select em,
.quote-form .field.phone .input select i,
.quote-form .field.phone .input select em {
  font-style: italic;
}
.sprout-form .field.phone .input select i b,
.sprout-form .field.phone .input select i strong,
.sprout-form .field.phone .input select em b,
.sprout-form .field.phone .input select em strong,
.quote-form .field.phone .input select i b,
.quote-form .field.phone .input select i strong,
.quote-form .field.phone .input select em b,
.quote-form .field.phone .input select em strong {
  font-style: italic;
}
.sprout-form .field.phone .input select b,
.sprout-form .field.phone .input select strong,
.quote-form .field.phone .input select b,
.quote-form .field.phone .input select strong {
  font-weight: 600;
}
.sprout-form .field.phone .input select b i,
.sprout-form .field.phone .input select b em,
.sprout-form .field.phone .input select strong i,
.sprout-form .field.phone .input select strong em,
.quote-form .field.phone .input select b i,
.quote-form .field.phone .input select b em,
.quote-form .field.phone .input select strong i,
.quote-form .field.phone .input select strong em {
  font-weight: 600;
}
.sprout-form .field.phone .input select a,
.quote-form .field.phone .input select a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.sprout-form .field.phone .input select a:focus,
.quote-form .field.phone .input select a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .sprout-form .field.phone .input select a:hover,
.quote-form .field.phone .input select a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .sprout-form .field.phone .input select,
.quote-form .field.phone .input select {
    font-size: 1.0625rem;
  }
}
@media screen and (max-width: 34rem) {
  .sprout-form .field.phone .input select,
.quote-form .field.phone .input select {
    font-size: 1rem;
  }
}
.sprout-form .field.phone .input .field.errors.phone .input select,
.quote-form .field.phone .input .field.errors.phone .input select {
  padding: 0.3125rem 0.875rem 0.3125rem 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid #d10014;
}

/* ----------------------------------
    Hero for Case Studies
---------------------------------- */
[data-block-type=case_study_hero] {
  display: flex;
  flex-direction: column-reverse;
  /*  Intro Content
  ---------------------------------- */
  /*  Metadata Content
  ---------------------------------- */
  /*  Hero Image
  ---------------------------------- */
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=case_study_hero] {
    flex-direction: column;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=case_study_hero] .bound {
    display: block;
  }
  [data-block-type=case_study_hero] .bound::after {
    clear: both;
    content: "";
    display: table;
  }
}
[data-block-type=case_study_hero] .intro {
  /*  Layout ---- */
  /* Tablet */
  /* Desktop */
  /*  Content ---- */
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=case_study_hero] .intro {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 65.1452282158%;
  }
  [data-block-type=case_study_hero] .intro:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=case_study_hero] .intro {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 58.3643122677%;
  }
  [data-block-type=case_study_hero] .intro:last-child {
    margin-right: 0;
  }
}
[data-block-type=case_study_hero] .intro h4 {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #4b5254;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=case_study_hero] .intro h4 {
    margin-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=case_study_hero] .intro h4 {
    margin-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=case_study_hero] .intro h4 {
    margin-bottom: 0.875rem;
  }
}
[data-block-type=case_study_hero] .intro h4 i,
[data-block-type=case_study_hero] .intro h4 em {
  font-style: italic;
}
[data-block-type=case_study_hero] .intro h4 i b,
[data-block-type=case_study_hero] .intro h4 i strong,
[data-block-type=case_study_hero] .intro h4 em b,
[data-block-type=case_study_hero] .intro h4 em strong {
  font-style: italic;
}
[data-block-type=case_study_hero] .intro h4 b,
[data-block-type=case_study_hero] .intro h4 strong {
  font-weight: 600;
}
[data-block-type=case_study_hero] .intro h4 b i,
[data-block-type=case_study_hero] .intro h4 b em,
[data-block-type=case_study_hero] .intro h4 strong i,
[data-block-type=case_study_hero] .intro h4 strong em {
  font-weight: 600;
}
[data-block-type=case_study_hero] .intro h4 a {
  transition: color 200ms;
  color: #4b5254;
  cursor: pointer;
  /*  Hover & Active States ---- */
}
[data-block-type=case_study_hero] .intro h4 a:focus {
  color: #ff579e;
}
@media (hover: hover) {
  [data-block-type=case_study_hero] .intro h4 a:hover {
    color: #ff579e;
  }
}
[data-block-type=case_study_hero] .intro h1 {
  font-family: "Poppins", sans-serif;
  font-size: 3.4375rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.1em;
}
[data-block-type=case_study_hero] .intro h1 i,
[data-block-type=case_study_hero] .intro h1 em {
  font-style: italic;
}
[data-block-type=case_study_hero] .intro h1 i b,
[data-block-type=case_study_hero] .intro h1 i strong,
[data-block-type=case_study_hero] .intro h1 em b,
[data-block-type=case_study_hero] .intro h1 em strong {
  font-style: italic;
}
[data-block-type=case_study_hero] .intro h1 b,
[data-block-type=case_study_hero] .intro h1 strong {
  font-weight: 600;
}
[data-block-type=case_study_hero] .intro h1 b i,
[data-block-type=case_study_hero] .intro h1 b em,
[data-block-type=case_study_hero] .intro h1 strong i,
[data-block-type=case_study_hero] .intro h1 strong em {
  font-weight: 600;
}
[data-block-type=case_study_hero] .intro h1 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=case_study_hero] .intro h1 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=case_study_hero] .intro h1 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=case_study_hero] .intro h1 {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=case_study_hero] .intro h1 {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=case_study_hero] .intro h1 {
    margin-bottom: 2.375rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 61.9375rem) {
  [data-block-type=case_study_hero] .intro h1 {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=case_study_hero] .intro h1 {
    font-size: 2.8125rem;
    line-height: 1.15em;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=case_study_hero] .intro h1 {
    font-size: 2.1875rem;
  }
}
[data-block-type=case_study_hero] .intro h1 b, [data-block-type=case_study_hero] .intro h1 strong {
  color: #ff579e;
}
[data-block-type=case_study_hero] .intro .wysiwyg > *:first-child {
  margin-top: 0;
}
[data-block-type=case_study_hero] .intro .wysiwyg > *:only-child,
[data-block-type=case_study_hero] .intro .wysiwyg > *:last-child {
  margin-bottom: 0;
}
[data-block-type=case_study_hero] .meta {
  /* Tablet */
  /* Desktop */
  /*  Content ---- */
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=case_study_hero] .meta {
    display: flex;
  }
  [data-block-type=case_study_hero] .meta > * {
    width: 48%;
  }
  [data-block-type=case_study_hero] .meta > * li {
    width: 100%;
  }
  [data-block-type=case_study_hero] .meta > *:nth-child(odd) {
    margin-right: 2%;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=case_study_hero] .meta {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
    margin-top: 0;
  }
  [data-block-type=case_study_hero] .meta:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=case_study_hero] .meta {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 32.3420074349%;
    margin-left: 5.2044609665%;
  }
  [data-block-type=case_study_hero] .meta:last-child {
    margin-right: 0;
  }
}
[data-block-type=case_study_hero] .meta h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=case_study_hero] .meta h3 {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=case_study_hero] .meta h3 {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=case_study_hero] .meta h3 {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=case_study_hero] .meta h3 {
    margin-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=case_study_hero] .meta h3 {
    margin-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=case_study_hero] .meta h3 {
    margin-bottom: 0.875rem;
  }
}
[data-block-type=case_study_hero] .meta h3 i,
[data-block-type=case_study_hero] .meta h3 em {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta h3 i b,
[data-block-type=case_study_hero] .meta h3 i strong,
[data-block-type=case_study_hero] .meta h3 em b,
[data-block-type=case_study_hero] .meta h3 em strong {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta h3 b,
[data-block-type=case_study_hero] .meta h3 strong {
  font-weight: 500;
}
[data-block-type=case_study_hero] .meta h3 b i,
[data-block-type=case_study_hero] .meta h3 b em,
[data-block-type=case_study_hero] .meta h3 strong i,
[data-block-type=case_study_hero] .meta h3 strong em {
  font-weight: 500;
}
[data-block-type=case_study_hero] .meta h3 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=case_study_hero] .meta h3 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=case_study_hero] .meta h3 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=case_study_hero] .meta h3 {
    font-size: 1.5rem;
  }
}
[data-block-type=case_study_hero] .meta h3 b, [data-block-type=case_study_hero] .meta h3 strong {
  color: #ff579e;
}
[data-block-type=case_study_hero] .meta ul {
  display: flex;
  flex-wrap: wrap;
}
[data-block-type=case_study_hero] .meta ul li {
  position: relative;
  padding-left: 1em;
  padding-right: 0.7142857143em;
  margin: 0.3125em 0;
  min-width: 50%;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
}
[data-block-type=case_study_hero] .meta ul li i,
[data-block-type=case_study_hero] .meta ul li em {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta ul li i b,
[data-block-type=case_study_hero] .meta ul li i strong,
[data-block-type=case_study_hero] .meta ul li em b,
[data-block-type=case_study_hero] .meta ul li em strong {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta ul li b,
[data-block-type=case_study_hero] .meta ul li strong {
  font-weight: 600;
}
[data-block-type=case_study_hero] .meta ul li b i,
[data-block-type=case_study_hero] .meta ul li b em,
[data-block-type=case_study_hero] .meta ul li strong i,
[data-block-type=case_study_hero] .meta ul li strong em {
  font-weight: 600;
}
[data-block-type=case_study_hero] .meta ul li a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  /*  Hover & Active States ---- */
}
[data-block-type=case_study_hero] .meta ul li a:focus {
  color: #ff579e;
}
@media (hover: hover) {
  [data-block-type=case_study_hero] .meta ul li a:hover {
    color: #ff579e;
  }
}
[data-block-type=case_study_hero] .meta ul li:before {
  content: "–";
  position: absolute;
  left: 0;
  color: #ff579e;
}
[data-block-type=case_study_hero] .meta ul li span {
  display: block;
}
[data-block-type=case_study_hero] .meta ul li [class*=btn-] {
  margin-top: 0.9375rem;
}
[data-block-type=case_study_hero] .meta .award {
  display: flex;
}
[data-block-type=case_study_hero] .meta .award .icon {
  margin-right: 0.625rem;
}
[data-block-type=case_study_hero] .meta .award h4 {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.2em;
  margin: 0.5em 0;
}
[data-block-type=case_study_hero] .meta .award h4 i,
[data-block-type=case_study_hero] .meta .award h4 em {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta .award h4 i b,
[data-block-type=case_study_hero] .meta .award h4 i strong,
[data-block-type=case_study_hero] .meta .award h4 em b,
[data-block-type=case_study_hero] .meta .award h4 em strong {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta .award h4 b,
[data-block-type=case_study_hero] .meta .award h4 strong {
  font-weight: 600;
}
[data-block-type=case_study_hero] .meta .award h4 b i,
[data-block-type=case_study_hero] .meta .award h4 b em,
[data-block-type=case_study_hero] .meta .award h4 strong i,
[data-block-type=case_study_hero] .meta .award h4 strong em {
  font-weight: 600;
}
[data-block-type=case_study_hero] .meta .award h4 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=case_study_hero] .meta .award h4 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=case_study_hero] .meta .award h4 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
[data-block-type=case_study_hero] .meta .award h5 {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.42em;
}
[data-block-type=case_study_hero] .meta .award h5 i,
[data-block-type=case_study_hero] .meta .award h5 em {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta .award h5 i b,
[data-block-type=case_study_hero] .meta .award h5 i strong,
[data-block-type=case_study_hero] .meta .award h5 em b,
[data-block-type=case_study_hero] .meta .award h5 em strong {
  font-style: italic;
}
[data-block-type=case_study_hero] .meta .award h5 b,
[data-block-type=case_study_hero] .meta .award h5 strong {
  font-weight: 600;
}
[data-block-type=case_study_hero] .meta .award h5 b i,
[data-block-type=case_study_hero] .meta .award h5 b em,
[data-block-type=case_study_hero] .meta .award h5 strong i,
[data-block-type=case_study_hero] .meta .award h5 strong em {
  font-weight: 600;
}
[data-block-type=case_study_hero] .meta .award h5 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=case_study_hero] .meta .award h5 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=case_study_hero] .meta .award h5 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
[data-block-type=case_study_hero] .img img {
  width: 100%;
}
[data-block-type=case_study_hero] .img.artdirected .desktop {
  display: none;
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=case_study_hero] .img.artdirected .mobile {
    display: none;
  }
  [data-block-type=case_study_hero] .img.artdirected .desktop {
    display: block;
  }
}

/* ----------------------------------
    Hero
---------------------------------- */
[data-block-type=hero] {
  /*  Hero Circle Background
  ---------------------------------- */
  /*  Nav Circle Background
  ---------------------------------- */
  /*  Menu Open
  ---------------------------------- */
}
[data-block-type=hero] .foundation-container {
  position: relative;
  z-index: 3;
}
[data-block-type=hero] .bkgrd-main {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 142%;
  transform: translateX(6%);
  padding-top: 71%;
  background: #2e408a;
  bottom: 0;
  border-bottom-left-radius: 100% 200%;
  border-bottom-right-radius: 100% 200%;
  left: auto;
  right: 0;
  background-color: #317aae;
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=hero] .bkgrd-main {
    left: 0;
    transform: translateX(-25%);
    width: 160%;
    height: 150%;
    border-bottom-left-radius: 300% 100%;
    border-bottom-right-radius: 300% 100%;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=hero] .bkgrd-main:after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    padding-top: 50%;
    background: #2e408a;
    bottom: 100%;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
  }
  [data-block-type=hero] .bkgrd-main:after {
    background-color: inherit;
  }
}
[data-block-type=hero] .bkgrd-nav {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  padding-top: 40%;
  background: #2e408a;
  bottom: 0;
  border-bottom-left-radius: 100% 200%;
  border-bottom-right-radius: 100% 200%;
  bottom: auto;
  top: 0;
  left: 100%;
  z-index: 2;
  background-color: #ff579e;
  transform: translate(-75%, -75%);
  mix-blend-mode: multiply;
  transition: all 300ms cubic-bezier(0.16, 1, 0.3, 1);
}
@media screen and (max-width: 61.9375rem) {
  [data-block-type=hero] .bkgrd-nav {
    width: 60%;
    padding-top: 30%;
    transform: translate(-50%, -70%);
  }
}
[data-block-type=hero] .bkgrd-nav:after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  padding-top: 50%;
  background: #2e408a;
  bottom: 100%;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
}
[data-block-type=hero] .bkgrd-nav:after {
  background-color: inherit;
}
@media screen and (max-width: 61.9375rem) {
  .menu_open [data-block-type=hero] .bkgrd-nav {
    transform: scale(8);
    background-color: #001623;
  }
}

/* ----------------------------------
    HR Block
---------------------------------- */
[data-block-type=hr] {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  /*  Sizing
  ---------------------------------- */
}
[data-block-type=hr]::after {
  clear: both;
  content: "";
  display: table;
}
[data-block-type=hr] hr {
  display: inline-block;
  width: 100%;
  height: 0;
  border: none;
  border-top: 0.0625rem solid #828e90;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=hr] hr:not(.flush) {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=hr] hr:not(.flush) {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=hr] hr:not(.flush) {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=hr] hr:not(.flush) {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=hr] hr:not(.flush) {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=hr] hr:not(.flush) {
    margin-bottom: 2.375rem;
  }
}
[data-block-type=hr].small {
  width: 28.3333333333%;
}
[data-block-type=hr].medium {
  width: 42.5%;
}
[data-block-type=hr].large {
  width: 85%;
}

/* ----------------------------------
    Foundational Image - Content Block Styles
---------------------------------- */
[data-block-type=image] .foundation-container img {
  width: 100%;
}

/* ----------------------------------
   Instagram Gallery Block
---------------------------------- */
[data-block-type=instagram-gallery] {
  /*  Heading area
  ---------------------------------- */
  /*  Night Mode ---- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=instagram-gallery] .header {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=instagram-gallery] .header {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=instagram-gallery] .header {
    margin-bottom: 2.375rem;
  }
}
[data-block-type=instagram-gallery] h3 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
}
[data-block-type=instagram-gallery] h3 i,
[data-block-type=instagram-gallery] h3 em {
  font-style: italic;
}
[data-block-type=instagram-gallery] h3 i b,
[data-block-type=instagram-gallery] h3 i strong,
[data-block-type=instagram-gallery] h3 em b,
[data-block-type=instagram-gallery] h3 em strong {
  font-style: italic;
}
[data-block-type=instagram-gallery] h3 b,
[data-block-type=instagram-gallery] h3 strong {
  font-weight: 600;
}
[data-block-type=instagram-gallery] h3 b i,
[data-block-type=instagram-gallery] h3 b em,
[data-block-type=instagram-gallery] h3 strong i,
[data-block-type=instagram-gallery] h3 strong em {
  font-weight: 600;
}
[data-block-type=instagram-gallery] h3 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=instagram-gallery] h3 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=instagram-gallery] h3 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=instagram-gallery] h3 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=instagram-gallery] h3 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=instagram-gallery] h3 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=instagram-gallery] h3 {
    font-size: 1.9166666667rem;
    line-height: 1.255em;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=instagram-gallery] h3 {
    font-size: 1.75rem;
  }
}
[data-block-type=instagram-gallery] h3 b, [data-block-type=instagram-gallery] h3 strong {
  color: #ff579e;
}
[data-block-type=instagram-gallery] .name {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: #828e90;
  line-height: 1.15em;
  /*  Hover & Active States ---- */
}
[data-block-type=instagram-gallery] .name i,
[data-block-type=instagram-gallery] .name em {
  font-style: italic;
}
[data-block-type=instagram-gallery] .name i b,
[data-block-type=instagram-gallery] .name i strong,
[data-block-type=instagram-gallery] .name em b,
[data-block-type=instagram-gallery] .name em strong {
  font-style: italic;
}
[data-block-type=instagram-gallery] .name b,
[data-block-type=instagram-gallery] .name strong {
  font-weight: 600;
}
[data-block-type=instagram-gallery] .name b i,
[data-block-type=instagram-gallery] .name b em,
[data-block-type=instagram-gallery] .name strong i,
[data-block-type=instagram-gallery] .name strong em {
  font-weight: 600;
}
[data-block-type=instagram-gallery] .name a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=instagram-gallery] .name a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=instagram-gallery] .name a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
[data-block-type=instagram-gallery] .name svg {
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  margin-right: 0.5em;
  fill: #333;
  transition: 0.8 200ms ease-in-out;
}
[data-block-type=instagram-gallery] .name:focus svg {
  fill: #000;
}
@media (hover: hover) {
  [data-block-type=instagram-gallery] .name:hover svg {
    fill: #000;
  }
}
[data-block-type=instagram-gallery][data-night-mode=true] .header h3 {
  color: rgba(255, 255, 255, 0.6);
}
[data-block-type=instagram-gallery][data-night-mode=true] .header h3 b,
[data-block-type=instagram-gallery][data-night-mode=true] .header h3 strong {
  color: white;
}
[data-block-type=instagram-gallery][data-night-mode=true] .header a {
  color: rgba(255, 255, 255, 0.6);
  /*  Hover & Active States ---- */
}
[data-block-type=instagram-gallery][data-night-mode=true] .header a svg {
  fill: rgba(255, 255, 255, 0.6);
}
[data-block-type=instagram-gallery][data-night-mode=true] .header a:focus {
  color: white;
}
[data-block-type=instagram-gallery][data-night-mode=true] .header a:focus svg {
  fill: white;
}
@media (hover: hover) {
  [data-block-type=instagram-gallery][data-night-mode=true] .header a:hover {
    color: white;
  }
  [data-block-type=instagram-gallery][data-night-mode=true] .header a:hover svg {
    fill: white;
  }
}
[data-block-type=instagram-gallery] .content-grid {
  /*  Grid Layout
  ---------------------------------- */
  /*  Content Styling
  ---------------------------------- */
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=instagram-gallery] .content-grid li {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 46.4968152866%;
  }
  [data-block-type=instagram-gallery] .content-grid li:last-child {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid li:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid li:nth-child(2n+1) {
    clear: left;
  }
  [data-block-type=instagram-gallery] .content-grid li:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
[data-block-type=instagram-gallery] .content-grid[data-column-count=four] li {
  /* Desktop */
  /* Tablet */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 22.4615384615%;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:last-child {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:nth-child(4n) {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:nth-child(4n+1) {
    clear: left;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:nth-last-child(-n+4) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:last-child {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:nth-child(3n) {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:nth-child(3n+1) {
    clear: left;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=four] li:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
[data-block-type=instagram-gallery] .content-grid[data-column-count=three] li {
  /* Desktop */
  /* Tablet */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 31.0769230769%;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li:last-child {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li:nth-child(3n) {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li:last-child {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li:nth-child(3n) {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=three] li:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li {
    float: left;
    display: block;
    margin-right: 3.3846153846%;
    width: 48.3076923077%;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li:last-child {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li:last-child {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li:nth-child(2n) {
    margin-right: 0;
  }
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=instagram-gallery] .content-grid[data-column-count=two] li:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=instagram-gallery] .content-grid li {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=instagram-gallery] .content-grid li {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=instagram-gallery] .content-grid li {
    margin-bottom: 1.25rem;
  }
}
[data-block-type=instagram-gallery] .content-grid li img {
  width: 100%;
}

/* ----------------------------------
    Media Carousel Block
---------------------------------- */
.media_carousel {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  /*  Reveal Slider when Loaded
  ---------------------------------- */
  /*  Number of Slides
  ---------------------------------- */
  /*  Media Carousel Child Specifics
  ---------------------------------- */
  /*  Link & Text version
  ---------------------------------- */
  /*  Media Query Adjustments
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  .media_carousel {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_carousel {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_carousel {
    margin-bottom: 2.375rem;
  }
}
.media_carousel.flickity-enabled {
  opacity: 1;
}
.media_carousel[data-slide-group="4"] .gallery-cell {
  width: 25%;
}
.media_carousel[data-slide-group="3"] .gallery-cell {
  width: 33.33%;
}
.media_carousel[data-slide-group="2"] .gallery-cell {
  width: 50%;
}
.media_carousel[data-slide-group="1"] .gallery-cell {
  width: 100%;
}
.media_carousel .flickity-slider {
  position: relative;
}
.media_carousel .gallery-cell {
  padding: 0 0.9375rem;
  /*  Linked Image Hover State
  ---------------------------------- */
}
.media_carousel .gallery-cell figure {
  position: relative;
  display: block;
}
.media_carousel .gallery-cell figure img {
  width: 100%;
  /* fade in image when loaded */
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
.media_carousel .gallery-cell figure img.flickity-lazyloaded, .media_carousel .gallery-cell figure img.flickity-lazyerror {
  opacity: 1;
}
.media_carousel .gallery-cell p {
  text-align: center;
}
.media_carousel .gallery-cell p.headline {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
  text-transform: none;
}
.media_carousel .gallery-cell p.headline i,
.media_carousel .gallery-cell p.headline em {
  font-style: italic;
}
.media_carousel .gallery-cell p.headline i b,
.media_carousel .gallery-cell p.headline i strong,
.media_carousel .gallery-cell p.headline em b,
.media_carousel .gallery-cell p.headline em strong {
  font-style: italic;
}
.media_carousel .gallery-cell p.headline b,
.media_carousel .gallery-cell p.headline strong {
  font-weight: 400;
}
.media_carousel .gallery-cell p.headline b i,
.media_carousel .gallery-cell p.headline b em,
.media_carousel .gallery-cell p.headline strong i,
.media_carousel .gallery-cell p.headline strong em {
  font-weight: 400;
}
.media_carousel .gallery-cell p.headline a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.media_carousel .gallery-cell p.headline a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .media_carousel .gallery-cell p.headline a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (max-width: 34rem) {
  .media_carousel .gallery-cell p.headline {
    font-size: 0.9375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .media_carousel .gallery-cell p.headline {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_carousel .gallery-cell p.headline {
    margin-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_carousel .gallery-cell p.headline {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .media_carousel .gallery-cell p.headline {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_carousel .gallery-cell p.headline {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_carousel .gallery-cell p.headline {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 34.0625rem) {
  .media_carousel .gallery-cell p.headline {
    font-size: 1.375rem;
  }
}
.media_carousel .gallery-cell p.sub-headline {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  font-size: 1rem;
}
.media_carousel .gallery-cell p.sub-headline i,
.media_carousel .gallery-cell p.sub-headline em {
  font-style: italic;
}
.media_carousel .gallery-cell p.sub-headline i b,
.media_carousel .gallery-cell p.sub-headline i strong,
.media_carousel .gallery-cell p.sub-headline em b,
.media_carousel .gallery-cell p.sub-headline em strong {
  font-style: italic;
}
.media_carousel .gallery-cell p.sub-headline b,
.media_carousel .gallery-cell p.sub-headline strong {
  font-weight: 500;
}
.media_carousel .gallery-cell p.sub-headline b i,
.media_carousel .gallery-cell p.sub-headline b em,
.media_carousel .gallery-cell p.sub-headline strong i,
.media_carousel .gallery-cell p.sub-headline strong em {
  font-weight: 500;
}
.media_carousel .gallery-cell p.sub-headline a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.media_carousel .gallery-cell p.sub-headline a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .media_carousel .gallery-cell p.sub-headline a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .media_carousel .gallery-cell p.sub-headline {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .media_carousel .gallery-cell p.sub-headline {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .media_carousel .gallery-cell p.sub-headline {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_carousel .gallery-cell p.sub-headline {
    margin-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_carousel .gallery-cell p.sub-headline {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .media_carousel .gallery-cell p.sub-headline {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_carousel .gallery-cell p.sub-headline {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_carousel .gallery-cell p.sub-headline {
    margin-bottom: 0.625rem;
  }
}
.media_carousel .gallery-cell .headline + .sub-headline {
  margin-top: 0;
}
.media_carousel .gallery-cell a {
  position: relative;
  display: block;
  /*  Show Link if hover isn't an option ---- */
  /*  Show link on hover ---- */
}
.media_carousel .gallery-cell a .media-carousel-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 22, 35, 0);
  transition: background-color 0.2s ease-in-out;
}
.media_carousel .gallery-cell a figcaption {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.75em;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0;
  opacity: 0;
  color: #667acc;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease-in-out;
}
.media_carousel .gallery-cell a figcaption i,
.media_carousel .gallery-cell a figcaption em {
  font-style: italic;
}
.media_carousel .gallery-cell a figcaption i b,
.media_carousel .gallery-cell a figcaption i strong,
.media_carousel .gallery-cell a figcaption em b,
.media_carousel .gallery-cell a figcaption em strong {
  font-style: italic;
}
.media_carousel .gallery-cell a figcaption b,
.media_carousel .gallery-cell a figcaption strong {
  font-weight: 600;
}
.media_carousel .gallery-cell a figcaption b i,
.media_carousel .gallery-cell a figcaption b em,
.media_carousel .gallery-cell a figcaption strong i,
.media_carousel .gallery-cell a figcaption strong em {
  font-weight: 600;
}
.media_carousel .gallery-cell a figcaption a {
  transition: color 200ms;
  color: #2e408a;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(27, 37, 80, 0.4) 1.4em, rgba(27, 37, 80, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(27, 37, 80, 0.4) 1.4em, rgba(27, 37, 80, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.media_carousel .gallery-cell a figcaption a:focus {
  color: #1b2550;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1b2550 1.4em, #1b2550 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1b2550 1.4em, #1b2550 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .media_carousel .gallery-cell a figcaption a:hover {
    color: #1b2550;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1b2550 1.4em, #1b2550 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1b2550 1.4em, #1b2550 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .media_carousel .gallery-cell a figcaption {
    font-size: 1.0625rem;
  }
}
@media screen and (max-width: 34rem) {
  .media_carousel .gallery-cell a figcaption {
    font-size: 1rem;
    line-height: 1.4em;
  }
}
@media (hover: none) {
  .media_carousel .gallery-cell a .media-carousel-overlay {
    display: none;
  }
  .media_carousel .gallery-cell a figcaption {
    position: relative;
    margin: 0.5em 0;
    left: 0;
    opacity: 1;
    transform: translate(0, 0);
  }
}
@media (hover: hover) {
  .media_carousel .gallery-cell a:hover .media-carousel-overlay {
    background-color: rgba(0, 22, 35, 0.58);
  }
  .media_carousel .gallery-cell a:hover figcaption {
    opacity: 1;
  }
}
.media_carousel a.has_txt {
  /*  Show link on hover ---- */
}
.media_carousel a.has_txt img {
  transition: box-shadow 200ms ease-in-out;
}
.media_carousel a.has_txt p {
  transition: all 200ms ease-in-out;
}
@media (hover: hover) {
  .media_carousel a.has_txt:hover img {
    box-shadow: 0.25rem 0.25rem 0 #2e408a;
  }
  .media_carousel a.has_txt:hover .headline {
    color: #1b2550;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_carousel[data-slide-group="4"] .gallery-cell, .media_carousel[data-slide-group="3"] .gallery-cell, .media_carousel[data-slide-group="2"] .gallery-cell {
    width: 100%;
  }
  .media_carousel .gallery-cell figcaption {
    font-size: 1.125rem;
  }
}

/* ----------------------------------
    Media Gallery Block
---------------------------------- */
/*  Fade Gallery in Nicely
---------------------------------- */
[data-block-type=media_gallery] .bound {
  opacity: 0;
  transition: opacity 0.24s ease-in;
}
[data-block-type=media_gallery] .bound.visible {
  opacity: 1;
}

/*  Media Gallery
---------------------------------- */
.media_gallery {
  border-bottom: 1px solid #828e90;
  opacity: 0;
  transition: opacity 0.4s ease-in;
}
@media screen and (min-width: 68.8125rem) {
  .media_gallery {
    padding-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_gallery {
    padding-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_gallery {
    padding-bottom: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .media_gallery {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_gallery {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_gallery {
    margin-bottom: 0.625rem;
  }
}
.media_gallery::after {
  clear: both;
  content: "";
  display: table;
}
.media_gallery .flickity-slider {
  position: relative;
  z-index: 0;
  transform: none !important;
}
.media_gallery .flickity-viewport {
  transition: height 0.2s;
}
.media_gallery .button-row {
  display: none;
  bottom: -2.75rem;
}
.media_gallery .gallery-cell {
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.media_gallery .gallery-cell.is-selected {
  opacity: 1;
  z-index: 1;
}
.media_gallery .gallery-cell.is-selected figure {
  opacity: 1;
}
.media_gallery .gallery-cell:note(.is-selected) figure {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.media_gallery .gallery-cell figure {
  position: relative;
  width: 98%;
  margin: 0 auto;
  /*  Slide Content
  ---------------------------------- */
}
.media_gallery .gallery-cell figure:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 92.8571428571%;
}
.media_gallery .gallery-cell figure .gallery-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.media_gallery .gallery-cell figure .gallery-image-placement {
  position: relative;
  width: 100%;
  height: 100%;
}
.media_gallery .gallery-cell figure .gallery-image {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
.media_gallery .gallery-cell figure .gallery-image.flickity-lazyloaded, .media_gallery .gallery-cell figure .gallery-image.flickity-lazyerror {
  opacity: 1;
}
.media_gallery.flickity-enabled {
  opacity: 1;
}
@media screen and (max-width: 46.875rem) {
  .media_gallery {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 100%;
    margin-right: 0;
    border-bottom-color: transparent;
  }
  .media_gallery:last-child {
    margin-right: 0;
  }
  .media_gallery .button-row {
    position: relative;
    display: block;
    bottom: auto;
    white-space: no-wrap;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  .media_gallery .button-row {
    margin-top: 1.5rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_gallery .button-row {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  .media_gallery .button-row {
    margin-top: 1.25rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  .media_gallery .button-row {
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .media_gallery .button-row {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  .media_gallery .button-row {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_gallery .button-row .flickity-page-dots {
    position: static;
    width: 60%;
    margin: 0 auto;
    padding-top: 0.5rem;
    white-space: normal;
  }
}
@media screen and (max-width: 46.875rem) {
  .media_gallery .button-row .flickity-prev-next-button {
    vertical-align: top;
  }
  .media_gallery .button-row .flickity-prev-next-button:disabled {
    opacity: 0.5;
  }
}

/*  Image/Video Select Button Styles
---------------------------------- */
.gallery-btn-row {
  position: relative;
  font-size: 0;
  /*  Media Query Adjustments
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  .gallery-btn-row {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .gallery-btn-row {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .gallery-btn-row {
    margin-bottom: 1.25rem;
  }
}
.gallery-btn-row .button-group {
  text-align: center;
}
.gallery-btn-row .gallery-thumbnail {
  overflow: hidden;
  position: relative;
  width: 6.375rem;
  height: 5.125rem;
  margin: 3px;
  background: transparent;
  outline: none;
  vertical-align: bottom;
  border: 1px solid #c3c9ca;
  cursor: pointer;
}
.gallery-btn-row .gallery-thumbnail.is-selected {
  border-color: #2e408a;
}
.gallery-btn-row .gallery-thumbnail figure {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
}
.gallery-btn-row .gallery-thumbnail figure * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.gallery-btn-row .gallery-thumbnail figure .video:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent #2e408a;
}
.gallery-btn-row .gallery-thumbnail figure figcaption {
  position: absolute;
  display: block;
  height: 1px;
  overflow: hidden;
  font-size: 0;
}
@media screen and (max-width: 46.875rem) {
  .gallery-btn-row {
    display: none;
  }
}

/* ----------------------------------
    Mosaic Block
---------------------------------- */
[data-block-type=mosaic] {
  /*  Layout
  ---------------------------------- */
  /*  Filter Navigation
  ---------------------------------- */
  /*  Grid Layout & Settings
  ---------------------------------- */
  /*  Column Count & Spacing ---- */
  /*  Tile Content Itself
  ---------------------------------- */
}
[data-block-type=mosaic] .bound {
  width: calc(85% + 1.875rem);
  max-width: 76.875rem;
}
[data-block-type=mosaic] .grid-visibility {
  visibility: hidden;
}
[data-block-type=mosaic] .grid-visibility.displayed {
  visibility: visible;
}
[data-block-type=mosaic] .filters {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
[data-block-type=mosaic] .filters::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=mosaic] .filters {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=mosaic] .filters {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=mosaic] .filters {
    margin-bottom: 2.375rem;
  }
}
[data-block-type=mosaic] .filters label {
  padding: 0.625em 0.875em;
  margin: 0.25em;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  color: #828e90;
}
[data-block-type=mosaic] .filters button {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #828e90;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.1875rem;
  background-color: transparent;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
  font-weight: 300;
  font-size: 1rem;
  margin: 0.25em;
}
[data-block-type=mosaic] .filters button i,
[data-block-type=mosaic] .filters button em {
  font-style: italic;
}
[data-block-type=mosaic] .filters button i b,
[data-block-type=mosaic] .filters button i strong,
[data-block-type=mosaic] .filters button em b,
[data-block-type=mosaic] .filters button em strong {
  font-style: italic;
}
[data-block-type=mosaic] .filters button b,
[data-block-type=mosaic] .filters button strong {
  font-weight: 600;
}
[data-block-type=mosaic] .filters button b i,
[data-block-type=mosaic] .filters button b em,
[data-block-type=mosaic] .filters button strong i,
[data-block-type=mosaic] .filters button strong em {
  font-weight: 600;
}
[data-block-type=mosaic] .filters button a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=mosaic] .filters button a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=mosaic] .filters button a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
[data-block-type=mosaic] .filters button:focus {
  color: #001623;
  background-image: none;
  text-decoration: none;
  background-color: transparent;
  border-color: #ff579e;
}
@media (hover: hover) {
  [data-block-type=mosaic] .filters button:hover {
    color: #001623;
    background-image: none;
    text-decoration: none;
    background-color: transparent;
    border-color: #ff579e;
  }
}
[data-block-type=mosaic] .filters button.inactive, [data-block-type=mosaic] .filters button:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
[data-block-type=mosaic] .filters button.inactive:focus, [data-block-type=mosaic] .filters button:disabled:focus {
  color: #828e90;
  border-color: transparent;
  background-color: transparent;
}
@media (hover: hover) {
  [data-block-type=mosaic] .filters button.inactive:hover, [data-block-type=mosaic] .filters button:disabled:hover {
    color: #828e90;
    border-color: transparent;
    background-color: transparent;
  }
}
[data-block-type=mosaic] .filters button.active {
  color: #001623;
  border-color: #ff579e;
}
[data-block-type=mosaic] .grid-sizer,
[data-block-type=mosaic] .tile {
  width: 100%;
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=mosaic] .grid-sizer,
[data-block-type=mosaic] .tile {
    width: 33.33%;
  }
}
[data-block-type=mosaic] .gutter-sizer {
  width: 1.875rem;
}
[data-block-type=mosaic] .tile {
  /*  Tile Sizing - Grid ---- */
  /*  Tile Sizing - Heights ---- */
  height: 14.0845070423rem;
  /*  Content Box Sizing ---- */
}
[data-block-type=mosaic] .tile.full {
  width: 100%;
}
[data-block-type=mosaic] .tile.small {
  width: 50%;
}
@media screen and (min-width: 34.0625rem) {
  [data-block-type=mosaic] .tile.tall {
    width: 50%;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=mosaic] .tile.small, [data-block-type=mosaic] .tile.tall {
    width: 33.33%;
  }
  [data-block-type=mosaic] .tile.normal {
    width: 50%;
  }
  [data-block-type=mosaic] .tile.wide {
    width: 66.66%;
  }
}
[data-block-type=mosaic] .tile.full {
  height: 21.1267605634rem;
}
[data-block-type=mosaic] .tile.tall {
  height: 28.1690140845rem;
}
[data-block-type=mosaic] .tile.small {
  height: calc(42.5vw + 0.9375rem);
}
@media screen and (min-width: 34.0625rem) {
  [data-block-type=mosaic] .tile {
    height: 45vw;
  }
  [data-block-type=mosaic] .tile.full {
    height: 67.5vw;
  }
  [data-block-type=mosaic] .tile.tall {
    height: 90vw;
  }
  [data-block-type=mosaic] .tile.small {
    height: 45vw;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=mosaic] .tile {
    height: 29.236vw;
  }
  [data-block-type=mosaic] .tile.full {
    height: 43.854vw;
  }
  [data-block-type=mosaic] .tile.tall {
    height: 58.472vw;
  }
  [data-block-type=mosaic] .tile.small {
    height: 29.236vw;
  }
}
@media screen and (min-width: 75rem) {
  [data-block-type=mosaic] .tile {
    height: 24.375rem;
  }
  [data-block-type=mosaic] .tile.full {
    height: 36.5625rem;
  }
  [data-block-type=mosaic] .tile.tall {
    height: 48.75rem;
  }
  [data-block-type=mosaic] .tile.small {
    height: 24.375rem;
  }
}
[data-block-type=mosaic] .tile figure {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 0.3125rem;
}
[data-block-type=mosaic] .tile figcaption {
  position: absolute;
  z-index: 4;
  right: 0.9375rem;
  bottom: 0.9375rem;
  left: 0.9375rem;
  padding-bottom: 0;
  transition: padding-bottom 200ms ease-in-out;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=mosaic] .tile figcaption {
    right: 1.875rem;
    bottom: 1.875rem;
    left: 1.875rem;
  }
}
[data-block-type=mosaic] .tile figcaption.drop-right {
  text-align: right;
}
@media screen and (min-width: 34.0625rem) {
  [data-block-type=mosaic] .tile.full figcaption, [data-block-type=mosaic] .tile.wide figcaption, [data-block-type=mosaic] .tile.normal figcaption {
    right: auto;
    width: 60%;
  }
  [data-block-type=mosaic] .tile.full figcaption.drop-right, [data-block-type=mosaic] .tile.wide figcaption.drop-right, [data-block-type=mosaic] .tile.normal figcaption.drop-right {
    right: 0.9375rem;
    left: auto;
  }
}
@media screen and (min-width: 34.0625rem) and (min-width: 68.8125rem) {
  [data-block-type=mosaic] .tile.full figcaption.drop-right, [data-block-type=mosaic] .tile.wide figcaption.drop-right, [data-block-type=mosaic] .tile.normal figcaption.drop-right {
    right: 1.875rem;
  }
}
[data-block-type=mosaic] .tile {
  padding: 0.9375rem;
  border: none;
  outline: none;
  /*  Background
  ---------------------------------- */
  /*  Foreground
  ---------------------------------- */
  /*  Hover stuffs
  ---------------------------------- */
  /*  Caption
  ---------------------------------- */
  /*  Drop-Right Alignment ---- */
  /*  Hover State
  ---------------------------------- */
  /*  Hover & Active States ---- */
}
[data-block-type=mosaic] .tile .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  border-radius: 0.3125rem;
}
[data-block-type=mosaic] .tile .background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
[data-block-type=mosaic] .tile .background:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.24);
  transition: opacity ease-in-out 200ms;
}
[data-block-type=mosaic] .tile .foreground {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
}
[data-block-type=mosaic] .tile .foreground img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}
[data-block-type=mosaic] .tile .foreground.center {
  width: 100%;
}
[data-block-type=mosaic] .tile .foreground.center img {
  margin: 0 auto;
}
[data-block-type=mosaic] .tile .foreground.right {
  right: 0;
  left: auto;
}
[data-block-type=mosaic] .tile .foreground.overflow-top {
  top: -0.9375rem;
}
[data-block-type=mosaic] .tile .foreground.overflow-right {
  right: -0.9375rem;
}
[data-block-type=mosaic] .tile .foreground.overflow-bottom {
  bottom: -0.9375rem;
}
[data-block-type=mosaic] .tile .foreground.overflow-left {
  left: -0.9375rem;
}
[data-block-type=mosaic] .tile.tall .foreground {
  bottom: 15%;
}
[data-block-type=mosaic] .tile:after {
  content: "";
  position: absolute;
  top: 1.875rem;
  left: 1.875rem;
  bottom: 1.875rem;
  right: 1.875rem;
  z-index: 0;
  border-radius: 20%;
  opacity: 0;
  box-shadow: 0 1.25rem 2.625rem 0 rgba(0, 0, 0, 0.8);
  background: white;
  transform: scale(0.75);
  transition: all ease-in-out 200ms;
}
[data-block-type=mosaic] .tile .arrow {
  position: absolute;
  z-index: 5;
  width: 2.625rem;
  height: 2.625rem;
  bottom: 0.9375rem;
  left: 0.9375rem;
  opacity: 0;
  transition: all 200ms ease-in-out;
}
[data-block-type=mosaic] .tile .arrow .arrow {
  fill: white;
  transform-origin: center;
  transition: all 200ms ease-in-out;
  box-shadow: 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.24);
}
[data-block-type=mosaic] .tile .arrow .circle {
  fill: white;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.24);
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=mosaic] .tile .arrow {
    bottom: 1.875rem;
    left: 1.875rem;
  }
}
[data-block-type=mosaic] .tile figcaption.drop-right + .arrow {
  right: 0.9375rem;
  left: auto;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=mosaic] .tile figcaption.drop-right + .arrow {
    right: 1.875rem;
    left: auto;
  }
}
[data-block-type=mosaic] .tile figcaption {
  font-family: "Poppins", sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  color: white;
  line-height: 1.15em;
  text-shadow: 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.24);
}
[data-block-type=mosaic] .tile figcaption i,
[data-block-type=mosaic] .tile figcaption em {
  font-style: italic;
}
[data-block-type=mosaic] .tile figcaption i b,
[data-block-type=mosaic] .tile figcaption i strong,
[data-block-type=mosaic] .tile figcaption em b,
[data-block-type=mosaic] .tile figcaption em strong {
  font-style: italic;
}
[data-block-type=mosaic] .tile figcaption b,
[data-block-type=mosaic] .tile figcaption strong {
  font-weight: 500;
}
[data-block-type=mosaic] .tile figcaption b i,
[data-block-type=mosaic] .tile figcaption b em,
[data-block-type=mosaic] .tile figcaption strong i,
[data-block-type=mosaic] .tile figcaption strong em {
  font-weight: 500;
}
[data-block-type=mosaic] .tile figcaption a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=mosaic] .tile figcaption a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=mosaic] .tile figcaption a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (max-width: 68.75rem) {
  [data-block-type=mosaic] .tile figcaption {
    font-size: 1.625rem;
  }
}
@media screen and (max-width: 61.9375rem) {
  [data-block-type=mosaic] .tile figcaption {
    font-size: 1.5rem;
    line-height: 1.255em;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=mosaic] .tile figcaption {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=mosaic] .tile.small figcaption {
    font-size: 1.125rem;
  }
}
[data-block-type=mosaic] .tile:focus figcaption {
  padding-bottom: 3.125rem;
}
[data-block-type=mosaic] .tile:focus .arrow {
  opacity: 1;
}
[data-block-type=mosaic] .tile:focus .arrow .arrow {
  transform: translateX(-20%) scale(1.24);
}
[data-block-type=mosaic] .tile:focus .arrow .circle {
  opacity: 0.6;
}
[data-block-type=mosaic] .tile:focus:after {
  opacity: 1;
  transform: scale(1);
}
[data-block-type=mosaic] .tile:focus .background:after {
  opacity: 1;
}
@media (hover: hover) {
  [data-block-type=mosaic] .tile:hover figcaption {
    padding-bottom: 3.125rem;
  }
  [data-block-type=mosaic] .tile:hover .arrow {
    opacity: 1;
  }
  [data-block-type=mosaic] .tile:hover .arrow .arrow {
    transform: translateX(-20%) scale(1.24);
  }
  [data-block-type=mosaic] .tile:hover .arrow .circle {
    opacity: 0.6;
  }
  [data-block-type=mosaic] .tile:hover:after {
    opacity: 1;
    transform: scale(1);
  }
  [data-block-type=mosaic] .tile:hover .background:after {
    opacity: 1;
  }
}

/*  Overall
---------------------------------- */
@media screen and (min-width: 68.8125rem) {
  .multi-pane-content.space-after {
    margin-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .multi-pane-content.space-after {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .multi-pane-content.space-after {
    margin-bottom: 4.6875rem;
  }
}
.multi-pane-content .extraneous-tabs-controller {
  box-shadow: inset 0 -1px 0 #2e408a;
  position: relative;
  z-index: 2;
}
.multi-pane-content .extraneous-tabs-controller .inner {
  width: 100%;
  display: table;
}
.multi-pane-content .extraneous-tabs-controller .tabs-track {
  width: 100%;
  display: table-row;
}
.multi-pane-content .extraneous-tabs-controller li {
  width: 20%;
  display: table-cell;
  padding: 0.75em 0 1.5em;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: all 180ms;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.multi-pane-content .extraneous-tabs-controller li:first-of-type {
  border-left: 0;
}
.multi-pane-content .extraneous-tabs-controller li.active {
  box-shadow: inset 0 -1px 0 #212e64, inset 0 -5px 0 #2e408a;
}
.multi-pane-content .extraneous-tabs-controller li.active:after {
  content: "";
  display: block;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  width: 23px;
  height: 9px;
  background: url("/images/ui-glyphs/tab-selected-triangle-blue.png") no-repeat left top;
  background-size: 23px 9px;
}
.multi-pane-content .extraneous-tabs-controller .icon {
  position: relative;
  max-width: 60px;
  margin: 0 auto 1em;
}
.multi-pane-content .extraneous-tabs-controller .icon:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.multi-pane-content .extraneous-tabs-controller .icon > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.multi-pane-content .extraneous-tabs-controller img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
.multi-pane-content .extraneous-tabs-controller .label {
  display: block;
  text-align: center;
  padding: 0 1.5em;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.75em;
}
.multi-pane-content .extraneous-tabs-controller .label i,
.multi-pane-content .extraneous-tabs-controller .label em {
  font-style: italic;
}
.multi-pane-content .extraneous-tabs-controller .label i b,
.multi-pane-content .extraneous-tabs-controller .label i strong,
.multi-pane-content .extraneous-tabs-controller .label em b,
.multi-pane-content .extraneous-tabs-controller .label em strong {
  font-style: italic;
}
.multi-pane-content .extraneous-tabs-controller .label b,
.multi-pane-content .extraneous-tabs-controller .label strong {
  font-weight: 600;
}
.multi-pane-content .extraneous-tabs-controller .label b i,
.multi-pane-content .extraneous-tabs-controller .label b em,
.multi-pane-content .extraneous-tabs-controller .label strong i,
.multi-pane-content .extraneous-tabs-controller .label strong em {
  font-weight: 600;
}
.multi-pane-content .extraneous-tabs-controller .label a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.multi-pane-content .extraneous-tabs-controller .label a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .multi-pane-content .extraneous-tabs-controller .label a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.multi-pane-content .extraneous-tabs-controller .label span {
  transition: all 180ms;
}
.multi-pane-content .extraneous-tabs-controller li:hover {
  box-shadow: inset 0 -1px 0 #212e64, inset 0 -10px 0 #2e408a;
}
@media screen and (max-width: 61.9375rem) {
  .multi-pane-content .extraneous-tabs-controller {
    display: none;
  }
}
.multi-pane-content .extraneous-tabs-controller li[data-textual=true] {
  padding: 0.75em 0;
  vertical-align: middle;
}
.multi-pane-content .extraneous-tabs-controller li[data-textual=true].inactive:hover {
  box-shadow: inset 0 -1px 0 #212e64, inset 0 -5px 0 #2e408a;
}
.multi-pane-content .pane {
  display: none;
  width: 100%;
  position: relative;
  z-index: 1;
}
.multi-pane-content .pane.visible {
  display: block;
}
.multi-pane-content .pane-blade {
  width: 100%;
  box-shadow: 0 -1px 0 #ff579e;
  border-bottom: 1px solid #ff579e;
  cursor: pointer;
  transition: all 200ms;
}
.multi-pane-content .pane-blade .inner {
  position: relative;
  box-sizing: border-box;
  padding: 0.875rem 3.4375rem 0.875rem 0;
}
.multi-pane-content .pane-blade .label-icon-pairing {
  display: table-row;
}
.multi-pane-content .pane-blade .icon-container,
.multi-pane-content .pane-blade .label {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.multi-pane-content .pane-blade .label.sans-image {
  display: inline-block;
  padding: 7px 0;
}
.multi-pane-content .pane-blade .icon {
  width: 48px;
  height: 48px;
}
.multi-pane-content .pane-blade img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
.multi-pane-content .pane-blade .label {
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 1.625rem;
  font-weight: 400;
  color: #001623;
  line-height: 1.75em;
}
.multi-pane-content .pane-blade .label i,
.multi-pane-content .pane-blade .label em {
  font-style: italic;
}
.multi-pane-content .pane-blade .label i b,
.multi-pane-content .pane-blade .label i strong,
.multi-pane-content .pane-blade .label em b,
.multi-pane-content .pane-blade .label em strong {
  font-style: italic;
}
.multi-pane-content .pane-blade .label b,
.multi-pane-content .pane-blade .label strong {
  font-weight: 600;
}
.multi-pane-content .pane-blade .label b i,
.multi-pane-content .pane-blade .label b em,
.multi-pane-content .pane-blade .label strong i,
.multi-pane-content .pane-blade .label strong em {
  font-weight: 600;
}
.multi-pane-content .pane-blade .label a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.multi-pane-content .pane-blade .label a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .multi-pane-content .pane-blade .label a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.multi-pane-content .pane-blade .label span {
  transition: all 200ms;
}
.multi-pane-content .pane-blade:hover {
  background: rgba(46, 64, 138, 0.15);
}
.multi-pane-content .pane-blade .indicator {
  display: block;
  width: 22px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  transition: all 300ms;
}
.multi-pane-content .pane-blade .indicator svg {
  display: block;
}
.multi-pane-content .pane-blade .indicator g {
  fill: #2e408a;
}
.multi-pane-content .pane-blade:hover .label span {
  color: #1b2550;
}
.multi-pane-content .pane-blade:hover .indicator {
  opacity: 1;
}
.multi-pane-content .pane-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 300ms;
}
.multi-pane-content .pane[data-status=open] .pane-blade {
  padding-bottom: 4px;
  border-bottom-color: #212e64;
  box-shadow: inset 0 -4px 0 #2e408a, 0 -1px 0 #ff579e;
}
.multi-pane-content .pane[data-status=open] .pane-blade .indicator {
  opacity: 1;
  transform: translateY(-50%) rotate(180deg);
}
.multi-pane-content .pane[data-status=open] .pane-content {
  max-height: 99999px;
  opacity: 1;
}
@media screen and (min-width: 62rem) {
  .multi-pane-content[data-display-tabs-by-default=true] .pane-blade {
    display: none;
  }
  .multi-pane-content[data-display-tabs-by-default=true] .pane-content {
    transition: none;
  }
  .multi-pane-content[data-display-tabs-by-default=true] .pane[data-exempt=true] .pane-blade {
    padding-bottom: 4px;
    border-bottom-color: #212e64;
    box-shadow: inset 0 -4px 0 #2e408a, 0 -1px 0 #ff579e;
  }
  .multi-pane-content[data-display-tabs-by-default=true] .pane[data-exempt=true] .pane-blade .indicator {
    opacity: 1;
    transform: translateY(-50%) rotate(180deg);
  }
  .multi-pane-content[data-display-tabs-by-default=true] .pane[data-exempt=true] .pane-content {
    max-height: 99999px;
    opacity: 1;
  }
}

/* 	Specifically when I'm in an FAQ context...
---------------------------------- */
[data-block-type=faqs] .multi-pane-content .pane-content {
  background: #efefef;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.08);
}

/* ----------------------------------
    Content Carousel Block
---------------------------------- */
/*  Content Block Styles
---------------------------------- */
[data-block-type=quote_carousel] {
  /*  Dark & Light Theme Styles
  ---------------------------------- */
  /*  Content Block Styles when inside a Flickity
  ---------------------------------- */
  /*  Content Block Styles Shared: in a Flickety or Not
  ---------------------------------- */
}
[data-block-type=quote_carousel] .quote-carousel {
  position: relative;
  text-align: center;
  /*  Quote Component Styles
  ---------------------------------- */
  /*  Citation Component Styles
  ---------------------------------- */
  /*  Media Query Adjustments
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-right: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-right: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-right: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-bottom: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-left: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-left: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-left: 5.25rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel img {
  margin-right: 0;
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;
  /* fade in image when loaded */
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
[data-block-type=quote_carousel] .quote-carousel img.bypass-flickity {
  opacity: 1;
}
[data-block-type=quote_carousel] .quote-carousel img.flickity-lazyloaded, [data-block-type=quote_carousel] .quote-carousel img.flickity-lazyerror {
  opacity: 1;
}
[data-block-type=quote_carousel] .quote-carousel img[data-image-size=icon] {
  max-width: 15%;
}
[data-block-type=quote_carousel] .quote-carousel img[data-image-size=small] {
  max-width: 30%;
}
[data-block-type=quote_carousel] .quote-carousel img[data-image-size=medium] {
  max-width: 65%;
}
[data-block-type=quote_carousel] .quote-carousel img[data-image-size=large] {
  max-width: 100%;
}
[data-block-type=quote_carousel] .quote-carousel cite {
  position: relative;
  display: block;
  margin: 0 auto;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  font-size: 3.4375rem;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote em {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote i b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote i strong,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote em b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote em strong {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote strong {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote b i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote b em,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote strong i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote strong em {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote {
    font-size: 1.5rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote b, [data-block-type=quote_carousel] .quote-carousel[data-font-size=large] blockquote strong {
  color: #ff579e;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  font-size: 1.25rem;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote em {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote i b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote i strong,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote em b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote em strong {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote strong {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote b i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote b em,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote strong i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote strong em {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote {
    font-size: 1.5rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote b, [data-block-type=quote_carousel] .quote-carousel[data-font-size=medium] blockquote strong {
  color: #ff579e;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote em {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote i b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote i strong,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote em b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote em strong {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote strong {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote b i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote b em,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote strong i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote strong em {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote {
    font-size: 1.5rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote b, [data-block-type=quote_carousel] .quote-carousel[data-font-size=small] blockquote strong {
  color: #ff579e;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  font-size: 1rem;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote em {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote i b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote i strong,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote em b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote em strong {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote b,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote strong {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote b i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote b em,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote strong i,
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote strong em {
  font-weight: 500;
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote {
    font-size: 1.5rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote b, [data-block-type=quote_carousel] .quote-carousel[data-font-size=tiny] blockquote strong {
  color: #ff579e;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  /*  Default Text Styles ---- */
  /*  Layout with a 'Main Image' ---- */
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
  position: relative;
  /* Quote Marks */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    margin-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    margin-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    margin-bottom: 0.875rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote:before, [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote:after {
  content: "";
  position: absolute;
  display: block;
  height: 3.125rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote:before {
  top: 0;
  left: 0;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote:after {
  right: 0;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote[data-quote-layout="1"] {
  display: block;
  position: relative;
  z-index: 0;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote[data-quote-layout="1"] .quote-carousel-main {
  display: block;
  margin: 0 auto;
  z-index: 0;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote[data-quote-layout="1"] blockquote {
  display: block;
  z-index: -1;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation {
  text-align: center;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
  font-size: 1rem;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person i,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person em {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person i b,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person i strong,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person em b,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person em strong {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person b,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person strong {
  font-weight: 400;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person b i,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person b em,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person strong i,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person strong em {
  font-weight: 400;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    font-size: 0.9375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-person {
    margin-bottom: 0.625rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company i,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company em {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company i b,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company i strong,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company em b,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company em strong {
  font-style: italic;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company b,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company strong {
  font-weight: 600;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company b i,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company b em,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company strong i,
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company strong em {
  font-weight: 600;
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-company {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-image {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-image {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-image {
    margin-top: 1.25rem;
  }
}
[data-block-type=quote_carousel] .quote-carousel .quote-carousel-citation .cite-image img {
  display: block;
  margin: 0 auto;
  max-width: 120px;
}
[data-block-type=quote_carousel] .quote-carousel .cta-atom {
  position: relative;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel .cta-atom {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .cta-atom {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .cta-atom {
    margin-top: 1.25rem;
  }
}
@media screen and (max-width: 1600px) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote {
    width: 80%;
  }
}
@media screen and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote {
    width: 100%;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-top: 2.625rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-top: 2.375rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-right: 1.5rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-right: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-right: 1.25rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-bottom: 2.625rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-bottom: 2.375rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-left: 1.5rem;
  }
}
@media screen and (max-width: 46.875rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-left: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel {
    padding-left: 1.25rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .quote-carousel .quote-carousel-quote blockquote {
    padding: 5rem 0;
  }
}
[data-block-type=quote_carousel][data-night-mode=false] {
  background-color: white;
}
[data-block-type=quote_carousel][data-night-mode=false] .add-opacity {
  background-color: white;
}
[data-block-type=quote_carousel][data-night-mode=false] .quote-carousel .quote-carousel-quote blockquote {
  color: #001623;
}
[data-block-type=quote_carousel][data-night-mode=false] .quote-carousel-citation {
  color: #001623;
}
[data-block-type=quote_carousel][data-night-mode=false] .cta-atom .delimiter {
  color: #001623;
}
[data-block-type=quote_carousel][data-night-mode=true] {
  background-color: #290e19;
}
[data-block-type=quote_carousel][data-night-mode=true] .add-opacity {
  background-color: #001623;
}
[data-block-type=quote_carousel][data-night-mode=true] .quote-carousel-quote blockquote {
  color: white;
}
[data-block-type=quote_carousel][data-night-mode=true] .quote-carousel-citation {
  color: white;
}
[data-block-type=quote_carousel][data-night-mode=true] .quote-carousel-citation .cite-person {
  color: white;
}
[data-block-type=quote_carousel][data-night-mode=true] .quote-carousel-citation .cite-company {
  color: white;
}
[data-block-type=quote_carousel][data-night-mode=true] .cta-atom .delimiter {
  color: white;
}
[data-block-type=quote_carousel] .content-carousel {
  opacity: 0;
  /*  Reveal Slider when Loaded
  ---------------------------------- */
  /*  Media Query Adjustments
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .content-carousel {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .content-carousel {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .content-carousel {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .content-carousel {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .content-carousel {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .content-carousel {
    padding-bottom: 4.6875rem;
  }
}
[data-block-type=quote_carousel] .content-carousel.flickity-enabled {
  opacity: 1;
}
[data-block-type=quote_carousel] .content-carousel .quote-carousel {
  margin: 0;
  width: 60%;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 200ms ease-in-out;
  opacity: 0.4;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-right: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-right: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-right: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-bottom: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-left: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-left: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-left: 2.375rem;
  }
}
[data-block-type=quote_carousel] .content-carousel .quote-carousel .quote-carousel-quote {
  width: 100%;
}
[data-block-type=quote_carousel] .content-carousel .quote-carousel.is-selected {
  opacity: 1;
}
@media screen and (max-width: 68.75rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel-quote {
    width: 66%;
  }
}
@media screen and (max-width: 61.9375rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    width: 100%;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=quote_carousel] .content-carousel .quote-carousel {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
[data-block-type=quote_carousel] .content-carousel,
[data-block-type=quote_carousel] .quote-carousel {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
[data-block-type=quote_carousel] .content-carousel .add-opacity,
[data-block-type=quote_carousel] .quote-carousel .add-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* ----------------------------------
    Related Case Studies
---------------------------------- */
[data-block-type=related_casestudies] {
  /*  Hover States
  ---------------------------------- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=related_casestudies] {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=related_casestudies] {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=related_casestudies] {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=related_casestudies] {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=related_casestudies] {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=related_casestudies] {
    padding-bottom: 5.25rem;
  }
}
[data-block-type=related_casestudies] h3 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  font-weight: 400;
}
[data-block-type=related_casestudies] h3 i,
[data-block-type=related_casestudies] h3 em {
  font-style: italic;
}
[data-block-type=related_casestudies] h3 i b,
[data-block-type=related_casestudies] h3 i strong,
[data-block-type=related_casestudies] h3 em b,
[data-block-type=related_casestudies] h3 em strong {
  font-style: italic;
}
[data-block-type=related_casestudies] h3 b,
[data-block-type=related_casestudies] h3 strong {
  font-weight: 600;
}
[data-block-type=related_casestudies] h3 b i,
[data-block-type=related_casestudies] h3 b em,
[data-block-type=related_casestudies] h3 strong i,
[data-block-type=related_casestudies] h3 strong em {
  font-weight: 600;
}
[data-block-type=related_casestudies] h3 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=related_casestudies] h3 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=related_casestudies] h3 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=related_casestudies] h3 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=related_casestudies] h3 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=related_casestudies] h3 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=related_casestudies] h3 {
    font-size: 1.9166666667rem;
    line-height: 1.255em;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=related_casestudies] h3 {
    font-size: 1.75rem;
  }
}
[data-block-type=related_casestudies] h3 b, [data-block-type=related_casestudies] h3 strong {
  color: #ff579e;
}
[data-block-type=related_casestudies] ul {
  width: 100%;
}
@media screen and (min-width: 34.0625rem) {
  [data-block-type=related_casestudies] ul {
    display: block;
  }
  [data-block-type=related_casestudies] ul::after {
    clear: both;
    content: "";
    display: table;
  }
}
[data-block-type=related_casestudies] ul li {
  width: 100%;
  /* Tablet */
  /* Tablet */
  /* Desktop */
}
@media screen and (min-width: 34.0625rem) {
  [data-block-type=related_casestudies] ul li {
    float: left;
    display: block;
    margin-right: 7.0063694268%;
    width: 46.4968152866%;
  }
  [data-block-type=related_casestudies] ul li:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=related_casestudies] ul li {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 47.7178423237%;
  }
  [data-block-type=related_casestudies] ul li:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=related_casestudies] ul li {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 47.9553903346%;
  }
  [data-block-type=related_casestudies] ul li:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=related_casestudies] ul li:first-child {
    margin-bottom: 1.5rem;
  }
}
[data-block-type=related_casestudies] ul a {
  position: relative;
  display: block;
  padding-top: 66.01941748%;
}
[data-block-type=related_casestudies] ul a:after {
  content: "";
  position: absolute;
  top: 1.875rem;
  left: 0.625rem;
  bottom: 0.625rem;
  right: 0.625rem;
  z-index: 0;
  border-radius: 20%;
  opacity: 0;
  box-shadow: 0 1.25rem 2.625rem 0 rgba(0, 0, 0, 0.6);
  background: white;
  transform: scale(0.75);
  transition: all ease-in-out 200ms;
}
[data-block-type=related_casestudies] h5 {
  position: absolute;
  z-index: 4;
  bottom: 1.25rem;
  left: 1.25rem;
  right: 1.25rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: white;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  font-weight: 400;
  text-shadow: 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.24);
}
[data-block-type=related_casestudies] h5 i,
[data-block-type=related_casestudies] h5 em {
  font-style: italic;
}
[data-block-type=related_casestudies] h5 i b,
[data-block-type=related_casestudies] h5 i strong,
[data-block-type=related_casestudies] h5 em b,
[data-block-type=related_casestudies] h5 em strong {
  font-style: italic;
}
[data-block-type=related_casestudies] h5 b,
[data-block-type=related_casestudies] h5 strong {
  font-weight: 500;
}
[data-block-type=related_casestudies] h5 b i,
[data-block-type=related_casestudies] h5 b em,
[data-block-type=related_casestudies] h5 strong i,
[data-block-type=related_casestudies] h5 strong em {
  font-weight: 500;
}
[data-block-type=related_casestudies] h5 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=related_casestudies] h5 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=related_casestudies] h5 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=related_casestudies] h5 {
    font-size: 1.5rem;
  }
}
[data-block-type=related_casestudies] h5 b, [data-block-type=related_casestudies] h5 strong {
  color: #ff579e;
}
@media screen and (max-width: 68.75rem) {
  [data-block-type=related_casestudies] h5 {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 61.9375rem) {
  [data-block-type=related_casestudies] h5 {
    font-size: 1.25rem;
  }
}
[data-block-type=related_casestudies] h5 .arrow {
  display: inline-block;
  vertical-align: middle;
  width: 1.42em;
  margin-left: 0.24em;
  margin-top: -0.2em;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
[data-block-type=related_casestudies] h5 .arrow .arrow {
  fill: white;
  transform-origin: center;
  transition: all 200ms ease-in-out;
  box-shadow: 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.24);
}
[data-block-type=related_casestudies] h5 .arrow .circle {
  fill: white;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.24);
}
[data-block-type=related_casestudies] .foreground {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
[data-block-type=related_casestudies] .foreground img {
  width: 100%;
}
[data-block-type=related_casestudies] .background {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 94.11764706%;
  border-radius: 0.3125rem;
  overflow: hidden;
}
[data-block-type=related_casestudies] .background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
[data-block-type=related_casestudies] .background .circle {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 160%;
  transform: translateX(-65%);
  padding-top: 80%;
  background: rgba(255, 255, 255, 0.14);
  bottom: 10%;
  border-bottom-left-radius: 100% 200%;
  border-bottom-right-radius: 100% 200%;
}
[data-block-type=related_casestudies] .background:after {
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.24);
  transition: opacity ease-in-out 200ms;
}
[data-block-type=related_casestudies] li:last-child .background .circle {
  transform: translateX(-35%);
}
[data-block-type=related_casestudies] a {
  /*  Hover & Active States ---- */
}
[data-block-type=related_casestudies] a:focus:after {
  opacity: 1;
  transform: scale(1);
}
[data-block-type=related_casestudies] a:focus .arrow {
  opacity: 1;
}
[data-block-type=related_casestudies] a:focus .arrow .arrow {
  transform: translateX(-20%) scale(1.24);
}
[data-block-type=related_casestudies] a:focus .arrow .circle {
  opacity: 0.6;
}
[data-block-type=related_casestudies] a:focus .background:after {
  opacity: 1;
}
@media (hover: hover) {
  [data-block-type=related_casestudies] a:hover:after {
    opacity: 1;
    transform: scale(1);
  }
  [data-block-type=related_casestudies] a:hover .arrow {
    opacity: 1;
  }
  [data-block-type=related_casestudies] a:hover .arrow .arrow {
    transform: translateX(-20%) scale(1.24);
  }
  [data-block-type=related_casestudies] a:hover .arrow .circle {
    opacity: 0.6;
  }
  [data-block-type=related_casestudies] a:hover .background:after {
    opacity: 1;
  }
}

/* ----------------------------------
    Screen Shots Grid

    Note: the layout for this Block
    is defined withing Content Grid.
---------------------------------- */
[data-block-type=screen_shots_grid] .graphic.iphoneX {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 14.0522875817%/6.847133758%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.iphoneX:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 205.2287581699%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.iphoneX .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 90.522875817%;
  height: 95.3821656051%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 2.3089171975%;
}
[data-block-type=screen_shots_grid] .graphic.iphoneX .screen img,
[data-block-type=screen_shots_grid] .graphic.iphoneX .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.iphoneX:before {
  background-image: url("/assets/images/ui/device-iphone_x.svg");
}
[data-block-type=screen_shots_grid] .graphic.iphoneOld {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 14.4295302013%/6.9579288026%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.iphoneOld:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 207.3825503356%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.iphoneOld .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 90.2684563758%;
  height: 76.0517799353%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 11.9741100324%;
}
[data-block-type=screen_shots_grid] .graphic.iphoneOld .screen img,
[data-block-type=screen_shots_grid] .graphic.iphoneOld .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.iphoneOld:before {
  background-image: url("/assets/images/ui/device-iphone_5.svg");
}
[data-block-type=screen_shots_grid] .graphic.androidPixel {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 12.9032258065%/6.4516129032%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.androidPixel:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 200%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.androidPixel .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 93.2258064516%;
  height: 92.9032258065%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 5.1612903226%;
}
[data-block-type=screen_shots_grid] .graphic.androidPixel .screen img,
[data-block-type=screen_shots_grid] .graphic.androidPixel .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.androidPixel:before {
  background-image: url("/assets/images/ui/device-android_pixel.svg");
}
[data-block-type=screen_shots_grid] .graphic.androidOld {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 12.7388535032%/6.0422960725%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.androidOld:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 210.8280254777%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.androidOld .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 89.8089171975%;
  height: 84.7432024169%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 7.0996978852%;
}
[data-block-type=screen_shots_grid] .graphic.androidOld .screen img,
[data-block-type=screen_shots_grid] .graphic.androidOld .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.androidOld:before {
  background-image: url("/assets/images/ui/device-android_old.svg");
}
[data-block-type=screen_shots_grid] .graphic.ipadPortrait {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 5.6818181818%/3.9404553415%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.ipadPortrait:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 144.1919191919%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.ipadPortrait .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 92.9292929293%;
  height: 85.9019264448%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 7.0490367776%;
}
[data-block-type=screen_shots_grid] .graphic.ipadPortrait .screen img,
[data-block-type=screen_shots_grid] .graphic.ipadPortrait .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.ipadPortrait:before {
  background-image: url("/assets/images/ui/device-ipad-portrait.svg");
}
[data-block-type=screen_shots_grid] .graphic.ipadLandscape {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 3.9404553415%/5.6818181818%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.ipadLandscape:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 69.3520140105%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.ipadLandscape .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 85.9019264448%;
  height: 92.9292929293%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 3.5353535354%;
}
[data-block-type=screen_shots_grid] .graphic.ipadLandscape .screen img,
[data-block-type=screen_shots_grid] .graphic.ipadLandscape .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.ipadLandscape:before {
  background-image: url("/assets/images/ui/device-ipad-landscape.svg");
}
[data-block-type=screen_shots_grid] .graphic.ipadProPortrait {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 5.2503052503%/3.7587412587%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.ipadProPortrait:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 139.6825396825%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.ipadProPortrait .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 92.673992674%;
  height: 94.8426573427%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 2.5786713287%;
}
[data-block-type=screen_shots_grid] .graphic.ipadProPortrait .screen img,
[data-block-type=screen_shots_grid] .graphic.ipadProPortrait .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.ipadProPortrait:before {
  background-image: url("/assets/images/ui/device-ipad-pro-portrait.svg");
}
[data-block-type=screen_shots_grid] .graphic.ipadProLandscape {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 3.7587412587%/5.2503052503%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.ipadProLandscape:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 71.5909090909%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.ipadProLandscape .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 94.8426573427%;
  height: 92.673992674%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 3.663003663%;
}
[data-block-type=screen_shots_grid] .graphic.ipadProLandscape .screen img,
[data-block-type=screen_shots_grid] .graphic.ipadProLandscape .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.ipadProLandscape:before {
  background-image: url("/assets/images/ui/device-ipad-pro-landscape.svg");
}
[data-block-type=screen_shots_grid] .graphic.appleWatchSeries3 {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 0%/0%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
  background: none;
  box-shadow: none;
}
[data-block-type=screen_shots_grid] .graphic.appleWatchSeries3:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 158.5714285714%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=screen_shots_grid] .graphic.appleWatchSeries3 .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 50.1587301587%;
  height: 39.4394394394%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 30.2802802803%;
}
[data-block-type=screen_shots_grid] .graphic.appleWatchSeries3 .screen img,
[data-block-type=screen_shots_grid] .graphic.appleWatchSeries3 .screen video {
  width: 100%;
}
[data-block-type=screen_shots_grid] .graphic.appleWatchSeries3:before {
  background-image: url("/assets/images/ui/device-apple_watch-series_3.svg");
}
[data-block-type=screen_shots_grid] .graphic.appleWatchSeries3 .screen {
  background: black;
}
[data-block-type=screen_shots_grid] .graphic.browser {
  position: relative;
  float: none;
  z-index: 1;
  border-radius: 0.3125rem;
  background: white;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=screen_shots_grid] .graphic.browser:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 4.3715846995%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url("/assets/images/ui/device-browser.svg");
}
[data-block-type=screen_shots_grid] .graphic.browser .screen {
  overflow: hidden;
  border: 0.0625rem solid rgba(193, 193, 193, 0.2);
  border-radius: 0 0 0.1875rem 0.1875rem;
  background: white;
}
[data-block-type=screen_shots_grid] h4 {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  text-align: center;
}
[data-block-type=screen_shots_grid] h4 i,
[data-block-type=screen_shots_grid] h4 em {
  font-style: italic;
}
[data-block-type=screen_shots_grid] h4 i b,
[data-block-type=screen_shots_grid] h4 i strong,
[data-block-type=screen_shots_grid] h4 em b,
[data-block-type=screen_shots_grid] h4 em strong {
  font-style: italic;
}
[data-block-type=screen_shots_grid] h4 b,
[data-block-type=screen_shots_grid] h4 strong {
  font-weight: 500;
}
[data-block-type=screen_shots_grid] h4 b i,
[data-block-type=screen_shots_grid] h4 b em,
[data-block-type=screen_shots_grid] h4 strong i,
[data-block-type=screen_shots_grid] h4 strong em {
  font-weight: 500;
}
[data-block-type=screen_shots_grid] h4 a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=screen_shots_grid] h4 a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=screen_shots_grid] h4 a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=screen_shots_grid] h4 {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=screen_shots_grid] h4 {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=screen_shots_grid] h4 {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=screen_shots_grid] h4 {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=screen_shots_grid] h4 {
    padding-top: 2.375rem;
  }
}

/* ----------------------------------
    Spacer

    Yes, it's basically a responsive
    spacer gif, but whatcha gonna
    do? It's a CMS after all :)
---------------------------------- */
.sp {
  display: block;
}
@media screen and (min-width: 68.8125rem) {
  .sp[data-height=spacing-xxl] {
    padding-top: 12.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sp[data-height=spacing-xxl] {
    padding-top: 9.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sp[data-height=spacing-xxl] {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sp[data-height=spacing-xl] {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sp[data-height=spacing-xl] {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sp[data-height=spacing-xl] {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sp[data-height=spacing-l] {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sp[data-height=spacing-l] {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sp[data-height=spacing-l] {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sp[data-height=spacing-m] {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sp[data-height=spacing-m] {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sp[data-height=spacing-m] {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sp[data-height=spacing-s] {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sp[data-height=spacing-s] {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sp[data-height=spacing-s] {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sp[data-height=spacing-xs] {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sp[data-height=spacing-xs] {
    padding-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sp[data-height=spacing-xs] {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .sp[data-height=spacing-xxs] {
    padding-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .sp[data-height=spacing-xxs] {
    padding-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .sp[data-height=spacing-xxs] {
    padding-top: 0.625rem;
  }
}

/* ----------------------------------
    Testimonial with a Box Background
---------------------------------- */
[data-block-type=testimonial-box] .quote-bkgrd {
  width: 100%;
  box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.15);
  background: white;
  border-radius: 0.1875rem;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-box] .quote-bkgrd {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=testimonial-box] .quote-bkgrd {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=testimonial-box] .quote-bkgrd {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-box] .quote-bkgrd {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=testimonial-box] .quote-bkgrd {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=testimonial-box] .quote-bkgrd {
    padding-bottom: 2.375rem;
  }
}
[data-block-type=testimonial-box] .quote {
  margin: 0 1.25rem;
  /* Tablet */
  /* Desktop */
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-box] .quote {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 76.7634854772%;
    margin-left: 11.6182572614%;
    float: none;
  }
  [data-block-type=testimonial-box] .quote:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-box] .quote {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 79.1821561338%;
    margin-left: 10.4089219331%;
    float: none;
  }
  [data-block-type=testimonial-box] .quote:last-child {
    margin-right: 0;
  }
}

/* ----------------------------------
    Testimonial with Headline
---------------------------------- */
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-headline] .bound {
    display: flex;
    align-items: center;
  }
}
[data-block-type=testimonial-headline] .headline {
  position: relative;
  z-index: 1;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  /* Tablet */
  /* Desktop */
}
[data-block-type=testimonial-headline] .headline i,
[data-block-type=testimonial-headline] .headline em {
  font-style: italic;
}
[data-block-type=testimonial-headline] .headline i b,
[data-block-type=testimonial-headline] .headline i strong,
[data-block-type=testimonial-headline] .headline em b,
[data-block-type=testimonial-headline] .headline em strong {
  font-style: italic;
}
[data-block-type=testimonial-headline] .headline b,
[data-block-type=testimonial-headline] .headline strong {
  font-weight: 600;
}
[data-block-type=testimonial-headline] .headline b i,
[data-block-type=testimonial-headline] .headline b em,
[data-block-type=testimonial-headline] .headline strong i,
[data-block-type=testimonial-headline] .headline strong em {
  font-weight: 600;
}
[data-block-type=testimonial-headline] .headline a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=testimonial-headline] .headline a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=testimonial-headline] .headline a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-headline] .headline {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=testimonial-headline] .headline {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=testimonial-headline] .headline {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=testimonial-headline] .headline {
    font-size: 1.9166666667rem;
    line-height: 1.255em;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=testimonial-headline] .headline {
    font-size: 1.75rem;
  }
}
[data-block-type=testimonial-headline] .headline b, [data-block-type=testimonial-headline] .headline strong {
  color: #ff579e;
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-headline] .headline {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 30.2904564315%;
    margin-top: 0;
    float: none;
  }
  [data-block-type=testimonial-headline] .headline:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-headline] .headline {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 32.3420074349%;
    float: none;
  }
  [data-block-type=testimonial-headline] .headline:last-child {
    margin-right: 0;
  }
}
[data-block-type=testimonial-headline] .quote {
  position: relative;
  z-index: 0;
  /* Tablet */
  /* Desktop */
  /*  Bkgrds ---- */
  /*  Quote Marks ---- */
  /*  Removing content quote marks ---- */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-headline] .quote {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=testimonial-headline] .quote {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=testimonial-headline] .quote {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-headline] .quote {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=testimonial-headline] .quote {
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=testimonial-headline] .quote {
    padding-bottom: 4.6875rem;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-headline] .quote {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 65.1452282158%;
    float: none;
  }
  [data-block-type=testimonial-headline] .quote:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-headline] .quote {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 58.3643122677%;
    margin-left: 5.2044609665%;
    float: none;
  }
  [data-block-type=testimonial-headline] .quote:last-child {
    margin-right: 0;
  }
}
[data-block-type=testimonial-headline] .quote:before, [data-block-type=testimonial-headline] .quote:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: inherit;
}
[data-block-type=testimonial-headline] .quote:before {
  width: 5.25rem;
  right: 99.9%;
  border-radius: 0.1875rem 0 0 0.1875rem;
}
[data-block-type=testimonial-headline] .quote:after {
  width: 30vw;
  left: 99.9%;
}
[data-block-type=testimonial-headline] .quote svg {
  position: absolute;
  top: -0.625rem;
  left: -2.625rem;
  width: 4.375rem;
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-headline] .quote svg {
    top: -1.5625rem;
    left: 0;
  }
}
[data-block-type=testimonial-headline] .quote .no-quotes p:before,
[data-block-type=testimonial-headline] .quote .no-quotes p:after {
  display: none;
}

/* ----------------------------------
    Testimonial with a Box Background
---------------------------------- */
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-image] .bound {
    display: flex;
    align-items: center;
  }
}
[data-block-type=testimonial-image] .img {
  /*  Going all the way to the edge... ---- */
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-image] .img {
    order: 1;
  }
}
[data-block-type=testimonial-image] .img img {
  width: 100%;
}
[data-block-type=testimonial-image] .img.toedge {
  width: 90%;
  max-width: 29.375rem;
  margin-left: -7.6vw;
}
@media screen and (min-width: 62rem) {
  [data-block-type=testimonial-image] .img.toedge {
    margin-left: -14.8230769231vw;
  }
}
[data-block-type=testimonial-image] .quote {
  margin: 0 1.25rem;
  flex-shrink: 0;
  /* Tablet */
  /* Desktop */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-image] .quote {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=testimonial-image] .quote {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=testimonial-image] .quote {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-image] .quote {
    padding-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=testimonial-image] .quote {
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=testimonial-image] .quote {
    padding-bottom: 2.375rem;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=testimonial-image] .quote {
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 65.1452282158%;
    margin-left: 5.8091286307%;
    float: none;
    order: 2;
  }
  [data-block-type=testimonial-image] .quote:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=testimonial-image] .quote {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 68.7732342007%;
    margin-left: 5.2044609665%;
    float: none;
    margin-right: 0;
  }
  [data-block-type=testimonial-image] .quote:last-child {
    margin-right: 0;
  }
}

/* ----------------------------------
    Timelapse Drag Block
---------------------------------- */
.timelapse {
  position: relative;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  z-index: 0;
}
.timelapse.is-hidden {
  display: none;
}
.timelapse:not(.is-hidden) {
  opacity: 1;
}
.timelapse figure {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 68.8125rem) {
  .timelapse figure {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse figure {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timelapse figure {
    padding-top: 2.375rem;
  }
}
.timelapse img {
  position: relative;
  max-width: 100%;
  opacity: 1;
}
.timelapse img.not-in-view, .timelapse img.is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
@media screen and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-right: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-right: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-right: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-left: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-left: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-left: 2.375rem;
  }
}
.timelapse .timelapse-slider {
  position: relative;
  width: 100%;
  height: 0.125rem;
  background: #828e90;
}
@media screen and (min-width: 68.8125rem) {
  .timelapse .timelapse-slider {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .timelapse-slider {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timelapse .timelapse-slider {
    margin-top: 2.375rem;
  }
}
.timelapse .timelapse-slider .slider-icon {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 0%;
  width: 2.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.625rem;
  background-image: url("/assets/images/theme/timelapse-icon.svg");
  cursor: pointer;
  transform: translateY(-50%);
}
.timelapse .timelapse-slider .slider-icon:before {
  content: "DRAG";
  position: absolute;
  top: -1.25rem;
  left: 50%;
  color: #2e408a;
  transform: translateX(-50%);
}
@media screen and (min-width: 68.8125rem) and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 68.8125rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-top: 1.375rem;
  }
}
@media screen and (min-width: 68.8125rem) and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-right: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-right: 5rem;
  }
}
@media screen and (min-width: 68.8125rem) and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-right: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 68.8125rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-bottom: 1.375rem;
  }
}
@media screen and (min-width: 68.8125rem) and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) and (min-width: 68.8125rem) {
  .timelapse .wysiwyg {
    padding-left: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timelapse .wysiwyg {
    padding-left: 5rem;
  }
}
@media screen and (min-width: 68.8125rem) and (max-width: 46.875rem) {
  .timelapse .wysiwyg {
    padding-left: 4.6875rem;
  }
}
@media screen and (max-width: 61.9375rem) {
  .timelapse .wysiwyg {
    padding-left: 0;
    padding-right: 0;
  }
}

.timelapse-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.timelapse-container .add-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.timelapse-container[data-timelapse-theme=light] {
  background-color: white;
}
.timelapse-container[data-timelapse-theme=light] .add-opacity {
  background-color: white;
}
.timelapse-container[data-timelapse-theme=light] .h-huge {
  color: #001623;
}
.timelapse-container[data-timelapse-theme=light] .h-normal {
  color: #001623;
}
.timelapse-container[data-timelapse-theme=light] .h-small {
  color: #001623;
}
.timelapse-container[data-timelapse-theme=light] .h-tiny {
  color: #001623;
}
.timelapse-container[data-timelapse-theme=light] .h-very_tiny {
  color: #001623;
}
.timelapse-container[data-timelapse-theme=light] p {
  color: #001623;
}
.timelapse-container[data-timelapse-theme=dark] {
  background-color: #290e19;
}
.timelapse-container[data-timelapse-theme=dark] .add-opacity {
  background-color: #001623;
}
.timelapse-container[data-timelapse-theme=dark] .h-huge {
  color: white;
}
.timelapse-container[data-timelapse-theme=dark] .h-normal {
  color: white;
}
.timelapse-container[data-timelapse-theme=dark] .h-small {
  color: white;
}
.timelapse-container[data-timelapse-theme=dark] .h-tiny {
  color: white;
}
.timelapse-container[data-timelapse-theme=dark] .h-very_tiny {
  color: white;
}
.timelapse-container[data-timelapse-theme=dark] p {
  color: white;
}
.timelapse-container.mobile {
  display: none;
}
@media screen and (max-width: 46.875rem) {
  .timelapse-container.desktop {
    display: none;
  }
  .timelapse-container.mobile {
    display: block;
  }
}

/* ----------------------------------
    Timeline Horizontal Block
---------------------------------- */
.timeline-carousel {
  /*  Lazy Load Images
  ---------------------------------- */
  /*  Customize Flickity Styles
  ---------------------------------- */
  /*  Card Styles
  ---------------------------------- */
  /*   Timeline Tick Styles
  ---------------------------------- */
  /*   Toggle All Background Styles
  ---------------------------------- */
  /*  Media Query Adjustments
  ---------------------------------- */
}
.timeline-carousel img {
  /* fade in image when loaded */
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
.timeline-carousel img.flickity-lazyloaded, .timeline-carousel img.flickity-lazyerror {
  opacity: 1;
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .flickity-prev-next-button {
    top: auto;
    width: 2.8125rem;
    height: 2.8125rem;
    bottom: 0.625rem;
    transform: translateY(0);
  }
  .timeline-carousel .flickity-prev-next-button.next {
    right: 50%;
    transform: translateX(120%);
  }
  .timeline-carousel .flickity-prev-next-button.previous {
    left: 50%;
    transform: translateX(-120%);
  }
}
.timeline-carousel .timeline-card {
  bottom: 0;
  width: 45%;
  margin: 0 5%;
  /*  Shared Styles: Card with Text and Only Image ---- */
  /*  Card with Only Image Styles ---- */
  /*  Card with Text Styles ---- */
  /*  Dark Mode  */
}
@media screen and (min-width: 68.8125rem) {
  .timeline-carousel .timeline-card {
    padding-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-carousel .timeline-card {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card {
    padding-bottom: 5.25rem;
  }
}
.timeline-carousel .timeline-card .full-card,
.timeline-carousel .timeline-card .table-card {
  position: relative;
  min-height: 28.125rem;
  /* Card bottom icon default layout */
}
.timeline-carousel .timeline-card .full-card:before, .timeline-carousel .timeline-card .full-card:after,
.timeline-carousel .timeline-card .table-card:before,
.timeline-carousel .timeline-card .table-card:after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  transform: translateX(-50%);
  background-color: #687273;
}
.timeline-carousel .timeline-card .full-card:before,
.timeline-carousel .timeline-card .table-card:before {
  bottom: -3.125rem;
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
}
.timeline-carousel .timeline-card .full-card:after,
.timeline-carousel .timeline-card .table-card:after {
  bottom: -2.8125rem;
  height: 2.8125rem;
  width: 0.0625rem;
}
.timeline-carousel .timeline-card .full-card img {
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 20px -5px #ffd1e5;
  border: 1px solid #828e90;
}
.timeline-carousel .timeline-card .full-card:after {
  background-color: transparent;
}
.timeline-carousel .timeline-card .table-card {
  display: table;
  background: white;
  box-shadow: 0 0 20px -5px #ffd1e5;
  border: 1px solid #828e90;
  /*  Image is masked and occupies its entire cell */
}
.timeline-carousel .timeline-card .table-card .image-mask {
  display: table-cell;
  border-collapse: collapse;
  position: relative;
  height: 100%;
  width: 50%;
  vertical-align: top;
  overflow: hidden;
}
.timeline-carousel .timeline-card .table-card img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}
.timeline-carousel .timeline-card .table-card figcaption {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
@media screen and (min-width: 68.8125rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-right: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-right: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-left: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-left: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card .table-card figcaption {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-carousel .timeline-card .table-card .cta-atom a {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card .table-card .cta-atom a {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card .table-card .cta-atom a {
    margin-bottom: 0.625rem;
  }
}
.timeline-carousel .timeline-card[data-night-mode=true] .table-card {
  background: black;
}
.timeline-carousel .timeline-ticks {
  position: absolute;
  bottom: 4.375rem;
  width: 100%;
  background-size: contain;
  height: 0.625rem;
  background-image: url("/assets/images/ui/timeline-tick.svg");
}
.timeline-carousel [data-showcard=false] .full-card,
.timeline-carousel [data-showcard=false] .table-card {
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.timeline-carousel [data-showcard=false] .full-card:before,
.timeline-carousel [data-showcard=false] .table-card:before {
  background-color: #687273;
}
.timeline-carousel [data-showcard=false] .full-card:after,
.timeline-carousel [data-showcard=false] .table-card:after {
  background-color: transparent;
}
.timeline-carousel [data-showcard=false] .full-card img,
.timeline-carousel [data-showcard=false] .table-card img {
  border-color: transparent;
  box-shadow: none;
}
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card {
  box-shadow: none;
}
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card {
  border-color: transparent;
  background-color: transparent;
}
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card:before,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card:before {
  background-color: #687273;
}
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card:after,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card:after {
  background-color: transparent;
}
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card img,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card img {
  border-color: transparent;
  box-shadow: none;
}
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg h1,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg h2,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg h3,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg h4,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg .h-huge,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg .h-normal,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg .h-small,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg .h-tiny,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg .h-very_tiny,
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .wysiwyg p,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg h1,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg h2,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg h3,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg h4,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg .h-huge,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg .h-normal,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg .h-small,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg .h-tiny,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg .h-very_tiny,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .wysiwyg p {
  color: #001623;
}
.timeline-carousel [data-showcard=false][data-night-mode=true] .full-card .delimiter,
.timeline-carousel [data-showcard=false][data-night-mode=true] .table-card .delimiter {
  color: #001623;
}
@media screen and (max-width: 81.25em) {
  .timeline-carousel .timeline-card {
    width: 55%;
  }
}
@media screen and (min-width: 62rem) and (max-width: 68.75rem) {
  .timeline-carousel .timeline-card {
    padding-bottom: 119px;
  }
}
@media screen and (max-width: 61.9375rem) {
  .timeline-carousel .timeline-card {
    width: 65%;
    padding-bottom: 119px;
  }
  .timeline-carousel .timeline-card .table-card {
    display: block;
  }
  .timeline-carousel .timeline-card .table-card .image-mask {
    display: block;
    width: 100%;
  }
  .timeline-carousel .timeline-card .table-card .image-mask:before {
    bottom: -3.1875rem;
  }
  .timeline-carousel .timeline-card .table-card .image-mask:after {
    bottom: -2.8125rem;
    width: 0.0625rem;
  }
  .timeline-carousel .timeline-card .table-card .image-mask img {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    max-width: 100%;
    transform: none;
  }
  .timeline-carousel .timeline-card .table-card figcaption {
    display: block;
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-carousel .timeline-card {
    width: 90%;
    margin: 0 5%;
  }
}

/* ----------------------------------
    Timeline Vertical - Shared
---------------------------------- */
.timeline-vertical {
  position: relative;
  background-color: #ebebeb;
  /*  Timeline Track
  ---------------------------------- */
  /*  Event & Card Layout
  ---------------------------------- */
  /*  Card Content
  ---------------------------------- */
}
.timeline-vertical .track {
  padding: 80px 0;
  max-width: 1076px;
  margin: 0 auto;
  background: url("/assets/images/ui/timeline-vertical-track-hash.png") repeat-y center top, url("/assets/images/ui/dot-black-10p.png") repeat-y center top, url("/assets/images/ui/dot-black-10p.png") repeat-y center top;
  background-size: 7px 8px, 1px 1px, 1px 1px;
}
@media screen and (max-width: 61.9375rem) {
  .timeline-vertical .track {
    margin-left: 0;
    background-position: left top, 4px top, 4px top;
  }
}
.timeline-vertical .event {
  margin-bottom: 50px;
  /*  Dark Background
  ---------------------------------- */
  /*  No Card Background
  ---------------------------------- */
}
@media screen and (max-width: 61.9375rem) {
  .timeline-vertical .event {
    padding-left: 26px;
  }
}
.timeline-vertical .event .card {
  position: relative;
  max-width: 550px;
  background: white;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25), 0 1px 5px rgba(0, 0, 0, 0.2);
}
.timeline-vertical .event .card:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -26px;
  width: 26px;
  height: 9px;
  background-image: url("/assets/images/ui/timeline-vertical-connector-dt-leftward.png");
  background-size: 26px 9px;
  background-repeat: no-repeat;
  background-position: 0 0;
}
@media screen and (min-width: 62rem) {
  .timeline-vertical .event:nth-of-type(even) {
    padding: 0 0 0 50%;
  }
  .timeline-vertical .event:nth-of-type(even) .contain {
    padding: 0 0 0 26px;
  }
  .timeline-vertical .event:nth-of-type(even) .card {
    margin-left: -4px;
  }
}
.timeline-vertical .event:nth-of-type(even) .card:after {
  right: auto;
}
@media screen and (min-width: 62rem) {
  .timeline-vertical .event:nth-of-type(odd) {
    padding: 0 50% 0 0;
  }
  .timeline-vertical .event:nth-of-type(odd) .contain {
    padding: 0 26px 0 0;
  }
  .timeline-vertical .event:nth-of-type(odd) .card {
    margin-right: -4px;
  }
  .timeline-vertical .event:nth-of-type(odd) .card:after {
    right: -26px;
    left: auto;
    background-image: url("/assets/images/ui/timeline-vertical-connector-dt-rightward.png");
  }
}
.timeline-vertical .event[data-night-mode=true] .card {
  background: black;
}
.timeline-vertical .event[data-showcard=false] .card {
  box-shadow: none;
  background-color: transparent;
  /* Image takes up entire card when just image and no background */
}
.timeline-vertical .event[data-showcard=false] .card figure,
.timeline-vertical .event[data-showcard=false] .card .essay {
  padding-left: 0;
  padding-right: 0;
}
.timeline-vertical .event[data-showcard=false] .card figure {
  padding-top: 0;
}
.timeline-vertical .event[data-showcard=false] .card:after {
  height: 0.5625rem;
  width: 0.5625rem;
  background: #ababab;
  background-size: contain;
  border-radius: 50%;
}
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .card {
  background: transparent;
}
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg .h-huge,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg .h-normal,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg .h-small,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg .h-tiny,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg .h-very_tiny,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg h1,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg h2,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg h3,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg h4,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg h5,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg h6,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg p,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg ul,
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .wysiwyg ol {
  color: #001623;
}
.timeline-vertical .event[data-showcard=false][data-night-mode=true] .delimiter {
  color: #001623;
}
.timeline-vertical .card figure img {
  display: block;
  width: 100%;
}
@media screen and (min-width: 68.8125rem) {
  .timeline-vertical .card .essay {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-vertical .card .essay {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-vertical .card .essay {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-vertical .card .essay {
    padding-right: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-vertical .card .essay {
    padding-right: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-vertical .card .essay {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-vertical .card .essay {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-vertical .card .essay {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-vertical .card .essay {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .timeline-vertical .card .essay {
    padding-left: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .timeline-vertical .card .essay {
    padding-left: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .timeline-vertical .card .essay {
    padding-left: 1.25rem;
  }
}
.timeline-vertical .card .essay > *:last-child {
  margin-bottom: 0;
}

/* ----------------------------------
    Tripple Callout Block
---------------------------------- */
[data-block-type=tripple_callout] {
  /*  Main Pane
  ---------------------------------- */
  /*  Text (Content) Pane
  ---------------------------------- */
  /*  Secondary image

      This one is odd cause it's size
      is CMS based and weird...
  ---------------------------------- */
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=tripple_callout] .bound {
    display: flex;
    align-items: center;
  }
}
[data-block-type=tripple_callout] .main_pane {
  position: relative;
  border-radius: 0 0.1875rem 0.1875rem 0;
  /* Tablet */
  /* Desktop */
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .main_pane {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=tripple_callout] .main_pane {
    padding-top: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=tripple_callout] .main_pane {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .main_pane {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=tripple_callout] .main_pane {
    padding-bottom: 5.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=tripple_callout] .main_pane {
    padding-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=tripple_callout] .main_pane {
    flex-shrink: 0;
    float: left;
    display: block;
    margin-right: 4.5643153527%;
    width: 41.9087136929%;
    margin-right: 0;
    float: none;
  }
  [data-block-type=tripple_callout] .main_pane:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .main_pane {
    float: left;
    display: block;
    margin-right: 4.0892193309%;
    width: 47.9553903346%;
    margin-right: 0;
    float: none;
  }
  [data-block-type=tripple_callout] .main_pane:last-child {
    margin-right: 0;
  }
}
[data-block-type=tripple_callout] .main_pane .graphic {
  width: 80%;
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneX {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 14.0522875817%/6.847133758%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneX:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 205.2287581699%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneX .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 90.522875817%;
  height: 95.3821656051%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 2.3089171975%;
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneX .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.iphoneX .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneX:before {
  background-image: url("/assets/images/ui/device-iphone_x.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneOld {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 14.4295302013%/6.9579288026%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneOld:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 207.3825503356%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneOld .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 90.2684563758%;
  height: 76.0517799353%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 11.9741100324%;
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneOld .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.iphoneOld .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.iphoneOld:before {
  background-image: url("/assets/images/ui/device-iphone_5.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.androidPixel {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 12.9032258065%/6.4516129032%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.androidPixel:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 200%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.androidPixel .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 93.2258064516%;
  height: 92.9032258065%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 5.1612903226%;
}
[data-block-type=tripple_callout] .main_pane .graphic.androidPixel .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.androidPixel .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.androidPixel:before {
  background-image: url("/assets/images/ui/device-android_pixel.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.androidOld {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 12.7388535032%/6.0422960725%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.androidOld:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 210.8280254777%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.androidOld .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 89.8089171975%;
  height: 84.7432024169%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 7.0996978852%;
}
[data-block-type=tripple_callout] .main_pane .graphic.androidOld .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.androidOld .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.androidOld:before {
  background-image: url("/assets/images/ui/device-android_old.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadPortrait {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 5.6818181818%/3.9404553415%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadPortrait:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 144.1919191919%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadPortrait .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 92.9292929293%;
  height: 85.9019264448%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 7.0490367776%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadPortrait .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.ipadPortrait .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadPortrait:before {
  background-image: url("/assets/images/ui/device-ipad-portrait.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadLandscape {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 3.9404553415%/5.6818181818%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadLandscape:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 69.3520140105%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadLandscape .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 85.9019264448%;
  height: 92.9292929293%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 3.5353535354%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadLandscape .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.ipadLandscape .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadLandscape:before {
  background-image: url("/assets/images/ui/device-ipad-landscape.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProPortrait {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 5.2503052503%/3.7587412587%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProPortrait:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 139.6825396825%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProPortrait .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 92.673992674%;
  height: 94.8426573427%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 2.5786713287%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProPortrait .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.ipadProPortrait .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProPortrait:before {
  background-image: url("/assets/images/ui/device-ipad-pro-portrait.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProLandscape {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 3.7587412587%/5.2503052503%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProLandscape:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 71.5909090909%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProLandscape .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 94.8426573427%;
  height: 92.673992674%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 3.663003663%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProLandscape .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.ipadProLandscape .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.ipadProLandscape:before {
  background-image: url("/assets/images/ui/device-ipad-pro-landscape.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.appleWatchSeries3 {
  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: 0%/0%;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
  background: none;
  box-shadow: none;
}
[data-block-type=tripple_callout] .main_pane .graphic.appleWatchSeries3:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 158.5714285714%;
  background-repeat: no-repeat;
  background-size: contain;
}
[data-block-type=tripple_callout] .main_pane .graphic.appleWatchSeries3 .screen {
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 50.1587301587%;
  height: 39.4394394394%;
  transform: translate(-50%, 0);
  overflow: hidden;
  top: 30.2802802803%;
}
[data-block-type=tripple_callout] .main_pane .graphic.appleWatchSeries3 .screen img,
[data-block-type=tripple_callout] .main_pane .graphic.appleWatchSeries3 .screen video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane .graphic.appleWatchSeries3:before {
  background-image: url("/assets/images/ui/device-apple_watch-series_3.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.appleWatchSeries3 .screen {
  background: black;
}
[data-block-type=tripple_callout] .main_pane .graphic.browser {
  position: relative;
  float: none;
  z-index: 1;
  border-radius: 0.3125rem;
  background: white;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
[data-block-type=tripple_callout] .main_pane .graphic.browser:before {
  content: "";
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-top: 4.3715846995%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url("/assets/images/ui/device-browser.svg");
}
[data-block-type=tripple_callout] .main_pane .graphic.browser .screen {
  overflow: hidden;
  border: 0.0625rem solid rgba(193, 193, 193, 0.2);
  border-radius: 0 0 0.1875rem 0.1875rem;
  background: white;
}
[data-block-type=tripple_callout] .main_pane .graphic img,
[data-block-type=tripple_callout] .main_pane .graphic video {
  width: 100%;
}
[data-block-type=tripple_callout] .main_pane:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30vw;
  right: 99.9%;
  background-color: inherit;
}
[data-block-type=tripple_callout] .text_pane {
  position: relative;
  z-index: 2;
  margin-top: -2.625rem;
  padding-left: 1.25rem;
  border-radius: 0.1875rem 0 0 0.1875rem;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-bottom: 12.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-bottom: 9.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-bottom: 6.25rem;
  }
}
[data-block-type=tripple_callout] .text_pane:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30vw;
  left: 99.9%;
  background-color: inherit;
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-left: 2.5rem;
    border-radius: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .text_pane {
    padding-left: 5.25rem;
  }
}
[data-block-type=tripple_callout] .secondary_img {
  position: relative;
  z-index: 3;
  margin-top: -2.375rem;
  /*  Width ---- */
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=tripple_callout] .secondary_img {
    margin-top: -5.625rem;
    margin-left: -5%;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .secondary_img {
    margin-top: -6.25rem;
    margin-left: -8%;
  }
}
@media screen and (min-width: 75rem) {
  [data-block-type=tripple_callout] .secondary_img {
    margin-left: -10%;
  }
}
[data-block-type=tripple_callout] .secondary_img img {
  width: 100%;
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=tripple_callout] .secondary_img.smaller {
    width: 75%;
  }
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=tripple_callout] .secondary_img.full {
    width: 120%;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=tripple_callout] .secondary_img.full {
    width: 118%;
  }
}
@media screen and (min-width: 75rem) {
  [data-block-type=tripple_callout] .secondary_img.full {
    width: 115%;
  }
}
[data-block-type=tripple_callout] .secondary_img.toEdge {
  width: 114%;
}
@media screen and (min-width: 46.9375rem) {
  [data-block-type=tripple_callout] .secondary_img.toEdge {
    width: 134%;
  }
}
@media screen and (min-width: 62rem) {
  [data-block-type=tripple_callout] .secondary_img.toEdge {
    width: 158%;
  }
}
@media screen and (min-width: 75rem) {
  [data-block-type=tripple_callout] .secondary_img.toEdge {
    width: 155%;
  }
}

/* ----------------------------------
    Shared Video Grid
---------------------------------- */
[data-block-type=video_grid] {
  /*  Video Grid Itself
  ---------------------------------- */
}
[data-block-type=video_grid] .video-grid {
  /*  Each Video ---- */
}
[data-block-type=video_grid] .video-grid::after {
  clear: both;
  content: "";
  display: table;
}
[data-block-type=video_grid] .video-grid li {
  float: left;
  display: block;
  margin-right: 3.3846153846%;
  width: 48.3076923077%;
  display: none;
}
[data-block-type=video_grid] .video-grid li:last-child {
  margin-right: 0;
}
[data-block-type=video_grid] .video-grid li:nth-child(2n) {
  margin-right: 0;
}
[data-block-type=video_grid] .video-grid li:nth-child(2n+1) {
  clear: left;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li {
    margin-bottom: 2.375rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=video_grid] .video-grid li {
    width: 100%;
  }
}
[data-block-type=video_grid] .video-grid li.visible {
  display: block;
}
[data-block-type=video_grid] .video-grid li video {
  width: 100%;
}
[data-block-type=video_grid] .video-grid li h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
}
[data-block-type=video_grid] .video-grid li h3 i,
[data-block-type=video_grid] .video-grid li h3 em {
  font-style: italic;
}
[data-block-type=video_grid] .video-grid li h3 i b,
[data-block-type=video_grid] .video-grid li h3 i strong,
[data-block-type=video_grid] .video-grid li h3 em b,
[data-block-type=video_grid] .video-grid li h3 em strong {
  font-style: italic;
}
[data-block-type=video_grid] .video-grid li h3 b,
[data-block-type=video_grid] .video-grid li h3 strong {
  font-weight: 600;
}
[data-block-type=video_grid] .video-grid li h3 b i,
[data-block-type=video_grid] .video-grid li h3 b em,
[data-block-type=video_grid] .video-grid li h3 strong i,
[data-block-type=video_grid] .video-grid li h3 strong em {
  font-weight: 600;
}
[data-block-type=video_grid] .video-grid li h3 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=video_grid] .video-grid li h3 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=video_grid] .video-grid li h3 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-top: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li h3 {
    margin-bottom: 0.625rem;
  }
}
[data-block-type=video_grid] .video-grid li .duration {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #fff0f6;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
  display: block;
}
[data-block-type=video_grid] .video-grid li .duration i,
[data-block-type=video_grid] .video-grid li .duration em {
  font-style: italic;
}
[data-block-type=video_grid] .video-grid li .duration i b,
[data-block-type=video_grid] .video-grid li .duration i strong,
[data-block-type=video_grid] .video-grid li .duration em b,
[data-block-type=video_grid] .video-grid li .duration em strong {
  font-style: italic;
}
[data-block-type=video_grid] .video-grid li .duration b,
[data-block-type=video_grid] .video-grid li .duration strong {
  font-weight: 400;
}
[data-block-type=video_grid] .video-grid li .duration b i,
[data-block-type=video_grid] .video-grid li .duration b em,
[data-block-type=video_grid] .video-grid li .duration strong i,
[data-block-type=video_grid] .video-grid li .duration strong em {
  font-weight: 400;
}
[data-block-type=video_grid] .video-grid li .duration a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=video_grid] .video-grid li .duration a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=video_grid] .video-grid li .duration a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li .duration {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li .duration {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li .duration {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=video_grid] .video-grid li .duration {
    font-size: 0.9375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li .duration {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li .duration {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li .duration {
    margin-bottom: 0.625rem;
  }
}
[data-block-type=video_grid] .video-grid li .description {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  border-top: 1px solid #828e90;
}
[data-block-type=video_grid] .video-grid li .description i,
[data-block-type=video_grid] .video-grid li .description em {
  font-style: italic;
}
[data-block-type=video_grid] .video-grid li .description i b,
[data-block-type=video_grid] .video-grid li .description i strong,
[data-block-type=video_grid] .video-grid li .description em b,
[data-block-type=video_grid] .video-grid li .description em strong {
  font-style: italic;
}
[data-block-type=video_grid] .video-grid li .description b,
[data-block-type=video_grid] .video-grid li .description strong {
  font-weight: 500;
}
[data-block-type=video_grid] .video-grid li .description b i,
[data-block-type=video_grid] .video-grid li .description b em,
[data-block-type=video_grid] .video-grid li .description strong i,
[data-block-type=video_grid] .video-grid li .description strong em {
  font-weight: 500;
}
[data-block-type=video_grid] .video-grid li .description a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=video_grid] .video-grid li .description a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=video_grid] .video-grid li .description a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li .description {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 34rem) {
  [data-block-type=video_grid] .video-grid li .description {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li .description {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .video-grid li .description {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .video-grid li .description {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .video-grid li .description {
    padding-top: 1.25rem;
  }
}
[data-block-type=video_grid] .load-videos {
  text-align: center;
}
@media screen and (min-width: 68.8125rem) {
  [data-block-type=video_grid] .load-videos {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  [data-block-type=video_grid] .load-videos {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  [data-block-type=video_grid] .load-videos {
    margin-bottom: 2.375rem;
  }
}
[data-block-type=video_grid] .load-videos .button-loadmore {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: white;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0 0;
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.1875rem;
  background-color: #667acc;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
  display: inline-table;
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
  /* 	Smaller Buttons ---- */
}
[data-block-type=video_grid] .load-videos .button-loadmore i,
[data-block-type=video_grid] .load-videos .button-loadmore em {
  font-style: italic;
}
[data-block-type=video_grid] .load-videos .button-loadmore i b,
[data-block-type=video_grid] .load-videos .button-loadmore i strong,
[data-block-type=video_grid] .load-videos .button-loadmore em b,
[data-block-type=video_grid] .load-videos .button-loadmore em strong {
  font-style: italic;
}
[data-block-type=video_grid] .load-videos .button-loadmore b,
[data-block-type=video_grid] .load-videos .button-loadmore strong {
  font-weight: 600;
}
[data-block-type=video_grid] .load-videos .button-loadmore b i,
[data-block-type=video_grid] .load-videos .button-loadmore b em,
[data-block-type=video_grid] .load-videos .button-loadmore strong i,
[data-block-type=video_grid] .load-videos .button-loadmore strong em {
  font-weight: 600;
}
[data-block-type=video_grid] .load-videos .button-loadmore a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
[data-block-type=video_grid] .load-videos .button-loadmore a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  [data-block-type=video_grid] .load-videos .button-loadmore a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
[data-block-type=video_grid] .load-videos .button-loadmore:focus {
  color: white;
  background-image: none;
  text-decoration: none;
  background-color: #2e408a;
}
@media (hover: hover) {
  [data-block-type=video_grid] .load-videos .button-loadmore:hover {
    color: white;
    background-image: none;
    text-decoration: none;
    background-color: #2e408a;
  }
}
[data-block-type=video_grid] .load-videos .button-loadmore.inactive, [data-block-type=video_grid] .load-videos .button-loadmore:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
[data-block-type=video_grid] .load-videos .button-loadmore.inactive:focus, [data-block-type=video_grid] .load-videos .button-loadmore:disabled:focus {
  color: white;
  background-color: #667acc;
}
@media (hover: hover) {
  [data-block-type=video_grid] .load-videos .button-loadmore.inactive:hover, [data-block-type=video_grid] .load-videos .button-loadmore:disabled:hover {
    color: white;
    background-color: #667acc;
  }
}
[data-block-type=video_grid] .load-videos .button-loadmore span {
  display: table-cell;
  padding: 0.875em 1.2em;
  vertical-align: middle;
}
[data-block-type=video_grid] .load-videos .button-loadmore span.txt {
  background-color: #2e408a;
  padding: 0.875em 1.5em;
  transition: background-color 200ms;
}
[data-block-type=video_grid] .load-videos .button-loadmore em {
  font-style: normal;
}
[data-block-type=video_grid] .load-videos .button-loadmore:focus span.txt {
  background-color: #1b2550;
}
@media (hover: hover) {
  [data-block-type=video_grid] .load-videos .button-loadmore:hover span.txt {
    background-color: #1b2550;
  }
}
[data-block-type=video_grid] .load-videos .button-loadmore.inactive {
  /*  Hover & Active States ---- */
  /* 	Special inactive state if it's in a filter bar ---- */
}
[data-block-type=video_grid] .load-videos .button-loadmore.inactive:focus span {
  background-color: #2e408a;
}
@media (hover: hover) {
  [data-block-type=video_grid] .load-videos .button-loadmore.inactive:hover span {
    background-color: #2e408a;
  }
}
[data-block-type=video_grid] .load-videos .button-loadmore.inactive.filter {
  color: #23adff;
  border-color: #151d3e;
}
[data-block-type=video_grid] .load-videos .button-loadmore.inactive.filter span {
  background-color: #2e408a;
}

/* ----------------------------------
    Foundational Video - Content Block Styles
---------------------------------- */
[data-block-type=video] .foundation-container video {
  width: 100%;
}

/* ----------------------------------
		Shared WYSIWYG & HTML Blocks
---------------------------------- */
.wysiwyg {
  border: 1px solid transparent;
  /* ----------------------------------
  		Typography

      Keeping all the text styles
      scoped within the wysiwyg class.
      Think of these as "normal"
      typography styles...
  ---------------------------------- */
  /* 	Intra Text Justification
      Just for WYSIWYG plugin output...
      Please don't actually use classes in source..
  ---------------------------------- */
  /* 	Fancy Headline **STYLES**
  ---------------------------------- */
  /* 	Article Content Headlines
  ---------------------------------- */
  /* 	Font Size - Default (Large up) ---- */
  /* 	Basic Content Styling
  ---------------------------------- */
  /* 	HRs
  ---------------------------------- */
  /*  Cool HR treatment
  ---------------------------------- */
  /* Moving the HR up a bit when following styled headlines (to even out spacing) ---- */
  /* 	List Styles
  ---------------------------------- */
  /*  Ruled Headlines
  ---------------------------------- */
  /*  Change spacing for larger Headlines ---- */
  /*  Change Color for Primary ---- */
  /* ----------------------------------
      Blockquote
  ---------------------------------- */
  /* ----------------------------------
      Buttons
  ---------------------------------- */
}
.wysiwyg .text-left {
  text-align: left;
}
.wysiwyg .text-right {
  text-align: right;
}
.wysiwyg .text-center {
  text-align: center;
}
.wysiwyg .h-huge {
  font-family: "Poppins", sans-serif;
  font-size: 4.0625rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.1em;
  margin-top: 0;
}
.wysiwyg .h-huge i,
.wysiwyg .h-huge em {
  font-style: italic;
}
.wysiwyg .h-huge i b,
.wysiwyg .h-huge i strong,
.wysiwyg .h-huge em b,
.wysiwyg .h-huge em strong {
  font-style: italic;
}
.wysiwyg .h-huge b,
.wysiwyg .h-huge strong {
  font-weight: 600;
}
.wysiwyg .h-huge b i,
.wysiwyg .h-huge b em,
.wysiwyg .h-huge strong i,
.wysiwyg .h-huge strong em {
  font-weight: 600;
}
.wysiwyg .h-huge a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4125em, rgba(0, 0, 0, 0) 1.4125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4125em, rgba(0, 0, 0, 0) 1.4125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-huge a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4125em, rgba(0, 0, 0, 0) 1.4125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4125em, rgba(0, 0, 0, 0) 1.4125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-huge a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4125em, rgba(0, 0, 0, 0) 1.4125em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4125em, rgba(0, 0, 0, 0) 1.4125em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-huge {
    margin-bottom: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-huge {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-huge {
    margin-bottom: 4.6875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 61.9375rem) {
  .wysiwyg .h-huge {
    font-size: 3.703125rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg .h-huge {
    font-size: 3.34375rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-huge {
    font-size: 2.625rem;
  }
}
.wysiwyg .h-huge b, .wysiwyg .h-huge strong {
  color: #ff579e;
}
.wysiwyg .h-large {
  font-family: "Poppins", sans-serif;
  font-size: 3.4375rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.1em;
  margin-top: 0;
}
.wysiwyg .h-large i,
.wysiwyg .h-large em {
  font-style: italic;
}
.wysiwyg .h-large i b,
.wysiwyg .h-large i strong,
.wysiwyg .h-large em b,
.wysiwyg .h-large em strong {
  font-style: italic;
}
.wysiwyg .h-large b,
.wysiwyg .h-large strong {
  font-weight: 600;
}
.wysiwyg .h-large b i,
.wysiwyg .h-large b em,
.wysiwyg .h-large strong i,
.wysiwyg .h-large strong em {
  font-weight: 600;
}
.wysiwyg .h-large a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-large a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-large a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4181818182em, rgba(0, 0, 0, 0) 1.4181818182em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-large {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-large {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-large {
    margin-bottom: 2.375rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 61.9375rem) {
  .wysiwyg .h-large {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg .h-large {
    font-size: 2.8125rem;
    line-height: 1.15em;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-large {
    font-size: 2.1875rem;
  }
}
.wysiwyg .h-large b, .wysiwyg .h-large strong {
  color: #ff579e;
}
.wysiwyg .h-medium {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  margin-top: 0;
}
.wysiwyg .h-medium i,
.wysiwyg .h-medium em {
  font-style: italic;
}
.wysiwyg .h-medium i b,
.wysiwyg .h-medium i strong,
.wysiwyg .h-medium em b,
.wysiwyg .h-medium em strong {
  font-style: italic;
}
.wysiwyg .h-medium b,
.wysiwyg .h-medium strong {
  font-weight: 600;
}
.wysiwyg .h-medium b i,
.wysiwyg .h-medium b em,
.wysiwyg .h-medium strong i,
.wysiwyg .h-medium strong em {
  font-weight: 600;
}
.wysiwyg .h-medium a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-medium a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-medium a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.43125em, rgba(0, 0, 0, 0) 1.43125em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-medium {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-medium {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-medium {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg .h-medium {
    font-size: 1.9166666667rem;
    line-height: 1.255em;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-medium {
    font-size: 1.75rem;
  }
}
.wysiwyg .h-medium b, .wysiwyg .h-medium strong {
  color: #ff579e;
}
.wysiwyg .h-small {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  margin-top: 0;
}
.wysiwyg .h-small i,
.wysiwyg .h-small em {
  font-style: italic;
}
.wysiwyg .h-small i b,
.wysiwyg .h-small i strong,
.wysiwyg .h-small em b,
.wysiwyg .h-small em strong {
  font-style: italic;
}
.wysiwyg .h-small b,
.wysiwyg .h-small strong {
  font-weight: 500;
}
.wysiwyg .h-small b i,
.wysiwyg .h-small b em,
.wysiwyg .h-small strong i,
.wysiwyg .h-small strong em {
  font-weight: 500;
}
.wysiwyg .h-small a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-small a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-small a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.44em, rgba(0, 0, 0, 0) 1.44em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-small {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-small {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-small {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-small {
    font-size: 1.5rem;
  }
}
.wysiwyg .h-small b, .wysiwyg .h-small strong {
  color: #ff579e;
}
.wysiwyg .h-very_small {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: #001623;
  line-height: 1.1em;
  letter-spacing: 0.01em;
  margin-top: 0;
}
.wysiwyg .h-very_small i,
.wysiwyg .h-very_small em {
  font-style: italic;
}
.wysiwyg .h-very_small i b,
.wysiwyg .h-very_small i strong,
.wysiwyg .h-very_small em b,
.wysiwyg .h-very_small em strong {
  font-style: italic;
}
.wysiwyg .h-very_small b,
.wysiwyg .h-very_small strong {
  font-weight: 600;
}
.wysiwyg .h-very_small b i,
.wysiwyg .h-very_small b em,
.wysiwyg .h-very_small strong i,
.wysiwyg .h-very_small strong em {
  font-weight: 600;
}
.wysiwyg .h-very_small a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-very_small a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-very_small a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-very_small {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-very_small {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-very_small {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-very_small {
    font-size: 1.125rem;
  }
}
.wysiwyg .h-tiny {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  margin-top: 0;
}
.wysiwyg .h-tiny i,
.wysiwyg .h-tiny em {
  font-style: italic;
}
.wysiwyg .h-tiny i b,
.wysiwyg .h-tiny i strong,
.wysiwyg .h-tiny em b,
.wysiwyg .h-tiny em strong {
  font-style: italic;
}
.wysiwyg .h-tiny b,
.wysiwyg .h-tiny strong {
  font-weight: 600;
}
.wysiwyg .h-tiny b i,
.wysiwyg .h-tiny b em,
.wysiwyg .h-tiny strong i,
.wysiwyg .h-tiny strong em {
  font-weight: 600;
}
.wysiwyg .h-tiny a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-tiny a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-tiny a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-tiny {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-tiny {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-tiny {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-tiny {
    font-size: 1.125rem;
  }
}
.wysiwyg .h-very_tiny {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
  margin-top: 0;
}
.wysiwyg .h-very_tiny i,
.wysiwyg .h-very_tiny em {
  font-style: italic;
}
.wysiwyg .h-very_tiny i b,
.wysiwyg .h-very_tiny i strong,
.wysiwyg .h-very_tiny em b,
.wysiwyg .h-very_tiny em strong {
  font-style: italic;
}
.wysiwyg .h-very_tiny b,
.wysiwyg .h-very_tiny strong {
  font-weight: 400;
}
.wysiwyg .h-very_tiny b i,
.wysiwyg .h-very_tiny b em,
.wysiwyg .h-very_tiny strong i,
.wysiwyg .h-very_tiny strong em {
  font-weight: 400;
}
.wysiwyg .h-very_tiny a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-very_tiny a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-very_tiny a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4833333333em, rgba(0, 0, 0, 0) 1.4833333333em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-very_tiny {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-very_tiny {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-very_tiny {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-very_tiny {
    font-size: 0.9375rem;
  }
}
.wysiwyg .h-decorative {
  font-family: "Poppins", sans-serif;
  font-size: 1.5625rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.6em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.wysiwyg .h-decorative i,
.wysiwyg .h-decorative em {
  font-style: italic;
}
.wysiwyg .h-decorative i b,
.wysiwyg .h-decorative i strong,
.wysiwyg .h-decorative em b,
.wysiwyg .h-decorative em strong {
  font-style: italic;
}
.wysiwyg .h-decorative b,
.wysiwyg .h-decorative strong {
  font-weight: 500;
}
.wysiwyg .h-decorative b i,
.wysiwyg .h-decorative b em,
.wysiwyg .h-decorative strong i,
.wysiwyg .h-decorative strong em {
  font-weight: 500;
}
.wysiwyg .h-decorative a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .h-decorative a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .h-decorative a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-decorative {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-decorative {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-decorative {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-decorative {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-decorative {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-decorative {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg .h-decorative {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .h-decorative {
    font-size: 1.25rem;
  }
}
.wysiwyg .primary_color {
  color: #2e408a !important;
}
.wysiwyg .flush {
  margin: 0 !important;
}
.wysiwyg .all_caps {
  text-transform: uppercase !important;
}
.wysiwyg h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #001623;
  line-height: 1.1em;
}
.wysiwyg h1 i,
.wysiwyg h1 em {
  font-style: italic;
}
.wysiwyg h1 i b,
.wysiwyg h1 i strong,
.wysiwyg h1 em b,
.wysiwyg h1 em strong {
  font-style: italic;
}
.wysiwyg h1 b,
.wysiwyg h1 strong {
  font-weight: 500;
}
.wysiwyg h1 b i,
.wysiwyg h1 b em,
.wysiwyg h1 strong i,
.wysiwyg h1 strong em {
  font-weight: 500;
}
.wysiwyg h1 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg h1 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg h1 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg h1 {
    margin-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg h1 {
    margin-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg h1 {
    margin-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg h1 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg h1 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg h1 {
    margin-bottom: 1.25rem;
  }
}
.wysiwyg h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #001623;
  line-height: 1.1em;
}
.wysiwyg h2 i,
.wysiwyg h2 em {
  font-style: italic;
}
.wysiwyg h2 i b,
.wysiwyg h2 i strong,
.wysiwyg h2 em b,
.wysiwyg h2 em strong {
  font-style: italic;
}
.wysiwyg h2 b,
.wysiwyg h2 strong {
  font-weight: 500;
}
.wysiwyg h2 b i,
.wysiwyg h2 b em,
.wysiwyg h2 strong i,
.wysiwyg h2 strong em {
  font-weight: 500;
}
.wysiwyg h2 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg h2 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg h2 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg h2 {
    margin-top: 5.25rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg h2 {
    margin-top: 5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg h2 {
    margin-top: 4.6875rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg h2 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg h2 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg h2 {
    margin-bottom: 1.25rem;
  }
}
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #001623;
  line-height: 1.2em;
}
.wysiwyg h3 i,
.wysiwyg h3 em,
.wysiwyg h4 i,
.wysiwyg h4 em,
.wysiwyg h5 i,
.wysiwyg h5 em,
.wysiwyg h6 i,
.wysiwyg h6 em {
  font-style: italic;
}
.wysiwyg h3 i b,
.wysiwyg h3 i strong,
.wysiwyg h3 em b,
.wysiwyg h3 em strong,
.wysiwyg h4 i b,
.wysiwyg h4 i strong,
.wysiwyg h4 em b,
.wysiwyg h4 em strong,
.wysiwyg h5 i b,
.wysiwyg h5 i strong,
.wysiwyg h5 em b,
.wysiwyg h5 em strong,
.wysiwyg h6 i b,
.wysiwyg h6 i strong,
.wysiwyg h6 em b,
.wysiwyg h6 em strong {
  font-style: italic;
}
.wysiwyg h3 b,
.wysiwyg h3 strong,
.wysiwyg h4 b,
.wysiwyg h4 strong,
.wysiwyg h5 b,
.wysiwyg h5 strong,
.wysiwyg h6 b,
.wysiwyg h6 strong {
  font-weight: 500;
}
.wysiwyg h3 b i,
.wysiwyg h3 b em,
.wysiwyg h3 strong i,
.wysiwyg h3 strong em,
.wysiwyg h4 b i,
.wysiwyg h4 b em,
.wysiwyg h4 strong i,
.wysiwyg h4 strong em,
.wysiwyg h5 b i,
.wysiwyg h5 b em,
.wysiwyg h5 strong i,
.wysiwyg h5 strong em,
.wysiwyg h6 b i,
.wysiwyg h6 b em,
.wysiwyg h6 strong i,
.wysiwyg h6 strong em {
  font-weight: 500;
}
.wysiwyg h3 a,
.wysiwyg h4 a,
.wysiwyg h5 a,
.wysiwyg h6 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg h3 a:focus,
.wysiwyg h4 a:focus,
.wysiwyg h5 a:focus,
.wysiwyg h6 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg h3 a:hover,
.wysiwyg h4 a:hover,
.wysiwyg h5 a:hover,
.wysiwyg h6 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-bottom: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-bottom: 0.875rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-bottom: 0.875rem;
  }
}
.wysiwyg h5, .wysiwyg h6 {
  font-weight: 600;
}
.wysiwyg h1 {
  font-size: 3.819445111rem;
}
.wysiwyg h2 {
  font-size: 3.0547960555rem;
}
.wysiwyg h3 {
  font-size: 2.443228969rem;
}
.wysiwyg h4 {
  font-size: 1.9540969959rem;
}
.wysiwyg h5 {
  font-size: 1.56288875rem;
}
.wysiwyg h6 {
  font-size: 1.25rem;
}
.wysiwyg p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
}
.wysiwyg p i,
.wysiwyg p em {
  font-style: italic;
}
.wysiwyg p i b,
.wysiwyg p i strong,
.wysiwyg p em b,
.wysiwyg p em strong {
  font-style: italic;
}
.wysiwyg p b,
.wysiwyg p strong {
  font-weight: 500;
}
.wysiwyg p b i,
.wysiwyg p b em,
.wysiwyg p strong i,
.wysiwyg p strong em {
  font-weight: 500;
}
.wysiwyg p a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg p a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg p a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg p {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg p {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg p {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg p {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg p {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg p {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg p {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg p {
    font-size: 0.875rem;
  }
}
.wysiwyg .p-intro {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
}
.wysiwyg .p-intro i,
.wysiwyg .p-intro em {
  font-style: italic;
}
.wysiwyg .p-intro i b,
.wysiwyg .p-intro i strong,
.wysiwyg .p-intro em b,
.wysiwyg .p-intro em strong {
  font-style: italic;
}
.wysiwyg .p-intro b,
.wysiwyg .p-intro strong {
  font-weight: 500;
}
.wysiwyg .p-intro b i,
.wysiwyg .p-intro b em,
.wysiwyg .p-intro strong i,
.wysiwyg .p-intro strong em {
  font-weight: 500;
}
.wysiwyg .p-intro a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .p-intro a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .p-intro a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .p-intro {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .p-intro {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .p-intro {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .p-intro {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .p-intro {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .p-intro {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg .p-intro {
    font-size: 1.0625rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .p-intro {
    font-size: 1rem;
  }
}
.wysiwyg .p-smaller {
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
}
.wysiwyg .p-smaller i,
.wysiwyg .p-smaller em {
  font-style: italic;
}
.wysiwyg .p-smaller i b,
.wysiwyg .p-smaller i strong,
.wysiwyg .p-smaller em b,
.wysiwyg .p-smaller em strong {
  font-style: italic;
}
.wysiwyg .p-smaller b,
.wysiwyg .p-smaller strong {
  font-weight: 500;
}
.wysiwyg .p-smaller b i,
.wysiwyg .p-smaller b em,
.wysiwyg .p-smaller strong i,
.wysiwyg .p-smaller strong em {
  font-weight: 500;
}
.wysiwyg .p-smaller a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .p-smaller a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .p-smaller a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .p-smaller {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .p-smaller {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .p-smaller {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .p-smaller {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .p-smaller {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .p-smaller {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg .p-smaller {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .p-smaller {
    font-size: 0.75rem;
  }
}
.wysiwyg hr {
  display: inline-block;
  width: 100%;
  height: 0;
  border: none;
  border-top: 0.0625rem solid #828e90;
  /* Removing extra spacing above headlines if they follow a HR */
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg hr:not(.flush) {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg hr:not(.flush) {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg hr:not(.flush) {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg hr:not(.flush) {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg hr:not(.flush) {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg hr:not(.flush) {
    margin-bottom: 2.375rem;
  }
}
.wysiwyg hr + .h-huge, .wysiwyg hr + .h-normal, .wysiwyg hr + .h-small, .wysiwyg hr + .h-tiny, .wysiwyg hr + .h-very_tiny, .wysiwyg hr + h1, .wysiwyg hr + h2, .wysiwyg hr + h3, .wysiwyg hr + h4, .wysiwyg hr + h5, .wysiwyg hr + h6, .wysiwyg hr + p {
  margin-top: 0;
}
.wysiwyg .hr-small {
  display: inline-block;
  width: 5.625rem;
  height: 0;
  border: none;
  border-top: 0.0625rem solid #c3c9ca;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .hr-small:not(.flush) {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .hr-small:not(.flush) {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .hr-small:not(.flush) {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .hr-small:not(.flush) {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .hr-small:not(.flush) {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .hr-small:not(.flush) {
    margin-bottom: 2.375rem;
  }
}
.wysiwyg .hr-medium {
  display: inline-block;
  width: 11.25rem;
  height: 0;
  border: none;
  border-top: 0.0625rem solid #c3c9ca;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .hr-medium:not(.flush) {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .hr-medium:not(.flush) {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .hr-medium:not(.flush) {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .hr-medium:not(.flush) {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .hr-medium:not(.flush) {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .hr-medium:not(.flush) {
    margin-bottom: 2.375rem;
  }
}
.wysiwyg .hr-centered {
  display: inline-block;
  width: 11.25rem;
  height: 0;
  border: none;
  border-top: 0.0625rem solid #c3c9ca;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .hr-centered:not(.flush) {
    margin-top: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .hr-centered:not(.flush) {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .hr-centered:not(.flush) {
    margin-top: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .hr-centered:not(.flush) {
    margin-bottom: 2.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .hr-centered:not(.flush) {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .hr-centered:not(.flush) {
    margin-bottom: 2.375rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-huge + .hr-small,
.wysiwyg .h-huge + .hr-medium {
    margin-top: -1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-huge + .hr-small,
.wysiwyg .h-huge + .hr-medium {
    margin-top: -rem(22);
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-huge + .hr-small,
.wysiwyg .h-huge + .hr-medium {
    margin-top: -rem(20);
  }
}
.wysiwyg .h-medium + .hr-small,
.wysiwyg .h-medium + .hr-medium {
  margin-top: 0;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg .h-small + .hr-small,
.wysiwyg .h-tiny + .hr-small,
.wysiwyg .h-very_tiny + .hr-small,
.wysiwyg .h-small + .hr-medium,
.wysiwyg .h-tiny + .hr-medium,
.wysiwyg .h-very_tiny + .hr-medium {
    margin-top: 0.875rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg .h-small + .hr-small,
.wysiwyg .h-tiny + .hr-small,
.wysiwyg .h-very_tiny + .hr-small,
.wysiwyg .h-small + .hr-medium,
.wysiwyg .h-tiny + .hr-medium,
.wysiwyg .h-very_tiny + .hr-medium {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg .h-small + .hr-small,
.wysiwyg .h-tiny + .hr-small,
.wysiwyg .h-very_tiny + .hr-small,
.wysiwyg .h-small + .hr-medium,
.wysiwyg .h-tiny + .hr-medium,
.wysiwyg .h-very_tiny + .hr-medium {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ul {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ul {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ul {
    margin-bottom: 0.625rem;
  }
}
.wysiwyg ul li {
  position: relative;
  margin: inherit 0;
  padding-left: 1em;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  list-style-type: none !important;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ul li {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ul li {
    margin-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ul li {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ul li {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ul li {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ul li {
    margin-bottom: 0.625rem;
  }
}
.wysiwyg ul li i,
.wysiwyg ul li em {
  font-style: italic;
}
.wysiwyg ul li i b,
.wysiwyg ul li i strong,
.wysiwyg ul li em b,
.wysiwyg ul li em strong {
  font-style: italic;
}
.wysiwyg ul li b,
.wysiwyg ul li strong {
  font-weight: 500;
}
.wysiwyg ul li b i,
.wysiwyg ul li b em,
.wysiwyg ul li strong i,
.wysiwyg ul li strong em {
  font-weight: 500;
}
.wysiwyg ul li a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg ul li a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg ul li a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg ul li {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg ul li {
    font-size: 0.875rem;
  }
}
.wysiwyg ul li:before {
  content: "•";
  position: absolute;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  top: 0;
  left: 0;
  font-size: 1.6em;
  line-height: 1em;
  width: 0.5em;
  color: #ff579e;
}
.wysiwyg ul ul,
.wysiwyg ul ol {
  margin-left: 0.5em;
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ul ul,
.wysiwyg ul ol {
    margin-left: 0;
  }
}
.wysiwyg ul ol li {
  padding-left: 0;
}
.wysiwyg ol {
  padding-left: 2em;
  counter-reset: li;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ol {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ol {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol {
    margin-bottom: 0.625rem;
  }
}
.wysiwyg ol > li {
  counter-increment: li;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  list-style-type: none !important;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ol > li {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ol > li {
    margin-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol > li {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ol > li {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ol > li {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol > li {
    margin-bottom: 0.625rem;
  }
}
.wysiwyg ol > li i,
.wysiwyg ol > li em {
  font-style: italic;
}
.wysiwyg ol > li i b,
.wysiwyg ol > li i strong,
.wysiwyg ol > li em b,
.wysiwyg ol > li em strong {
  font-style: italic;
}
.wysiwyg ol > li b,
.wysiwyg ol > li strong {
  font-weight: 500;
}
.wysiwyg ol > li b i,
.wysiwyg ol > li b em,
.wysiwyg ol > li strong i,
.wysiwyg ol > li strong em {
  font-weight: 500;
}
.wysiwyg ol > li a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg ol > li a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg ol > li a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg ol > li {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg ol > li {
    font-size: 0.875rem;
  }
}
.wysiwyg ol > li:before {
  content: counter(li) ".";
  position: absolute;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  top: 0;
  left: auto;
  right: 100%;
  padding-right: 0.6em;
  font-size: 1em;
  line-height: inherit;
  min-width: 2em;
  color: #ff579e;
  text-align: right;
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol {
    padding-left: 0;
  }
  .wysiwyg ol > li {
    padding-left: 0;
  }
  .wysiwyg ol > li:before {
    position: relative;
    top: 0;
    right: auto;
    left: auto;
  }
}
.wysiwyg ol ol {
  padding-left: 2em;
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol ol {
    margin-left: 0;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ol ul {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ol ul {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol ul {
    margin-bottom: 0.625rem;
  }
}
.wysiwyg ol ul li {
  position: relative;
  margin: inherit 0;
  padding-left: 1em;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.75em;
  list-style-type: none !important;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ol ul li {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ol ul li {
    margin-top: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol ul li {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg ol ul li {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg ol ul li {
    margin-bottom: 0.625rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol ul li {
    margin-bottom: 0.625rem;
  }
}
.wysiwyg ol ul li i,
.wysiwyg ol ul li em {
  font-style: italic;
}
.wysiwyg ol ul li i b,
.wysiwyg ol ul li i strong,
.wysiwyg ol ul li em b,
.wysiwyg ol ul li em strong {
  font-style: italic;
}
.wysiwyg ol ul li b,
.wysiwyg ol ul li strong {
  font-weight: 500;
}
.wysiwyg ol ul li b i,
.wysiwyg ol ul li b em,
.wysiwyg ol ul li strong i,
.wysiwyg ol ul li strong em {
  font-weight: 500;
}
.wysiwyg ol ul li a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg ol ul li a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg ol ul li a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg ol ul li {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg ol ul li {
    font-size: 0.875rem;
  }
}
.wysiwyg ol ul li:before {
  content: "•";
  position: absolute;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  top: 0;
  left: 0;
  font-size: 1.6em;
  line-height: 1em;
  width: 0.5em;
  color: #ff579e;
}
.wysiwyg ol ul ul,
.wysiwyg ol ul ol {
  margin-left: 0.5em;
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg ol ul ul,
.wysiwyg ol ul ol {
    margin-left: 0;
  }
}
.wysiwyg ol ul ol li {
  padding-left: 0;
}
.wysiwyg .h-ruled {
  display: inline-block;
  position: relative;
  max-width: 100%;
  text-align: center;
  padding: 0.625em 2.625rem;
}
.wysiwyg .h-ruled:before, .wysiwyg .h-ruled:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background: #c3c9ca;
}
.wysiwyg .h-ruled:before {
  top: 0;
}
.wysiwyg .h-ruled:after {
  bottom: 0;
}
.wysiwyg .h-decorative .h-ruled {
  padding-top: 0.625em;
  padding-bottom: 0.25em;
}
.wysiwyg .h-decorative .h-ruled:before, .wysiwyg .h-decorative .h-ruled:after {
  height: 2px;
  border-radius: 2px;
}
.wysiwyg .h-huge .h-ruled:before, .wysiwyg .h-huge .h-ruled:after,
.wysiwyg .h-medium .h-ruled:before,
.wysiwyg .h-medium .h-ruled:after {
  height: 2px;
  border-radius: 2px;
}
.wysiwyg .h-huge .h-ruled,
.wysiwyg .h-large .h-ruled,
.wysiwyg .h-medium .h-ruled,
.wysiwyg .h-small .h-ruled,
.wysiwyg .h-very_small .h-ruled {
  padding-top: 0.3125em;
  padding-bottom: 0.3125em;
}
.wysiwyg .primary_color .h-ruled:before, .wysiwyg .primary_color .h-ruled:after {
  background: rgba(46, 64, 138, 0.4);
}
.wysiwyg blockquote p {
  font-family: "Poppins", sans-serif;
  font-size: 1.375rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.42em;
}
.wysiwyg blockquote p i,
.wysiwyg blockquote p em {
  font-style: italic;
}
.wysiwyg blockquote p i b,
.wysiwyg blockquote p i strong,
.wysiwyg blockquote p em b,
.wysiwyg blockquote p em strong {
  font-style: italic;
}
.wysiwyg blockquote p b,
.wysiwyg blockquote p strong {
  font-weight: 500;
}
.wysiwyg blockquote p b i,
.wysiwyg blockquote p b em,
.wysiwyg blockquote p strong i,
.wysiwyg blockquote p strong em {
  font-weight: 500;
}
.wysiwyg blockquote p a {
  transition: color 200ms;
  color: #317aae;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(28, 71, 101, 0.4) 1.4em, rgba(28, 71, 101, 0.4) 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg blockquote p a:focus {
  color: #1c4765;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg blockquote p a:hover {
    color: #1c4765;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #1c4765 1.4em, #1c4765 1.4555555556em, rgba(0, 0, 0, 0) 1.4555555556em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg blockquote p {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg blockquote p {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg blockquote p {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg blockquote p {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg blockquote p {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg blockquote p {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg blockquote p {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg blockquote p {
    font-size: 1.0625rem;
  }
}
.wysiwyg blockquote p:first-child {
  margin-top: 0;
}
.wysiwyg blockquote p:first-child:before {
  content: "“";
  color: rgba(0, 22, 35, 0.75);
}
.wysiwyg blockquote p:only-of-type {
  margin-top: 0;
  margin-bottom: 0;
}
.wysiwyg blockquote p:only-of-type:before {
  content: "“";
  color: rgba(0, 22, 35, 0.75);
}
.wysiwyg blockquote p:only-of-type:after {
  content: "”";
  color: rgba(0, 22, 35, 0.75);
}
.wysiwyg blockquote p:last-of-type {
  margin-bottom: 0;
}
.wysiwyg blockquote p:last-of-type:after {
  content: "”";
  color: rgba(0, 22, 35, 0.75);
}
.wysiwyg blockquote cite {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 300;
  color: #001623;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  opacity: 0.75;
}
@media screen and (min-width: 68.8125rem) {
  .wysiwyg blockquote cite {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .wysiwyg blockquote cite {
    margin-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .wysiwyg blockquote cite {
    margin-top: 1.25rem;
  }
}
.wysiwyg blockquote cite i,
.wysiwyg blockquote cite em {
  font-style: italic;
}
.wysiwyg blockquote cite i b,
.wysiwyg blockquote cite i strong,
.wysiwyg blockquote cite em b,
.wysiwyg blockquote cite em strong {
  font-style: italic;
}
.wysiwyg blockquote cite b,
.wysiwyg blockquote cite strong {
  font-weight: 500;
}
.wysiwyg blockquote cite b i,
.wysiwyg blockquote cite b em,
.wysiwyg blockquote cite strong i,
.wysiwyg blockquote cite strong em {
  font-weight: 500;
}
.wysiwyg blockquote cite a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  /*  Hover & Active States ---- */
}
.wysiwyg blockquote cite a:focus {
  color: #2e408a;
}
@media (hover: hover) {
  .wysiwyg blockquote cite a:hover {
    color: #2e408a;
  }
}
.wysiwyg blockquote cite:before {
  content: "- ";
  opacity: 0.75;
}
.wysiwyg .btn_primary {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: white;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.1875rem;
  background-color: #2e408a;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
}
.wysiwyg .btn_primary i,
.wysiwyg .btn_primary em {
  font-style: italic;
}
.wysiwyg .btn_primary i b,
.wysiwyg .btn_primary i strong,
.wysiwyg .btn_primary em b,
.wysiwyg .btn_primary em strong {
  font-style: italic;
}
.wysiwyg .btn_primary b,
.wysiwyg .btn_primary strong {
  font-weight: 600;
}
.wysiwyg .btn_primary b i,
.wysiwyg .btn_primary b em,
.wysiwyg .btn_primary strong i,
.wysiwyg .btn_primary strong em {
  font-weight: 600;
}
.wysiwyg .btn_primary a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .btn_primary a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .btn_primary a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.wysiwyg .btn_primary:focus {
  color: white;
  background-image: none;
  text-decoration: none;
  background-color: #ff579e;
  border-color: transparent;
}
@media (hover: hover) {
  .wysiwyg .btn_primary:hover {
    color: white;
    background-image: none;
    text-decoration: none;
    background-color: #ff579e;
    border-color: transparent;
  }
}
.wysiwyg .btn_primary.inactive, .wysiwyg .btn_primary:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.wysiwyg .btn_primary.inactive:focus, .wysiwyg .btn_primary:disabled:focus {
  color: white;
  border-color: transparent;
  background-color: #2e408a;
}
@media (hover: hover) {
  .wysiwyg .btn_primary.inactive:hover, .wysiwyg .btn_primary:disabled:hover {
    color: white;
    border-color: transparent;
    background-color: #2e408a;
  }
}
.wysiwyg .btn_secondary {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #2e408a;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #2e408a;
  border-radius: 0.1875rem;
  background-color: transparent;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
}
.wysiwyg .btn_secondary i,
.wysiwyg .btn_secondary em {
  font-style: italic;
}
.wysiwyg .btn_secondary i b,
.wysiwyg .btn_secondary i strong,
.wysiwyg .btn_secondary em b,
.wysiwyg .btn_secondary em strong {
  font-style: italic;
}
.wysiwyg .btn_secondary b,
.wysiwyg .btn_secondary strong {
  font-weight: 600;
}
.wysiwyg .btn_secondary b i,
.wysiwyg .btn_secondary b em,
.wysiwyg .btn_secondary strong i,
.wysiwyg .btn_secondary strong em {
  font-weight: 600;
}
.wysiwyg .btn_secondary a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .btn_secondary a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .btn_secondary a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.wysiwyg .btn_secondary:focus {
  color: #fff;
  background-image: none;
  text-decoration: none;
  background-color: #2e408a;
  border-color: #2e408a;
}
@media (hover: hover) {
  .wysiwyg .btn_secondary:hover {
    color: #fff;
    background-image: none;
    text-decoration: none;
    background-color: #2e408a;
    border-color: #2e408a;
  }
}
.wysiwyg .btn_secondary.inactive, .wysiwyg .btn_secondary:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.wysiwyg .btn_secondary.inactive:focus, .wysiwyg .btn_secondary:disabled:focus {
  color: #2e408a;
  border-color: #2e408a;
  background-color: transparent;
}
@media (hover: hover) {
  .wysiwyg .btn_secondary.inactive:hover, .wysiwyg .btn_secondary:disabled:hover {
    color: #2e408a;
    border-color: #2e408a;
    background-color: transparent;
  }
}
.wysiwyg .btn_link {
  font-family: "Poppins", sans-serif;
  font-size: 1.25em;
  font-weight: 500;
  color: #2e408a;
  line-height: 1.1em;
  display: inline-block;
  transition: color 200ms, background-color 200ms;
  padding-right: 1.25em;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  padding-right: 1em;
  font-weight: 600;
}
.wysiwyg .btn_link i,
.wysiwyg .btn_link em {
  font-style: italic;
}
.wysiwyg .btn_link i b,
.wysiwyg .btn_link i strong,
.wysiwyg .btn_link em b,
.wysiwyg .btn_link em strong {
  font-style: italic;
}
.wysiwyg .btn_link b,
.wysiwyg .btn_link strong {
  font-weight: 600;
}
.wysiwyg .btn_link b i,
.wysiwyg .btn_link b em,
.wysiwyg .btn_link strong i,
.wysiwyg .btn_link strong em {
  font-weight: 600;
}
.wysiwyg .btn_link a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.wysiwyg .btn_link a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .wysiwyg .btn_link a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.wysiwyg .btn_link:after {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  margin: -0.2em -1.25em 0 0.25em;
  background-image: url("/assets/images/theme/link-arrow-left.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: opacity 200ms;
}
@media screen and (min-width: 34.0625rem) and (max-width: 46.875rem) {
  .wysiwyg .btn_link {
    font-size: 1.1875em;
  }
}
@media screen and (max-width: 34rem) {
  .wysiwyg .btn_link {
    font-size: 1.125em;
  }
}
.wysiwyg .btn_link:focus {
  color: #ff579e;
}
.wysiwyg .btn_link:focus:after {
  opacity: 0.6;
}
@media (hover: hover) {
  .wysiwyg .btn_link:hover {
    color: #ff579e;
  }
  .wysiwyg .btn_link:hover:after {
    opacity: 0.6;
  }
}
.wysiwyg .btn_link:after {
  height: 0.8em;
  width: 1em;
  background-size: 0.8em 0.8em;
  background-position: right center;
  margin-right: -1em;
}

/* ----------------------------------
		Night Mode Color Shift
---------------------------------- */
[data-night-mode=true] .wysiwyg {
  color: white;
}
[data-night-mode=true] .wysiwyg .h-huge,
[data-night-mode=true] .wysiwyg .h-large,
[data-night-mode=true] .wysiwyg .h-medium,
[data-night-mode=true] .wysiwyg .h-normal,
[data-night-mode=true] .wysiwyg .h-small,
[data-night-mode=true] .wysiwyg .h-very_small {
  color: rgba(255, 255, 255, 0.6);
}
[data-night-mode=true] .wysiwyg .h-huge b,
[data-night-mode=true] .wysiwyg .h-huge strong,
[data-night-mode=true] .wysiwyg .h-large b,
[data-night-mode=true] .wysiwyg .h-large strong,
[data-night-mode=true] .wysiwyg .h-medium b,
[data-night-mode=true] .wysiwyg .h-medium strong,
[data-night-mode=true] .wysiwyg .h-normal b,
[data-night-mode=true] .wysiwyg .h-normal strong,
[data-night-mode=true] .wysiwyg .h-small b,
[data-night-mode=true] .wysiwyg .h-small strong,
[data-night-mode=true] .wysiwyg .h-very_small b,
[data-night-mode=true] .wysiwyg .h-very_small strong {
  color: white;
}
[data-night-mode=true] .wysiwyg h1,
[data-night-mode=true] .wysiwyg h2,
[data-night-mode=true] .wysiwyg h3,
[data-night-mode=true] .wysiwyg h4,
[data-night-mode=true] .wysiwyg h5,
[data-night-mode=true] .wysiwyg h6,
[data-night-mode=true] .wysiwyg p,
[data-night-mode=true] .wysiwyg ul,
[data-night-mode=true] .wysiwyg a,
[data-night-mode=true] .wysiwyg ol,
[data-night-mode=true] .wysiwyg li {
  color: white;
}
[data-night-mode=true] .wysiwyg blockquote p:before,
[data-night-mode=true] .wysiwyg blockquote p:after,
[data-night-mode=true] .wysiwyg blockquote cite {
  color: rgba(255, 255, 255, 0.75);
}

/* ----------------------------------
    Case Study Template
---------------------------------- */
/*  Global updates
---------------------------------- */
@media screen and (min-width: 62rem) {
  .case_study #main .bound {
    width: 70.3538461538%;
    max-width: 62.0769230769rem;
  }
}

/* ----------------------------------
    Footer
---------------------------------- */
#footer {
  position: relative;
  z-index: 1;
  color: white;
  background: #001623;
}
#footer .wrapper {
  margin: 0 auto;
  padding: 2.625rem 0;
  width: 85%;
  max-width: 75rem;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto auto;
  grid-column-gap: 3.75rem;
  grid-template-areas: "logo nav" "logo copywrite";
}
@media screen and (max-width: 46.875rem) {
  #footer .wrapper {
    padding: 4.6875rem 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: "nav" "logo" "copywrite";
    justify-items: center;
    grid-row-gap: 2.375rem;
  }
}
#footer .navigation {
  grid-area: nav;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875em;
  font-weight: 300;
}
@media screen and (max-width: 46.875rem) {
  #footer .navigation {
    flex-direction: column;
    justify-content: center;
  }
}
#footer .navigation .contact li {
  line-height: 1;
  padding-bottom: 0.2857142857em;
}
#footer .navigation .contact .tel,
#footer .navigation .contact .email {
  position: relative;
  display: inline-block;
  line-height: 1.7142857143em;
  overflow: hidden;
  white-space: nowrap;
  /*  Hover & Active States ---- */
}
#footer .navigation .contact .tel:before,
#footer .navigation .contact .email:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.0714285714em;
  height: 1.0714285714em;
  margin-top: -0.1428571429em;
  margin-right: 0.5714285714em;
  background-size: 100% 100%;
  opacity: 0.75;
}
#footer .navigation .contact .tel:after,
#footer .navigation .contact .email:after {
  content: "";
  position: absolute;
  left: 1.6428571429em;
  bottom: 0;
  width: 0;
  height: 0.0625rem;
  opacity: 0;
  background: #ff579e;
  transition: width 200ms ease-in-out, opacity 200ms ease-in-out;
}
#footer .navigation .contact .tel:focus:after,
#footer .navigation .contact .email:focus:after {
  width: 100%;
  opacity: 1;
}
@media (hover: hover) {
  #footer .navigation .contact .tel:hover:after,
#footer .navigation .contact .email:hover:after {
    width: 100%;
    opacity: 1;
  }
}
#footer .navigation .contact .tel:before {
  background-image: url("/assets/images/theme/icon-phone.svg");
}
#footer .navigation .contact .email:before {
  background-image: url("/assets/images/theme/icon-envelope.svg");
}
#footer .navigation .social {
  display: flex;
}
@media screen and (max-width: 46.875rem) {
  #footer .navigation .social {
    margin-top: 2.375rem;
  }
}
#footer .navigation .social a {
  margin: 0 0.625rem;
}
#footer .navigation .social .instagram {
  display: inline-block;
  text-indent: -100%;
  font: 0/0 Arial;
  overflow: hidden;
  display: inline-block;
  height: 1.5625rem;
  width: 1.5625rem;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
  border: none;
  outline: none;
  background: none;
  background-image: url("/assets/images/theme/icon_instagram-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /*  Hover & Active States ---- */
}
#footer .navigation .social .instagram:focus {
  opacity: 1;
}
@media (hover: hover) {
  #footer .navigation .social .instagram:hover {
    opacity: 1;
  }
}
#footer .navigation .social .facebook {
  display: inline-block;
  text-indent: -100%;
  font: 0/0 Arial;
  overflow: hidden;
  display: inline-block;
  height: 1.5625rem;
  width: 1.5625rem;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
  border: none;
  outline: none;
  background: none;
  background-image: url("/assets/images/theme/icon_facebook-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /*  Hover & Active States ---- */
}
#footer .navigation .social .facebook:focus {
  opacity: 1;
}
@media (hover: hover) {
  #footer .navigation .social .facebook:hover {
    opacity: 1;
  }
}
#footer .navigation .social .twitter {
  display: inline-block;
  text-indent: -100%;
  font: 0/0 Arial;
  overflow: hidden;
  display: inline-block;
  height: 1.5625rem;
  width: 1.5625rem;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
  border: none;
  outline: none;
  background: none;
  background-image: url("/assets/images/theme/icon_twitter-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /*  Hover & Active States ---- */
}
#footer .navigation .social .twitter:focus {
  opacity: 1;
}
@media (hover: hover) {
  #footer .navigation .social .twitter:hover {
    opacity: 1;
  }
}
#footer .navigation .social .linkedin {
  display: inline-block;
  text-indent: -100%;
  font: 0/0 Arial;
  overflow: hidden;
  display: inline-block;
  height: 1.5625rem;
  width: 1.5625rem;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
  border: none;
  outline: none;
  background: none;
  background-image: url("/assets/images/theme/icon_linkedin-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /*  Hover & Active States ---- */
}
#footer .navigation .social .linkedin:focus {
  opacity: 1;
}
@media (hover: hover) {
  #footer .navigation .social .linkedin:hover {
    opacity: 1;
  }
}
#footer .navigation .social .github {
  display: inline-block;
  text-indent: -100%;
  font: 0/0 Arial;
  overflow: hidden;
  display: inline-block;
  height: 1.5625rem;
  width: 1.5625rem;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
  border: none;
  outline: none;
  background: none;
  background-image: url("/assets/images/theme/icon_github-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /*  Hover & Active States ---- */
}
#footer .navigation .social .github:focus {
  opacity: 1;
}
@media (hover: hover) {
  #footer .navigation .social .github:hover {
    opacity: 1;
  }
}
#footer .logo {
  grid-area: logo;
  width: 6.25rem;
}
#footer .copywrite {
  grid-area: copywrite;
  font-size: 0.75em;
  font-weight: 300;
  opacity: 0.5;
}
#footer .copywrite .ver {
  display: none;
}

/* ----------------------------------
    Header
---------------------------------- */
.site_header {
  position: relative;
  z-index: 2;
  /*  Logo(s)
  ---------------------------------- */
  /*  Hidden Keyboard/Accessibility Nav
  ---------------------------------- */
  /*  Main Site Nav
  ---------------------------------- */
  /*  When on Desktop Size with Background Bubble ---- */
  /*  Responsive Behavior/Layout
  ---------------------------------- */
  /*  Desktop (default) ---- */
  /*  Mobile ---- */
  /*  Menu Open
  ---------------------------------- */
}
.site_header .wrapper {
  margin: 0 auto;
  width: 85%;
  max-width: 75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 68.8125rem) {
  .site_header .wrapper {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_header .wrapper {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_header .wrapper {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .site_header .wrapper {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_header .wrapper {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_header .wrapper {
    padding-bottom: 1.25rem;
  }
}
.site_header .floaty_moby,
.site_header .circle_moby {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 61.9375rem) {
  .site_header .floaty_moby,
.site_header .circle_moby {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_header .floaty_moby,
.site_header .circle_moby {
    font-size: 0.7rem;
  }
}
.site_header .floaty_moby .moby,
.site_header .circle_moby .moby {
  width: 5.625em;
  margin-left: 1.5em;
}
.site_header .floaty_moby .icon {
  width: 5em;
}
@media screen and (max-width: 46.875rem) {
  .site_header .floaty_moby .icon {
    width: 4.375em;
  }
}
.site_header .floaty_moby .icon .floaty-whale {
  animation: hover ease-in-out alternate 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}
@keyframes hover {
  0% {
    transform: translate(0, -4px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.site_header .floaty_moby .icon .floaty-shadow {
  animation: scale ease-in-out alternate 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}
@keyframes scale {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(0.95) scaleY(0.95);
  }
}
.site_header .floaty_moby .moby {
  fill: white;
}
.site_header .circle_moby .icon {
  width: 4.6875em;
}
.site_header .circle_moby .moby {
  fill: #202B2D;
}
.site_header #accessibility_nav {
  position: absolute;
  z-index: 10;
  top: -100%;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  transition: top 200ms ease-in-out;
}
.site_header #accessibility_nav:after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 600%;
  transform: translateX(-50%);
  padding-top: 300%;
  background: #2e408a;
  bottom: -60%;
  border-bottom-left-radius: 100% 200%;
  border-bottom-right-radius: 100% 200%;
}
.site_header #accessibility_nav a {
  position: relative;
  z-index: 1;
  padding: 0.125em 0.5em;
  font-weight: 600;
  color: white;
  border-bottom: 0 solid transparent;
  padding-bottom: 0.125em;
  transition: all 200ms;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /*  Hover & Active States ---- */
}
.site_header #accessibility_nav a:focus {
  padding-bottom: 0;
  border-bottom: 0.125em solid white;
}
@media (hover: hover) {
  .site_header #accessibility_nav a:hover {
    padding-bottom: 0;
    border-bottom: 0.125em solid white;
  }
}
.site_header #accessibility_nav:focus-within {
  top: 10%;
}
.site_header #navigation {
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  font-size: 0.9375rem;
  /*  Buttons ---- */
}
.site_header #navigation a {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.site_header #navigation .txt {
  display: inline-block;
  padding: 0.25em;
  margin: 0;
  border-bottom: 0.1875em solid transparent;
  transition: all 200ms;
  white-space: nowrap;
  /*  Hover & Active States ---- */
}
.site_header #navigation .txt.active {
  padding-bottom: 0;
  margin-bottom: 0.25em;
  border-bottom-color: #ff579e;
}
.site_header #navigation .txt:focus {
  padding-bottom: 0;
  margin-bottom: 0.25em;
  border-bottom-color: #ff579e;
}
@media (hover: hover) {
  .site_header #navigation .txt:hover {
    padding-bottom: 0;
    margin-bottom: 0.25em;
    border-bottom-color: #ff579e;
  }
}
.site_header #navigation .solid {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: white;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.75em 1em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.1875rem;
  background-color: #2e408a;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
}
.site_header #navigation .solid i,
.site_header #navigation .solid em {
  font-style: italic;
}
.site_header #navigation .solid i b,
.site_header #navigation .solid i strong,
.site_header #navigation .solid em b,
.site_header #navigation .solid em strong {
  font-style: italic;
}
.site_header #navigation .solid b,
.site_header #navigation .solid strong {
  font-weight: 600;
}
.site_header #navigation .solid b i,
.site_header #navigation .solid b em,
.site_header #navigation .solid strong i,
.site_header #navigation .solid strong em {
  font-weight: 600;
}
.site_header #navigation .solid a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.site_header #navigation .solid a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .site_header #navigation .solid a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.site_header #navigation .solid:focus {
  color: white;
  background-image: none;
  text-decoration: none;
  background-color: #ff579e;
  border-color: transparent;
}
@media (hover: hover) {
  .site_header #navigation .solid:hover {
    color: white;
    background-image: none;
    text-decoration: none;
    background-color: #ff579e;
    border-color: transparent;
  }
}
.site_header #navigation .solid.inactive, .site_header #navigation .solid:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.site_header #navigation .solid.inactive:focus, .site_header #navigation .solid:disabled:focus {
  color: white;
  border-color: transparent;
  background-color: #2e408a;
}
@media (hover: hover) {
  .site_header #navigation .solid.inactive:hover, .site_header #navigation .solid:disabled:hover {
    color: white;
    border-color: transparent;
    background-color: #2e408a;
  }
}
.site_header #navigation .outlined {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #001623;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.75em 1em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #001623;
  border-radius: 0.1875rem;
  background-color: transparent;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
}
.site_header #navigation .outlined i,
.site_header #navigation .outlined em {
  font-style: italic;
}
.site_header #navigation .outlined i b,
.site_header #navigation .outlined i strong,
.site_header #navigation .outlined em b,
.site_header #navigation .outlined em strong {
  font-style: italic;
}
.site_header #navigation .outlined b,
.site_header #navigation .outlined strong {
  font-weight: 600;
}
.site_header #navigation .outlined b i,
.site_header #navigation .outlined b em,
.site_header #navigation .outlined strong i,
.site_header #navigation .outlined strong em {
  font-weight: 600;
}
.site_header #navigation .outlined a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.site_header #navigation .outlined a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .site_header #navigation .outlined a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.site_header #navigation .outlined:focus {
  color: #fff;
  background-image: none;
  text-decoration: none;
  background-color: #ff579e;
  border-color: transparent;
}
@media (hover: hover) {
  .site_header #navigation .outlined:hover {
    color: #fff;
    background-image: none;
    text-decoration: none;
    background-color: #ff579e;
    border-color: transparent;
  }
}
.site_header #navigation .outlined.inactive, .site_header #navigation .outlined:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.site_header #navigation .outlined.inactive:focus, .site_header #navigation .outlined:disabled:focus {
  color: #001623;
  border-color: #001623;
  background-color: transparent;
}
@media (hover: hover) {
  .site_header #navigation .outlined.inactive:hover, .site_header #navigation .outlined:disabled:hover {
    color: #001623;
    border-color: #001623;
    background-color: transparent;
  }
}
.site_header.has_bkgrd #navigation a {
  color: white;
}
.site_header.has_bkgrd #navigation a.outlined {
  border-color: #fff;
  /*  Hover & Active States ---- */
}
.site_header.has_bkgrd #navigation a.outlined:focus {
  border-color: transparent;
}
@media (hover: hover) {
  .site_header.has_bkgrd #navigation a.outlined:hover {
    border-color: transparent;
  }
}
.site_header #navigation ul {
  position: relative;
  display: flex;
  align-items: baseline;
  z-index: 1;
}
.site_header #navigation ul li {
  margin: 0 0.9375em;
}
.site_header #btn-nav_toggle {
  display: none;
}
@media screen and (max-width: 61.9375rem) {
  .site_header {
    display: flex;
    /*  When on Desktop Size with Background Bubble ---- */
  }
  .site_header .wrapper {
    position: relative;
    z-index: 1;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .site_header .circle_moby .moby {
    transition: fill 300ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .site_header #navigation {
    flex-grow: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 25rem;
    padding: 5rem 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    font-size: 1.5rem;
    transition: opacity 300ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .site_header #navigation:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 0);
    background-color: #001623;
  }
  .site_header #navigation ul {
    position: relative;
    z-index: 1;
    width: 85%;
    margin: 0 auto;
    flex-direction: column;
    transform: scale(1.042, 1.042);
    transition: transform 600ms cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  .site_header #navigation ul li {
    margin: 0.84em 0;
  }
  .site_header #navigation a {
    color: white;
  }
  .site_header #navigation a.outlined {
    color: white;
    border-color: #fff;
    /*  Hover & Active States ---- */
  }
  .site_header #navigation a.outlined:focus {
    border-color: transparent;
  }
}
@media screen and (max-width: 61.9375rem) and (hover: hover) {
  .site_header #navigation a.outlined:hover {
    border-color: transparent;
  }
}
@media screen and (max-width: 61.9375rem) {
  .site_header #btn-nav_toggle {
    display: block;
    position: absolute;
    z-index: 4;
    top: 1.4375rem;
    right: -0.9375rem;
    padding: 1.25rem 0.625rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    /*  Hover & Active States ---- */
  }
}
@media screen and (max-width: 61.9375rem) and (max-width: 46.875rem) {
  .site_header #btn-nav_toggle {
    top: 0.875rem;
  }
}
@media screen and (max-width: 61.9375rem) {
  .site_header #btn-nav_toggle:focus .icon span,
.site_header #btn-nav_toggle:focus .icon:before,
.site_header #btn-nav_toggle:focus .icon:after {
    background-color: #ff579e;
  }
}
@media screen and (max-width: 61.9375rem) and (hover: hover) {
  .site_header #btn-nav_toggle:hover .icon span,
.site_header #btn-nav_toggle:hover .icon:before,
.site_header #btn-nav_toggle:hover .icon:after {
    background-color: #ff579e;
  }
}
@media screen and (max-width: 61.9375rem) {
  .site_header #btn-nav_toggle:focus {
    box-shadow: 0 0 0.1875rem 0.125rem #ff579e;
  }
}
@media screen and (max-width: 61.9375rem) {
  .site_header #btn-nav_toggle i {
    display: inline-block;
    text-indent: -100%;
    font: 0/0 Arial;
    overflow: hidden;
  }
}
@media screen and (max-width: 61.9375rem) {
  .site_header #btn-nav_toggle .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.625rem;
    height: 0.125rem;
    transform: translateX(-50%) translateY(-50%);
  }
  .site_header #btn-nav_toggle .icon span {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #001623;
    transition: all 200ms cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  .site_header #btn-nav_toggle .icon:before, .site_header #btn-nav_toggle .icon:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #001623;
    transition: all 200ms cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  .site_header #btn-nav_toggle .icon:before {
    top: -0.5rem;
  }
  .site_header #btn-nav_toggle .icon:after {
    bottom: -0.5rem;
  }
}
@media screen and (max-width: 61.9375rem) {
  .site_header #btn-nav_toggle.active {
    /*  Hover & Active States ---- */
  }
  .site_header #btn-nav_toggle.active .icon span {
    opacity: 0;
  }
  .site_header #btn-nav_toggle.active .icon:before {
    background-color: white;
    top: 0;
    transform: rotate(-135deg);
  }
  .site_header #btn-nav_toggle.active .icon:after {
    background-color: white;
    bottom: 0;
    transform: rotate(135deg);
  }
  .site_header #btn-nav_toggle.active:focus .icon span,
.site_header #btn-nav_toggle.active:focus .icon:before,
.site_header #btn-nav_toggle.active:focus .icon:after {
    background-color: #ff579e;
  }
}
@media screen and (max-width: 61.9375rem) and (hover: hover) {
  .site_header #btn-nav_toggle.active:hover .icon span,
.site_header #btn-nav_toggle.active:hover .icon:before,
.site_header #btn-nav_toggle.active:hover .icon:after {
    background-color: #ff579e;
  }
}
@media screen and (max-width: 61.9375rem) {
  .site_header.has_bkgrd #btn-nav_toggle {
    /*  Hover & Active States ---- */
  }
  .site_header.has_bkgrd #btn-nav_toggle .icon span,
.site_header.has_bkgrd #btn-nav_toggle .icon:before,
.site_header.has_bkgrd #btn-nav_toggle .icon:after {
    background-color: white;
  }
  .site_header.has_bkgrd #btn-nav_toggle:focus .icon span,
.site_header.has_bkgrd #btn-nav_toggle:focus .icon:before,
.site_header.has_bkgrd #btn-nav_toggle:focus .icon:after {
    background-color: #ff579e;
  }
}
@media screen and (max-width: 61.9375rem) and (hover: hover) {
  .site_header.has_bkgrd #btn-nav_toggle:hover .icon span,
.site_header.has_bkgrd #btn-nav_toggle:hover .icon:before,
.site_header.has_bkgrd #btn-nav_toggle:hover .icon:after {
    background-color: #ff579e;
  }
}
@media screen and (max-width: 61.9375rem) {
  .menu_open .site_header .circle_moby .moby {
    fill: white;
  }
  .menu_open .site_header #navigation {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
  }
  .menu_open .site_header #navigation:after {
    opacity: 1;
    visibility: visible;
  }
  .menu_open .site_header #navigation ul {
    transform: scale(1, 1);
  }
}

.notification {
  position: fixed;
  left: 50%;
  bottom: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  padding: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  transform: translateX(-50%);
  background-color: #e53c45;
  border-radius: 6px;
  box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: bottom cubic-bezier(0.54, -0.38, 0.97, 1.06) 500ms;
}
.notification p {
  margin-right: 16px;
}
.notification button {
  height: 14px;
  width: 14px;
  background: no-repeat center/contain;
  background-image: url("/assets/images/theme/icon_dismiss_grey.svg");
  border-width: 0;
  transform: scale(1);
  transition: transform ease-in 250ms;
  /*  Hover & Active States ---- */
}
.notification button:focus {
  transform: scale(1.2);
  transition: transform ease-in 250ms;
  cursor: pointer;
}
@media (hover: hover) {
  .notification button:hover {
    transform: scale(1.2);
    transition: transform ease-in 250ms;
    cursor: pointer;
  }
}
.notification.visible {
  bottom: 3rem;
  transition: bottom cubic-bezier(0.46, 0.04, 0.14, 1.43) 500ms;
}

/* ----------------------------------
    Site Alert (Modal)
---------------------------------- */
.site_alert {
  text-align: center;
  max-width: 31.25rem;
}
@media screen and (min-width: 68.8125rem) {
  .site_alert {
    padding-top: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_alert {
    padding-top: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_alert {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .site_alert {
    padding-right: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_alert {
    padding-right: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_alert {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .site_alert {
    padding-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_alert {
    padding-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_alert {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 68.8125rem) {
  .site_alert {
    padding-left: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_alert {
    padding-left: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_alert {
    padding-left: 1.25rem;
  }
}
.site_alert h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #001623;
  line-height: 1.15em;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.site_alert h3 i,
.site_alert h3 em {
  font-style: italic;
}
.site_alert h3 i b,
.site_alert h3 i strong,
.site_alert h3 em b,
.site_alert h3 em strong {
  font-style: italic;
}
.site_alert h3 b,
.site_alert h3 strong {
  font-weight: 600;
}
.site_alert h3 b i,
.site_alert h3 b em,
.site_alert h3 strong i,
.site_alert h3 strong em {
  font-weight: 600;
}
.site_alert h3 a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.site_alert h3 a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .site_alert h3 a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.45em, rgba(0, 0, 0, 0) 1.45em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
@media screen and (min-width: 68.8125rem) {
  .site_alert h3 {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_alert h3 {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_alert h3 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 34rem) {
  .site_alert h3 {
    font-size: 1.125rem;
  }
}
.site_alert p {
  font-style: italic;
}
@media screen and (min-width: 68.8125rem) {
  .site_alert p {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 46.9375rem) and (max-width: 68.75rem) {
  .site_alert p {
    margin-bottom: 1.375rem;
  }
}
@media screen and (max-width: 46.875rem) {
  .site_alert p {
    margin-bottom: 1.25rem;
  }
}
.site_alert .actions > * {
  margin: 0 0.625rem;
}
.site_alert .actions .secondary {
  font-weight: 600;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.25em;
  font-weight: 500;
  color: #333;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.625em 0.875em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.1875rem;
  background-color: transparent;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
  padding: 0.8em;
  font-size: 1em;
}
.site_alert .actions .secondary i,
.site_alert .actions .secondary em {
  font-style: italic;
}
.site_alert .actions .secondary i b,
.site_alert .actions .secondary i strong,
.site_alert .actions .secondary em b,
.site_alert .actions .secondary em strong {
  font-style: italic;
}
.site_alert .actions .secondary b,
.site_alert .actions .secondary strong {
  font-weight: 600;
}
.site_alert .actions .secondary b i,
.site_alert .actions .secondary b em,
.site_alert .actions .secondary strong i,
.site_alert .actions .secondary strong em {
  font-weight: 600;
}
.site_alert .actions .secondary a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.site_alert .actions .secondary a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .site_alert .actions .secondary a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.site_alert .actions .secondary:focus {
  color: #2e408a;
  background-image: none;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
@media (hover: hover) {
  .site_alert .actions .secondary:hover {
    color: #2e408a;
    background-image: none;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
  }
}
.site_alert .actions .secondary.inactive, .site_alert .actions .secondary:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.site_alert .actions .secondary.inactive:focus, .site_alert .actions .secondary:disabled:focus {
  color: #333;
  border-color: transparent;
  background-color: transparent;
}
@media (hover: hover) {
  .site_alert .actions .secondary.inactive:hover, .site_alert .actions .secondary:disabled:hover {
    color: #333;
    border-color: transparent;
    background-color: transparent;
  }
}
.site_alert .actions .primary {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: white;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  padding: 0.375em 0.5em;
  text-transform: capitalize;
  border-width: 0.125rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.1875rem;
  background-color: #2e408a;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;
  /*  Focus & Hover ---- */
  /*  Hover & Active States ---- */
  /* 	Inactive State ---- */
}
.site_alert .actions .primary i,
.site_alert .actions .primary em {
  font-style: italic;
}
.site_alert .actions .primary i b,
.site_alert .actions .primary i strong,
.site_alert .actions .primary em b,
.site_alert .actions .primary em strong {
  font-style: italic;
}
.site_alert .actions .primary b,
.site_alert .actions .primary strong {
  font-weight: 600;
}
.site_alert .actions .primary b i,
.site_alert .actions .primary b em,
.site_alert .actions .primary strong i,
.site_alert .actions .primary strong em {
  font-weight: 600;
}
.site_alert .actions .primary a {
  transition: color 200ms;
  color: #001623;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, rgba(46, 64, 138, 0.4) 1.4em, rgba(46, 64, 138, 0.4) 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
  /*  Hover & Active States ---- */
}
.site_alert .actions .primary a:focus {
  color: #2e408a;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
@media (hover: hover) {
  .site_alert .actions .primary a:hover {
    color: #2e408a;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1.4em, #2e408a 1.4em, #2e408a 1.4625em, rgba(0, 0, 0, 0) 1.4625em);
    text-decoration: none;
    background-size: 2px 2em;
    background-repeat: repeat-x;
  }
}
.site_alert .actions .primary:focus {
  color: white;
  background-image: none;
  text-decoration: none;
  background-color: #ff579e;
  border-color: transparent;
}
@media (hover: hover) {
  .site_alert .actions .primary:hover {
    color: white;
    background-image: none;
    text-decoration: none;
    background-color: #ff579e;
    border-color: transparent;
  }
}
.site_alert .actions .primary.inactive, .site_alert .actions .primary:disabled {
  opacity: 0.4;
  cursor: default;
  user-select: none;
  /*  Hover & Active States ---- */
}
.site_alert .actions .primary.inactive:focus, .site_alert .actions .primary:disabled:focus {
  color: white;
  border-color: transparent;
  background-color: #2e408a;
}
@media (hover: hover) {
  .site_alert .actions .primary.inactive:hover, .site_alert .actions .primary:disabled:hover {
    color: white;
    border-color: transparent;
    background-color: #2e408a;
  }
}