/* ----------------------------------
    Spacer

    Yes, it's basically a responsive
    spacer gif, but whatcha gonna
    do? It's a CMS after all :)
---------------------------------- */

.sp {
  display: block;

  &[data-height="spacing-xxl"] {   @include responsive-spacing(padding, spacing-xxl null null); }
  &[data-height="spacing-xl"] {    @include responsive-spacing(padding, spacing-xl null null); }
  &[data-height="spacing-l"] {     @include responsive-spacing(padding, spacing-l null null); }
  &[data-height="spacing-m"] {     @include responsive-spacing(padding, spacing-m null null); }
  &[data-height="spacing-s"] {     @include responsive-spacing(padding, spacing-s null null); }
  &[data-height="spacing-xs"] {    @include responsive-spacing(padding, spacing-xs null null); }
  &[data-height="spacing-xxs"] {   @include responsive-spacing(padding, spacing-xxs null null); }
}
