/* ----------------------------------
		Billboard Base
---------------------------------- */


/* 	Shared Layout & Basics
---------------------------------- */

[data-block-type="billboard"] {
  position: relative;
  background-color: white;
  color: white;


  /* 	Content Bucket Layout ---- */

  .content-buckets {
    position: relative;
    z-index: 4;
  }

  /*  Logo Icon Sizing (same as "Small" image) ---- */

  .logo-icon {
    width: 80%;
    max-width: rem(325);

    img {
      width: 100%;
      height: auto;
    }
  }

  /* 	Foreground Icon Sizing ---- */

  .foreground-icon {
    width: rem(80);
    margin-bottom: 1rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  /* Foreground Image Justification ---- */

  .essay[data-justification="center"] {
    .foreground-icon,
    .logo-icon {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .essay[data-justification="right"] {
    .foreground-icon, .logo-icon        { float: right; }
    .foreground-icon + *, .logo-icon + *{ clear: both; }
  }
}




/* ----------------------------------
		Content Buckets
---------------------------------- */

.content-buckets {

  /* 	Basics ---- */
  @include outer-container;
  height: 100%;
  width: 100%;


  /*  Diptych Content Layout
  ---------------------------------- */

  .essay {

    .essay-content {
      @include responsive-spacing(padding, spacing-l null spacing-l null);
    }
  }


  /*  Image & Essay Sizing
  ---------------------------------- */

  &[data-horiz-split="none"] {
    .essay                              { width: 100%; }
    .art                                { width: 100%; }
    .foreground-video                   { width: 100%; }
  }

  &[data-horiz-split="halves"][data-presumption="image"] {

    .essay {
      align-self: center;
      @include media($vp-xl-up)         { @include span-columns(6); }
      @include media($vp-m-to-xl)       { @include span-columns(4.5); }
    }

    .art {
      @include media($vp-xl-up)         { @include span-columns(6); }
      @include media($vp-m-to-xl)       { @include span-columns(4.5); }
    }
  }

  &[data-horiz-split="essay-heavy"][data-presumption="image"] {

    .essay {
      align-self: center;
      @include media($vp-xl-up)         { @include span-columns(8); }
      @include media($vp-m-to-xl)       { @include span-columns(6); }
    }

    .art {
      @include media($vp-xl-up)         { @include span-columns(4); }
      @include media($vp-m-to-xl)       { @include span-columns(3); }
    }
  }

  &[data-horiz-split="essay-light"][data-presumption="image"] {

    .essay {
      align-self: center;
      @include media($vp-xl-up)         { @include span-columns(4); }
      @include media($vp-m-to-xl)       { @include span-columns(3); }
    }

    .art {
      @include media($vp-xl-up)         { @include span-columns(8); }
      @include media($vp-m-to-xl)       { @include span-columns(6); }
    }
  }

  /*  Vertically Center Essay Content on Desktop
  ---------------------------------- */

  @include media($vp-m-up) {
    &[data-axis="horizontal-reverse"],
    &[data-axis="horizontal-forward"] {
      .essay {
        display: flex;
        @include align-items(center);
        .essay-wrap {
          width: 100%;
          height: auto;
        }
      }
    }
  }


  /* 	Image & Essay Layout (Tablet Plus)
  ---------------------------------- */

  @include media($vp-m-up) {

    &[data-presumption="image"] {

      &[data-axis="horizontal-reverse"] {
        justify-content: space-between;

        .essay {
          order: 2;
          -ms-flex-order: 1;
          @include shift(0.3);
          @include omega;
        }

        .art {
          order: 1;
          -ms-flex-order: 0;
        }

        .art + .essay {
          margin-left: 0;
        }
      }


      /* Foreground Media Behaviors ---- */

      &[data-axis="horizontal-forward"],
      &[data-axis="horizontal-reverse"] {
        min-height: 100%;
        display: flex;
        @include align-items;

        .art-wrap {
          width: 100%;
          height: auto;
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }



  /*  Video & Essay Sizing & Layout
  ---------------------------------- */

  &[data-presumption="video"][data-horiz-split="halves"] {

    @include media($vp-m-up) {
      min-height: 100%;
      display: flex;
      @include align-items(center);

      &[data-axis="horizontal-forward"],
      &[data-axis="horizontal-reverse"] {

        .foreground-video-wrap {
          width: 100%;
          height: auto;
          @include responsive-spacing(padding, spacing-l null);
        }
      }
    }

    @include media($vp-m-down) {

      .foreground-video {
        @include responsive-spacing(padding, null null spacing-l null);
      }
    }

    &[data-axis="horizontal-forward"] {

      .essay {

        @include media($vp-xl-up) {
          @include span-columns(6);
        }

        @include media($vp-m-to-xl)  {
          width: 100%;
        }
      }
      .foreground-video {

        @include media($vp-xl-up) {
          @include span-columns(6);
          float: right;
          @include omega();
        }

        @include media($vp-m-to-xl)  {
          width: 100%;
        }
      }
    }

    &[data-axis="horizontal-reverse"] {

      @include media($vp-m-up) {
        justify-content: space-between;

        .essay {
          order: 2;
          @include shift(0.4);
          @include omega;
        }

        .foregraound-video { order: 1; }

        .foreground-video + .essay {
          margin-left: 0;
        }
      }

      .essay {

        @include media($vp-xl-up) {
          @include span-columns(6);
          @include omega();
          right: 0;
        }

        @include media($vp-m-to-xl)  {
          width: 100%;
        }
      }

      .foreground-video {
        position: relative;

        @include media($vp-xl-up) {
          @include span-columns(6);
        }

        @include media($vp-m-to-xl)  {
          width: 100%;
        }
      }
    }
  }




  /* 	CTAs
  ---------------------------------- */
  .essay-content {

    .cta-atom {
      @include responsive-spacing(margin, spacing-s null null);
    }
  }



  /* 	Essay Max Widths
  ---------------------------------- */

  .essay-content {
    margin: 0 auto;

    // These have to be pixel-based, not column-/percentage-based, because they apply to the children of floated parents, too.

    &[data-essay-max-width="small"]   { max-width: rem(442); }
    &[data-essay-max-width="medium"]  { max-width: rem(624); }
    &[data-essay-max-width="large"]   { max-width: rem(852); }
    &[data-essay-max-width="full"]    { max-width: 100%; }
  }


  /* 	Prosaic Billboards
  ---------------------------------- */

  &[data-presumption="prosaic"] {

    .essay[data-justification="center"][data-plate="off"] {
      .essay-content {
        margin: 0 auto;
      }
    }

    .essay[data-justification="right"][data-plate="off"] {
      .essay-content {
        float: right;
      }
    }
  }




  /* 	Essay Justification
  ---------------------------------- */
  /*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  TEXT JUSTIFICATION:

  Gets assigned on a per-element basis, from craft, ASSIGNED TO
  the actual prosaic element.

  Which means that it overrides both .essay and .wysiwyg
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */

  .essay[data-justification="left"]     { text-align: left; }
  .essay[data-justification="center"]   { text-align: center; }
  .essay[data-justification="right"]    { text-align: right; }

  .essay[data-justification="left"]:not([data-plate="on"]) .wysiwyg,
  .essay[data-justification="center"]:not([data-plate="on"]) .wysiwyg,
  .essay[data-justification="right"]:not([data-plate="on"]) .wysiwyg {
    text-align: left;
  }



  /* 	CTA Justification Overrides
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      (NOTE: This is a temporary patch
      that will be moot after CTAs
      are refactored to exhibit their
      own atomic L/C/R justification)
  ---------------------------------- */
  &[data-presumption="prosaic"] {
    .essay[data-justification="right"]  { text-align: left; }
  }






  /* 	Image Vertical Behavior
  ---------------------------------- */

  /*  Tablets & Desktop Only ---- */

  @include media($vp-m-up) {

    &[data-axis="vertical-ceiling"],
    &[data-axis="vertical-superior"],
    &[data-axis="vertical-inferior"],
    &[data-axis="vertical-ground"] {
      .essay          { margin: 0 auto; }
      .art-wrap       { margin: 0 auto; }
    }
  }

  /*  All Sizes ---- */

  &[data-axis="vertical-ceiling"] {
    .essay-content        { @include responsive-spacing(padding, spacing-l null spacing-xl); }
    .art,
    .foreground-video     { padding: 0; }
  }

  &[data-axis="vertical-superior"] {
    .essay-content        { @include responsive-spacing(padding, spacing-l null spacing-xl); }
    .art,
    .foreground-video     { @include responsive-spacing(padding, spacing-xl null null); }
  }

  &[data-axis="vertical-inferior"] {
    .essay-content        { @include responsive-spacing(padding, spacing-xl null spacing-l); }
    .art,
    .foreground-video     { @include responsive-spacing(padding, null null spacing-xl); }
  }

  &[data-axis="vertical-ground"] {
    .essay-content        { @include responsive-spacing(padding, spacing-xl null); }
    .art,
    .foreground-video     { padding: 0; }
  }


  /* 	Image Widths w/in Parent ("Fullness")
  ---------------------------------- */

  &[data-presumption="image"] {

    .art img {
      width: 100%;
      height: auto;
    }

    .art-wrap[data-fullness="full"]   { width: 100%; }
    .art-wrap[data-fullness="large"]  { width: 75%; }
    .art-wrap[data-fullness="medium"] { width: 50%; }
    .art-wrap[data-fullness="small"]  { width: 25%; }
    .art-wrap[data-fullness="icon"]   { width: 48px; }


    /* 	Mobile ---- */

    @include media($vp-m-down) {
      .art img                          { margin: 0 auto; }
      .art-wrap[data-fullness="large"]  { width: 100%; }
      .art-wrap[data-fullness="medium"] { width: 75%; }
      .art-wrap[data-fullness="small"]  { width: 50%; }
      .art-wrap[data-fullness="icon"]   { width: 48px; }
    }
  }


  /* 	Image Gravity w/in Parent
  ---------------------------------- */

  /*  Tablets & Desktop Only ---- */

  @include media($vp-m-up) {

    &[data-axis="horizontal-forward"],
    &[data-axis="horizontal-reverse"] {


      .art[data-gravity="ceiling"] {
        img { @include responsive-spacing(padding, null null spacing-l null); }
      }

      .art[data-gravity="ground"] {
        display: flex;
        @include align-items(flex-end);
         img { @include responsive-spacing(padding, spacing-l null null); }
      }

      .art[data-gravity="floaty"] {
        display: flex;
        @include align-items(center);
        img {
          @include responsive-spacing(padding, spacing-l null);
        }
      }
    }
  }

  /*  Mobile Only
  ---------------------------------- */

  @include media($vp-m-down)   {

    .art[data-gravity="floaty"] img {
      @include responsive-spacing(padding, null null spacing-l);
    }
  }


  /* 	Mobile (Lone, Block, Single,
      Vertical Column) Behavior
  ---------------------------------- */


  /*  Mobile Only ---- */


  @include media($vp-m-down)   {

    &[data-axis="horizontal-forward"] .essay-content,
    &[data-axis="horizontal-reverse"] .essay-content {
      @include responsive-spacing(padding, spacing-xl null spacing-l);
    }

    .essay + .foreground-video    { padding-top: 0; }
    .art-wrap                     { margin: 0 auto; }

  }


}//.content-buckets



/* ----------------------------------
    Content Frame & Plate
---------------------------------- */


[data-block-type="billboard"] {


  /* 	Frame Around Content
  ---------------------------------- */

  .essay[data-frame="on"] {
    padding: spacing(spacing-xl, desktop);

    @include media($vp-xl-down) {
      padding: spacing(spacing-l, desktop) 0;
    }

    @include media($vp-l-down) {
      padding: spacing(spacing-l, tablet) 0;
    }

    @include media($vp-m-down) {
      padding: spacing(spacing-s, mobile) 0;
    }

    .essay-content {
      display: table;
      min-height: rem(400);
      box-sizing: border-box;
      margin: 0 auto;
      border: solid rgba(0, 0, 0, 0.2);

      @include media($vp-l-up) {
        min-width: rem(400);
        border-width: rem(14);
        padding: spacing(spacing-m, desktop);
      }

      @include media($vp-l-down) {
        min-width: auto;
        width: 80%;
        border-width: rem(12);
        padding: spacing(spacing-s, tablet);
      }

      @include media($vp-m-down) {
        min-width: auto;
        width: 100%;
        border-width: rem(8);
        padding: spacing(spacing-s, mobile);
      }

      .essay-content-wrapper {
        display: table-cell;
        vertical-align: middle;
      }

      > .wysiwyg,
      > .cta-atom {
        width: 100%;

        // word-break: break-all;
      }

      &[data-essay-max-width="small"]   { max-width: rem(400); }
    }
  }

  &[data-night-mode="true"] .essay[data-frame="on"] .essay-content {
    border-color: rgba(255, 255, 255, 0.8);
  }


  /* 	Plate Under Content
  ---------------------------------- */

  .essay[data-plate="on"] {
    @include responsive-spacing(padding, spacing-m null);

    .essay-content {
      box-sizing: border-box;
      @include responsive-spacing(padding, spacing-m);
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &[data-night-mode="true"] .essay[data-plate="on"] .essay-content {
    background-color: rgba(0, 0, 0, 0.6);
  }
}


/* ----------------------------------
    Billboard with Snug Header
---------------------------------- */

@include media($vp-l-up) {

  .snug + .main [data-block-type="billboard"] .bound {
    padding-top: $spacing-m;
  }
}
