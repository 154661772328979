/* ----------------------------------
    Device Frames

    This adds a frame around an image or Video
    to match the chosen device.

    Note you can use this mixin as a
    single frame or just add it to a
    div to allow all frames to be chosen
    in the CMS.

    Note that the source needs to have
    the image or video within a .screen
    child element.
---------------------------------- */

/*  Shared styling
---------------------------------- */

@mixin device_base(
  $device-width,
  $device-height,
  $screen-width,
  $screen-height,
  $top-offset: null,
  $border-radius: 0
){
  $device-aspect: ($device-height / $device-width) * 100%;
  $screen-width-percent: ($screen-width / $device-width) * 100%;
  $screen-height-percent: ($screen-height / $device-height) * 100%;

  $corner-width: ($border-radius / $device-width) * 100%;
  $corner-height: ($border-radius / $device-height) * 100%;

  position: relative;
  float: none;
  z-index: 1;
  background: white;
  border-radius: #{$corner-width} / #{$corner-height};
  box-shadow: 0 0 rem(50) rem(10) rgba(0,0,0,0.10);

  &:before {
    content: '';
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding-top: $device-aspect;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .screen {
    position: absolute;
    z-index: 0;
    left: 50%;
    width: $screen-width-percent;
    height: $screen-height-percent;
    transform: translate(-50%, 0);
    overflow: hidden;

    @if $top-offset {
      top: ($top-offset / $device-height) * 100%;
    } @else {
      top: (100 - $screen-height-percent) / 2;
    }

    img,
    video {
      width: 100%;
    }
  }
}




/*  Individual Devices
---------------------------------- */

@mixin device-iphoneX(){

  @include device_base(
    $device-width: 306,
    $device-height: 628,
    $screen-width: 277,
    $screen-height: 599,
    $border-radius: 43
  );

  &:before {
    background-image: url('/assets/images/ui/device-iphone_x.svg');
  }
}

@mixin device-iphoneOld(){

  @include device_base(
    $device-width: 298,
    $device-height: 618,
    $screen-width: 269,
    $screen-height: 470,
    $border-radius: 43
  );

  &:before {
    background-image: url('/assets/images/ui/device-iphone_5.svg');
  }
}


@mixin device-androidPixel(){

  @include device_base(
    $device-width: 310,
    $device-height: 620,
    $screen-width: 289,
    $screen-height: 576,
    $top-offset: 32,
    $border-radius: 40
  );

  &:before {
    background-image: url('/assets/images/ui/device-android_pixel.svg');
  }
}


@mixin device-androidOld(){

  @include device_base(
    $device-width: 314,
    $device-height: 662,
    $screen-width: 282,
    $screen-height: 561,
    $top-offset: 47,
    $border-radius: 40
  );

  &:before {
    background-image: url('/assets/images/ui/device-android_old.svg');
  }
}

@mixin device-ipadPortrait(){

  @include device_base(
    $device-width: 792,
    $device-height: 1142,
    $screen-width: 736,
    $screen-height: 981,
    $border-radius: 45
  );

  &:before {
    background-image: url('/assets/images/ui/device-ipad-portrait.svg');
  }
}


@mixin device-ipadLandscape(){

  @include device_base(
    $device-width: 1142,
    $device-height: 792,
    $screen-width: 981,
    $screen-height: 736,
    $border-radius: 45
  );

  &:before {
    background-image: url('/assets/images/ui/device-ipad-landscape.svg');
  }
}


@mixin device-ipadProPortrait(){

  @include device_base(
    $device-width: 819,
    $device-height: 1144,
    $screen-width: 759,
    $screen-height: 1085,
    $border-radius: 43
  );

  &:before {
    background-image: url('/assets/images/ui/device-ipad-pro-portrait.svg');
  }
}


@mixin device-ipadProLandscape(){

  @include device_base(
    $device-width: 1144,
    $device-height: 819,
    $screen-width: 1085,
    $screen-height: 759,
    $border-radius: 43
  );

  &:before {
    background-image: url('/assets/images/ui/device-ipad-pro-landscape.svg');
  }
}

@mixin device-appleWatchSeries3(){

  @include device_base(
    $device-width: 630,
    $device-height: 999,
    $screen-width: 316,
    $screen-height: 394,
    $border-radius: 0
  );

  background: none;
  box-shadow: none;

  &:before {
    background-image: url('/assets/images/ui/device-apple_watch-series_3.svg');
  }

  .screen {
    background: black;
  }
}

@mixin device-browser(){

  position: relative;
  float: none;
  z-index: 1;
  border-radius: rem(5);
  background: white;
  box-shadow: 0 0 rem(50) rem(10) rgba(0,0,0,0.10);

  &:before {
    content: '';
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding-top: (40 / 915) * 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-image: url('/assets/images/ui/device-browser.svg');
  }

  .screen {
    overflow: hidden;
    border: rem(1) solid rgba(193,193,193,0.20);
    border-radius: 0 0 rem(3) rem(3);
    background: white;
  }
}




/*  All Devices at once that's hooked up to pull `deviceFrame` from the CMS.
---------------------------------- */

@mixin device-frames(){

  &.iphoneX {           @include device-iphoneX; }
  &.iphoneOld {         @include device-iphoneOld; }
  &.androidPixel {      @include device-androidPixel; }
  &.androidOld {        @include device-androidOld; }
  &.ipadPortrait {      @include device-ipadPortrait; }
  &.ipadLandscape {     @include device-ipadLandscape; }
  &.ipadProPortrait {   @include device-ipadProPortrait; }
  &.ipadProLandscape {  @include device-ipadProLandscape; }
  &.appleWatchSeries3 { @include device-appleWatchSeries3; }

  &.browser {           @include device-browser; }
}
