/* ----------------------------------
    Related Case Studies
---------------------------------- */


[data-block-type="related_casestudies"] {
  @include responsive-spacing(padding, spacing-xl null);

  h3 {
    @include brand-font-medium();
    font-weight: $weight-book;
  }

  ul {
    width: 100%;

    @include media($vp-s-up) {
      @include row;
    }

    li {
      width: 100%;

      /* Tablet */
      @include media($vp-s-up) {
        @include span-columns(3 of 6);
      }

      /* Tablet */
      @include media($vp-m-up) {
        @include span-columns(4.5 of 9);
      }

      /* Desktop */
      @include media($vp-xl-up) {
        @include span-columns(5 of 10);
      }

      @include media($vp-s-down) {

        &:first-child {
          margin-bottom: $spacing-s;
        }
      }
    }

    a {
      position: relative;
      display: block;
      padding-top: 66.01941748%;

      &:after {
        content: '';
        position: absolute;
        top: rem(30);
        left: rem(10);
        bottom: rem(10);
        right: rem(10);
        z-index: 0;
        border-radius: 20%;
        opacity: 0;
        box-shadow: 0 rem(20) rem(42) 0 rgba(0, 0, 0, 0.6);
        background: white;
        transform: scale(0.75);
        transition: all $animation-easing $animation-timing;
      }
    }
  }

  h5 {
    position: absolute;
    z-index: 4;
    bottom: rem(20);
    left: rem(20);
    right: rem(20);
    @include brand-font-small(
      $color: white,
      $include_margin: false
    );
    font-weight: $weight-book;
    text-shadow: 0 rem(1) rem(5) rgba(0,0,0,0.24);

    @include media($vp-xl-down) {
      font-size: rem(22);
    }

    @include media($vp-l-down) {
      font-size: rem(20);
    }

    .arrow {
      display: inline-block;
      vertical-align: middle;
      width: 1.42em;
      margin-left: 0.24em;
      margin-top: -0.2em;
      opacity: 0;
      transition: opacity $animation-default;

      .arrow {
        fill: white;
        transform-origin: center;
        transition: all $animation-default;
        box-shadow: 0 rem(1) rem(5) rgba(0,0,0,0.24);
      }

      .circle {
        fill: white;
        transition: opacity $animation-default;
        box-shadow: 0 rem(1) rem(5) rgba(0,0,0,0.24);
      }
    }
  }

  .foreground {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img {
      width: 100%;
    }
  }

  .background {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    height: 94.11764706%;
    border-radius: rem(5);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .circle {
      @include circle_bkgrd(
        $self: true,
        $type: 'bottom',
        $bottom: 10%,
        $shifted: -65%,
        $width: 160%,
        $color: rgba(255,255,255,0.14)
      );
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 3;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(0, 0, 0, 0.24);
      transition: opacity $animation-easing $animation-timing;
    }
  }

  li:last-child .background .circle {
    transform: translateX(-35%);
  }

  /*  Hover States
  ---------------------------------- */

  a {

    @include hover(){

      &:after {
        opacity: 1;
        transform: scale(1);
      }

      .arrow {
        opacity: 1;

        .arrow {
          transform: translateX(-20%) scale(1.24);
        }

        .circle {
          opacity: 0.6;
        }
      }

      .background {

        &:after {
          opacity: 1;
        }
      }
    }
  }
}
