/* ----------------------------------
    Moby Circle Background
---------------------------------- */

@mixin circle_bkgrd(
  $self: false,
  $type: 'bottom',
  $shifted: -50%,
  $top: null,
  $bottom: null,
  $width: 400%,
  $color: $moby-purple
  ){

  @if ($self){
    position: absolute;
    z-index: 0;
    left: 50%;
    width: $width;
    transform: translateX($shifted);
    padding-top: $width/2;
    background: $color;

    @if ($top) {
      top: $top;
    }

    @if ($bottom) {
      bottom: $bottom;
    }

    @if ($type == 'bottom') {
      border-bottom-left-radius: 100% 200%;
      border-bottom-right-radius: 100% 200%;
    }

    @if ($type == 'top') {
      border-top-left-radius: 100% 200%;
      border-top-right-radius: 100% 200%;
    }
  } @else {

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      left: 50%;
      width: $width;
      transform: translateX($shifted);
      padding-top: $width/2;
      background: $color;

      @if ($top) {
        top: $top;
      }

      @if ($bottom) {
        bottom: $bottom;
      }

      @if ($type == 'bottom') {
        border-bottom-left-radius: 100% 200%;
        border-bottom-right-radius: 100% 200%;
      }

      @if ($type == 'top') {
        border-top-left-radius: 100% 200%;
        border-top-right-radius: 100% 200%;
      }
    }
  }
}
