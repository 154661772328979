/* ----------------------------------
    Hero for Case Studies
---------------------------------- */

[data-block-type="case_study_hero"] {

  display: flex;
  flex-direction: column-reverse;

  @include media($vp-m-up) {
    flex-direction: column;
  }

  .bound {
    @include media($vp-m-up) {
      @include row;
    }
  }


  /*  Intro Content
  ---------------------------------- */

  .intro {

    /*  Layout ---- */

    /* Tablet */
    @include media($vp-m-up) {
      @include span-columns(6 of 9);
    }

    /* Desktop */
    @include media($vp-xl-up) {
      @include span-columns(6 of 10);
    }

    /*  Content ---- */

    h4 {
      @include responsive-spacing(margin, null null spacing-xs);
      @include font-base(
        $size: rem(15),
        $line-height: 1.2em,
        $weight: $weight-light,
        $color: $brand-gray-dark,
        $show-link: false,
        $color-a: $brand-gray-dark,
        $color-a-hover: $color-secondary,
        $a-underline-height: em(1, 15)
      );
      text-transform: uppercase;
      letter-spacing: $tracking-100;
    }

    h1 {
      @include brand-font-large;
    }

    .wysiwyg > *:first-child {
      margin-top: 0;
    }

    .wysiwyg > *:only-child,
    .wysiwyg > *:last-child {
      margin-bottom: 0;
    }
  }


  /*  Metadata Content
  ---------------------------------- */

  .meta {

    @include media($vp-s-to-m) {
      display: flex;

      & > * {
        width: 48%;

        li {
          width: 100%;
        }

        &:nth-child(odd) {
          margin-right: 2%;
        }
      }
    }

    /* Tablet */
    @include media($vp-m-up) {
      @include span-columns(3 of 9);
      margin-top: 0;
    }

    /* Desktop */
    @include media($vp-xl-up) {
      @include span-columns(3.5 of 10);
      @include shift(0.5);
    }

    /*  Content ---- */

    h3 {
      @include responsive-spacing(margin, spacing-m null spacing-xs);
      @include brand-font-small(
        $include_margin: false
      );
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        position: relative;
        padding-left: 1em;
        padding-right: em(10, 14);
        margin: em(5) 0;
        min-width: 50%;
        @include font-base(
          $color: $color-base,
          $color-a: $color-base,
          $color-a-hover: $color-secondary,
          $size: rem(14),
          $line-height: 1.2em,
          $weight: $weight-light,
          $show-link: false
        );

        &:before {
          content: "–";
          position: absolute;
          left: 0;
          color: $color-secondary;
        }

        span {
          display: block;
        }

        [class*="btn-"] {
          margin-top: rem(15);
        }
      }
    }

    .award {
      display: flex;

      .icon {
        margin-right: rem(10);
      }

      h4 {
        @include font-base(
          $color: $color-base,
          $size: rem(15),
          $weight: $weight-regular,
          $line-height: 1.2em
        );
        margin: 0.5em 0;
      }

      h5 {
        @include font-base(
          $color: $color-base,
          $size: rem(14),
          $weight: $weight-light,
          $line-height: 1.42em
        );
      }
    }
  }


  /*  Hero Image
  ---------------------------------- */

  .img {

    img {
      width: 100%;
    }

    &.artdirected {

      .desktop {
        display: none;
      }

      @include media($vp-m-up) {

        .mobile {
          display: none;
        }

        .desktop {
          display: block;
        }
      }
    }
  }
}
