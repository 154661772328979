/* ----------------------------------
    Case Study Template
---------------------------------- */

/*  Global updates
---------------------------------- */

.case_study #main {

  .bound {
    @include media($vp-l-up) {

      width: $bound-width * 0.827692307692; // span 10 of 12 in a % from our normal 85% width of the bound.
      max-width: $max-width * 0.827692307692;
    }
  }
}
