/* ----------------------------------
    Flickity Overrides
---------------------------------- */

/*  Prev/next buttons
---------------------------------- */

.flickity-prev-next-button {
  width: rem(42);
  height: rem(42);
  border: rem(2) solid $color-primary;

  .arrow {
    fill: $color-primary;
  }
}


/*  Button row
---------------------------------- */

.button-row {
  @include responsive-spacing(margin, spacing-xs null);
  position: relative;
  display: block;
  text-align: center;

  /*  Move Prev/Next into bottom if
      they're used with the pg dots ---- */

  .flickity-prev-next-button,
  .flickity-page-dots {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .flickity-prev-next-button {
    transform: translateY(0);
    left: auto;
    right: auto;
    width: rem(24);
    height: rem(24);
    background: transparent;
    border: rem(2) solid $color-primary;

    .arrow {
      fill: $color-primary;
    }
  }

  /*  Page Dots ---- */

  .flickity-page-dots {
    bottom: auto;
    width: auto;
    margin: 0 rem(10);

    .dot {
      opacity: 1;
      background: transparent;
      border: rem(1) solid $color-primary;

      &.is-selected {
        box-shadow: inset 0 0 0 rem(1) white;
        background: $color-primary;
      }
    }
  }
}
