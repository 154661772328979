/* ----------------------------------
    CTA - Arrow and text
---------------------------------- */

@mixin btn-cta-txt {
  display: inline-flex;
  align-items: center;
  font-weight: bold;

  svg {
    width: 1.84em;
    margin-left: 0.84em;

    .arrow {
      transform-origin: center;
      transition: all $animation-default;
    }

    .circle {
      transition: all $animation-default;
    }
  }

  @include hover(){

    svg {

      .arrow {
        transform: translateX(-20%) scale(1.24);
      }

      .circle {
        opacity: 0.1;
      }
    }
  }
}

.btn-cta-txt {
  @include btn-cta-txt;
}
