/* ----------------------------------
    Sprout Forms Shared Styles
---------------------------------- */

.sprout-form,
.quote-form {
  @include responsive-spacing(margin, null null spacing-xl);

  /*  Messaging ---- */
  .sprout-form-error {
    @include error-bar();
    @include responsive-spacing(margin, null null spacing-m);
  }

  .sprout-form-success {
    @include success-bar($color-primary-light);
    @include responsive-spacing(margin, null null spacing-m);
  }

  /*  Tab Structure ---- */

  .tab {
    @include responsive-spacing(margin, null null spacing-m);
    @include responsive-spacing(padding, null null spacing-m);
    border-bottom: 1px solid $color-border-primary;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    h3 {
      @include brand-font-tiny();
      @include responsive-spacing(margin, null null spacing-s);
    }
  }

  /*  Field Structure ---- */

  .field {
    position: relative;
    @include responsive-spacing(margin, spacing-s null);

    &:last-child {
      margin-bottom: 0;
    }

    .heading {

      label,
      legend {
        display: inline-block;
        @include brand-font-very-tiny(
          $include_margin: false
        );
        font-weight: $weight-book;
        margin-bottom: $spacing-xxs;
        text-transform: none;
      }

      p {
        @include font-base();
        color: $color-secondary;
        font-size: rem(14);
      }
    }

    // If it's erroring out...
    .errors {
      @include password-error();
    }

    // If this is required show the *
    &.required {
      .heading label:after {
        content: " *";
        color: $color-primary;
      }
    }

    /*  Sprout Notes Field ---- */

    &.sproutfields_notes {
      @include responsive-spacing(margin, spacing-s null spacing-xs);

      .heading {
        padding-left: 0;
      }
    }
  }

  /*  Text Inputs ---- */

  input[type="text"],
  input[type="url"],
  input[type="tel"],
  input[type="number"],
  input[type="password"],
  input[type="email"] {
    display: inline-block;
    @include input-base();
    @include input-border($color: $color-secondary);
    @include font-base(
      $size: rem(18),
      $family: $brand-font-primary,
      $color: #333333
    );
    width: 100%;

    @include media($vp-s-to-m) {
      font-size: rem(17);
    }

    @include media($vp-s-down) {
      font-size: rem(16);
    }

    &:focus {
      @include input-border();
    }
  }

  input[type="number"] {
    width: auto;
    min-width: 25%;
  }

  .field.errors {
    input[type="text"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="email"] {
      @include input-border($color: $color-error);
    }
  }

  /*  TextArea ---- */

  textarea {
    @include input-base();
    @include input-border-full($color: $color-secondary);
    @include font-base(
      $size: rem(18),
      $family: $brand-font-primary,
      $color: #333333
    );
    width: 100%;

    @include media($vp-s-to-m) {
      font-size: rem(17);
    }

    @include media($vp-s-down) {
      font-size: rem(16);
    }

    &:focus {
      @include input-border-full();
    }
  }

  .field.errors {
    textarea {
      @include input-border-full($color: $color-error);
    }
  }

  /*  Select ---- */

  .dropdown .input,
  .sproutfields_emailselect .input {
    display: inline-block;
    position: relative;
    width: 100%;
    @include input-border($color: $color-secondary);
    @include responsive-spacing(margin, null null null null);

    select {
      position: relative;
      display: inline-block;
      z-index: 3;
      width: 100%;
      @include input-base();
      @include appearance(none);
      border-radius: 0;
      background: none;
      cursor: pointer;
      transition: all 150ms;

      @include font-base(
        $size: rem(18),
        $family: $brand-font-primary,
        $color: #333333
      );

      @include media($vp-s-to-m) {
        font-size: rem(17);
      }

      @include media($vp-s-down) {
        font-size: rem(16);
      }

      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //Firefox is...weird.
      //credit: codepen.io/joaocunha/pen/qLgCG
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: '';
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    }

    &:after {
      content: "";
      position: absolute;
      right: rem(16);
      top: 50%;
      z-index: 2;
      transform: translateY(-50%) rotateZ(180deg);
      width: 24px;
      height: 24px;
      background: url('/assets/images/theme/link-carrot-up.svg') no-repeat center;
      background-size: 24px 24px;
      transition: all 150ms;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
      select {
        color: black;
      }
    }
  }

  .field.errors {
    &.dropdown .input,
    &.sproutfields_emailselect .input {
      @include input-border($color: $color-error);
    }
  }

  /*  Multi Select ---- */

  .multiselect .input select {
    @include input-base();
    @include input-border-full();
    @include font-base(
      $size: rem(18),
      $family: $brand-font-body
    );
    width: 100%;
    padding: 0;
    @include responsive-spacing(margin, spacing-xxs null null null);

    @include media($vp-s-to-m) {
      font-size: rem(17);
    }

    @include media($vp-s-down) {
      font-size: rem(16);
    }

    option {
      margin: rem(1) 0;
      padding: rem(6) rem(16);
    }
  }

  .field.errors {
    &.multiselect .input select {
      @include input-border-full($color: $color-error);
    }
  }

  /*  Radio & Checkboxes ---- */

  .multiplechoice .input,
  .radiobuttons .input,
  .checkboxes .input {
    padding: rem(6) rem(10);
    @include responsive-spacing(margin, spacing-xxs null null null);

    label {
      position: relative;
      display: flex;
      align-items: center;
      @include input-base();
      @include font-base(
        $size: rem(18),
        $family: $brand-font-primary,
        $color: #333333
      );
      @include radio-label();
    }

    input {
      margin-right: 1em;
      margin-top: 4px;
    }
  }

  /*  Submit Button ---- */
  .submit {
    button {
      @include button-solid();
      text-transform: uppercase;
    }
  }

  /*  Phone Input ---- */

  .field.phone .input {
    border-bottom: 0;
    padding: 0;

    & > * {
      @include media($vp-m-up) {
        display: flex;
      }
    }

    input {
      flex-grow: 1;
      padding-left: 1em;
    }

    select {
      position: relative;
      display: inline-block;
      z-index: 3;
      flex-grow: 0;
      @include input-base();
      @include appearance(none);
      border-radius: 0;
      background: none;
      @include input-border($color: $color-secondary);
      cursor: pointer;
      transition: all 150ms;
      background: url('/assets/images/theme/link-carrot-down.svg') no-repeat center right;
      background-size: 24px 24px;

      @include font-base(
        $size: rem(18),
        $family: $brand-font-primary,
        $color: #333333
      );

      @include media($vp-s-to-m) {
        font-size: rem(17);
      }

      @include media($vp-s-down) {
        font-size: rem(16);
      }

      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //Firefox is...weird.
      //credit: codepen.io/joaocunha/pen/qLgCG
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: '';
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    }

    .field.errors {
      &.phone .input select {
        @include input-border($color: $color-error);
      }
    }
  }
}
