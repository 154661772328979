/* ----------------------------------
    Mosaic Block
---------------------------------- */

[data-block-type="mosaic"] {

  $mosaic-grid-gutter: rem(30); // Going ahead and making this rem based since it needs to be used in calcs, as vertical gutter, and we're pushing images outside the bounds by rem(15)

  /*  Layout
  ---------------------------------- */

  .bound {
    width: calc(#{$bound-width} + #{$mosaic-grid-gutter});
    max-width: $max-width + $mosaic-grid-gutter;
  }

  .grid-visibility {
    visibility: hidden;

    &.displayed {
      visibility: visible;;
    }
  }


  /*  Filter Navigation
  ---------------------------------- */

  .filters {
    @include outer-container();
    @include responsive-spacing(margin, null null spacing-m);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    label {
      padding: $spacing-xxs-em $spacing-xs-em;
      margin:  em(4);
      font-size: 1rem;
      font-style: italic;
      font-weight: $weight-light;
      color: $brand-gray;
    }

    button {
      @include button-base (
        $color: $brand-gray,
        $color-hover: $brand-black,
        $bgcolor: transparent,
        $bgcolor-hover: transparent,
        $bordercolor: transparent,
        $bordercolor-hover: $color-secondary
      );
      font-weight: $weight-light;
      font-size: 1rem;
      margin: em(4);

      &.active {
        color: $brand-black;
        border-color: $color-secondary;
      }
    }
  }


  /*  Grid Layout & Settings
  ---------------------------------- */

  /*  Column Count & Spacing ---- */
  // Columns are based on "small" grid item...

  .grid-sizer,
  .tile {
    // 2 columns for mobile ("normal" is actually 2 columns)
    width: 100%;

    // 3 columns for above mobile
    @include media($vp-m-up) {
      width: 33.33%;
    }
  }

  .gutter-sizer {
    width: $mosaic-grid-gutter;
  }

  .tile {

    /*  Tile Sizing - Grid ---- */

    &.full {
      width: 100%;
    }

    &.small {
      width: 50%;
    }

    @include media($vp-s-up) {

      &.tall {
        width: 50%;
      }
    }

    @include media($vp-m-up) {

      &.small,
      &.tall {
        width: 33.33%;
      }

      &.normal {
        width: 50%; // sigh, nor our "normal" isn't actually the normal size...
      }

      &.wide {
        width: 66.66%;
      }
    }

    /*  Tile Sizing - Heights ---- */

    // Have to switch to fixed height for min/max so it doesn't keep getting smaller/bigger
    $grid-height-min: rem(320);
    $grid-height-2col: 45vw;
    $grid-height-3col: 29.236vw;
    $grid-height-3col-max: rem(390);

    height: $grid-height-min / 1.42; // don't quiet want it to be a square.

    &.full {
      height: ($grid-height-min / 1.42) * 1.5;
    }

    &.tall {
      height: ($grid-height-min / 1.42) * 2;
    }

    &.small {
      height: calc(42.5vw + #{$mosaic-grid-gutter / 2}); // square via calc on $bound-width as vw
    }

    @include media($vp-s-up) {

      height: $grid-height-2col;

      &.full {
        height: $grid-height-2col * 1.5;
      }

      &.tall {
        height: $grid-height-2col * 2;
      }

      &.small {
        height: $grid-height-2col; // not square anymore...
      }
    }

    @include media($vp-m-up) {

      height: $grid-height-3col;

      &.full {
        height: $grid-height-3col * 1.5;
      }

      &.tall {
        height: $grid-height-3col * 2;
      }

      &.small {
        height: $grid-height-3col;
      }
    }

    @include media($vp-max-up) {

      height: $grid-height-3col-max;

      &.full {
        height: $grid-height-3col-max * 1.5;
      }

      &.tall {
        height: $grid-height-3col-max * 2;
      }

      &.small {
        height: $grid-height-3col-max;
      }
    }


    /*  Content Box Sizing ---- */

    figure {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: rem(5);
    }

    figcaption {
      position: absolute;
      z-index: 4;
      right: $mosaic-grid-gutter / 2;
      bottom: $mosaic-grid-gutter / 2;
      left: $mosaic-grid-gutter / 2;
      padding-bottom: 0;
      transition: padding-bottom $animation-default;

      @include media($vp-xl-up) {
        right: $mosaic-grid-gutter;
        bottom: $mosaic-grid-gutter;
        left: $mosaic-grid-gutter;
      }
    }

    figcaption.drop-right {
      text-align: right;
    }

    @include media($vp-s-up) {

      &.full figcaption,
      &.wide figcaption,
      &.normal figcaption {
        right: auto;
        width: 60%;
      }

      &.full figcaption.drop-right,
      &.wide figcaption.drop-right,
      &.normal figcaption.drop-right {
        right: $mosaic-grid-gutter / 2;
        left: auto;

        @include media($vp-xl-up) {
          right: $mosaic-grid-gutter;
        }
      }
    }
  }


  /*  Tile Content Itself
  ---------------------------------- */

  .tile {
    padding: $mosaic-grid-gutter / 2;
    border: none;
    outline: none;


    /*  Background
    ---------------------------------- */

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      overflow: hidden;
      border-radius: rem(5);

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: rgba(0, 0, 0, 0.24);
        transition: opacity $animation-easing $animation-timing;
      }
    }


    /*  Foreground
    ---------------------------------- */

    .foreground {
      position: absolute;
      z-index: 3;
      top: 0;
      bottom: 0;
      left: 0;

      img {
        height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      &.center {
        width: 100%;

        img {
          margin: 0 auto;
        }
      }

      &.right {
        right: 0;
        left: auto;
      }

      &.overflow-top {
        top: -($mosaic-grid-gutter / 2);
      }

      &.overflow-right {
        right: -($mosaic-grid-gutter / 2);
      }

      &.overflow-bottom {
        bottom: -($mosaic-grid-gutter / 2);
      }

      &.overflow-left {
        left: -($mosaic-grid-gutter / 2);
      }
    }

    &.tall .foreground {
      bottom: 15%;
    }


    /*  Hover stuffs
    ---------------------------------- */

    &:after {
      content: '';
      position: absolute;
      top: rem(30);
      left: rem(30);
      bottom: rem(30);
      right: rem(30);
      z-index: 0;
      border-radius: 20%;
      opacity: 0;
      box-shadow: 0 rem(20) rem(42) 0 rgba(0, 0, 0, 0.8);
      background: white;
      transform: scale(0.75);
      transition: all $animation-easing $animation-timing;
    }

    .arrow {
      position: absolute;
      z-index: 5;
      width: rem(42);
      height: rem(42);
      bottom: $mosaic-grid-gutter / 2;
      left: $mosaic-grid-gutter / 2;
      opacity: 0;
      transition: all $animation-default;

      .arrow {
        fill: white;
        transform-origin: center;
        transition: all $animation-default;
        box-shadow: 0 rem(1) rem(5) rgba(0,0,0,0.24);
      }

      .circle {
        fill: white;
        transition: opacity $animation-default;
        box-shadow: 0 rem(1) rem(5) rgba(0,0,0,0.24);
      }

      @include media($vp-xl-up) {
        bottom: $mosaic-grid-gutter;
        left: $mosaic-grid-gutter;
      }
    }

    figcaption.drop-right + .arrow {
      right: $mosaic-grid-gutter / 2;
      left: auto;

      @include media($vp-xl-up) {
        right: $mosaic-grid-gutter;
        left: auto;
      }
    }


    /*  Caption
    ---------------------------------- */

    figcaption {
      @include font-base(
        $size: rem(30),
        $line-height: 1.15em,
        $weight: $weight-book,
        $weight-b: $weight-regular,
        $color: white
      );
      text-shadow: 0 rem(1) rem(5) rgba(0,0,0,0.24);

      @include media($vp-xl-down) {
        font-size: rem(26);
      }

      @include media($vp-l-down) {
        font-size: rem(24);
        line-height: 1.255em;
      }

      @include media($vp-s-down) {
        font-size: rem(22);
      }
    }

    &.small figcaption {

      @include media($vp-s-down) {
        font-size: rem(18);
      }
    }

    /*  Drop-Right Alignment ---- */

    figcaption.drop-right {


    }


    /*  Hover State
    ---------------------------------- */

    @include hover(){

      figcaption {
        padding-bottom: rem(50);
      }

      .arrow {
        opacity: 1;

        .arrow {
          transform: translateX(-20%) scale(1.24);
        }

        .circle {
          opacity: 0.6;
        }
      }

      &:after {
        opacity: 1;
        transform: scale(1);
      }

      .background {

        &:after {
          opacity: 1;
        }
      }
    }
  }
}
