/* ----------------------------------
    Basic Layout Items
---------------------------------- */

/*  Body Resets ---- */

html {
  background-color: $color-bkgrd-primary;
}

body {
  line-height: $base-line-height;
  font-style: normal;
  color: $color-base;
  font-weight: 400;
  font-family: $brand-font-body;
  min-width: rem(320);
  text-rendering: optimizeLegibility;
  background-color: $color-bkgrd-primary;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-print-color-adjust: exact !important;

  &.menu_open {
    overflow: hidden;
  }
}

#app {
  display: block;
  overflow-x: hidden;
  background: $color-bkgrd-pg;
}

#main {
  position: relative;
  z-index: 0;
  min-height: rem(500);
}

.bound {
  @include outer-container();
  position: relative;
  z-index: 3;
  width: $bound-width;
}


/*  Print
---------------------------------- */


@page {
  size: 8.5in 11in;
  margin: 20pt;
}
