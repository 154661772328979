/* ----------------------------------
    Media Carousel Block
---------------------------------- */

.media_carousel {
  @include responsive-spacing(margin, null null spacing-m);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;


  /*  Reveal Slider when Loaded
  ---------------------------------- */

  &.flickity-enabled {
    opacity: 1;
  }


  /*  Number of Slides
  ---------------------------------- */

  &[data-slide-group="4"] {

    .gallery-cell {
      width: 25%;
    }
  }

  &[data-slide-group="3"] {

    .gallery-cell {
      width: 33.33%;
    }
  }

  &[data-slide-group="2"] {

    .gallery-cell {
      width: 50%;
    }
  }

  &[data-slide-group="1"] {

    .gallery-cell {
      width: 100%;
    }
  }



  /*  Media Carousel Child Specifics
  ---------------------------------- */

  .flickity-slider {
    position: relative;
  }

  .gallery-cell {
    padding: 0 rem(15);

    figure {
      position: relative;
      display: block;

      img {
        width: 100%;

        /* fade in image when loaded */
        transition: opacity 0.4s ease-in-out;
        opacity: 0;

        &.flickity-lazyloaded,
        &.flickity-lazyerror {
          opacity: 1;
        }
      }
    }

    p {
      text-align: center;

      &.headline {
        @include brand-font-very-tiny($include_margin: false);
        @include responsive-spacing(margin, spacing-xs null spacing-xxs);
        @include media($vp-s-up) {
          font-size: rem(22);
        }

        text-transform: none;
      }

      &.sub-headline {
        @include brand-font-body($include_margin: false);
        @include responsive-spacing(margin, spacing-xs null spacing-xxs);
        font-size: $base-font-size;
      }
    }

    .headline + .sub-headline {
      margin-top: 0;
    }

    /*  Linked Image Hover State
    ---------------------------------- */

    a {
      position: relative;
      display: block;


      .media-carousel-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: transparentize($color-base, 1);
        transition: background-color 0.2s ease-in-out;
      }


      figcaption {

        @include font-base(
          $size: rem(18),
          $family: $brand-font-body,
          $color: $color-base,
          $color-a: $color-primary,
          $color-a-hover: $color-primary-dark,
          $a-underline-height: em(1, 18)
        );
        @include media($vp-s-to-m) {
          font-size: rem(17);
        }
        @include media($vp-s-down) {
          font-size: rem(16);
          line-height: 1.4em;
        }

        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 0;
        opacity: 0;
        color: $color-primary-light;
        text-align: center;
        transform: translate(-50%, -50%);
        transition: opacity 0.2s ease-in-out;
      }

      /*  Show Link if hover isn't an option ---- */

      @media (hover: none) {

        .media-carousel-overlay {
          display: none;
        }

        figcaption {
          position: relative;
          margin: 0.5em 0;
          left: 0;
          opacity: 1;
          transform: translate(0, 0);
        }
      }

      /*  Show link on hover ---- */
      @media (hover: hover) {

        &:hover {
          .media-carousel-overlay {
            background-color: transparentize($color-base, 0.42);
          }

          figcaption {
            opacity: 1;
          }
        }
      }
    }
  }

  /*  Link & Text version
  ---------------------------------- */

  a.has_txt {

    img {
      transition: box-shadow $animation-timing $animation-easing;
    }

    p {
      transition: all $animation-timing $animation-easing;
    }

    /*  Show link on hover ---- */
    @media (hover: hover) {

      &:hover {

        img {
          box-shadow: rem(4) rem(4) 0 $color-primary;
        }

        .headline {
          color: $color-primary-dark;
        }
      }
    }
  }


  /*  Media Query Adjustments
  ---------------------------------- */

  @include media($vp-m-down) {

    &[data-slide-group="4"],
    &[data-slide-group="3"],
    &[data-slide-group="2"] {

      .gallery-cell {
        width: 100%;
      }
    }

    .gallery-cell {

      figcaption {
        font-size: rem(18);
      }
    }
  }
}
