/* ----------------------------------
    App Download Buttons

    Note that these are classed
    because they're in an atom html
---------------------------------- */

.btn-app_store,
.btn-play_store {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #333;
  font-size: 1em;
  border-radius: rem(8);
  text-decoration: none;
  transition: background-color $animation-default;

  .logo {
    display: flex;
    align-items: center;

    svg {
      height: em(26);
      margin: em(5) 0 em(5) em(8);
    }
  }

  .txt {
    margin: em(5) em(8) em(5) em(4);
  }

  i {
    display: block;
    padding: 0 em(5);
    line-height: 1.1;
    font-size: em(10);
    color: transparentize(white, 0.4);
    white-space: nowrap;
  }

  b {
    display: block;
    padding: 0 em(5);
    grid-area: storename;
    line-height: 1.1;
    font-size: em(15);
    font-weight: bold;
    color: white;
    white-space: nowrap;
  }

  @include hover {
    background-color: #000;
  }
}
