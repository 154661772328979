/* ----------------------------------
    Hero
---------------------------------- */

[data-block-type="hero"] {

  .foundation-container {
    position: relative;
    z-index: 3;
  }


  /*  Hero Circle Background
  ---------------------------------- */

  .bkgrd-main {
    @include circle_bkgrd(
      $self: true,
      $shifted: 6%,
      $bottom: 0,
      $width: 142%
    );
    left: auto;
    right: 0;
    background-color: $moby-blue;

    @include media($vp-m-down) {
      left: 0;
      transform: translateX(-25%);
      width: 160%;
      height: 150%;
      border-bottom-left-radius: 300% 100%;
      border-bottom-right-radius: 300% 100%;
    }

    // Making it a complete circle if needed.
    @include media($vp-m-up) {

      @include circle_bkgrd(
        $type: 'top',
        $bottom: 100%,
        $width: 100%
      );

      &:after {
        background-color: inherit;
      }
    }
  }


  /*  Nav Circle Background
  ---------------------------------- */

  .bkgrd-nav {
    @include circle_bkgrd(
      $self: true,
      $bottom: 0,
      $width: 80%
    );
    bottom: auto;
    top: 0;
    left: 100%;
    z-index: 2;
    background-color: $moby-pink;
    transform: translate(-75%, -75%);
    mix-blend-mode: multiply;
    transition: all $animation-timing*1.5 cubic-bezier(0.16, 1, 0.3, 1);

    @include media($vp-l-down) {
      width: 60%;
      padding-top: 30%;
      transform: translate(-50%, -70%);
    }

    @include circle_bkgrd(
      $type: 'top',
      $bottom: 100%,
      $width: 100%
    );

    &:after {
      background-color: inherit;
    }
  }



  /*  Menu Open
  ---------------------------------- */

  @include media($vp-l-down) {

    .menu_open & {

      .bkgrd-nav {
        transform: scale(8);
        background-color: $color-bkgrd-primary;
      }
    }
  }
}
