/* ----------------------------------
    Theme Typography
---------------------------------- */

/* 	Font Basics
---------------------------------- */

/* 	Font Families ---- */
$brand-font-primary: 'Poppins', sans-serif;
$brand-font-secondary: 'Barlow', sans-serif;
$brand-font-body: $brand-font-primary;

/*  Font Sizes ---- */
$base-font-size: rem(16);
$base-line-height: 1.75em;


/*  Headline Sizes
---------------------------------- */

@mixin brand-font-huge(
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $color-b: $color-secondary,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: rem(65),
    $line-height: 1.1em,
    $weight: $weight-medium,
    $weight-b: $weight-medium,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 80)
  );
  @if ($include_margin) {
    @include responsive-spacing(margin, null null spacing-l);
  }

  @include media($vp-m-to-l) {
    font-size: scale-stops(rem(65), rem(42), 4, 3);
  }

  @include media($vp-s-to-m) {
    font-size: scale-stops(rem(65), rem(42), 4, 2);
  }

  @include media($vp-s-down) {
    font-size: rem(42);
  }

  b, strong {
    color: $color-b;
  }
}

@mixin brand-font-large(
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $color-b: $color-secondary,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: rem(55),
    $line-height: 1.1em,
    $weight: $weight-medium,
    $weight-b: $weight-medium,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 55)
  );
  @if ($include_margin) {
    @include responsive-spacing(margin, null null spacing-m);
  }

  @include media($vp-m-to-l) {
    font-size: scale-stops(rem(55), rem(35), 4, 3);
  }

  @include media($vp-s-to-m) {
    font-size: scale-stops(rem(55), rem(35), 4, 2);
    line-height: 1.15em;
  }

  @include media($vp-s-down) {
    font-size: rem(35);
  }

  b, strong {
    color: $color-b;
  }
}

@mixin brand-font-medium(
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $color-b: $color-secondary,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: rem(32),
    $line-height: 1.15em,
    $weight: $weight-medium,
    $weight-b: $weight-medium,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 32)
  );
  @if ($include_margin) {
    @include responsive-spacing(margin, null null spacing-s);
  }

  @include media($vp-s-to-m) {
    font-size: scale-stops(rem(32), rem(28), 3, 2);
    line-height: 1.255em;
  }

  @include media($vp-s-down) {
    font-size: rem(28);
  }

  b, strong {
    color: $color-b;
  }
}

@mixin brand-font-small(
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $color-b: $color-secondary,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: rem(25),
    $line-height: 1.1em,
    $weight: $weight-regular,
    $weight-b: $weight-regular,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 25)
  );
  @if ($include_margin) {
    @include responsive-spacing(margin, null null spacing-s);
  }
  letter-spacing: $tracking-10;

  @include media($vp-s-down) {
    font-size: rem(24);
  }

  b, strong {
    color: $color-b;
  }
}

@mixin brand-font-very-small(
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: rem(20),
    $line-height: 1.1em,
    $weight: $weight-regular,
    $weight-b: $weight-medium,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 20)
  );
  @if ($include_margin) {
    @include responsive-spacing(margin, null null spacing-s);
  }
  letter-spacing: $tracking-10;

  @include media($vp-s-down) {
    font-size: rem(18);
  }
}

@mixin brand-font-tiny(
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: rem(20),
    $line-height: 1.15em,
    $weight: $weight-medium,
    $weight-b: $weight-bold,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 20)
  );
  @if ($include_margin) {
    @include responsive-spacing(margin, null null spacing-s);
  }
  text-transform: uppercase;
  letter-spacing: $tracking-10;

  @include media($vp-s-down) {
    font-size: rem(18);
  }
}

@mixin brand-font-very-tiny(
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: rem(16),
    $line-height: 1.2em,
    $weight: $weight-light,
    $weight-b: $weight-book,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 12)
  );
  @if ($include_margin) {
    @include responsive-spacing(margin, null null spacing-s);
  }
  text-transform: uppercase;
  letter-spacing: $tracking-10;
  opacity: 0.75;

  @include media($vp-s-down) {
    font-size: rem(15);
  }
}

/* 	Body Sizes
---------------------------------- */

@mixin brand-font-body(
  $color: $color-base,
  $color-a: $moby-blue,
  $color-a-hover: $moby-blue-dark,
  $size-d: rem(16),
  $size-t: rem(15),
  $size-m: rem(14),
  $line-height: $base-line-height,
  $show-link: true,
  $include_margin: true
  ) {
  @include font-base(
    $size: $size-d,
    $line-height: $line-height,
    $family: $brand-font-body,
    $weight: $weight-light,
    $weight-b: $weight-regular,
    $color: $color,
    $show-link: $show-link,
    $color-a: $color-a,
    $color-a-hover: $color-a-hover,
    $a-underline-height: em(1, 18)
  );

  @if ($include_margin) {
    @include responsive-spacing(margin, spacing-s null);
  }

  @include media($vp-s-to-m) {
    font-size: $size-t;
  }

  @include media($vp-s-down) {
    font-size: $size-m;
  }
}


@mixin brand-font-body-largest(){

  @include brand-font-body(
    $size-d: rem(22),
    $size-t: rem(18),
    $size-m: rem(17),
    $line-height: 1.42em
  );
}

@mixin brand-font-body-larger(){
  @include brand-font-body(
    $size-d: rem(18),
    $size-t: rem(17),
    $size-m: rem(16)
  );
}

@mixin brand-font-body-smaller(){
  @include brand-font-body(
    $size-d: rem(15),
    $size-t: rem(14),
    $size-m: rem(12)
  );
}

/* 	Basic "Normal" Typogaphy
---------------------------------- *//*

These are scoped just to the .wysiwyg element. Take a look in that block styles file to see all the normal H1-6, ul, ol, etc styling.

That's also a good place to reference spacing considerations between the special headlines; Very Tiny Headline + Huge Headline, Huge + Normal, etc

*/
