/* ----------------------------------
		Billboard - Showcase
---------------------------------- */

.content-buckets[data-presumption="showcase"] {
  display: table;

  &[data-linearity="true"] { direction: rtl; }
  & > *                    { direction: ltr; }


  /* 	Brochure & Portrait Defaults ---- */

  .brochure,
  .portrait {
    box-sizing: border-box;
    display: table-cell;
    vertical-align: middle;
  }

  .brochure {
    width: 60.307692307692%; // remainder after .portrait (784 of 1300), but before inset nudging
    padding: $spacing-xl 0;
  }

  .portrait {
    width: 39.692307692308%; // exactly 5 of 12 cols

    img {
      width: 100%;
      height: auto;
    }
  }

  &[data-linearity="true"] .brochure-inner {
    margin-right: 19.897959183673%; // R: 156 / 784 ...inset nudging away from edge of .portrait
  }

  &[data-linearity="false"] .brochure-inner {
    margin-left: 19.897959183673%; // R: 156 / 784 ...inset nudging away from edge of .portrait
  }

  .flagship {
    margin-bottom: $spacing-s;
    padding-bottom: $spacing-s;

    &.simple-feature {
      border-bottom: 1px solid rgba(black, 0.15);
    }
  }

  .appendices {

    p {
      margin-bottom: 0 !important;
    }

    .feature {
      width: 46.496815286624%; // 292 / 628
      float: left;
      margin-right: 3.503184713376%;
      @include responsive-spacing(margin, null null spacing-s);

      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n+1) {
        clear: both;
      }

    }

    .foreground-icon {
      width: 23.287671232877%; // 68 / 292
      max-width: 68px;
      @include responsive-spacing(margin, null null spacing-s);
    }

    h4 {
      @include brand-font-very_tiny();
      text-transform: none;
      @include responsive-spacing(margin, null null spacing-xxs);
    }

    p {
      @include brand-font-body-smaller();
      @include responsive-spacing(margin, 0 null);
    }
  }


  /*
  ---------------------------------- */


  @include media($vp-m-to-xl) {
    .appendices .feature {
      float: none !important;
      width: 100%;
    }
  }




  /*
  ---------------------------------- */

  @include media($vp-m-down) {

    display: block;

    .brochure,
    .portrait {
      display: block;
      width: 100%;
    }

    .portrait {
      padding-top: $spacing-xl;

      img {
        max-width: 400px;
        margin: 0 auto;
      }
    }

    .brochure-inner {
      max-width: 600px;
      margin: 0 auto !important;
    }
  }


  /* 	Layout - S down
  ---------------------------------- */

  @include media($vp-s-down) {

    .appendices .feature {
      float: none !important;
      width: 100%;

    }
  }
}


/* ----------------------------------
		Night Mode
---------------------------------- */
.content-block[data-night-mode="true"] .content-buckets[data-presumption="showcase"]  {

  .appendices {
    h4,
    p {
      color: white;
    }
  }

  .flagship                   { border-bottom: 1px solid rgba(white, 0.3); }
}
