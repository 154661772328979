/* ----------------------------------
    Constrain Image by Aspect Ratio
---------------------------------- *

The mixin assumes you'll be nesting an element
with the class of content inside your initial
block. Like this:

  <div class="foo-img">
    <div class="content">
      insert content here
    </div>
  </div>
*/


@mixin constrain-img(
  $width: $width,
  $height: $width
  ) {
  @include aspect-ratio($width, $height);

  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin crop-img(
  $width: $width,
  $height: $width
  ) {
  @include aspect-ratio($width, $height);

  .content {
    overflow: hidden;
  }

  img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
