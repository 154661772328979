/* ----------------------------------
    HR Block
---------------------------------- */

[data-block-type="hr"] {
  @include outer-container();

  hr {
    @include hr();
  }

  /*  Sizing
  ---------------------------------- */

  &.small {
    width: $bound-width/3;
  }

  &.medium {
    width: $bound-width/2;
  }

  &.large {
    width: $bound-width;
  }
}
