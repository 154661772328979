/* ----------------------------------
    CTA Atom
---------------------------------- */

@mixin cta-base() {

  [data-btn-type="primary"] {   @include button-solid(); }
  [data-btn-type="secondary"] { @include button-outlined(); }
  [data-btn-type="linkText"] {  @include link-icon(); }
  [data-btn-type="circleTextLink"] {
    @include btn-cta-txt;
    font-size: em(20);
    color: $color-primary;

    @include media($vp-s-to-m) {
      font-size: scale-stops(em(20), em(16), 4, 3);
    }

    @include media($vp-s-down) {
      font-size: scale-stops(em(20), em(16), 4, 2);
    }

    /*  Focus & Hover ---- */

    @include hover(){
      color: $color-secondary;
    }
  }

  .cta-atom-interior {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  .cta-atom-primary,
  .cta-atom-secondary {
    margin: em(10) 0;
  }

  .with-secondary {
    margin-right: em(18);
  }

  .delimiter {
    display: inline-block;
    margin: em(10) em(18) em(10) 0;
    @include brand-font-body(
      $include_margin: false
    );
    font-style: italic;
    color: $color-border-primary;
  }


  [data-btn-type="linkText"] + .delimiter {
    margin-left: 0.25em;

    & + [data-btn-type="linkText"] {
      margin-left: 0.25em;
    }
  }

}

.cta-atom {
  @include cta-base();

  /*  Justification
  ---------------------------------- */

  &[data-ctaJustification="center"] {
    .cta-atom-interior {
      justify-content: center;
    }
  }

  &[data-ctaJustification="right"] {
    .cta-atom-interior {
      justify-content: flex-end;
    }
  }
}



/*  Night Mode
---------------------------------- */

[data-night-mode="true"] .cta-atom {

  [data-btn-type="secondary"] {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.42);

    @include hover(){
      background: transparent;
      border-color: #fff;
    }
  }

  [data-btn-type="linkText"] {
    color: #fff;

    &:after {
      transition: all $animation-default;
    }

    @include hover(){
      color: #fff;
      background-color: none;
      box-shadow: none;

      &:after {
        -webkit-filter: saturate(0) brightness(500%);
        filter: saturate(0) brightness(500%);
        opacity: 1;
      }
    }
  }

  [data-btn-type="circleTextLink"] {
    color: #fff;

    @include hover(){

      .arrow,
      .circle {
        fill: #fff;
      }
    }
  }

  .delimiter {
    color: rgba(255, 255, 255, 0.6);
  }
}
