/* ----------------------------------
    Timelapse Drag Block
---------------------------------- */


.timelapse {
  position: relative;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  z-index: 0;

  &.is-hidden {
    display: none;
  }

  &:not(.is-hidden) {
    opacity: 1;
  }

  figure {
    @include responsive-spacing(padding, spacing-m null null);
    position: relative;
    z-index: 1;
  }

  img {
    position: relative;
    max-width: 100%;
    opacity: 1;

    &.not-in-view,
    &.is-hidden {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  .wysiwyg {
    @include responsive-spacing(padding, spacing-s spacing-m);
  }

  .timelapse-slider {
    @include responsive-spacing(margin, spacing-m null null);
    position: relative;
    width: 100%;
    height: rem(2);
    background: $color-border-primary;

    .slider-icon {
      display: inline-block;
      position: relative;
      top: 50%;
      left: 0%;
      width: rem(40);
      background-size: contain;
      background-repeat: no-repeat;
      height: rem(26);
      background-image: url('/assets/images/theme/timelapse-icon.svg');
      cursor: pointer;
      transform: translateY(-50%);

      &:before {
        content: "DRAG";
        position: absolute;
        top: rem(-20);
        left: 50%;
        color: $color-primary;
        transform: translateX(-50%);
      }
    }
  }

  @include media($vp-xl-up) {
    .wysiwyg {
      @include responsive-spacing(padding, spacing-s spacing-l);
    }
  }

  @include media($vp-l-down) {
    .wysiwyg {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.timelapse-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .add-opacity {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  &[data-timelapse-theme="light"] {
    background-color: $color-base-inverted;

    .add-opacity {
      background-color: $color-base-inverted;
    }

    .h-huge {     color: $color-base;   }
    .h-normal {   color: $color-base;   }
    .h-small {    color: $color-base;   }
    .h-tiny {     color: $color-base;   }
    .h-very_tiny {color: $color-base;   }

    p {           color: $color-base;   }

  }

  &[data-timelapse-theme="dark"] {
    background-color: $color-secondary-dark;

    .add-opacity {
      background-color: $color-base;
    }

    .h-huge {     color: $color-base-inverted;   }
    .h-normal {   color: $color-base-inverted;   }
    .h-small {    color: $color-base-inverted;   }
    .h-tiny {     color: $color-base-inverted;   }
    .h-very_tiny {color: $color-base-inverted;   }

    p {           color: $color-base-inverted;   }
  }

  &.mobile {
    display: none;
  }

  @include media($vp-m-down) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
}
