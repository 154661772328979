/* ----------------------------------
    Site Alert (Modal)
---------------------------------- */

.site_alert {
  text-align: center;
  @include responsive-spacing(padding, spacing-s);
  max-width: rem(500);

  h3 {
    @include brand-font-tiny;
  }

  p {
    font-style: italic;
    @include responsive-spacing(margin, null null spacing-s);
  }

  .actions {

    & > * {
      margin: 0 rem(10);
    }

    .secondary {
      @include button-cancel(
        $size: 'small'
      );
    }

    .primary {
      @include button-solid(
        $size: 'small'
      );
    }
  }
}
