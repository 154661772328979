/* ----------------------------------
		Maintain Aspect Ratio
---------------------------------- *

  from: https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

  The mixin assumes you'll be nesting an element
  with the class of content inside your initial
  block. Like this:

    <div class="sixteen-nine">
      <div class="content">
        insert content here
      </div>
    </div>

/* Use --------------------------- *

  .sixteen-nine {
    @include aspect-ratio(16, 9);
  }

---------------------------------- */


@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/*    For Flickity Aspect Raitio
---------------------------------- */
@mixin aspect-ratio-flickity($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .flickity-viewport {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
