/* ----------------------------------
    Modal Base

    These are styles that are shared
    across all modals.
---------------------------------- */


body.modal-open {
	overflow: hidden;
}


/* ----------------------------------
    Wrapper
---------------------------------- */

.modal-wrapper {
  position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: safe center;
  overflow: auto;
  padding: spacing(spacing-l, desktop);

  @include media($vp-l-down) {
    padding: spacing(spacing-m, tablet);
  }

  @include media($vp-m-down) {
    padding: spacing(spacing-xxs, mobile);
  }

	.modal-container {
  	opacity: 0;
  	transition: opacity $animation-timing*2 $animation-easing;
	}

	&.active {

  	.modal-container {
			opacity: 1;
  	}
	}
}


/* ----------------------------------
    Modal itself
---------------------------------- */

.modal-container {
  @include outer-container();
  position: relative;
  margin: auto;
  display: flex;
  flex-shrink: 0;
  min-width: rem(300);
  max-width: 100%;
  padding: $spacing-xxs;
  background: white;

  .modal-content {
    position: relative;
    z-index: 0;
    flex-grow: 1;
  }

  /* ----------------------------------
      Close Button
  ---------------------------------- */

  .btn-close {
    @include btn-close;
    position: absolute;
    z-index: 1;
    top: $spacing-xxs;
    right: $spacing-xxs;
    margin-bottom: $spacing-xxs;
  }
}
