/* ----------------------------------
    Media Gallery Block
---------------------------------- */


/*  Fade Gallery in Nicely
---------------------------------- */

[data-block-type="media_gallery"] {

  .bound {
    opacity: 0;
    transition: opacity 0.24s ease-in;

    &.visible {
      opacity: 1;
    }
  }
}

/*  Media Gallery
---------------------------------- */

.media_gallery {
  @include responsive-spacing(padding, null null spacing-xxs);
  @include responsive-spacing(margin, null null spacing-xxs);
  @include clearfix();
  border-bottom: 1px solid $color-border-primary;
  opacity: 0;
  transition: opacity 0.4s ease-in;

  .flickity-slider {
    position: relative;
    z-index: 0;

    // This keeps flickity from sliding
    transform: none !important;
  }

  .flickity-viewport {
    // Animate resizing of the gallery between slides
    transition: height 0.2s;
  }

  .button-row {
    display: none;
    bottom: -2.75rem;
  }

  .gallery-cell {
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;

    &.is-selected {
      opacity: 1;
      z-index: 1;

      figure {
        opacity: 1;
      }
    }

    &:note(.is-selected) {

      figure {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
      }
    }

    figure {
      position: relative;
      width: 98%;
      margin: 0 auto;

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: (13/14) * 100%;
      }

      .gallery-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .gallery-image-placement {
        position: relative;
        width: 100%;
        height: 100%;
      }


      /*  Slide Content
      ---------------------------------- */

      .gallery-image {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        transform: translate(-50%, -50%);

        // fade in image when loaded
        transition: opacity 0.4s ease-in-out;
        opacity: 0;

        &.flickity-lazyloaded,
        &.flickity-lazyerror {
          opacity: 1;
        }
      }
    }
  }

  &.flickity-enabled {
    opacity: 1;
  }

  @include media($vp-m-down) {
    @include span-columns(6 of 6);
    @include omega();
    border-bottom-color: transparent;

    .button-row {
      @include responsive-spacing(margin, spacing-s null);
      position: relative;
      display: block;
      bottom: auto;
      white-space: no-wrap;

      .flickity-page-dots {
        position: static;
        width: 60%;
        margin: 0 auto;
        padding-top: rem(8);
        white-space: normal;
      }

      .flickity-prev-next-button {
        vertical-align: top;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}


/*  Image/Video Select Button Styles
---------------------------------- */

.gallery-btn-row {
  @include responsive-spacing(margin, null null spacing-s);
  position: relative;
  font-size: 0;

  .button-group {
    text-align: center;
  }

  .gallery-thumbnail {
    overflow: hidden;
    position: relative;
    width: rem(102);
    height: rem(82);
    margin: 3px;
    background: transparent;
    outline: none;
    vertical-align: bottom;
    border: 1px solid $color-border-secondary;
    cursor: pointer;

    &.is-selected {
      border-color: $color-primary;
    }

    figure {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;

      * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }

      .video:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        border-style: solid;
        border-width: 10px 0 10px 20px;
        border-color: transparent transparent transparent $color-primary;
      }

      figcaption {
        position: absolute;
        display: block;
        height: 1px;
        overflow: hidden;
        font-size: 0;
      }
    }
  }

    /*  Media Query Adjustments
  ---------------------------------- */

  @include media($vp-m-down) {
    display: none;
  }
}
