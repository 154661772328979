/* ----------------------------------
    Testimonial with a Box Background
---------------------------------- */

[data-block-type="testimonial-image"] {

  .bound {

    @include media($vp-m-up) {
      display: flex;
      align-items: center;
    }
  }

  .img {

    @include media($vp-m-up) {
      order: 1;
    }

    img {
      width: 100%;
    }

    /*  Going all the way to the edge... ---- */

    &.toedge {
      width: 90%;
      max-width: rem(470);
      margin-left: -7.6vw;

      @include media($vp-l-up) {
        margin-left: -14.8230769231vw;
        // $bound-width * 0.827692307692 (70.3538461538)
      }
    }
  }

  .quote {
    @include responsive-spacing(padding, spacing-m null);
    margin: 0 spacing(spacing-s, mobile);
    flex-shrink: 0;

    /* Tablet */
    @include media($vp-m-up) {
      @include span-columns(6 of 9);
      @include shift(0.5);
      float: none;
      order: 2;
    }

    /* Desktop */
    @include media($vp-xl-up) {
      @include span-columns(7 of 10);
      @include shift(0.5);
      float: none;
      margin-right: 0;
    }
  }
}
