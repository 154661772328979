/* ----------------------------------
    Billboard Tabs Block
---------------------------------- */

[data-block-type="billboard-tabs"] {
  z-index: 0;

  // Reveal when Flickity is Ready
  &[data-visible="hidden"] {
    opacity: 0;
  }

  &[data-visible="visible"] {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
  }

  // Reset Bound so backgrounds go to edges
  .bound {
    max-width: none;
    width: 100%;
  }


  /*  Cell Content
  ---------------------------------- */

  .billboard-tabs {

    /*  Billboard/Cell Layout &
    /*  Flickity Behavior
    ---------------------------------- */

    z-index: 2;
    height: 100%;

    .flickity-viewport {
      transition: none;
    }

    .flickity-slider {
      position: relative;

      // This keeps flickity from sliding
      transform: none !important;
    }

    .cell-wrapper {
      @include outer-container();
      position: relative;
      z-index: 3;
      width: $bound-width;
    }

    .gallery-cell {
      left: 0 !important; // This keeps flickity from sliding
      bottom: 0;
      width: 100%;
      z-index: 0;
      @include responsive-spacing(padding, spacing-s null spacing-l);

      &.is-selected {
        opacity: 1;
        z-index: 1;

        .wysiwyg,
        .cell-bkgrd {
          opacity: 1;
        }

        .tab-icon {

          .img-wrapper {
            border-color: $color-primary;
            transition: border 0.4s ease-in-out;
          }
        }
      }

      &:not(.is-selected) {

        .tab-row,
        .tab-icon {
          opacity: 0;
        }
      }

      /*  Content ---- */

      .wysiwyg {
        position: relative;
        opacity: 0;
        z-index: 3;

        // Padding

        &[data-spacer-top="xxs"] {
          @include responsive-spacing(padding, spacing-xxs null null);
        }
        &[data-spacer-bottom="xxs"] {
          @include responsive-spacing(padding, null null spacing-xxs);
        }
        &[data-spacer-top="xs"] {
          @include responsive-spacing(padding, spacing-xs null null);
        }
        &[data-spacer-bottom="xs"] {
          @include responsive-spacing(padding, null null spacing-xs);
        }
        &[data-spacer-top="s"] {
          @include responsive-spacing(padding, spacing-s null null);
        }
        &[data-spacer-bottom="s"] {
          @include responsive-spacing(padding, null null spacing-s);
        }
        &[data-spacer-top="m"] {
          @include responsive-spacing(padding, spacing-m null null);
        }
        &[data-spacer-bottom="m"] {
          @include responsive-spacing(padding, null null spacing-m);
        }
        &[data-spacer-top="l"] {
          @include responsive-spacing(padding, spacing-l null null);
        }
        &[data-spacer-bottom="l"] {
          @include responsive-spacing(padding, null null spacing-l);
        }
        &[data-spacer-top="xl"] {
          @include responsive-spacing(padding, spacing-xl null null);
        }
        &[data-spacer-bottom="xl"] {
          @include responsive-spacing(padding, null null spacing-xl);
        }
        &[data-spacer-top="xxl"] {
          @include responsive-spacing(padding, spacing-xxl null null);
        }
        &[data-spacer-bottom="xxl"] {
          @include responsive-spacing(padding, null null spacing-xxl);
        }

        // Width
        max-width: 65%;

        @include media($vp-m-to-l) {
          max-width: 75%;
        }

        @include media($vp-m-down) {
          max-width: 100%
        }
      }

      /*  Cell Background ---- */

      .cell-bkgrd {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 1;

        // fade in background image when loaded
        .scenery.imagery {
          transition: opacity 0.4s ease-in-out;
          opacity: 0;

          &.flickity-bg-lazyloaded,
          &.flickity-bg-lazyerror {
            opacity: 1;
          }
        }
      }

      &.is-selected {

        .cell-bkgrd {
          opacity: 1;

          .scenery.imagery {
            opacity: 1;
          }
        }
      }


      /*  Re-order content on mobile
      ---------------------------------- */

      @include media($vp-m-down) {

        .cell-wrapper {
          display: flex;
          flex-direction: column;

          .wysiwyg {
            order: 2;
          }

          .tab-icon {
            order: 1;
            margin-top: $spacing-xs;
            margin-bottom: -($spacing-m);
          }
        }
      }
    }
  }



  /*  Tabs Naviation
  ---------------------------------- */
  // There's two nav items here. Row is on desktop where you see all the nav items at once, and Icon is just the 1 icon that's currently on.

  .tab-row {
    display: block;
    position: relative;
    opacity: 1;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    @include media($vp-m-down) {
      display: none;
    }
  }

  .tab-icon {
    display: none;
    position: relative;
    opacity: 1;
    z-index: 3;

    @include media($vp-m-down) {
      display: block;
    }
  }

  /*  Nav Buttons ---- */

  .tab-icon,
  .tab-thumbnail {
    margin: 0 2%;
    background: transparent;
    border: 0;
    outline: none;

    .img-wrapper {
      position: relative;
      width: rem(70);
      border-width: rem(3);
      border-color: $color-border-secondary;
      border-style: solid;
      transition: border 0.4s ease-in-out;

      img {
        width: 100%;
      }
    }

    figcaption {
      @include font-base(
        $size: rem(20),
        $weight: $weight-book
      );
      letter-spacing: $tracking-10;

      margin: $spacing-xxs 0 0;
    }
  }

  .tab-thumbnail {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &.is-selected {
      opacity: 1;

      figcaption {
        font-weight: $weight-regular;
      }

      .img-wrapper {
        border-color: $color-primary;
        transition: border 0.4s ease-in-out;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  /*  Dark Background Nav Colors ---- */

  [data-night-mode="true"] {

    .tab-icon,
    .tab-thumbnail {

      figcaption {
        color: white;
      }
    }
  }

  /*  Flickity Buttons/Dots Nav
  ---------------------------------- */

  .button-row {
    display: none;
    position: absolute;
    bottom: $spacing-xs;
    z-index: 1;
    left: 5%;
    width: 90%;

    @include media($vp-m-down) {
      display: block;
    }
  }
}
