/* ----------------------------------
    Manipulating the Layout of
    WYSIWYG, HTML, and CTA Blocks
---------------------------------- */

.content-block[data-block-type="wysiwyg"],
.content-block[data-block-type="general-form"],
.content-block[data-block-type="image"],
.content-block[data-block-type="video"],
.content-block[data-block-type="content-embed"],
.content-block[data-block-type="ctas"],
.content-block[data-block-type="hero"],
.content-block[data-block-type="form"] {

  /* 	Left Alignment
  ---------------------------------- */
  .bound[data-justification="left"] {

    .foundation-container {
      @include omega();

      /* 	Tablet & Desktop ---- */

      // 12 cols - ['4','5','6'*,'7','8','9','10','11','12']
      @include media($vp-xl-up) {
        &[data-desktop-width="1.5"] {@include span-columns(1.5); }
        &[data-desktop-width="2"] {  @include span-columns(2); }
        &[data-desktop-width="4"] {  @include span-columns(4); }
        &[data-desktop-width="5"] {  @include span-columns(5); }
        &[data-desktop-width="6"] {  @include span-columns(6); }
        &[data-desktop-width="7"] {  @include span-columns(7); }
        &[data-desktop-width="8"] {  @include span-columns(8); }
        &[data-desktop-width="9"] {  @include span-columns(9); }
        &[data-desktop-width="10"] { @include span-columns(10); }
        &[data-desktop-width="11"] { @include span-columns(11); }
        &[data-desktop-width="12"] { @include span-columns(12); }
      }

      // 9 cols
      @include media($vp-m-to-xl) {
        &[data-desktop-width="1.5"] {@include span-columns(1.5); }
        &[data-desktop-width="2"] {  @include span-columns(2); }
        &[data-desktop-width="4"] {  @include span-columns(4); }
        &[data-desktop-width="5"] {  @include span-columns(4); }
        &[data-desktop-width="6"] {  @include span-columns(4.5); }
        &[data-desktop-width="7"] {  @include span-columns(5); }
        &[data-desktop-width="8"] {  @include span-columns(6); }
        &[data-desktop-width="9"] {  @include span-columns(7); }
        &[data-desktop-width="10"] { @include span-columns(8); }
        &[data-desktop-width="11"] { @include span-columns(9); }
        &[data-desktop-width="12"] { @include span-columns(9); }
      }

      /* 	Mobile ---- */

      // 6 cols - ['4','6'*]
      @include media($vp-m-down) {
        &[data-mobile-width="2"] {  @include span-columns(2); }
        &[data-mobile-width="3.5"] {@include span-columns(3.5); }
        &[data-mobile-width="4"] {  @include span-columns(4); }
        &[data-mobile-width="6"] {  @include span-columns(6); }
      }
    }
  }


  /* 	Right Alignment
      (neat can be slightly annoying sometimes...)
  ---------------------------------- */
  .bound[data-justification="right"] {
    @include direction-context(right-to-left) {

      .foundation-container {
        @include omega();

        /* 	Tablet & Desktop ---- */

        // 12 cols - ['4','5','6'*,'7','8','9','10','11','12']
        @include media($vp-xl-up) {
          &[data-desktop-width="1.5"] {@include span-columns(1.5); }
          &[data-desktop-width="2"] {  @include span-columns(2); }
          &[data-desktop-width="4"] {  @include span-columns(4); }
          &[data-desktop-width="5"] {  @include span-columns(5); }
          &[data-desktop-width="6"] {  @include span-columns(6); }
          &[data-desktop-width="7"] {  @include span-columns(7); }
          &[data-desktop-width="8"] {  @include span-columns(8); }
          &[data-desktop-width="9"] {  @include span-columns(9); }
          &[data-desktop-width="10"] { @include span-columns(10); }
          &[data-desktop-width="11"] { @include span-columns(11); }
          &[data-desktop-width="12"] { @include span-columns(12); }
        }

        // 9 cols
        @include media($vp-m-to-xl) {
          &[data-desktop-width="1.5"] {@include span-columns(1.5); }
          &[data-desktop-width="2"] {  @include span-columns(2); }
          &[data-desktop-width="4"] {  @include span-columns(4); }
          &[data-desktop-width="5"] {  @include span-columns(4); }
          &[data-desktop-width="6"] {  @include span-columns(4.5); }
          &[data-desktop-width="7"] {  @include span-columns(5); }
          &[data-desktop-width="8"] {  @include span-columns(6); }
          &[data-desktop-width="9"] {  @include span-columns(7); }
          &[data-desktop-width="10"] { @include span-columns(8); }
          &[data-desktop-width="11"] { @include span-columns(9); }
          &[data-desktop-width="12"] { @include span-columns(9); }
        }

        /* 	Mobile ---- */

        // 6 cols - ['4','6'*]
        @include media($vp-m-down) {
          &[data-mobile-width="2"] {  @include span-columns(2); }
          &[data-mobile-width="3.5"] {@include span-columns(3.5); }
          &[data-mobile-width="4"] {  @include span-columns(4); }
          &[data-mobile-width="6"] {  @include span-columns(6); }
        }

      }
    }
  }


  /* 	Center Alignment
  ---------------------------------- */

  .bound[data-justification="center"] {

    .foundation-container {
      @include omega();

      /* 	Tablet & Desktop ---- */

      // 12 cols - ['4','5','6'*,'7','8','9','10','11','12']
      @include media($vp-xl-up) {
        &[data-desktop-width="1.5"] {@include span-columns(1.5); @include shift(5.25); }
        &[data-desktop-width="2"] {  @include span-columns(2);   @include shift(5); }
        &[data-desktop-width="4"] {  @include span-columns(4);   @include shift(4); }
        &[data-desktop-width="5"] {  @include span-columns(5);   @include shift(3.5); }
        &[data-desktop-width="6"] {  @include span-columns(6);   @include shift(3); }
        &[data-desktop-width="7"] {  @include span-columns(7);   @include shift(2.5); }
        &[data-desktop-width="8"] {  @include span-columns(8);   @include shift(2); }
        &[data-desktop-width="9"] {  @include span-columns(9);   @include shift(1.5); }
        &[data-desktop-width="10"] { @include span-columns(10);  @include shift(1); }
        &[data-desktop-width="11"] { @include span-columns(11);  @include shift(0.5); }
        &[data-desktop-width="12"] { @include span-columns(12); }
      }

      // 9 cols
      @include media($vp-m-to-xl) {
        &[data-desktop-width="1.5"] {@include span-columns(1.5); @include shift(3.75); }
        &[data-desktop-width="2"] {  @include span-columns(2);   @include shift(3.5); }
        &[data-desktop-width="4"] {  @include span-columns(4);   @include shift(2.5); }
        &[data-desktop-width="5"] {  @include span-columns(4);   @include shift(2.5); }
        &[data-desktop-width="6"] {  @include span-columns(4.5); @include shift(2.25); }
        &[data-desktop-width="7"] {  @include span-columns(5);   @include shift(2); }
        &[data-desktop-width="8"] {  @include span-columns(6);   @include shift(1.5); }
        &[data-desktop-width="9"] {  @include span-columns(7);   @include shift(1); }
        &[data-desktop-width="10"] { @include span-columns(8);   @include shift(0.5); }
        &[data-desktop-width="11"] { @include span-columns(9); }
        &[data-desktop-width="12"] { @include span-columns(9); }
      }

      /* 	Mobile ---- */

      // 6 cols - ['4','6'*]
      @include media($vp-m-down) {
        &[data-mobile-width="2"] {  @include span-columns(2);   @include shift(2); }
        &[data-mobile-width="3.5"] {@include span-columns(3.5); @include shift(1.25); }
        &[data-mobile-width="4"] {  @include span-columns(4);   @include shift(1); }
        &[data-mobile-width="6"] {  @include span-columns(6); }
      }

    }
  }

}
