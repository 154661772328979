/* ----------------------------------
    Hover & Focus

    We don't want to declare hover states
    because they can make touch devices
    freak out. So we'll use a media query
    to keep those to devices that have
    pointers. But this leads to annoyingly
    writting out duplicate styles for focus.

    Thus this mixin.
---------------------------------- */

@mixin hover() {

  /*  Hover & Active States ---- */

  &:focus {

    @content;
  }

  @media (hover: hover) {
    &:hover {

      @content;
    }
  }
}
