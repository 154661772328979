/* ----------------------------------
    Footer
---------------------------------- */

#footer {
  position: relative;
  z-index: 1;
  color: white;
  background: $color-bkgrd-primary;

  .wrapper {
    margin: 0 auto;
    padding: spacing(spacing-m, desktop) 0;
    width: $bound-width;
    max-width: $max-width;
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: auto auto;
    grid-column-gap: rem(60);
    grid-template-areas:
      "logo nav"
      "logo copywrite";

    @include media($vp-m-down){
      padding: spacing(spacing-l, mobile) 0;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "nav"
        "logo"
        "copywrite";
      justify-items: center;
      grid-row-gap: spacing(spacing-m, mobile);
    }
  }

  .navigation {
    grid-area: nav;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: em(14);
    font-weight: $weight-light;

    @include media($vp-m-down) {
      flex-direction: column;
      justify-content: center;
    }

    .contact {

      li {
        line-height: 1;
        padding-bottom: em(4, 14);
      }

      .tel,
      .email {
        position: relative;
        display: inline-block;
        line-height: em(24, 14);
        overflow: hidden;
        white-space: nowrap;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: em(15, 14);
          height: em(15, 14);
          margin-top: em(-2, 14);
          margin-right: em(8, 14);
          background-size: 100% 100%;
          opacity: 0.75;
        }

        &:after {
          content: '';
          position: absolute;
          left: em(23, 14);
          bottom: 0;
          width: 0;
          height: rem(1);
          opacity: 0;
          background: $color-secondary;
          transition: width $animation-default, opacity $animation-default;
        }

        @include hover {

          &:after {
            width: 100%;
            opacity: 1;
          }
        }
      }

      .tel:before {
        background-image: url('/assets/images/theme/icon-phone.svg')
      }

      .email:before {
        background-image: url('/assets/images/theme/icon-envelope.svg')
      }
    }

    .social {
      display: flex;

      @include media($vp-m-down) {
        margin-top: spacing(spacing-m, mobile);
      }

      a {
        margin: 0 rem(10);
      }

      .instagram {
        @include icon-only-button(
          $icon: '/assets/images/theme/icon_instagram-white.svg'
        )
      }

      .facebook {
        @include icon-only-button(
          $icon: '/assets/images/theme/icon_facebook-white.svg'
        )
      }

      .twitter {
        @include icon-only-button(
          $icon: '/assets/images/theme/icon_twitter-white.svg'
        )
      }

      .linkedin {
        @include icon-only-button(
          $icon: '/assets/images/theme/icon_linkedin-white.svg'
        )
      }

      .github {
        @include icon-only-button(
          $icon: '/assets/images/theme/icon_github-white.svg'
        )
      }
    }
  }

  .logo {
    grid-area: logo;
    width: rem(100);
  }

  .copywrite {
    grid-area: copywrite;
    font-size: em(12);
    font-weight: $weight-light;
    opacity: 0.5;

    .ver {
      display: none;
    }
  }
}
