/* ----------------------------------
    Billboard Slider Block
---------------------------------- */

/*  General Setup
---------------------------------- */

/*  Animate each slide when height changes ---- */

.flickity-viewport {
  transition: height 0.2s;
}

/*  Gallery layout & lazyload animation defaults ---- */

.gallery {

  .gallery-cell {
    width: 100%;

    .content-block {
      width: 100%;
    }

    /* fade in background image when loaded */
    .scenery.imagery {
      transition: opacity 0.4s ease-in-out;

      &.flickity-bg-lazyloaded,
      &.flickity-bg-lazyerror {
        opacity: 1;
      }
    }

    /* fade in image when loaded */
    img {
      transition: opacity 0.4s ease-in-out;
      opacity: 0;

      &.flickity-lazyloaded,
      &.flickity-lazyerror {
        opacity: 1;
      }
    }
  }
}

/*  Conistant Height Additions
---------------------------------- */

[data-height-option="1"] {
  .gallery-cell {
    min-height: 100%;
    // Need to set the height of .content-block to match height of .gallery-cell.
    display: flex;
    @include align-items;
  }

  .bound {
    height: 100%;
  }
}


/*  Page Dot Nav
---------------------------------- */

/*  Prev/Next button placement ---- *

.media-carousel,
.gallery {

  .flickity-prev-next-button {
    top: auto;
    bottom: auto;
  }
}

/* Page Dot Buttons ---- *

.button-row {
  display: block;
  position: absolute;
  width: 100%;
  bottom: rem(20);
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-page-dots {
  position: static;
  display: inline-block;
  bottom: auto;
  width: auto;
  padding-bottom: rem(5);

  .dot {
    opacity: 1;
  }
}

.flickity-prev-next-button {
  display: inline-block;
  position: static;
  height: rem(30);
  width: rem(30);
  margin: 0;
  background: transparent;
  vertical-align: bottom;
  transform: translateY(0);

  &.previous {
    left: auto;
  }

  &.next {
    right: auto;
  }

  &:hover {
    background: transparent;
  }

  &:disabled {
    opacity: 0.5;
  }

  .arrow {
    fill: $color-primary;
  }
}
--- */