/* ----------------------------------
    Testimonial with a Box Background
---------------------------------- */

[data-block-type="testimonial-box"] {

  .quote-bkgrd {
    @include responsive-spacing(padding, spacing-m null);
    width: 100%;
    box-shadow: $shadow;
    background: white;
    border-radius: rem(3);
  }

  .quote {
    margin: 0 spacing(spacing-s, mobile);

    /* Tablet */
    @include media($vp-m-up) {
      @include span-columns(7 of 9);
      @include shift(1);
      float: none;
    }

    /* Desktop */
    @include media($vp-xl-up) {
      @include span-columns(8 of 10);
      @include shift(1);
      float: none;
    }
  }
}
