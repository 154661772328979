/* ----------------------------------
    Featured Products - Large
---------------------------------- */

[data-block-type="featured_case_studies"] {
  @include responsive-spacing(padding, spacing-l 0);


  /*  Layout
  ---------------------------------- */

  section {
    @include row;

    &:not(.gallery) {
      @include responsive-spacing(margin, null null spacing-m null);
    }
  }

  section:not(.case_study_gallery) {

    .details {

      @include media($vp-l-up) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .cta {
          margin-left: rem(20);
        }
      }
    }
  }

  .featured {

    .case-study {

      @include media($vp-m-up) {
        @include span-columns(6 of 12);
        @include omega(2n);
      }

      @include media($vp-m-down) {
        &:first-child {
          padding-bottom: $spacing-m;
        }
      }
    }
  }

  .case_study_gallery {

    .case-study {
      padding-bottom: $spacing-s;

      @include media($bp-xl-up) {
        @include span-columns(3 of 12);
      }

      @include media($vp-m-to-xl) {
        @include span-columns(3 of 9);
      }

      @include media($vp-s-to-m) {
        @include span-columns(3 of 6);
      }

      @include media($vp-s-down) {
        width: 100%;
      }
    }
  }


  /*  Case Study
  ---------------------------------- */

  .case-study {


    /*  Photo
    ---------------------------------- */

    .photo {
      display: block;
      @include responsive-spacing(margin, null null spacing-s);

      img {
        width: 100%;
      }
    }


    /*  Content
    ---------------------------------- */

    h1 {
      text-transform: uppercase;
      margin-bottom: $spacing-xxs;
    }

    p {
      @include brand-font-body(
        $include_margin: false
      );
      margin-bottom: $spacing-xs;
      font-size: rem(20);
      font-style: italic;
      line-height: 1.2;
    }

    .cta {
      @include button-outlined (
        $size: small,
        $monochrome: true
      );
    }
  }

  .primary {

    .case-study {

      h1 {
        @include brand-font-small(
          $show-link: false,
          $include_margin: false
        );
        font-weight: $weight-regular;
      }
    }
  }

  .featured {

    .case-study {

      h1 {
        @include brand-font-very-small(
          $show-link: false,
          $include_margin: false
        );
        font-weight: $weight-regular;
      }
    }
  }

  .case_study_gallery {

    .case-study {

      .details {
        text-align: center;
      }

      h1 {
        @include brand-font-tiny(
          $show-link: false,
          $include_margin: false
        );
        font-weight: $weight-regular;
      }

      p {
        font-size: rem(15);
      }
    }
  }
}
