/* ----------------------------------
    AB Image Drag Block
---------------------------------- */

.ab_image_drag {
  position: relative;
  width: 100%;
  overflow: hidden;

  .image-a {
    max-width: 100%;
    user-select: none;
  }

  .image-b {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
    user-select: none;

    img {
      height: 100%;
      user-select: none;
    }
  }

  .is-visible .image-b {
    width: 50%;
    // animation: cd-bounce-in 0.7s;
  }

  .dragger {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 0;
    width: 2px;
    height: 100%;
    background-color: white;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.42);
    user-select: none;

    .dragger-icon {
      position: relative;
      top: 50%;
      left: -14px;
      height: 30px;
      width: 30px;
      cursor: pointer;
      transform: translateY(-50%) rotate(45deg);
      z-index: 2;
      background: white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.42);
      user-select: none;

      &:before {
        content: '';
        position: absolute;
        left: rem(-6);
        top: rem(8);
        width: rem(28);
        height: rem(28);
        background-image: url('/assets/images/theme/link-carrot-left-active.svg');
        background-size: 100% auto;
        transform: rotate(-45deg);
      }

      &:after {
        content: '';
        position: absolute;
        left: rem(8);
        top: rem(-6);
        width: rem(28);
        height: rem(28);
        background-image: url('/assets/images/theme/link-carrot-left-active.svg');
        background-size: 100% auto;
        transform: rotate(135deg);
      }
    }
  }
}

@keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
