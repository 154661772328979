
.content-block {
  position: relative;
  z-index: 5;

  & > * {
    position: relative;
    z-index: 5;
  }

  &[data-zindex="minus-4"] { z-index: 1; }
  &[data-zindex="minus-3"] { z-index: 2; }
  &[data-zindex="minus-2"] { z-index: 3; }
  &[data-zindex="minus-1"] { z-index: 4; }

  &[data-zindex="plus-1"] { z-index: 6; }
  &[data-zindex="plus-2"] { z-index: 7; }
  &[data-zindex="plus-3"] { z-index: 8; }
  &[data-zindex="plus-4"] { z-index: 9; }
}

/* 	Scenery
---------------------------------- */

.scenery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /*  Color  ---- */

  &.color {
    z-index: 1;
  }

  /*  Overlay  ---- */

  &.screen {
    z-index: 4;
    opacity: 0;
    background: white;
  }

  /*  Background Image & Video ---- */

  &.imagery,
  &.video {
    z-index: 2;
    overflow: hidden;

    img,
    video {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.video {
    z-index: 3;
  }
}


/* 	Night Mode coloring ---- */

[data-night-mode="true"] {

  .scenery.screen {
    background: black;
    mix-blend-mode: multiply;
  }
}

@each $key in edge1, edge2, edge3 {

  [data-top-rounded-edge="#{$key}"]::before,
  [data-bottom-rounded-edge="#{$key}"]::after {
    background-image: url('images/theme/bkgrd_#{$key}.png');
    content: "";
    position: absolute;
    right: 0;
    bottom: rem(-1);
    left: 0;
    z-index: 50;
    height: rem(40);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  [data-top-rounded-edge="#{$key}"]::before {
    transform: rotate(180deg);
    bottom: auto;
    top: rem(-1);
  }
}
