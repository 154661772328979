
/* ----------------------------------
		Global Reset
		Based upon 'reset.css' in the Yahoo! User Interface Library: http://developer.yahoo.com/yui
---------------------------------- */

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline-color: transparentize($color-secondary, 0.42);
}

*, html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, label, fieldset, input, p, blockquote, th, td { margin:0; padding:0 }
table { border-collapse:collapse; border-spacing:0 }
fieldset, img { border:0 }
address, caption, cite, code, dfn, em, strong, b, i, th, var { font-style:normal; font-weight:normal }
ol, ul, li { list-style:none}
caption, th { text-align:left }
h1, h2, h3, h4, h5, h6 { font-size:100%; font-weight:inherit; }
q:before, q:after { content:''}

header,
aside,
nav,
footer,
section,
article,
hgroup,
address,
figure,
figcaption,
video,
details,
small,
summary,
img,
main,
time,
dl,dt,dd,
li {
	display: block;
}

mark {
  background: none;
}

mark,
a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  min-height: 100%;
}




/* ----------------------------------
		Blow away Chrome's autofill input background-color
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Credit for this ingenius workaround: stackoverflow.com/a/37432260
---------------------------------- */

@-webkit-keyframes autofill {
    to {
        color: inherit;
        background: transparent;
    }
}
input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

/* 	Eliminate stock browser error states (esp. Firefox)
---------------------------------- */

:invalid {
  box-shadow: none;
}
:-moz-submit-invalid {
  box-shadow: none;
}
:-moz-ui-invalid {
  box-shadow:none;
}
