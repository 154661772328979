.result-template-thumbnail {
  display: flex;
  flex-direction: column;

  h5 {
    font-family: $brand-font-primary;
    font-size: $base-font-size;
    text-transform: uppercase;
    transition: color 200ms;
  }

  figure,
  .image-not-available {
    @include responsive-spacing(margin, null null spacing-xxs);
    background-color: #c8c8c8;
    transition: box-shadow 200ms;

    span {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 1rem;
      font-family: $brand-font-primary;
      font-size: rem(28);
      color: white;
      text-align: center;
      text-transform: uppercase;
      transform: translate(-50%, -50%);
    }
  }

  figure {
    @include constrain-img(1, 1);
    display: block;
  }

  .image-not-available {
    @include aspect-ratio(1, 1);
  }


  /*  Hover States ---- */

  @include hover(){

    figure,
    .image-not-available {
      box-shadow: rem(4) rem(4) 0 $color-primary;
    }

    h5 {
      color: $color-primary;
    }
  }
}
