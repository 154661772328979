
/*  Overall
---------------------------------- */

.multi-pane-content {

  &.space-after {
    @include responsive-spacing(margin, null null spacing-l);
  }

  .extraneous-tabs-controller {
    box-shadow: inset 0 -1px 0 $color-primary;
    position: relative;
    z-index: 2;
    .inner {
      width: 100%;
      display: table;
    }
    .tabs-track {
      width: 100%;
      display: table-row;
    }
    li {
      width: 20%;
      display: table-cell;
      padding: 0.75em 0 1.5em;
      position: relative;
      z-index: 1;
      cursor: pointer;
      transition: all 180ms;
      border-left: 1px solid rgba(black, 0.15);
    }
    li:first-of-type {
      border-left: 0;
    }
    li.active {
      box-shadow: inset 0 -1px 0 darken($color-primary, 10%), inset 0 -5px 0 $color-primary;
      &:after {
        content: "";
        display: block;
        z-index: 2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -8px;
        width: 23px;
        height: 9px;
        background: url("/images/ui-glyphs/tab-selected-triangle-blue.png") no-repeat left top;
        background-size: 23px 9px;
      }
    }
    .icon {
      @include aspect-ratio(1, 1);
      max-width: 60px;
      margin: 0 auto 1em;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
    .label {
      display: block;
      text-align: center;
      padding: 0 1.5em;
      @include font-base(
        $size: rem(18),
        $family: $brand-font-primary,
        $weight: $weight-regular
      );
    }
    .label span {
      transition: all 180ms;
    }
    li:hover {
      box-shadow: inset 0 -1px 0 darken($color-primary, 10%), inset 0 -10px 0 $color-primary;
    }
    @include media($vp-l-down) {
      display: none;
    }





    li[data-textual="true"] {
      padding: 0.75em 0;
      vertical-align: middle;
      &.inactive:hover {
        box-shadow: inset 0 -1px 0 darken($color-primary, 10%), inset 0 -5px 0 $color-primary;
      }
    }





  }//.extraneous-tabs-controller









  .pane {
    display: none;
    width: 100%;
    position: relative;
    z-index: 1;

    &.visible {
      display: block;
    }
  }




  .pane-blade {
    width: 100%;
    box-shadow: 0 -1px 0 $color-secondary;
    border-bottom: 1px solid $color-secondary;
    cursor: pointer;
    transition: all 200ms;
    .inner {
      position: relative;
      box-sizing: border-box;
      padding: $spacing-xs rem(55) $spacing-xs 0;
    }
    .label-icon-pairing {
      display: table-row;
    }
    .icon-container,
    .label {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
    }
    .label.sans-image {
      display: inline-block;
      padding: 7px 0;
    }
    .icon {
      width: 48px;
      height: 48px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
    .label {
      padding-left: 10px;
      @include font-base(
        $size: rem(26),
        $family: $brand-font-primary,
        $weight: $weight-book
      );
      span {
        transition: all 200ms;
      }
    }
    &:hover {
      background: rgba($color-primary, 0.15);
    }
    .indicator {
      display: block;
      width: 22px;
      height: 10px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      transition: all 300ms;
      svg {
        display: block;
      }
      g {
        fill: $color-primary;
      }
    }
    &:hover .label span {
      color: $color-primary-dark;
    }
    &:hover .indicator {
      opacity: 1;
    }

  }//.pane-blade






  .pane-content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 300ms;
  }


  @mixin revealPaneGuts() {
    .pane-blade {
      padding-bottom: 4px;
      border-bottom-color: darken($color-primary, 10%);
      box-shadow: inset 0 -4px 0 $color-primary, 0 -1px 0 $color-secondary;
    }
    .pane-blade .indicator {
      opacity: 1;
      transform: translateY(-50%) rotate(180deg);
    }
    .pane-content {
      max-height: 99999px;
      opacity: 1;
    }
  }


  .pane {

    &[data-status="open"] {
      @include revealPaneGuts();
    }

  }




  &[data-display-tabs-by-default="true"] {

    @include media($bp-l-up) {
      .pane-blade {
        display: none;
      }
      .pane-content {
        transition: none;
      }

      .pane[data-exempt="true"] {
        @include revealPaneGuts();
      }

    }

  }







}//.multi-pane-content






/* 	Specifically when I'm in an FAQ context...
---------------------------------- */
[data-block-type="faqs"] {
  .multi-pane-content {
    .pane-content {
      background: #efefef;
      box-shadow: inset 0 4px 0 rgba(black, 0.08);
    }
  }
}
