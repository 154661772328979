/* ----------------------------------
    Foundational Video - Content Block Styles
---------------------------------- */

[data-block-type="content-embed"] {

  .foundation-container {

    * {
      max-width: 100%;
    }
  }
}
