/* ----------------------------------
		Shared WYSIWYG & HTML Blocks
---------------------------------- */

.wysiwyg {

  // Fixing margin escaping.
  border: 1px solid transparent;


  /* ----------------------------------
  		Typography

      Keeping all the text styles
      scoped within the wysiwyg class.
      Think of these as "normal"
      typography styles...
  ---------------------------------- */


  /* 	Intra Text Justification
      Just for WYSIWYG plugin output...
      Please don't actually use classes in source..
  ---------------------------------- */

  .text-left { text-align: left; }
  .text-right { text-align: right; }
  .text-center { text-align: center; }


  /* 	Fancy Headline **STYLES**
  ---------------------------------- */

  .h-huge {     @include brand-font-huge();   margin-top: 0;}
  .h-large {    @include brand-font-large();  margin-top: 0;}
  .h-medium {   @include brand-font-medium(); margin-top: 0;}
  .h-small {    @include brand-font-small();  margin-top: 0;}
  .h-very_small{@include brand-font-very_small();  margin-top: 0;}
  .h-tiny {     @include brand-font-tiny();   margin-top: 0;}
  .h-very_tiny {@include brand-font-very-tiny();   margin-top: 0;}
  .h-decorative {
    @include brand-font-body(
      $size-d: rem(25),
      $size-t: rem(22),
      $size-m: rem(20),
      $line-height: 1.6em
    );
    text-transform: uppercase;
    letter-spacing: $tracking-10;
  }

  .primary_color { color: $color-primary !important; }
  .flush { margin: 0 !important; }
  .all_caps { text-transform: uppercase !important;}


  /* 	Article Content Headlines
  ---------------------------------- */

  h1 {
    @include font-base(
      $weight: $weight-book,
      $weight-b: $weight-regular,
      $line-height: 1.1em
    );
    @include responsive-spacing(margin, spacing-l null spacing-s);
  }

  h2 {
    @include font-base(
      $weight: $weight-book,
      $weight-b: $weight-regular,
      $line-height: 1.1em
    );
    @include responsive-spacing(margin, spacing-l null spacing-s);
  }

  h3,
  h4,
  h5,
  h6 {
    @include font-base(
      $weight: $weight-book,
      $weight-b: $weight-regular,
      $line-height: 1.2em
    );
    @include responsive-spacing(margin, spacing-m null spacing-xs);
  }

  h5, h6 {
    font-weight: $weight-medium;
  }

  /* 	Font Size - Default (Large up) ---- */
  @for $i from 1 through 6 {
    h#{$i} {
      font-size: modular-scale((6 - $i), rem(20), 1.250311);
    }
  }

  /* 	Basic Content Styling
  ---------------------------------- */

  p {
    @include brand-font-body();
  }

  .p-intro {
    @include brand-font-body-larger;
  }

  .p-smaller {
    @include brand-font-body-smaller;
  }


  /* 	HRs
  ---------------------------------- */

  hr {
    @include hr();

    /* Removing extra spacing above headlines if they follow a HR */
    & + .h-huge,
    & + .h-normal,
    & + .h-small,
    & + .h-tiny,
    & + .h-very_tiny,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + p {
      margin-top: 0;
    }
  }


  /*  Cool HR treatment
  ---------------------------------- */

  .hr-small {
    @include hr($width: rem(90), $color: $color-border-secondary);
  }

  .hr-medium {
    @include hr($width: rem(180), $color: $color-border-secondary);
  }

  .hr-centered {
    @include hr($width: rem(180), $color: $color-border-secondary);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  /* Moving the HR up a bit when following styled headlines (to even out spacing) ---- */
  .h-huge + .hr-small,
  .h-huge + .hr-medium {
    @include media($vp-xl-up)   {  margin-top: -$spacing-s; }
    @include media($vp-m-to-xl) {  margin-top: -rem(22);  }
    @include media($vp-m-down)  {  margin-top: -rem(20);  }
  }

  .h-medium + .hr-small,
  .h-medium + .hr-medium {
    margin-top: 0;
  }

  .h-small + .hr-small,
  .h-tiny + .hr-small,
  .h-very_tiny + .hr-small,
  .h-small + .hr-medium,
  .h-tiny + .hr-medium,
  .h-very_tiny + .hr-medium {
    @include media($vp-xl-up)   {  margin-top: $spacing-xs; }
    @include media($vp-m-to-xl) {  margin-top: rem(22);  }
    @include media($vp-m-down)  {  margin-top: rem(20);  }
  }


  /* 	List Styles
  ---------------------------------- */

  ul { @include content-ul(); }
  ol { @include content-ol(); }



  /*  Ruled Headlines
  ---------------------------------- */
  .h-ruled {
    display: inline-block;
    position: relative;
    max-width: 100%;
    text-align: center;
    //@include responsive-spacing(padding, spacing-xxs spacing-m);
    padding: $spacing-xxs-em $spacing-m;

    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      background: $color-border-secondary;
    }

    &:before {top: 0};
    &:after {bottom: 0;}
  }

  /*  Change spacing for larger Headlines ---- */

  .h-decorative .h-ruled {
    padding-top: em(10);
    padding-bottom: em(4);

    &:before,
    &:after {
      height: 2px;
      border-radius: 2px;
    }
  }

  .h-huge .h-ruled,
  .h-medium .h-ruled {
    &:before,
    &:after {
      height: 2px;
      border-radius: 2px;
    }
  }

  .h-huge .h-ruled,
  .h-large .h-ruled,
  .h-medium .h-ruled,
  .h-small .h-ruled,
  .h-very_small .h-ruled, {
    padding-top: em(5);
    padding-bottom: em(5);
  }

  /*  Change Color for Primary ---- */

  .primary_color .h-ruled {
    &:before,
    &:after {
      background: transparentize($color-primary, 0.6);
    }
  }


  /* ----------------------------------
      Blockquote
  ---------------------------------- */

  $quote-mark-color: transparentize($color-base, 0.25);

  blockquote {

    p {
      @include brand-font-body-largest();
    }

    p:first-child {
      margin-top: 0;

      &:before {
        content: '“';
        color: $quote-mark-color;
      }
    }

    p:only-of-type {
      margin-top: 0;
      margin-bottom: 0;

      &:before {
        content: '“';
        color: $quote-mark-color;
      }

      &:after {
        content: '”';
        color: $quote-mark-color;
      }
    }

    p:last-of-type {
      margin-bottom: 0;

      &:after {
        content: '”';
        color: transparentize($color-base, 0.25);
      }
    }

    cite {
      display: block;
      @include responsive-spacing(margin, spacing-s null null);
      @include font-base(
        $size: rem(14),
        $line-height: 1.2em,
        $weight: $weight-light,
        $weight-b: $weight-regular,
        $color: $color-base,
        $show-link: false
      );
      text-transform: uppercase;
      letter-spacing: $tracking-10;
      opacity: 0.75;

      &:before {
        content: '- ';
        opacity: 0.75;
      }
    }
  }


  /* ----------------------------------
      Buttons
  ---------------------------------- */

  .btn_primary {
    @include button-solid;
  }

  .btn_secondary {
    @include button-outlined;
  }

  .btn_link {
    @include link-icon;
  }

}



/* ----------------------------------
		Night Mode Color Shift
---------------------------------- */

[data-night-mode='true'] .wysiwyg {

  color: white;

  .h-huge,
  .h-large,
  .h-medium,
  .h-normal,
  .h-small,
  .h-very_small {
    color: rgba(255, 255, 255, 0.6);

    b,
    strong {
      color: white;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  a,
  ol,
  li {
    color: white;
  }

  blockquote {

    p:before,
    p:after,
    cite {
      color: transparentize(white, 0.25);
    }
  }
}
