/* ----------------------------------
    Shared Video Grid
---------------------------------- */


[data-block-type="video_grid"] {


  /*  Video Grid Itself
  ---------------------------------- */

  .video-grid {
    @include clearfix();

    /*  Each Video ---- */

    li {
      @include span-columns(6);
      @include omega(2n);
      @include responsive-spacing(margin, null null spacing-m);

      @include media($vp-s-down) {
        width: 100%;
      }

      display: none;

      &.visible {
        display: block;
      }

      video {
        width: 100%;
      }

      h3 {
        @include brand-font-very_small();
        @include responsive-spacing(margin, spacing-xs null spacing-xxs);
      }

      .duration {
        @include brand-font-very_tiny(
          $color: lighten($color-secondary, 30%)
        );
        @include responsive-spacing(margin, null null spacing-xxs);
        display: block;
      }

      .description {
        @include brand-font-body-smaller();
        @include responsive-spacing(margin, spacing-xxs null null);
        @include responsive-spacing(padding, spacing-s null null);
        border-top: 1px solid $color-border-primary;
      }
    }
  }

  .load-videos {
    @include responsive-spacing(margin, null null spacing-m);
    text-align: center;

    .button-loadmore {
      @include button-split();
    }
  }
}
