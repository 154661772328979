/* ----------------------------------
    Header
---------------------------------- */

.site_header {
  position: relative;
  z-index: 2;

  .wrapper {
    @include responsive-spacing(padding, spacing-s null);
    margin: 0 auto;
    width: $bound-width;
    max-width: $max-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  /*  Logo(s)
  ---------------------------------- */

  .floaty_moby,
  .circle_moby {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;

    @include media($vp-l-down) {
      font-size: 0.8rem;
    }

    @include media($vp-m-down) {
      font-size: 0.7rem;
    }

    .moby {
      width: em(90);
      margin-left: em(24);
    }
  }

  .floaty_moby {

    .icon {
      width: em(80);

      @include media($vp-m-down) {
        width: em(70);
      }

      .floaty-whale {
        animation: hover ease-in-out alternate 3s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
      }

      @keyframes hover {
        0%    { transform: translate(0, -4px);}
        100%  { transform: translate(0, 0);}
      }

      .floaty-shadow {
        animation: scale ease-in-out alternate 3s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
      }

      @keyframes scale {
        0%    { transform: scaleX(1.00) scaleY(1.00);}
        100%  { transform: scaleX(0.95) scaleY(0.95);}
      }
    }

    .moby {
      fill: white;
    }
  }

  .circle_moby {

    .icon {
      width: em(75);
    }

    .moby {
      fill: #202B2D;
    }
  }


  /*  Hidden Keyboard/Accessibility Nav
  ---------------------------------- */

  #accessibility_nav {
    position: absolute;
    z-index: 10;
    top: -100%;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
    transition: top $animation-default;
    @include circle_bkgrd(
      $bottom: -60%,
      $width: 600%
    );

    a {
      position: relative;
      z-index: 1;
      padding: em(2) em(8);
      font-weight: $weight-medium;
      color: white;
      border-bottom: 0 solid transparent;
      padding-bottom: em(2);
      transition: all 200ms;
      white-space: nowrap;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

      @include hover{
        padding-bottom: 0;
        border-bottom: em(2) solid white;
      }
    }

    &:focus-within {
      top: 10%;
    }
  }


  /*  Main Site Nav
  ---------------------------------- */

  #navigation {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    font-size: rem(15);

    a {
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    }

    /*  Buttons ---- */

    .txt {
      display: inline-block;
      padding: em(4);
      margin: 0;
      border-bottom: em(3) solid transparent;
      transition: all 200ms;
      white-space: nowrap;

      &.active {
        padding-bottom: 0;
        margin-bottom: em(4);
        border-bottom-color: $color-secondary;
      }

      @include hover{
        padding-bottom: 0;
        margin-bottom: em(4);
        border-bottom-color: $color-secondary;
      }
    }

    .solid {
      @include button-base (
        $size: 1em,
        $padding-tb: em(12),
        $padding-lr: em(16),
        $bgcolor-hover: $color-secondary
      );
    }

    .outlined {
      @include button-base (
        $size: 1em,
        $padding-tb: em(12),
        $padding-lr: em(16),
        $color: $color-base,
        $color-hover: #fff,
        $bgcolor: transparent,
        $bgcolor-hover: $color-secondary,
        $bordercolor: $color-base,
        $bordercolor-hover: transparent
      );
    }
  }

  /*  When on Desktop Size with Background Bubble ---- */

  &.has_bkgrd {

    #navigation a {
      color: white;

      &.outlined {
        border-color: #fff;

        @include hover(){
          border-color: transparent;
        }
      }
    }
  }





  /*  Responsive Behavior/Layout
  ---------------------------------- */

  /*  Desktop (default) ---- */

  #navigation ul {
    position: relative;
    display: flex;
    align-items: baseline;
    z-index: 1;

    li {
      margin: 0 em(15);
    }
  }

  #btn-nav_toggle {
    display: none;
  }


  /*  Mobile ---- */

  @include media($vp-l-down) {
    display: flex;

    .wrapper {
      position: relative;
      z-index: 1;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    .circle_moby .moby {
      transition: fill $animation-timing*1.5 cubic-bezier(0.16, 1, 0.3, 1);
    }

    #navigation {
      flex-grow: 1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: rem(400);
      padding: rem(80) 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      visibility: hidden;
      font-size: rem(24);
      transition: opacity $animation-timing*1.5 cubic-bezier(0.16, 1, 0.3, 1);

      // Menu Background
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 0);
        background-color: $color-bkgrd-primary;
      }

      ul {
        position: relative;
        z-index: 1;
        width: 85%;
        margin: 0 auto;
        flex-direction: column;
        transform:scale(1.042, 1.042);
        transition: transform $animation-timing*3 cubic-bezier(0.34, 1.56, 0.64, 1);

        li {
          margin: 0.84em 0;
        }
      }

      a {
        color: white;

        &.outlined {
          color: white;
          border-color: #fff;

          @include hover(){
            border-color: transparent;
          }
        }
      }
    }


    #btn-nav_toggle {
      display: block; // show this button on mobile...
      position: absolute;
      z-index: 4;
      top: rem(23);
      right: rem(-15);
      padding: rem(20) rem(10);
      width: rem(56);
      height: rem(56);
      border-radius: 100%;
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

      @include media($vp-m-down) {
        top: rem(14);
      }

      @include hover {
        .icon span,
        .icon:before,
        .icon:after {
          background-color: $color-secondary;
        }
      }

      &:focus {
        box-shadow: 0 0 rem(3) rem(2) $color-secondary;
      }

      i {
        @include hide-text;
      }

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: rem(26);
        height: rem(2);
        transform: translateX(-50%) translateY(-50%);

        span {
          display: block;
          width: 100%;
          height: 100%;
          opacity: 1;
          background-color: $color-base;
          transition: all $animation-timing cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color-base;
          transition: all $animation-timing cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &:before {
          top: rem(-8);
        }

        &:after {
          bottom: rem(-8);
        }
      }

      &.active {

        .icon {

          span {
            opacity: 0;
          }

          &:before {
            background-color: white;
            top:0;
            transform: rotate(-135deg);
          }

          &:after {
            background-color: white;
            bottom:0;
            transform: rotate(135deg);
          }
        }

        @include hover {
          .icon span,
          .icon:before,
          .icon:after {
            background-color: $color-secondary;
          }
        }
      }
    }

    /*  When on Desktop Size with Background Bubble ---- */

    &.has_bkgrd {

      #btn-nav_toggle {

        .icon span,
        .icon:before,
        .icon:after {
          background-color: white;
        }

        @include hover {
          .icon span,
          .icon:before,
          .icon:after {
            background-color: $color-secondary;
          }
        }
      }
    }
  }




  /*  Menu Open
  ---------------------------------- */

  @include media($vp-l-down) {

    .menu_open & {

      .circle_moby .moby {
        fill: white;
      }

      #navigation {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;

        &:after {
          opacity: 1;
          visibility: visible;
        }

        ul {
          transform:scale(1, 1);
        }
      }
    }
  }
}
