[data-block-type="features-grid"] {
  @include responsive-spacing(padding, spacing-l null 0);

  /*  Basics
  ---------------------------------- */
  img {
    display: block;
    width: 100%;
    height: auto;
  }





  /*  Standard Feature Splits
  ---------------------------------- */

  .feature {
    @include responsive-spacing(margin, null null spacing-l);

    &[data-axis="horiz"] {
      .inner {
        @include outer-container;
      }
    }

    //  ...........................................................................
    //  Regardless of DIPTYCH or TRIPTYCH:
    @include media($vp-s-to-l) {
      @include span-columns(4.5 of 9);
      @include omega(2n);
      .icon {
        @include span-columns(1.25 of 4.5);
      }
      &[data-axis="horiz"] {
        .prose {
          @include span-columns(3.25 of 4.5);
        }
      }
    }// S -> L
    //  ...........................................................................
    //  Regardless of DIPTYCH or TRIPTYCH:
    @include media($vp-s-down) {
      .icon {
        @include span-columns(2 of 6);
      }
      &[data-axis="vert"] {
        .prose {
          clear: both;
        }
      }
      &[data-axis="horiz"] {
        .prose {
          @include span-columns(4 of 6);
        }
      }
    }// S -> _

  }



  /*  Triptych vs Diptych
  ---------------------------------- */
  .anthology-of-features {
    @include outer-container;

    [data-axis="vert"] {
      .prose {
        clear: both;
        @include responsive-spacing(padding, spacing-s null null);
      }
    }

    //  ...........................................................................
    //  Exclusively TRIPTYCH:
    &[data-desktop-split="triptych"] {

      @include media($bp-l-up) {
        .feature {
          @include span-columns(4 of 12);
          @include omega(3n);
        }
        .icon {
          @include span-columns(1 of 4);
        }
        [data-axis="horiz"] {
          .prose {
            @include span-columns (3 of 4);
          }
        }
      }

    }

    //  ...........................................................................
    //  Exclusively DIPTYCH:
    &[data-desktop-split="diptych"] {

      @include media($bp-l-up) {
        .feature {
          @include span-columns(6 of 12);
          @include omega(2n);
        }
        .icon {
          @include span-columns(1 of 6);
        }
        [data-axis="horiz"] {
          .prose {
            @include span-columns (4.5 of 6);
          }
        }
      }

    }

  }




  /*  Content Styles
  /*  Different font-sizing based on available space...
  ---------------------------------- */

  [data-desktop-split="triptych"] {

    h1 {
      @include brand-font-very_small;
    }

    .wysiwyg p {
      @include brand-font-body-smaller();
    }

    .wysiwyg li {
      @include brand-font-body(
        $size-d: rem(15),
        $size-t: rem(14),
        $size-m: rem(12),
        $include_margin: false
      );
    }

    .wysiwyg > *:first-child {
      margin-top: 0;
    }

    .wysiwyg > *:last-child {
      margin-bottom: 0;
    }
  }

  [data-desktop-split="diptych"] {

    h1 {
      @include brand-font-small;
    }
  }


  mark {
    display: block;
    @include brand-font-very_tiny;
  }

  .wysiwyg > *:first-child {
    margin-top: 0;
  }

  .wysiwyg > *:last-child {
    margin-bottom: 0;
  }

  .wysiwyg + .cta-atom {
    @include responsive-spacing(margin, spacing-s null null);
  }

  &[data-night-mode='true'] {

    h1,
    mark,
    .wysiwyg p,
    .wysiwyg li,
    a,
    h1 a {
      color: white;
    }

    h1 a:hover,
    a:hover {
      color: transparentize(white, 0.4);
    }
  }





  /*  Slimline
  ---------------------------------- */
  .feature.slimline {
    h1 {
      margin: 0;
    }
    @include media($vp-m-to-xl) {
      h1 {
        font-size: 1.2rem;
      }
    }
    @include media($vp-m-down) {
      clear: both;
      float: none;
      width: 100%;
    }
  }

  .slimline-wrap {
    width: 100%;
  }
  .slimline-icon {
    display: table-cell;
    width: 16.831683168317%;
    vertical-align: middle;
  }
  .slimline-prose {
    display: table-cell;
    width: 83.168316831683%;
    padding-left: 13.095238095238%;
    vertical-align: middle;
  }

  &[data-desktop-split="diptych"] .feature.slimline {
    .slimline-icon {
      width: 10.828025477707%;
    }
    .slimline-prose {
      width: 89.171974522293%;
      padding-left: 7.857142857143%;
    }
  }

}//features-grid
