/* ----------------------------------
    Overlap Spacing
---------------------------------- */

@each $name, $values in $overlay-map {

  [data-overlap-spacing="#{$name}"] {
    margin-top: overlay-spacing($name, mobile);

    @include media($vp-m-up){
      margin-top: overlay-spacing($name, tablet);
    }

    @include media($vp-xl-up){
      margin-top: overlay-spacing($name, desktop);
    }
  }
}
