@mixin hide-visually {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  width: 1px;
  overflow: hidden;
}


@mixin hide-text {
  display: inline-block;
  text-indent: -100%;
  font: 0/0 Arial;
  overflow: hidden;
}
