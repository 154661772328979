/* ----------------------------------
    Testimonial with Headline
---------------------------------- */


[data-block-type="testimonial-headline"] {

  .bound {

    @include media($vp-m-up) {
      display: flex;
      align-items: center;
    }
  }

  .headline {
    position: relative;
    z-index: 1;
    @include brand-font-medium();

    /* Tablet */
    @include media($vp-m-up) {
      @include span-columns(3 of 9);
      margin-top: 0;
      float: none;
    }

    /* Desktop */
    @include media($vp-xl-up) {
      @include span-columns(3.5 of 10);
      float: none;
    }
  }

  .quote {
    position: relative;
    z-index: 0;
    @include responsive-spacing(padding, spacing-l null);

    /* Tablet */
    @include media($vp-m-up) {
      @include span-columns(6 of 9);
      float: none;
    }

    /* Desktop */
    @include media($vp-xl-up) {
      @include span-columns(6 of 10);
      @include shift(0.5);
      float: none;
    }

    /*  Bkgrds ---- */

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: inherit;
    }

    &:before {
      width: $spacing-l;
      right: 99.9%;
      border-radius: rem(3) 0 0 rem(3);
    }

    &:after {
      width: 30vw;
      left: 99.9%;
    }

    /*  Quote Marks ---- */

    svg {
      position: absolute;
      top: rem(-10);
      left: rem(-42);
      width: rem(70);

      @include media($vp-m-up) {
        top: rem(-25);
        left: 0;
      }
    }

    /*  Removing content quote marks ---- */

    .no-quotes {

      p:before,
      p:after {
        display: none;
      }
    }
  }
}
