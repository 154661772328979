/* ----------------------------------
    Base Typography

    These can mostly be ignored
    in practice.
---------------------------------- */


/*  Font Weights
---------------------------------- */
// For this site we've a fairly limited amount of weights by font. Switching these around here instead on each declaration.
// Poppins - 300, 500, 600
// Barlow - 400, 500

$weight-light: 300;
$weight-book: 400;
$weight-regular: 500;
$weight-medium: 600;
$weight-bold: 600;

/*  Letter Spacing
---------------------------------- */
$tracking-100: 0.1em;
$tracking-10: 0.01em;


/*  Base Font Mixin
---------------------------------- */

@mixin font-base(
  $size: $base-font-size,
  $line-height: $base-line-height,
  $weight: $weight-regular,
  $weight-b: $weight-bold,
  $family: $brand-font-primary,
  $color: $color-base,
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $show-link: true,
  $a-underline-height: em(1)
  ){
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $line-height;

  i,
  em {
    font-style: italic;

    b,
    strong {
      font-style: italic;
    }
  }

  b,
  strong {
    font-weight: $weight-b;

    i,
    em {
      font-weight: $weight-b;
    }
  }

  a {
    transition: color 200ms;
    color: $color-a;
    cursor: pointer;

    @if ($show-link) {
      @include underline(1.4em, $a-underline-height, transparentize($color-a-hover, 0.6));
    }

    @include hover(){
      color: $color-a-hover;

      @if ($show-link) {
        @include underline(1.4em, $a-underline-height, $color-a-hover);
      }
    }
  }
}


/*  Links
---------------------------------- */

/*  Is itself a link ---- */

@mixin is-link(
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $show-link: false,
  $show-link-hover: true,
  $color-a-underline: $color-a-hover,
  $touch-color: transparentize($color-primary, 0.4)
  ) {
  transition: text-decoration $animation-timing $animation-easing, color $animation-timing $animation-easing;
  color: $color-a;
  cursor: pointer;
  -webkit-tap-highlight-color: $touch-color;

  @if ($show-link) {
    text-decoration: underline;

    @supports (text-decoration-color: transparent) {
      text-decoration-color: transparentize($color-a-underline, 0.6);
    }
  }

  @include hover() {
    color: $color-a-hover;

    @if ($show-link-hover) {
      text-decoration: underline;

      @supports (text-decoration-color: transparent) {
        text-decoration-color: $color-a-underline;
      }
    }
  }
}

/*  Has a link inside it (headlines, etc) ---- */

@mixin has-link(
  $color-a: $color-base,
  $color-a-hover: $color-primary,
  $show-link: false,
  $show-link-hover: true,
  $color-a-underline: $color-a-hover,
  $touch-color: transparentize($color-primary, 0.4)
  ) {

  a {
    @include is-link(
      $color-a: $color-a,
      $color-a-hover: $color-a-hover,
      $show-link: $show-link,
      $show-link-hover: $show-link-hover,
      $color-a-underline: $color-a-underline,
      $touch-color: $touch-color
    );
  }
}


/* 	HRs
---------------------------------- */

@mixin hr(
  $color: $color-border-primary,
  $width: 100%
  ) {
  display: inline-block;
  width: $width;
  height: 0;
  border: none;
  border-top: rem(1) solid $color;

  &:not(.flush) {
    @include responsive-spacing(margin, spacing-m null);
  }
}


/* 	Lists
---------------------------------- */

@mixin content-ul(
  $color: $color-secondary,
  $spacing: spacing-xxs
  ) {
  @include responsive-spacing(margin, null null $spacing);

  li {
    position: relative;
    margin: inherit 0;
    @include responsive-spacing(margin, $spacing null);
    padding-left: 1.0em;
    @include brand-font-body($include_margin: false);
    list-style-type: none !important;

    &:before {
      content: "•";
      position: absolute;
      font-family:  Arial, 'Helvetica Neue', Helvetica, sans-serif;
      top: 0;
      left: 0;
      font-size: 1.6em;
      line-height: 1em;
      width: 0.5em;
      color: $color;
    }
  }

  ul,
  ol {
    margin-left: 0.5em;

    @include media($vp-m-down) {
      margin-left: 0;
    }
  }

  ol li {
    padding-left: 0;
  }
}


@mixin content-ol(
  $color: $color-secondary,
  $spacing: spacing-xxs
  ) {

  @include responsive-spacing(margin, null null $spacing);
  padding-left: 2em;
  counter-reset: li;

  > li {
    counter-increment: li;
    position: relative;
    @include responsive-spacing(margin, $spacing null);
    @include brand-font-body($include_margin: false);
    list-style-type: none !important;

    &:before {
      content: counter(li)".";
      position: absolute;
      display: inline-block;
      font-family: $brand-font-body;
      top: 0;
      left: auto;
      right: 100%;
      padding-right: 0.6em;
      font-size: 1em;
      line-height: inherit;
      min-width: 2em;
      color: $color;
      text-align: right;
    }
  }


  @include media($vp-m-down) {
    padding-left: 0;

    > li {
      padding-left: 0;

      &:before {
        position: relative;
        top: 0;
        right: auto;
        left: auto;
      }
    }
  }

  ol {
    padding-left: 2em;

    @include media($vp-m-down) {
      margin-left: 0;
    }
  }

  ul {
    @include content-ul;
  }
}
