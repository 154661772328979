/* ----------------------------------
    Background Media Block
---------------------------------- */

[data-block-type="background_media"] {

  .foundation-container {

    /*  Maintaining the aspect ratio for backgrounds ---- */

    // Desktop Heights

    @include media($vp-m-up) {
      &[data-desktop-height="small"] {
        @include aspect-ratio(4, 1);
      }

      &[data-desktop-height="medium"] {
        @include aspect-ratio(2, 1);
      }

      &[data-desktop-height="large"] {
        @include aspect-ratio(16, 9);
      }

      background: none !important;

    }


    // Mobile Heights

    @include media($vp-m-down) {
      background-size: cover;

      img { display: none; }

      &[data-mobile-height="small"] {
        @include aspect-ratio(4, 3);
      }

      &[data-mobile-height="medium"] {
        @include aspect-ratio(1, 1);
      }

      &[data-mobile-height="large"] {
        @include aspect-ratio(3, 4);
      }
    }
  }
}
