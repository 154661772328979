.notification {
  position: fixed;
  left: 50%;
  bottom: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  padding: 14px;
  font-family: $brand-font-primary;
  color: white;
  transform: translateX(-50%);
  background-color: rgba(229, 60, 69, 1);
  border-radius: 6px;
  box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, .5);
  cursor: pointer;
  transition: bottom cubic-bezier(.54,-0.38,.97,1.06) 500ms;

  p {
    margin-right: 16px;
  }

  button {
    height: 14px;
    width: 14px;
    background: no-repeat center / contain;
    background-image: url('/assets/images/theme/icon_dismiss_grey.svg');
    border-width: 0;
    transform: scale(1);
    transition: transform ease-in 250ms;

    @include hover(){
      transform: scale(1.2);
      transition: transform ease-in 250ms;
      cursor: pointer;
    }
  }

  &.visible {
    bottom: 3rem;
    transition: bottom cubic-bezier(.46,.04,.14,1.43) 500ms;
  }
}
