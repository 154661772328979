/* ----------------------------------
    Underlines for Links
---------------------------------- */

@mixin underline($pos, $height, $color: rgba(0, 0, 0, 1)) {
  @include background-image(
    linear-gradient(top,
      rgba(0, 0, 0, 0) $pos,
      $color $pos,
      $color $pos+$height,
      rgba(0, 0, 0, 0) $pos+$height
    )
  );
  text-decoration: none;
  background-size: 2px 2em;
  background-repeat: repeat-x;
}
