/* ----------------------------------
    Share Buttons
---------------------------------- */

.share_buttons {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0;
  line-height: 1;

  a {
    display: inline-block;
    padding: 0 rem(5);

    i {
      @include hide-visually;
      font-size: 1rem;
    }

    svg {
      display: inline-block;
      width: rem(25);
      fill: $color-base;
      transition: all $animation-easing $animation-timing;
    }

    @include hover(){
      svg {
        fill: $color-primary;
      }
    }
  }
}
