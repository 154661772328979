/* ----------------------------------
    Content Block Spacing
---------------------------------- */

@each $name, $values in $spacing-map {

  [data-spacing-top="#{$name}"] .bound {
    @include responsive-spacing(padding, $name null null);
  }

  [data-spacing-bottom="#{$name}"] .bound {
    @include responsive-spacing(padding, null null $name);
  }
}
