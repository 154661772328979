/* ----------------------------------
    Theme Buttons
---------------------------------- */


/* 	Text Links w/ Icons
---------------------------------- */

@mixin link-icon (
    $size: em(20),
    $icon: '/assets/images/theme/link-arrow-left.svg'
  ) {
  @include link-icon-base(
    $size: $size,
    $icon: $icon
  );
  padding-right: 1em;
  font-weight: $weight-medium;

  &:after {
    height: 0.8em;
    width: 1em;
    background-size: 0.8em 0.8em;
    background-position: right center;
    margin-right: -1em;
  }
}


/* 	Button-y Buttons
---------------------------------- */

@mixin button-solid(
  $size: null
  ){

  @if ($size == 'small') {
    @include button-base (
      $size: em(16),
      $padding-tb: em(6),
      $padding-lr: em(8)
    );

  } @else {

    @include button-base ();
  }
}

@mixin button-outlined(
  $size: null,
  $monochrome: false
  ){

  @if ($monochrome) {
    @include button-base (
      $color: black,
      $color-hover: #fff,
      $bgcolor: transparent,
      $bgcolor-hover: $color-primary,
      $bordercolor: black,
      $bordercolor-hover: $color-primary
    );
  } @else {
    @include button-base (
      $color: $color-primary,
      $color-hover: #fff,
      $bgcolor: transparent,
      $bgcolor-hover: $color-primary,
      $bordercolor: $color-primary,
      $bordercolor-hover: $color-primary
    );
  }

  @if ($size == 'small') {
    padding: 0.8em;
    font-size: em(16);
  }
}

@mixin button-cancel(
  $size: null
  ){
  font-weight: $weight-medium;
  text-align: center;

  @include button-base (
    $color: #333,
    $color-hover: $color-primary,
    $bgcolor: transparent,
    $size: em(20),
    $bgcolor-hover: transparent,
    $bordercolor: transparent,
    $bordercolor-hover: transparent
  );

  @if ($size == 'small') {
    padding: 0.8em;
    font-size: em(16);
  }
}


/* 	Split Buttons [ Load More|3 ]
---------------------------------- */

@mixin button-split (
  $btnsize: null,
  $text-transform: null
  ){
  $padding-tb: $spacing-xs-em;
  $padding-lr: $spacing-s-em;

  @include button-base (
    $padding-tb: 0,
    $padding-lr: 0,
    $text-transform: $text-transform,
    $bgcolor: $color-primary-light,
    $bgcolor-hover: $color-primary,
    $bordercolor: null,
    $bordercolor-hover: null
  );
  display: inline-table;

  span {
    display: table-cell;
    padding: $padding-tb $padding-lr/1.25;
    vertical-align: middle;
  }

  span.txt {
    background-color: $color-primary;
    padding: $padding-tb $padding-lr;
    transition: background-color 200ms;
  }

  em {
    font-style: normal;
  }

  @include hover(){

    span.txt {
      background-color: $color-primary-dark;
    }
  }

  /* 	Inactive State ---- */

  &.inactive {

    @include hover(){
      span {
        background-color: $color-primary;
      }
    }

    /* 	Special inactive state if it's in a filter bar ---- */

    &.filter {
      color: lighten($color-base, 50%);
      border-color: darken($color-bkgrd-secondary, 20%);
      span {
        background-color: $color-bkgrd-secondary;
      }
    }
  }

  /* 	Smaller Buttons ---- */

  @if ($btnsize == 'small') {
    span {
      font-size: em(11);
    }
  }
}
