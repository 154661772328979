/* ----------------------------------
    Timeline Vertical - Shared
---------------------------------- */

.timeline-vertical {
  position: relative;
  background-color: #ebebeb;


  /*  Timeline Track
  ---------------------------------- */

  .track {
    padding: 80px 0;
    max-width: 1076px;
    margin: 0 auto;

    background:
    url("/assets/images/ui/timeline-vertical-track-hash.png") repeat-y center top,
    url("/assets/images/ui/dot-black-10p.png") repeat-y center top,
    url("/assets/images/ui/dot-black-10p.png") repeat-y center top;
    background-size:
    7px 8px,
    1px 1px,
    1px 1px;

    @include media($vp-l-down) {
      margin-left: 0;
      background-position:
      left top,
      4px top,
      4px top;
    }
  }


  /*  Event & Card Layout
  ---------------------------------- */

  .event {
    margin-bottom: 50px;

    @include media($vp-l-down) {
      padding-left: 26px;
    }

    .card {
      position: relative;
      max-width: 550px;
      background: white;
      box-shadow: inset 0 0 0 1px rgba(black, 0.25), 0 1px 5px rgba(black, 0.2);

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -26px;
        width: 26px;
        height: 9px;
        background-image: url("/assets/images/ui/timeline-vertical-connector-dt-leftward.png");
        background-size: 26px 9px;
        background-repeat: no-repeat;
        background-position: 0 0;
      }
    }

    // Card to the Right
    &:nth-of-type(even) {

      @include media($bp-l-up) {
        padding:  0 0 0 50%;

        .contain  { padding: 0 0 0 26px; }

        .card { margin-left: -4px; }
      }

      // Default Card Carrot
      .card:after {
        right: auto;
      }
    }

    // Card to the Left
    &:nth-of-type(odd) {

      @include media($bp-l-up) {
        padding:  0 50% 0 0;

        .contain  { padding: 0 26px 0 0; }

        .card {
          margin-right: -4px;

          &:after {
            right: -26px;
            left: auto;
            background-image: url("/assets/images/ui/timeline-vertical-connector-dt-rightward.png");
          }
        }
      }
    }


    /*  Dark Background
    ---------------------------------- */

    &[data-night-mode="true"] {

      .card {
        background: darken($color-base, 20%);
      }
    }


    /*  No Card Background
    ---------------------------------- */

    &[data-showcard="false"] {

      .card {
        box-shadow: none;
        background-color: transparent;

        /* Image takes up entire card when just image and no background */
        figure,
        .essay {
          padding-left: 0;
          padding-right: 0;
        }

        figure {
          padding-top: 0;
        }

        &:after {
          height: rem(9);
          width: rem(9);
          background: rgb(171, 171, 171);
          background-size: contain;
          border-radius: 50%;
        }
      }

      // No background on night mode
      &[data-night-mode="true"] {

        .card {
          background: transparent;
        }

        .wysiwyg {
          .h-huge,
          .h-normal,
          .h-small,
          .h-tiny,
          .h-very_tiny,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          ul,
          ol {
            color: $color-base;
          }
        }

        .delimiter {
          color: $color-base;
        }
      }
    }
  }


  /*  Card Content
  ---------------------------------- */

  .card {
    figure {

      img {
        display: block;
        width: 100%;
      }
    }

    .essay {
      @include responsive-spacing(padding, spacing-s);

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
