/* ----------------------------------
    Foundational Video - Content Block Styles
---------------------------------- */

[data-block-type="video"] {

  .foundation-container {

    video {
      width: 100%;
    }
  }
}
