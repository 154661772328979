/* ----------------------------------
    Theme Forms
---------------------------------- */


/*  Input Email & Password
---------------------------------- */

@mixin input-base {
  line-height: 1;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;

  &::placeholder {
    color: #ccc;
  }
}

@mixin input-border($color: $color-primary) {
  padding: rem(5) $spacing-xs rem(5) 0;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid $color;
}

@mixin input-bottom-border($color: $color-primary) {
  padding: rem(5) $spacing-xs;
  background-size: 1px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid $color;
}

@mixin input-border-full($color: $color-primary) {
  padding: rem(6) $spacing-xs;
  border: 1px solid $color;
}


/*  Input Email (Currently used in Newsletter Signup)
---------------------------------- */
@mixin email-input($border-color: $color-primary) {
  @include input-base();
  margin: 0;
  padding: 0;

  // Can't override margin within brand-font-body, so have to include it :(
  @include font-base(
    $size: rem(18),
    $line-height: 1.55em,
    $family: $brand-font-body,
    $color: $color-base-inverted,
    $color-a: $color-base,
    $color-a-hover: $color-bkgrd-secondary,
    $a-underline-height: em(1, 18)
  );

  display: block;
  background-color: transparent;

  @include media($vp-s-to-m) {
    font-size: rem(17);
  }

  @include media($vp-s-down) {
    font-size: rem(16);
    line-height: 1.4em;
  }
}

@mixin label-slide() {
  @include responsive-spacing(padding, null null null spacing-xs);
  top: -0.5rem;
  color: $color-secondary;
  display: block;
  font-size: em(14);
  position: absolute;
  left: 0;
  margin: 0 !important;
  text-transform: none;
  opacity: 0;
  transition: all 0.1s linear;

  &.entered,
  &.selected {
    opacity: 1;
    top: -0.9rem;
  }
}

@mixin password-error() {
  @include responsive-spacing(margin, spacing-xxs null null spacing-xs);
  position: relative;
  padding-left: em(20);
  font-size: em(14);
  color: $color-border-primary;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    bottom: rem(1);
    display: block;
    height: rem(15);
    width: rem(15);
    background-image: url('/images/theme-definitive/dt_mini-checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

@mixin password-reveal() {
  position: absolute;
  top: 50%;
  right: $spacing-xs;
  height: rem(26);
  width: rem(28);
  background-image: url('/images/ui-glyphs/hide-password.png');
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: contain;
  border: 0;
  transform: translateY(-50%);
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &.password-revealed {
    background-image: url('/images/ui-glyphs/show-password.png');
  }
}

@mixin label-styles {
  @include brand-font-body();
  @include responsive-spacing(padding, null null null spacing-xs);
  display: block;
  padding-left: $spacing-xs;
  font-size: em(14);
  color: $color-secondary-light;
}

@mixin form-text() {
  label {
    @include label-styles();
  }
  .email-input {
    @include brand-font-body(0, 0);
    @include input-base();
    @include input-bottom-border();
    display: block;
    width: 100%;
    padding: rem(6) $spacing-xs;
  }
  .password-input {
    @include input-bottom-border();
    padding: rem(6) $spacing-xs;
    &.password-error {
      @include input-bottom-border($color-border-primary);
      +.password-warning {
        position: relative;
        margin-top: $spacing-xxs;
        margin-left: $spacing-xs;
        padding-left: $spacing-s;
        font-size: rem(14);
        color: $color-border-primary;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: rem(1);
          display: block;
          height: rem(15);
          width: rem(15);
          background-image: url('/images/theme-definitive/dt_mini-checkmark.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    input {
      @include brand-font-body(0, 0);
      @include input-base();
      display: inline-block;
      width: 90%;
    }
    button {
      float: right;
      height: rem(26);
      width: rem(28);
      background-image: url('/images/ui-glyphs/hide-password.png');
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: 0;

      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
      &.password-revealed {
        background-image: url('/images/ui-glyphs/show-password.png');
      }
    }
  }
}


/*  Success Bars
---------------------------------- */

@mixin success-bar($color, $bkcolor: $color-bkgrd-secondary, $icon: url('/images/theme-definitive/dt_checkmark.svg')) {
  @include brand-font-body(0, 0);
  position: relative;
  @include responsive-spacing(padding, spacing-s spacing-xs spacing-s spacing-l);
  background-color: $bkcolor;
  border: 1px solid $color;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: rem(20);
    height: rem(25);
    width: rem(30);
    margin-right: rem(25);
    background-image: $icon;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@mixin error-bar() {
  @include success-bar(
    mix(white, $color-border-primary, 50%),
    rgb(255, 238, 226), url('/images/theme-definitive/dt_exclamation.svg'));
}

/*  Radio & Checkbox Buttons
---------------------------------- */

@mixin radio-check-label() {
  position: relative;
  padding-left: rem(25);
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: rem(16);
    width: rem(16);
    transition: background 0.15s ease-in-out;
    transform: translateY(-57%);
  }
}

@mixin radio-checkbox-shared() {
  label {
    @include radio-check-label;
  }
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }
}

@mixin radio-label($btn-color: $color-secondary) {
  &:before,
  &:after {
    border-radius: 50%;
  }
  &:after {
    border: rem(1) solid $btn-color;
  }
}

@mixin checkbox-label() {
  color: transparentize($color-base, 0.6);
  transition: color 0.15 ease-in-out;
  &:before {
    border: 1px solid transparentize($color-base, 0.6);
    border-radius: rem(3);
  }
  &:after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
  }
}

@mixin radio-state($btn-color: $color-secondary, $select-color: $color-primary) {
  &[type="radio"] {
    &:checked {
      & ~ label {
        &:before {
          background-color: $select-color;
          border: rem(3.5) solid $color-base-inverted;
        }
      }
    }
  }
  &[disabled] {
    & + label {
      color: transparentize($color-base, 0.6);
      cursor: default;
      &:after {
        border-color: transparentize($btn-color, 0.6);
      }
    }
  }
}

@mixin checkbox-state() {
  &[type="checkbox"] {
    &:checked {
      & ~ label {
        color: $color-base;
        &:before {
          background-color: $color-primary;
          border-color: $color-primary;
        }
        &:after {
          background-image: url('/images/ui-glyphs/white-checkbox-small.png');
        }
      }
    }
  }
}

@mixin radio-btn() {
  @include radio-checkbox-shared();
  @include radio-state($color-base, $color-base);
  label {
    @include radio-label();
  }
}

@mixin radio-btn-alt() {
  @include radio-checkbox-shared();
  @include radio-state($color-base, $color-base);
  label {
    @include radio-label();
  }
  position: relative;
  display: inline-block;
  margin-left: rem(9);
  padding: rem(5) $spacing-xxs rem(3) rem(6);
  background-color: transparentize($color-secondary-light, 0.8);
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -9px;
    width: 0;
    height: 0;
    border-bottom: rem(13) solid transparent;  /* left arrow slant */
    border-top: rem(13) solid transparent; /* right arrow slant */
    border-right: rem(9) solid transparentize($color-secondary-light, 0.8);
  }
}

@mixin checkbox() {
  @include radio-checkbox-shared();
  @include checkbox-state();
  label {
    @include checkbox-label();
  }
}


/*  Dropdowns
---------------------------------- */

@mixin dropdown-button($color: transparentize($color-secondary-light, 0.5)) {
  @include brand-font-body(0, 0);
  display: block;
  position: relative;
  width: 100%;
  padding: $spacing-xs $spacing-l $spacing-xs $spacing-s;
  font-family: $brand-font-primary;
  color: $color-secondary-light;
  line-height: 1;
  text-transform: uppercase;
  text-align: left;
  background-color: transparent;
  border: 1px solid $color;
  cursor: pointer;
  outline: none;

  &:after {
    content: "\25BE";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: $spacing-xxs;
    font-family: $brand-font-primary;
    font-size: rem(14);
    color: $color;
    transform: translateY(-50%);
    animation: dropdown-flip-reverse 0.4s linear;
  }

  &.show-dropdown {
    border-bottom-width: 1;
  }

  &.show-dropdown:after {
    transform: translateY(-50%) rotate(180deg);
    animation: dropdown-flip 0.2s linear;
  }

  &.filter-selected {
    font-weight: $weight-medium;
  }
}

@mixin dropdown-button-alt() {
  @include dropdown-button($color-primary);
  font-family: $brand-font-secondary;
}

@mixin dropdown-content($border-color: transparentize($color-secondary-light, 0.5), $color: $color-secondary-light, $font: $brand-font-primary) {
  ul {
    display: none;
    visibility: hidden;
    &.show-dropdown {
      display: block;
      visibility: visible;
      li {
        font-size: rem(16);
        opacity: 1;
        transform: translateY(0%);
        transition: all 0.15s ease-in;
      }
    }
  }
  li {
    @include brand-font-body(0, 0);
    position: relative;
    padding: $spacing-xs $spacing-s $spacing-xs rem(30);
    font-family: $font;
    font-size: rem(12);
    color: $color;
    line-height: 1;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid $border-color;
    border-bottom-width: 0;
    cursor: pointer;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.15s ease-in;
    &:first-child {
      border-top-width: 0;
      transform: translateY(0%);
    }
    &:last-child {
      border-bottom-width: 1px;
    }
  }
}

@mixin dropdown-list($color: $color-secondary-light, $border-color: transparentize($color-secondary-light, 0.5)) {
  @include brand-font-body();
  @include responsive-spacing(padding, spacing-xxs spacing-m spacing-xxs spacing-xs);
  // @include responsive-spacing(margin, null null);
  margin: 0;
  position: relative;
  font-family: $brand-font-primary;
  font-size: rem(14);
  color: $color;
  line-height: 1;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid $border-color;
  border-bottom-width: 0;
  cursor: pointer;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.15s ease-in;
  &:first-child {
    border-top-width: 0;
    transform: translateY(0%);
  }
  &:last-child {
    border-bottom-width: 1px;
  }
}

@keyframes dropdown-flip {
  from {
    transform: translateY(-50%) rotateZ(0);
  }
  75% {
    transform: translateY(-50%) rotateZ(-180deg);
  }
  to {
    transform: translateY(-50%) rotateZ(-180deg);
  }
}
@keyframes dropdown-flip-reverse {
  from {
    transform: translateY(-50%) rotateZ(-180deg);
  }
  75% {
    transform: translateY(-50%) rotateZ(-360deg);
  }
  to {
    transform: translateY(-50%) rotateZ(-360deg);
  }
}


/*  Placeholder Text
---------------------------------- */
textarea::placeholder,
input::placeholder {
  color: #333333;
}


/*  IE: Placeholder Text
---------------------------------- */
input:-ms-input-placeholder {
  color: #333333 !important;
}

input::-ms-input-placeholder {
  color: #333333 !important;
}
