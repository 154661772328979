/* ----------------------------------
    Timeline Horizontal Block
---------------------------------- */


.timeline-carousel {


  /*  Lazy Load Images
  ---------------------------------- */

  img {

    /* fade in image when loaded */
    transition: opacity 0.4s ease-in-out;
    opacity: 0;

    &.flickity-lazyloaded,
    &.flickity-lazyerror {
      opacity: 1;
    }
  }


  /*  Customize Flickity Styles
  ---------------------------------- */

  .flickity-prev-next-button {

    &.previous {
      // margin-right: rem(50);
    }

    &.next {
      // margin-left: rem(50);
    }

    @include media($vp-m-down) {
      top: auto;
      width: rem(45);
      height: rem(45);
      bottom: rem(10);
      transform: translateY(0);

      &.next {
        right: 50%;
        transform: translateX(120%);
      }

      &.previous {
        left: 50%;
        transform: translateX(-120%);
      }
    }
  }


  /*  Card Styles
  ---------------------------------- */

  .timeline-card {
    @include responsive-spacing(padding, spacing-m null spacing-xl);
    bottom: 0;
    width: 45%;
    margin: 0 5%;


    /*  Shared Styles: Card with Text and Only Image ---- */

    .full-card,
    .table-card {
      position: relative;
      min-height: rem(450);

      /* Card bottom icon default layout */

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        background-color: mix(black, $color-border-primary, 20%);
      }

      &:before {
        bottom: rem(-50);
        height: rem(10);
        width: rem(10);
        border-radius: 50%;
      }

      &:after {
        bottom: rem(-45);
        height: rem(45);
        width: rem(1);
      }
    }


    /*  Card with Only Image Styles ---- */

    .full-card {

      img {
        width: 100%;
        margin: 0 auto;
        box-shadow: 0 0 20px -5px $color-secondary-light;
        border: 1px solid $color-border-primary;
      }

      &:after {
        background-color: transparent;
      }
    }


    /*  Card with Text Styles ---- */

    .table-card {
      display: table;
      background: white;
      box-shadow: 0 0 20px -5px $color-secondary-light;
      border: 1px solid $color-border-primary;


      /*  Image is masked and occupies its entire cell */

      .image-mask {
        display: table-cell;
        border-collapse: collapse;
        position: relative;
        height: 100%;
        width: 50%;
        vertical-align: top;
        overflow: hidden;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
      }

      figcaption {
        @include responsive-spacing(padding, spacing-s spacing-s);
        display: table-cell;
        width: 100%;
        vertical-align: top;
      }

      .cta-atom {
        a {
          @include responsive-spacing(margin, null null spacing-xxs);
        }
      }
    }


    /*  Dark Mode  */

    &[data-night-mode="true"] {

      .table-card {
        background: darken($color-base, 20%);
      }
    }
  }


  /*   Timeline Tick Styles
  ---------------------------------- */

  .timeline-ticks {
    position: absolute;
    bottom: rem(70);
    width: 100%;
    background-size: contain;
    height: rem(10);
    background-image: url('/assets/images/ui/timeline-tick.svg');
  }


  /*   Toggle All Background Styles
  ---------------------------------- */

  [data-showcard="false"] {
    .full-card,
    .table-card {
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;

      &:before {
        background-color: mix(black, $color-border-primary, 20%);
      }

      &:after {
        background-color: transparent;
      }

      img {
        border-color: transparent;
        box-shadow: none;
      }
    }

    &[data-night-mode="true"] {
      .table-card {
        box-shadow: none;
      }

      .full-card,
      .table-card {
        border-color: transparent;
        background-color: transparent;

        &:before {
          background-color: mix(black, $color-border-primary, 20%);
        }

        &:after {
          background-color: transparent;
        }

        img {
          border-color: transparent;
          box-shadow: none;
        }

        .wysiwyg {
          h1,
          h2,
          h3,
          h4,
          .h-huge,
          .h-normal,
          .h-small,
          .h-tiny,
          .h-very_tiny,
          p { color: $color-base; }
        }

        .delimiter {
          color: $color-base;
        }
      }
    }
  }


  /*  Media Query Adjustments
  ---------------------------------- */
  @media screen and (max-width: em(1300)) {
    .timeline-card {
      width: 55%;
    }
  }

  @include media($vp-l-to-xl) {
    .timeline-card {
      padding-bottom: 119px;
    }
  }

  @include media($vp-l-down) {
    .timeline-card {
      width: 65%;
      padding-bottom: 119px;

      .table-card {
        display: block;

        .image-mask {
          display: block;
          width: 100%;

          &:before {
            bottom: rem(-51);
          }

          &:after {
            bottom: rem(-45);
            width: rem(1);
          }

          img {
            position: relative;
            top: auto;
            left: auto;
            height: auto;
            max-width: 100%;
            transform: none;
          }
        }

        figcaption {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  @include media($vp-m-down) {
    .timeline-card {
      width: 90%;
      margin: 0 5%;
    }
  }
}
