/* ----------------------------------
   Instagram Gallery Block
---------------------------------- */

[data-block-type='instagram-gallery'] {

  /*  Heading area
  ---------------------------------- */

  .header {
    @include responsive-spacing(margin, null null spacing-m);
  }

  h3 {
    @include brand-font-medium;
  }

  .name {
    @include font-base(
      $size: rem(20),
      $line-height: 1.15em,
      $weight: $weight-regular,
      $weight-b: $weight-medium,
      $color: $brand-gray
    );

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 1.5em;
      margin-right: 0.5em;
      fill: #333;
      transition: 0.8 $animation-default;
    }

    @include hover {

      svg {
        fill: #000;
      }
    }
  }

  /*  Night Mode ---- */

  &[data-night-mode="true"] .header {

    h3 {
      color: rgba(255, 255, 255, 0.6);

      b,
      strong {
        color: white;
      }
    }

    a {
      color: rgba(255, 255, 255, 0.6);

      svg {
        fill: rgba(255, 255, 255, 0.6);
      }

      @include hover {
        color: white;

        svg {
          fill: white;
        }
      }
    }
  }

  .content-grid {

    /*  Grid Layout
    ---------------------------------- */

    li {
      @include media($vp-m-down) {
        @include span-columns(3 of 6);
        @include omega(2n);

        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }
    }

    &[data-column-count='four'] {

      li {

        /* Desktop */
        @include media($vp-xl-up) {
          @include span-columns(3 of 12);
          @include omega(4n);

          &:nth-last-child(-n+4) {
            margin-bottom: 0;
          }
        }

        /* Tablet */
        @include media($vp-m-to-xl) {
          @include span-columns(3 of 9);
          @include omega(3n);

          &:nth-last-child(-n+3) {
            margin-bottom: 0;
          }
        }
      }
    }

    &[data-column-count='three'] {

      li {

        /* Desktop */
        @include media($vp-xl-up) {
          @include span-columns(4 of 12);
          @include omega(3n);
        }

        /* Tablet */
        @include media($vp-m-to-xl) {
          @include span-columns(3 of 9);
          @include omega(3n);
        }

        &:nth-last-child(-n+3) {

          @include media($vp-m-up) {
            margin-bottom: 0;
          }
        }
      }
    }

    &[data-column-count='two'] {

      @include media($vp-xl-up) {

        li {
          @include span-columns(6 of 12);
          @include omega(2n);
        }
      }

      @include media($vp-m-to-xl) {

        li {
          @include span-columns(4.5 of 9);
          @include omega(2n);
        }
      }

      @include media($vp-m-up) {

        li:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }
    }


    /*  Content Styling
    ---------------------------------- */

    li {
      @include responsive-spacing(margin, null null spacing-s);

      img {
        width: 100%;
      }
    }
  }
}
