/* ----------------------------------
		Mixins for controlling spacing
		of elements based on the theme's
		variables.

    Blatently taken mostly from Bourbon's mixin: https://github.com/thoughtbot/bourbon/blob/v4-stable/app/assets/stylesheets/helpers/_directional-values.scss
---------------------------------- */

@mixin responsive-spacing($pre, $vals...) {

  // Property Names
  $top:    $pre + "-top";
  $right:  $pre + "-right";
  $bottom: $pre + "-bottom";
  $left:   $pre + "-left";
  $all:    $pre;

  // Noramlizing the vals so that they behave like normal css shorthand
  // eg (100px 50px) -> margin top & bottom = 100px, and margin left & right = 50px
  $vals: clean-spacing($vals);

  // Assigning each val a piece of logic to execute on.

  @if nth($vals, 1) {
    @include media($vp-xl-up)   {  #{$top}: spacing(nth($vals, 1), desktop); }
    @include media($vp-m-to-xl) {  #{$top}: spacing(nth($vals, 1), tablet);  }
    @include media($vp-m-down)  {  #{$top}: spacing(nth($vals, 1), mobile);  }
  }

  @if length($vals) == 1 {
    @if nth($vals, 1) {
      @include media($vp-xl-up)   {  #{$right}: spacing(nth($vals, 1), desktop); }
      @include media($vp-m-to-xl) {  #{$right}: spacing(nth($vals, 1), tablet);  }
      @include media($vp-m-down)  {  #{$right}: spacing(nth($vals, 1), mobile);  }
      @include media($vp-xl-up)   {  #{$bottom}: spacing(nth($vals, 1), desktop); }
      @include media($vp-m-to-xl) {  #{$bottom}: spacing(nth($vals, 1), tablet);  }
      @include media($vp-m-down)  {  #{$bottom}: spacing(nth($vals, 1), mobile);  }
      @include media($vp-xl-up)   {  #{$left}: spacing(nth($vals, 1), desktop); }
      @include media($vp-m-to-xl) {  #{$left}: spacing(nth($vals, 1), tablet);  }
      @include media($vp-m-down)  {  #{$left}: spacing(nth($vals, 1), mobile);  }
    }
  } @else {
    @if nth($vals, 2) {
      @include media($vp-xl-up)   {  #{$right}: spacing(nth($vals, 2), desktop); }
      @include media($vp-m-to-xl) {  #{$right}: spacing(nth($vals, 2), tablet);  }
      @include media($vp-m-down)  {  #{$right}: spacing(nth($vals, 2), mobile);  }
    }
  }

  @if length($vals) == 2 {
    @if nth($vals, 1) {
      @include media($vp-xl-up)   {  #{$bottom}: spacing(nth($vals, 1), desktop); }
      @include media($vp-m-to-xl) {  #{$bottom}: spacing(nth($vals, 1), tablet);  }
      @include media($vp-m-down)  {  #{$bottom}: spacing(nth($vals, 1), mobile);  }
    }
    @if nth($vals, 2) {
      @include media($vp-xl-up)   {  #{$left}: spacing(nth($vals, 2), desktop); }
      @include media($vp-m-to-xl) {  #{$left}: spacing(nth($vals, 2), tablet);  }
      @include media($vp-m-down)  {  #{$left}: spacing(nth($vals, 2), mobile);  }
    }
  } @else if length($vals) == 3 {
    @if nth($vals, 3) {
      @include media($vp-xl-up)   {  #{$bottom}: spacing(nth($vals, 3), desktop); }
      @include media($vp-m-to-xl) {  #{$bottom}: spacing(nth($vals, 3), tablet);  }
      @include media($vp-m-down)  {  #{$bottom}: spacing(nth($vals, 3), mobile);  }
    }
    @if nth($vals, 2) {
      @include media($vp-xl-up)   {  #{$left}: spacing(nth($vals, 2), desktop); }
      @include media($vp-m-to-xl) {  #{$left}: spacing(nth($vals, 2), tablet);  }
      @include media($vp-m-down)  {  #{$left}: spacing(nth($vals, 2), mobile);  }
    }
  } @else if length($vals) == 4 {
    @if nth($vals, 3) {
      @include media($vp-xl-up)   {  #{$bottom}: spacing(nth($vals, 3), desktop); }
      @include media($vp-m-to-xl) {  #{$bottom}: spacing(nth($vals, 3), tablet);  }
      @include media($vp-m-down)  {  #{$bottom}: spacing(nth($vals, 3), mobile);  }
    }
    @if nth($vals, 4) {
      @include media($vp-xl-up)   {  #{$left}: spacing(nth($vals, 4), desktop); }
      @include media($vp-m-to-xl) {  #{$left}: spacing(nth($vals, 4), tablet);  }
      @include media($vp-m-down)  {  #{$left}: spacing(nth($vals, 4), mobile);  }
    }
  }


}
