/* ----------------------------------
    Fancy Select element
---------------------------------- */

.fancy_select {
  position: relative;
  display: block;
  width: 100%;

  select {
    display: block;
    width: 100%;
    background-image:none;
    background-color:transparent;
    box-shadow: none;
    appearance: none;
    @include font-base(
      $size: rem(18)
    );
    border-radius: 0;
    border: rem(1) solid $color-base;
    padding: 0.3em em(35, 18) 0.3em 0.8em;
  }

  &:before {
    content: '';
    position: absolute;
    width: rem(25);
    height: rem(25);
    top: 50%;
    right: em(8, 18);
    transform: translateY(-49%) rotate(180deg);
    background-image: url('/assets/images/theme/link-carrot-up.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
