/* ----------------------------------
    Foundational Image - Content Block Styles
---------------------------------- */

[data-block-type="image"] {

  .foundation-container {

    img {
      width: 100%;
    }
  }
}
