/* ----------------------------------
    Tripple Callout Block
---------------------------------- */

[data-block-type="tripple_callout"] {


  .bound {

    @include media($vp-m-up) {
      display: flex;
      align-items: center;
    }
  }


  /*  Main Pane
  ---------------------------------- */

  .main_pane {
    position: relative;
    @include responsive-spacing(padding, spacing-xl null);
    border-radius: 0 rem(3) rem(3) 0;

    /* Tablet */
    @include media($vp-m-up) {
      flex-shrink: 0;
      @include span-columns(4 of 9);
      margin-right: 0;
      float: none;
    }

    /* Desktop */
    @include media($vp-xl-up) {
      @include span-columns(5 of 10);
      margin-right: 0;
      float: none;
    }

    .graphic {
      @include device-frames;
      width: 80%;

      img,
      video {
        width: 100%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30vw;
      right: 99.9%;
      background-color: inherit;
    }
  }


  /*  Text (Content) Pane
  ---------------------------------- */

  .text_pane {
    position: relative;
    z-index: 2;
    margin-top: -($spacing-m);
    @include responsive-spacing(padding, spacing-l null spacing-xxl);
    padding-left: spacing(spacing-s, mobile);
    border-radius: rem(3) 0 0 rem(3);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30vw;
      left: 99.9%;
      background-color: inherit;
    }

    @include media($vp-m-up) {
      padding-left: spacing(spacing-m, tablet);
      border-radius: 0;
    }

    @include media($vp-xl-up) {
      padding-left: spacing(spacing-l, desktop);
    }
  }


  /*  Secondary image

      This one is odd cause it's size
      is CMS based and weird...
  ---------------------------------- */

  .secondary_img {
    position: relative;
    z-index: 3;
    margin-top: -(spacing(spacing-m, mobile));

    @include media($vp-m-up) {
      margin-top: -(spacing(spacing-xl, tablet));
      margin-left: -5%;
    }

    @include media($vp-xl-up) {
      margin-top: -(spacing(spacing-xl, desktop));
      margin-left: -8%;
    }

    @include media($vp-max-up) {
      margin-left: -10%;
    }

    img {
      width: 100%;
    }

    /*  Width ---- */

    &.smaller {

      @include media($vp-m-up) {
        width: 75%;
      }
    }

    &.full {

      @include media($vp-m-up) {
        width: 120%;
      }

      @include media($vp-xl-up) {
        width: 118%;
      }

      @include media($vp-max-up) {
        width: 115%;
      }
    }

    &.toEdge {
      width: 114%;

      @include media($vp-m-up) {
        width: 134%;
      }

      @include media($vp-l-up) {
        width: 158%;
      }

      @include media($vp-max-up) {
        width: 155%;
      }
    }
  }
}
