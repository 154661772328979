/* ----------------------------------
    Screen Shots Grid

    Note: the layout for this Block
    is defined withing Content Grid.
---------------------------------- */

[data-block-type='screen_shots_grid'] {

  .graphic {
    @include device-frames;
  }

  h4 {
    @include brand-font-body(
      $size-d: rem(15),
      $size-t: rem(15),
      $size-m: rem(14),
      $include_margin: false
    );
    @include responsive-spacing(padding, spacing-m null null);
    text-align: center;
  }
}
