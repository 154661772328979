/* ----------------------------------
		Shared Buttons
---------------------------------- */

$inactive-button: 0.4;


/* 	Text Links w/ Icons
---------------------------------- */

@mixin link-icon-base(
  $display: inline-block,
  $size: em(20),
  $color: $color-primary,
  $color-hover: $color-secondary,
  $line-height: 1.1em,
  $icon: null
  ){

  @include font-base (
    $size: $size,
    $line-height: $line-height,
    $weight: $weight-regular,
    $color: $color
  );
  display: $display;
  transition: color 200ms, background-color 200ms;
  padding-right: 1.25em;
  cursor: pointer;

  &:after {
    content: "";
    display: inline-block;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    margin: -0.2em -1.25em 0 0.25em;
    background-image: url($icon);
    background-size: 100% auto;
    background-repeat: no-repeat;
    transition: opacity 200ms;
  }

  @include media($vp-s-to-m) {
    font-size: scale-stops($size, em(16), 4, 3);
  }

  @include media($vp-s-down) {
    font-size: scale-stops($size, em(16), 4, 2);
  }

  /*  Focus & Hover ---- */

  @include hover(){
    color: $color-hover;

    &:after {
      opacity: 0.6;
    }
  }
}


/* 	Base Button Mixin
---------------------------------- */

@mixin button-base (
  $display: inline-block,
  $opacity: 1,
  $size: 1em,
  $padding-tb: $spacing-xxs-em,
  $padding-lr: $spacing-xs-em,
  $color: white,
  $color-hover: white,
  $text-transform: capitalize,
  $bgcolor: $color-primary,
  $bgcolor-hover: $color-secondary,
  $border-width: rem(2),
  $bordercolor: transparent,
  $bordercolor-hover: transparent,
  $border-radius: rem(3)
  ){
  @include font-base (
    $size: $size,
    $line-height: 1,
    $weight: $weight-regular,
    $color: $color
  );
  display: $display;
  opacity: $opacity;
  padding: $padding-tb $padding-lr;
  text-transform: $text-transform;
  border-width: $border-width;
  border-style: solid;
  border-color: $bordercolor;
  border-radius: $border-radius;
  background-color: $bgcolor;
  background-image: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms, border-color 200ms, background-color 200ms, border-color 100ms;
  cursor: pointer;


  /*  Focus & Hover ---- */

  @include hover() {
    color: $color-hover;
    background-image: none;
    text-decoration: none;
    background-color: $bgcolor-hover;
    border-color: $bordercolor-hover;
  }


  /* 	Inactive State ---- */

  &.inactive,
  &:disabled {
    opacity: $inactive-button;
    cursor: default;
    user-select: none;

    @include hover(){
      color: $color;
      border-color: $bordercolor;
      background-color: $bgcolor;
    }
  }
}


/* 	Disabled submit buttons
    (angular kicks this in if only 1 character is inputted into the adjoinging search field)
---------------------------------- */
a.submit[disabled="disabled"] {
  cursor: default;
  opacity: 0.4;

  @include hover(){
    color: inherit !important;
  }
}


/* ----------------------------------
    Close Button
---------------------------------- */

@mixin btn-close {
  cursor: pointer;

	i {
		@include hide-visually;
	}

	.icon {
    position: relative;
		display: inline-block;
    width: em(50);
    height: em(50);

    @include media($vp-l-down) {
      width: em(40);
      height: em(40);
    }

    @include media($vp-m-down) {
      width: em(30);
      height: em(30);
    }

	  &:before,
	  &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: em(2);
      width: 100%;
      background-color: $color-primary;
      transition: background-color $animation-timing $animation-easing;
	  }

	  &:before {
		  transform: rotate(-225deg);
	  }

	  &:after {
		  transform: rotate(225deg);
	  }
	}

	/*  Focus & Hover State
	---------------------------------- */

  @include hover(){

    .icon:after,
    .icon:before {
      background-color: $color-primary-dark;
    }
  }
}


/* ----------------------------------
    Buttons with Icons
---------------------------------- */

@mixin icon-button(
  $display: inline-flex,
  $size: em(15),
  $mobilesize: em(15),
  $weight: $weight-book,
  $color: #333,
  $color-hover: $color-primary,
  $line-height: 1.5em,
  $side: 'left',
  $icon: null
  ){

  @include font-base (
    $size: $size,
    $line-height: $line-height,
    $weight: $weight,
    $color: $color
  );
  display: $display;
  align-items: center;
  cursor: pointer;
  letter-spacing: $tracking-10;
  text-transform: uppercase;
  transition: color $animation-default;
  white-space: nowrap;
  border: none;
  outline: none;
  background: none;

  @include media($vp-l-down) {
    font-size: scale-stops($size, $mobilesize, 3, 2);
  }

  @include media($vp-s-down) {
    font-size: $mobilesize;
  }

  @if ($side == 'right') {

    &:after {
      content: "";
      height: 1.2em;
      width: 1.2em;
      vertical-align: middle;
      margin-left: 0.24em;
      background-image: url($icon);
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }

  @if ($side == 'left') {

    &:before {
      content: "";
      height: 1.2em;
      width: 1.2em;
      vertical-align: middle;
      margin-right: 0.24em;
      background-image: url($icon);
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }

  /*  Focus & Hover ---- */

  @include hover(){
    color: $color-hover;
  }
}



/*  Icon Button
---------------------------------- */


@mixin icon-only-button (
  $display: inline-block,
  $size: rem(25),
  $opacity: 0.8,
  $hover-opacity: 1,
  $icon: null
  ){

  @include hide-text();
  display: $display;
  height: $size;
  width: $size;
  align-items: center;
  cursor: pointer;
  opacity: $opacity;
  transition: opacity $animation-default;
  white-space: nowrap;
  border: none;
  outline: none;
  background: none;
  background-image: url($icon);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @include hover {
    opacity: $hover-opacity;
  }
}
