/* ----------------------------------
    Content Carousel Block
---------------------------------- */


/*  Content Block Styles
---------------------------------- */

[data-block-type="quote_carousel"] {

  .quote-carousel {
    @include responsive-spacing(padding, spacing-m spacing-xl);
    position: relative;
    text-align: center;

    img {
      @include omega();
      display: inline-block;
      max-width: 100%;
      margin: 0 auto;

      /* fade in image when loaded */
      transition: opacity 0.4s ease-in-out;
      opacity: 0;

      &.bypass-flickity {
        opacity: 1;
      }

      &.flickity-lazyloaded,
      &.flickity-lazyerror {
        opacity: 1;
      }

      &[data-image-size="icon"] {
        max-width: 15%;
      }
      &[data-image-size="small"] {
        max-width: 30%;
      }
      &[data-image-size="medium"] {
        max-width: 65%;
      }
      &[data-image-size="large"] {
        max-width: 100%;
      }
    }

    cite {
      position: relative;
      display: block;
      margin: 0 auto;
    }

    &[data-font-size="large"] {
      blockquote {
        @include brand-font-small();
        font-size: rem(55);
      }
    }

    &[data-font-size="medium"] {
      blockquote {
        @include brand-font-small();
        font-size: rem(20);
      }
    }

    &[data-font-size="small"] {
      blockquote {
        @include brand-font-small();
      }
    }

    &[data-font-size="tiny"] {
      blockquote {
        @include brand-font-small();
        font-size: $base-font-size;
      }
    }


    /*  Quote Component Styles
    ---------------------------------- */

    .quote-carousel-quote {
      width: 60%;
      margin: 0 auto;
      text-align: center;


      /*  Default Text Styles ---- */

      blockquote {
        @include responsive-spacing(margin, spacing-m null spacing-xs);
        position: relative;


        /* Quote Marks */

        &:before,
        &:after {
          content: "";
          position: absolute;
          display: block;
          height: rem(50);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        &:before {
          top: 0;
          left: 0;
        }
        &:after {
          right: 0;
        }
      }


      /*  Layout with a 'Main Image' ---- */

      // z-index to keep the quotes under the image
      &[data-quote-layout="1"] {
        display: block;
        position: relative;
        z-index: 0;

        .quote-carousel-main {
          display: block;
          margin: 0 auto;
          z-index: 0;
        }

        blockquote {
          display: block;
          z-index: -1;
        }
      }
    }


    /*  Citation Component Styles
    ---------------------------------- */

    .quote-carousel-citation {
      text-align: center;

      .cite-person {
        @include brand-font-very-tiny();
        @include responsive-spacing(margin, spacing-s null spacing-xxs);
        font-size: $base-font-size;
      }

      .cite-company {
        @include brand-font-tiny();
        @include responsive-spacing(margin, null null spacing-xxs);
      }

      .cite-image {
        @include responsive-spacing(margin, spacing-s null null);
        img {
          display: block;
          margin: 0 auto;
          max-width: 120px;
        }
      }
    }

    .cta-atom {
      @include responsive-spacing(margin, spacing-s null null);
      position: relative;
    }


    /*  Media Query Adjustments
    ---------------------------------- */

    @media screen and (max-width: 1600px) {

      .quote-carousel-quote {
        width: 80%;
      }
    }

    @include media($vp-xl-down) {

      .quote-carousel-quote {
        width: 100%;
      }
    }

    @include media($vp-m-down) {
      @include responsive-spacing(padding, spacing-m spacing-s);

      .quote-carousel-quote {

        blockquote {
          padding-top: rem(60);
          padding-bottom: rem(60);
        }
      }
    }

    @include media($vp-s-down) {

      .quote-carousel-quote {

        blockquote {
          padding: rem(80) 0;
        }
      }
    }
  }


  /*  Dark & Light Theme Styles
  ---------------------------------- */

  &[data-night-mode="false"] {
    background-color: $color-base-inverted;

    .add-opacity {
      background-color: $color-base-inverted;
    }

    .quote-carousel {
      .quote-carousel-quote {
        blockquote {
          color: $color-base;
        }
      }
    }

    .quote-carousel-citation {
      color: $color-base;
    }

    .cta-atom {
      .delimiter {
        color: $color-base;
      }
    }
  }

  &[data-night-mode="true"] {
    background-color: $color-secondary-dark;

    .add-opacity {
      background-color: $color-base;
    }

    .quote-carousel-quote {
      blockquote {
        color: $color-base-inverted;
      }
    }

    .quote-carousel-citation {
      color: $color-base-inverted;

      .cite-person {
        color: $color-base-inverted;
      }

      .cite-company {
        color: $color-base-inverted;
      }
    }

    .cta-atom {
      .delimiter {
        color: $color-base-inverted;
      }
    }
  }


  /*  Content Block Styles when inside a Flickity
  ---------------------------------- */

  .content-carousel {
    @include responsive-spacing(padding, spacing-m null spacing-l);
    opacity: 0;

    /*  Reveal Slider when Loaded
    ---------------------------------- */

    &.flickity-enabled {
      opacity: 1;
    }

    .quote-carousel {
      @include responsive-spacing(padding, spacing-xs spacing-m);
      margin: 0;
      width: 60%;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 200ms ease-in-out;
      opacity: 0.4;

      .quote-carousel-quote {
        width: 100%;
      }

      &.is-selected {
        opacity: 1;
      }
    }


    /*  Media Query Adjustments
    ---------------------------------- */

    @include media($vp-xl-down) {
      .quote-carousel-quote {
        width: 66%;
      }
    }
    @include media($vp-l-down) {
      .quote-carousel {
        width: 100%;
      }
    }
    @include media($vp-s-down) {
      .quote-carousel {
        padding-left: rem(20);
        padding-right: rem(20);
      }
    }
  }


  /*  Content Block Styles Shared: in a Flickety or Not
  ---------------------------------- */

  .content-carousel,
  .quote-carousel {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .add-opacity {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
